/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import MainPage from "./pages/MainPage";
import { BrowserRouter as Router, useRoutes, Navigate, HashRouter } from "react-router-dom";
import AllStudents from "./pages/StudentsScreen/StudentsScreen";
import AllExams from "./pages/Exams/AllExam";
import FormEdit from "./Components/FormEdit/FormEdit";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from 'react';
import Loading from "./Modals/Loader/Loading";
import { getUser, isAuthenticated } from "./services/Firebase";
import LogIn from "./pages/LogIn/LogIn";
import ExamCenter from './Components/ExamCenter/ExamCenter'
import { readFile, WriteFile } from '../src/services/androidImports'

import Dialog from "@mui/material/Dialog";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

//* store
import action from "./Redux Store/actions";
import { useDispatch, useSelector } from "react-redux";
import SubExam from "./Components/SubExam/SubExam";
import { uploadFilesAWS } from "./services/AwsService";
import AttendancePage from "./pages/AttendancePage/AttendancePage";

import { registerPlugin } from '@capacitor/core'
import { DB } from "./db";
import axios from "axios";
import { dismissLoader, presentLoader } from "./services/loaderService";
const dialogStyle = {
  "& .MuiDialog-container": {
    background: "rgba(193, 201, 210, 0.7)"
  }
}
const { ipcRenderer } = window.location.hostname === '' ? window.require('electron') : { ipcRenderer: null }

function App() {
  const [isLoading, setIsLoading] = useState(false)
  // const Echo = registerPlugin('Echo');
  const dispatch = useDispatch();
  dispatch(action.authCheck());
  const isAuthenticated = useSelector((state) => state.Auth.isAuthenticated);
  useEffect(async () => {
    // if (window.location.hostname === '') {
    //   axios.get('http://127.0.0.1:5000/checkIfRunning').then((resp) => {

    //   }).catch((err) => {
    //     setIsLoading(true)
    //     ipcRenderer.send("startPranilExe", "");
    //     ipcRenderer.on("exeSuccessful", (event, arg) => {
    //       console.log("exeSuccessful", arg)
    //       if (arg == 'done') {
    //         setIsLoading(false)
    //       }
    //     })
    //   })
    // }
    // ipcRenderer.send("askForSystemInfo", "");
    // ipcRenderer.on("getSystemInfo", (event, arg) => {
    //   console.log("arg.homedir", arg.homedir)
    //   localStorage.setItem('indexedDbPath', arg.homedir)
    // })
    // const { value } = await Echo.echo({ value: 'Hello World!' })
    // console.log('Plugin:', value);
    // alert('Plugin:' + value);
    // const { bitmap } = await Echo.captureFingerprint({ value: "value" })
    // console.log('Mantra:', bitmap);
    // alert('Mantra :' + bitmap)
    // document.addEventListener("keydown", this.onKeyPressed.bind(this));
    // document.addEventListener('keydown', function (e) {
    //   // console.log(e.keyCode);
    //   if (e.keyCode === 123) {
    //     if (process.env.NODE_ENV === "production") {
    //       e.preventDefault();
    //     }
    //     return false;
    //   }
    //   if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
    //     e.preventDefault();
    //     return false;
    //   }
    //   if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
    //     e.preventDefault();
    //     return false;
    //   }
    //   if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
    //     e.preventDefault();
    //     return false;
    //   }
    // })

    // return () => {
    //   document.removeEventListener('keydown', function (e) {
    //     console.log(e);
    //   })
    // }
  }, [])

  const AppWrapper = () => {
    let routes = useRoutes([
      {
        path: '/', element: isAuthenticated ? <Navigate to='/home/student-verify' /> : <LogIn />,
      },
      {
        path: '/home', element: <MainPage />,
        children: [
          {
            path: '', element: isAuthenticated ? <Navigate to='/home/student-verify' /> : <Navigate to="/" />
          },
          {
            path: 'student-verify',
            element: isAuthenticated ? <AllExams /> : <Navigate to="/" />,

          },
          {
            path: 'student-verify/:examShortName/allSubExams/:subExamName/allExamCenters',
            element: isAuthenticated ? <ExamCenter /> : <Navigate to="/" />
          },
          {
            path: 'student-verify/:examShortName/allSubExams',
            element: isAuthenticated ? <SubExam /> : <Navigate to="/" />
          },
          {
            path: 'student-verify/:examShortName/allSubExams/:subExamName/allExamCenters/:examCenterName/allStudents',
            element: isAuthenticated ? <AllStudents /> : <Navigate to="/" />
          },
        ]
      },
      {
        path: 'home/student-verify/:examShortName/allSubExams/:subExamName/allExamCenters/:examCenterName/details',
        element: isAuthenticated ? <FormEdit /> : <Navigate to="/" />
      },
      {
        path: '/home/student-verify/:examShortName/allSubExams/:subExamName/allExamCenters/:examCenterName/attendance',
        element: isAuthenticated ? <AttendancePage /> : <Navigate to='/' />,
      },
    ]);
    return routes;
  }
  return (
    <div style={{ height: "100%" }}>
      <HashRouter>
        <AppWrapper />
      </HashRouter>
      <ToastContainer style={{ fontSize: "1.5rem" }} />
      <Loading />
      <StartLoader isLoading={isLoading} />
    </div>
  );
}

export default App;

function StartLoader({ isLoading }) {
  return (
    <Dialog
      sx={[dialogStyle]}
      // onClose={() => {setOpen(false)}} 
      open={isLoading}
    >
      <div style={{ padding: "4rem" }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CircularProgress />
          <p style={{ margin: '0', fontSize: '2rem', marginLeft: "1rem" }}>Starting Server this could take few minutes...</p>
        </Box>
      </div>
    </Dialog>
  );
}

// toDataURL(url, callback) {
//   var xhr = new XMLHttpRequest();
//   xhr.onload = function () {
//       var reader = new FileReader();
//       reader.onloadend = function () {
//           callback(reader.result);
//       }
//       reader.readAsDataURL(xhr.response);
//   };
//   xhr.open('GET', url);
//   xhr.responseType = 'blob';
//   xhr.send();
// }