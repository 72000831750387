import { initializeApp } from "firebase/app";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import 'firebase/compat/firestore'
import { enableIndexedDbPersistence, getFirestore } from "firebase/firestore";
import { initializeFirestore, CACHE_SIZE_UNLIMITED } from "firebase/firestore";
import { errorToast } from './services/Toast'
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRE_BASE_CONFIG_API_KEY,
  authDomain: process.env.REACT_APP_FIRE_BASE_CONFIG_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIRE_BASE_CONFIG_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIRE_BASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRE_BASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIRE_BASE_APP_ID,
  // measurementId:process.env.REACT_APP_FIRE_BASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);

const firestoreDb = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED
});
enableIndexedDbPersistence(getFirestore()).then((resp => {
  console.log('resp: ', resp);
})).catch((err) => {
  console.log(err);
  if (err.code === 'failed-precondition') {
    // Multiple tabs open, offline mode can only be enabled
    // in one tab at a a time.
    // ...
    errorToast("App already open in another tab")
  } else if (err.code === 'unimplemented') {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
    errorToast("Your browser does'nt support offline feature")
  }
});
export default firebase;