import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import cssModule from "./CreateModel.module.css";
import Box from "@mui/material/Box";

import { DataGrid } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
// import socketIOClient from "socket.io-client";
// import { adminAuthCreate } from '../../services/AdminsService';
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "../../Subcomponent/Button";
import { getFailedArray, replace } from "../../services/StudentService";
// import FailedEntires from "../FailedEntries/FailedEntries";
import * as XLSX from "xlsx";
// import Styles from "./FailedModel.module.css"
import { Timestamp } from "firebase/firestore";
import { errorToast } from "../../services/Toast";
import * as _ from 'lodash';
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "6px",
  outline: 0,
};

const useStyles = makeStyles((theme) => ({
  ButtonStyle: {
    width: "7rem",
    marginLeft: "auto",
    marginRight: "2rem",
    marginBottom: "1rem",
    marginTop: "1rem",
  },

  ProgressStyle: {
    backgroundColor: "#F9FAFB",
    padding: "2rem",
    border: "1px solid #EAECF0",
  },
  verticalLine: {
    borderLeft: "2px solid #D0D5DD",
    height: "30px",
    margin: "9px 0px 12px 13px ",
  },
  gridContainer: {
    display: "flex",
  },
  QuestionStyle: {
    margin: "1.2rem 1.8rem 1.5rem 3.5rem",
    fontFamily: "Inter-Regular",
    fontStyle: "normal",
    lineHeight: "2.4rem",
    fontSize: "1.3rem",
    fontWeight: "800",
  },
  Title: {
    fontFamily: "Inter-Regular",
    fontStyle: "normal",
    lineHeight: "2.4rem",
    fontSize: "1.6rem",
    color: "#344054",
    padding: "1.5rem 0rem 1.7rem 3rem",
    display: "flex",
    alignItems: "center",
  },
  ReplaceDiv: {
    padding: "1.5rem 0",
  },
  gridChild: {
    display: "flex",
    flexDirection: "column",
    padding: "0 0.9rem",
  },
  loader: {
    marginLeft: "2rem",
  },
  TextStyle: {
    fontFamily: "Inter-Regular",
    fontSize: "1.5rem",
    fontWeight: "800",
    lineHeight: "2.4rem",
    color: "#101828",
  },
  numberStyle: {
    fontFamily: "Inter-Regular",
    fontSize: "1.4rem",
    fontWeight: "100",
    lineHeight: "2rem",
    color: "#667085",
  },
  iconStyle: {
    color: "#7F56D9",
    width: "4rem",
    height: "5rem",
  },
  tickIcon: {
    // marginLeft:"17px",
  },
  UploadIcons: {
    margin: "0.3rem 0.5rem",
  },

  failedEntriesText: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center"
  },
  wrapperFailedEntries: {
    display: "flex",
    padding: "0rem 3.6rem 2rem 3.6rem",
    fontFamily: "Inter-Regular",
    fontSize: "1.3rem",
    fontWeight: "800",
    lineHeight: "2rem",
    marginTop: "1rem",
  },
  wrapperOpenModal: {
    marginLeft: "2rem",
  },
}));
const dataGridStyles = {
  fontWeight: "400",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  color: "#667085",
  fontFamily: "Inter-Medium",
  border: "none !important",
  '& div[data-colindex="1"]': {
    color: "#101828",
    fontWeight: "500",
  },
  '& div[data-StepperValue-colindex="7"]': {
    color: "#fff",
    fontWeight: "500",
  },
  "&  .MuiDataGrid-columnHeaders": {
    background: "#F9FAFB",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader": {
    padding: "0 4rem",

    "&:focus": {
      outline: "none",
    },
  },
  "&  .MuiDataGrid-row ": {
    //   maxHeight: "72px !important",
    //   minHeight: "72px !important",
  },
  "& .MuiDataGrid-cell": {
    padding: "0 4rem",
    //   minHeight: "72px !important",
    //   maxHeight: "72px !important",
    "&:focus": {
      outline: "none",
    },
  },
  "& .MuiButtonBase-root": {
    fontSize: "2rem",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "2rem",
  },
  "& .MuiDataGrid-footerContainer": {
    "& .MuiTablePagination-displayedRows ": {
      display: "none",
    },
    "& .MuiDataGrid-selectedRowCount": {
      visibility: "hidden ",
    },
  },
  "& .MuiPaginationRoot": {
    "& .MuiPagination-ul": {
      flexWrap: "nowrap",
      li: {
        "&:first-of-type": {
          flexBasis: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          "> button::after": {
            marginLeft: "10px",
            content: "'previous'",
          },
        },
        "&:last-of-type": {
          flexBasis: "100%",
          display: "flex",
          justifyCcontent: "flex-end",
          alignItems: "center",
          "> button::before": {
            marginRight: "10px",
            content: "'next'",
          },
        },
      },
    },
  },
};
export default function CreateModel({ handleClose, StepperValue, isOpenModal, photosProgress, firstProcessing, secondProcessing }) {

  const classes = useStyles();

  // const [addStudentModalToggle, setAddStudentModalToggle] = useState(false);

  // const [StepperValue, setStepperValue] = useState(0);
  // const [totalDataUploading, setTotalDataUploading] = useState(0);
  // const [addingStudentCount, setAddingStudentCount] = useState(0);
  // const [failedCandidatesArray, setFailedCandidatesArray] = useState([]);
  // const [duplicateCandidatesArray, setDuplicateCandidatesArray] = useState([]);

  // const [failedEntriesModal, setFailedEntriesModal] = useState(false);

  // function openFailedEntriesModal() {
  //   setFailedEntriesModal(true);
  // }

  function cleanupAndClose() {
    // setTotalDataUploading(0);
    // setAddingStudentCount(0);
    // setFailedCandidatesArray([]);
    // setDuplicateCandidatesArray([]);
    handleClose();
  }

  return (
    <>
      <Modal open={isOpenModal} aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className={classes.Title}>
            {" "}
            <div className={classes.UploadIcons}></div> Creating Model
          </div>

          <div className={classes.ProgressStyle}>
            <div
              className={classes.gridContainer}
              style={{ opacity: StepperValue >= 0 ? 1 : 0.7 }}
            >
              {StepperValue > 0 ? (
                <div className={classes.tickIcon}>
                  <CheckCircleIcon
                    sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                  />
                </div>
              ) : (
                <CircularProgress sx={{ color: "#7F56D9" }} size={30} />
              )}

              <div className={classes.gridChild}>
                <span className={classes.TextStyle}>
                  {" "}
                  Analyzing Images
                </span>
                <div className={classes.numberStyle}>
                  Progress : {firstProcessing} %
                </div>
              </div>
            </div>
            <div className={classes.verticalLine}></div>

            <div
              className={classes.gridContainer}
              style={{ opacity: StepperValue >= 1 ? 1 : 0.7 }}
            >
              {StepperValue > 1 ? (
                <div className={classes.tickIcon}>
                  <CheckCircleIcon
                    sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                  />
                </div>
              ) : (
                <CircularProgress sx={{ color: "#7F56D9" }} size={30} />
              )}

              <div className={classes.gridChild}>
                <span className={classes.TextStyle}>
                  {" "}
                  Processing Images
                </span>
                <div className={classes.numberStyle}>
                  Progress : {secondProcessing} %
                </div>
              </div>
            </div>
            <div className={classes.verticalLine}></div>

            <div
              className={classes.gridContainer}
              style={{ opacity: StepperValue >= 2 ? 1 : 0.7 }}
            >
              {StepperValue > 2 ? (
                <div className={classes.tickIcon}>
                  <CheckCircleIcon
                    sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                  />
                </div>
              ) : (
                <CircularProgress sx={{ color: "#7F56D9" }} size={30} />
              )}
              <div className={classes.gridChild}>
                <span className={classes.TextStyle}>
                  {" "}
                  Saving Model
                </span>
                {/* <div className={classes.numberStyle}>
                  Progress : {firstProcessing} %
                </div> */}
              </div>
            </div>

          </div>
          {/* { Duplicate Entries ?  */}
          {/* {StepperValue >= 3 &&
            <>
              <div className={classes.ButtonStyle}>
                <Button
                  style={{ color: "#ffffff", backgroundColor: "#6941c6" }}
                  btnName={"OK"}
                  btnIcon={false}
                  clicked={cleanupAndClose}
                />
              </div>
            </>
          } */}
          {/* <FieldMeritEntries openModal={failedEntriesModal} closeModal={() => { setFailedEntriesModal(false) }} failedCandidatesArray={failedCandidatesArray} /> */}
          {/* <FailedEntires openModal={failedEntriesModal} closeModal={() => { setFailedEntriesModal(false); }} failedCandidatesArray={failedCandidatesArray} /> */}
        </Box>
      </Modal>

    </>
  );
}
