import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import cssModule from "./DownloadStudentProgress.module.css";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "../../Subcomponent/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "6px",
  outline: 0,
};

const useStyles = makeStyles((theme) => ({
  ButtonStyle: {
    width: "7rem",
    marginLeft: "auto",
    marginRight: "2rem",
    marginBottom: "1rem",
    marginTop: "1rem",
  },

  ProgressStyle: {
    backgroundColor: "#F9FAFB",
    padding: "2rem",
    border: "1px solid #EAECF0",
  },
  verticalLine: {
    borderLeft: "2px solid #D0D5DD",
    height: "30px",
    margin: "9px 0px 12px 13px ",
  },
  gridContainer: {
    display: "flex",
  },
  QuestionStyle: {
    margin: "1.2rem 1.8rem 1.5rem 3.5rem",
    fontFamily: "Inter-Regular",
    fontStyle: "normal",
    lineHeight: "2.4rem",
    fontSize: "1.3rem",
    fontWeight: "800",
  },
  Title: {
    fontFamily: "Inter-Regular",
    fontStyle: "normal",
    lineHeight: "2.4rem",
    fontSize: "1.6rem",
    color: "#344054",
    padding: "1.5rem 0rem 1.7rem 3rem",
    display: "flex",
    alignItems: "center",
  },
  ReplaceDiv: {
    padding: "1.5rem 0",
  },
  gridChild: {
    display: "flex",
    flexDirection: "column",
    padding: "0 0.9rem",
  },
  loader: {
    marginLeft: "2rem",
  },
  TextStyle: {
    fontFamily: "Inter-Regular",
    fontSize: "1.5rem",
    fontWeight: "800",
    lineHeight: "2.4rem",
    color: "#101828",
  },
  numberStyle: {
    fontFamily: "Inter-Regular",
    fontSize: "1.4rem",
    fontWeight: "100",
    lineHeight: "2rem",
    color: "#667085",
  },
  iconStyle: {
    color: "#7F56D9",
    width: "4rem",
    height: "5rem",
  },
  tickIcon: {
    // marginLeft:"17px",
  },
  UploadIcons: {
    margin: "0.3rem 0.5rem",
  },

  failedEntriesText: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center"
  },
  wrapperFailedEntries: {
    display: "flex",
    padding: "0rem 3.6rem 2rem 3.6rem",
    fontFamily: "Inter-Regular",
    fontSize: "1.3rem",
    fontWeight: "800",
    lineHeight: "2rem",
    marginTop: "1rem",
  },
  wrapperOpenModal: {
    marginLeft: "2rem",
  },
}));

export default function DownloadStudentProgress({ open, handleClose, value, photosStatus, fingerprintPhotosStatus, docsPhotosStatus, base64Status, addStudentInLocalDb }) {

  const classes = useStyles();

  function cleanupAndClose() {
    handleClose();
  }
  
  return (
    <>
      <Modal open={open} aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className={classes.Title}>
            {" "}
            <div className={classes.UploadIcons}></div> Downloading Student Data
          </div>

          <div className={classes.ProgressStyle}>
            <div
              className={classes.gridContainer}
              style={{ opacity: value >= 0 ? 1 : 0.7 }}
            >
              {value >= 1 ? (
                <div className={classes.tickIcon}>
                  <CheckCircleIcon
                    sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                  />
                </div>
              ) : value < 0 ? (
                <CheckCircleIcon
                  sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                />
              ) : (
                <CircularProgress sx={{ color: "#7F56D9" }} size={30} />
              )}

              <div className={classes.gridChild}>
                <span className={classes.TextStyle}>Getting Data From Server</span>
              </div>
            </div>
            <div className={classes.verticalLine}></div>

            <div
              className={classes.gridContainer}
              style={{ opacity: value >= 1 ? 1 : 0.7 }}
            >
              {value >= 2 ? (
                <div className={classes.tickIcon}>
                  <CheckCircleIcon
                    sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                  />
                </div>
              ) : value > 1 ? (
                <CheckCircleIcon
                  sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                />
              ) : (
                <CircularProgress sx={{ color: "#7F56D9" }} size={30} />
              )}

              <div className={classes.gridChild}>
                <p className={classes.TextStyle}>Getting Photos</p>
                {/* <p className={classes.TextStyle} style={{fontWeight: "500", color: "#667085"}}>Progress {uploadingImagesProgress} %</p> */}
                <table border={0}>
                  <body className={cssModule.PhotosTableWrapper}>
                    <tr>
                      <th><p className={classes.TextStyle} style={{fontWeight: "500", color: "#667085"}}>Photos</p></th>
                      <th><p className={classes.TextStyle} style={{fontWeight: "500", color: "#667085"}}>Fingerprint Photos</p></th>
                      <th><p className={classes.TextStyle} style={{fontWeight: "500", color: "#667085"}}>Docs Photos</p></th>
                    </tr>
                    <tr>
                      <th><p className={classes.TextStyle} style={{fontWeight: "500", color: "#667085"}}>{(photosStatus && value > 0) ? "Done" : "Processing..."}</p></th>
                      <th><p className={classes.TextStyle} style={{fontWeight: "500", color: "#667085"}}>{(fingerprintPhotosStatus && value > 0) ? "Done" : "Processing..."}</p></th>
                      <th><p className={classes.TextStyle} style={{fontWeight: "500", color: "#667085"}}>{(docsPhotosStatus && value > 0) ? "Done" : "Processing..."}</p></th>
                    </tr>
                  </body>
                </table>
              </div>
            </div>
            <div className={classes.verticalLine}></div>

            <div
              className={classes.gridContainer}
              style={{ opacity: (value >= 2 && base64Status) ? 1 : 0.7 }}
            >
              {base64Status ? (
                <div className={classes.tickIcon}>
                  <CheckCircleIcon
                    sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                  />
                </div>
              ) : base64Status ? (
                <CheckCircleIcon
                  sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                />
              ) : (
                <CircularProgress sx={{ color: "#7F56D9" }} size={30} />
              )}
              <div className={classes.gridChild}>
                <span className={classes.TextStyle}>
                  {" "}
                  Getting All Docs
                </span>
                {/* <div className={classes.numberStyle}>
                  Total Photos: {totalDataUploading} 
                  <br />
                  Update Photos:{" "}{updatingStudentPhotoCount} 
                  <div>Failed Entries: {failedCandidatesCount}</div>
                </div> */}
              </div>
            </div>
            <div className={classes.verticalLine}></div>

            <div
              className={classes.gridContainer}
              style={{ opacity: addStudentInLocalDb ? 1 : 0.7 }}
            >
              {addStudentInLocalDb ? (
                <div className={classes.tickIcon}>
                  <CheckCircleIcon
                    sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                  />
                </div>
              ) : addStudentInLocalDb ? (
                <CheckCircleIcon
                  sx={{ color: "#7F56D9", width: "30px", height: "30px" }}
                />
              ) : (
                <CircularProgress sx={{ color: "#7F56D9" }} size={30} />
              )}
              <div className={classes.gridChild}>
                <span className={classes.TextStyle}>
                  {" "}
                  Store Data to Local Database
                </span>
                {/* <div className={classes.numberStyle}>
                  Total Photos: {totalDataUploading} 
                  <br />
                  Update Photos:{" "}{updatingStudentPhotoCount} 
                  <div>Failed Entries: {failedCandidatesCount}</div>
                </div> */}
              </div>
            </div>
          </div>
          {/* { Duplicate Entries ?  */}
          { (value === 2 && photosStatus && fingerprintPhotosStatus && docsPhotosStatus && base64Status && addStudentInLocalDb) &&
            <>
              <div className={classes.ButtonStyle}>
                <Button
                  style={{ color: "#ffffff", backgroundColor: "#6941c6" }}
                  btnName={"OK"}
                  btnIcon={false}
                  clicked={cleanupAndClose}
                />
              </div>
            </>
          }
        </Box>
      </Modal>

    </>
  );
}