import Dexie from 'dexie';

const DB = new Dexie('studentDB');

DB.version(1).stores({
  students: 'id, examID, subExamID, examCenterID,examCenterName,examName, isEdit,isUploaded, Applicant, checkingOfficer, checkingOfficerDesignation', // Primary key and indexed props
});

DB.version(1).stores({
  examCenters: 'id', // Primary key and indexed props
});

DB.version(1).stores({
  modelUrl: 'id', // Primary key and indexed props
});

DB.version(1).stores({
  base64: 'id,examName', // Primary key and indexed props
});

DB.version(1).stores({
  pdfBase64: 'id', // Primary key and indexed props
});

DB.version(1).stores({
  facenetArr: 'id', // Primary key and indexed props
});

DB.version(1).stores({
  loggedInUser: 'id', // Primary key and indexed props
});

DB.version(1).stores({
  allExams: 'id,examNameForSearch' // Primary key and indexed props
});

DB.version(1).stores({
  allSubExams: 'id,examDocId' // Primary key and indexed props
});

DB.version(1).stores({
  allExamCenters: 'id,examDocId' // Primary key and indexed props
});

export { DB }