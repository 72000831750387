import { query, orderBy, getFirestore, collection, getDocs, where, doc, getDoc, updateDoc, addDoc, setDoc } from "firebase/firestore";
import { uniqBy } from 'lodash'
async function getStudents(subExamId, searchField = "Full Name", lastDoc, studentSearchValue, examId, examCenterName) {
  console.log({ searchField, lastDoc, studentSearchValue, examId });
  const students = collection(getFirestore(), 'exams/' + examId + '/subExam/' + subExamId + '/meritStudents');
  let _query = query(students, orderBy(searchField, "asc"), where('examCenterName', '==', examCenterName));  //* for first time visit and without search
  // if (lastDoc && !studentSearchValue) {
  //   //* for next docs without Search
  //   _query = query(students, orderBy(searchField, "asc"), where('examCenterName', '==', examCenterName), startAfter(lastDoc), limit(11))
  // }
  // if (studentSearchValue && !lastDoc) {
  //   //* for search first time without last
  //   _query = query(students, orderBy(searchField, "asc"), where('examCenterName', '==', examCenterName), where(searchField, ">=", studentSearchValue), where(searchField, "<=", studentSearchValue + "\uf8ff"), limit(20));
  // }
  // if (lastDoc && studentSearchValue) {
  //   //* for search time with last doc
  //   _query = query(students, orderBy(searchField, "asc"), where('examCenterName', '==', examCenterName), startAfter(lastDoc), where(searchField, ">=", studentSearchValue), where(searchField, "<=", studentSearchValue + "\uf8ff"), limit(20));
  // }
  let getStudents = await getDocs(_query);
  return getStudents;
}

async function getStudentsCheck(subExamId, searchField = "Full Name", lastDoc, studentSearchValue, examId, examCenterName) {
  console.log({ searchField, lastDoc, studentSearchValue, examId });
  const students = collection(getFirestore(), 'exams/3JVKcfQapt5ZKfnw1WBh/subExam/J98g587R7zItlJFaIkss/meritStudents');
  let _query = query(students, orderBy(searchField, "asc"), where('examCenterName', '==', 'CAMBRIDGE College'));  //* for first time visit and without search
  // if (lastDoc && !studentSearchValue) {
  //   //* for next docs without Search
  //   _query = query(students, orderBy(searchField, "asc"), where('examCenterName', '==', examCenterName), startAfter(lastDoc), limit(11))
  // }
  // if (studentSearchValue && !lastDoc) {
  //   //* for search first time without last
  //   _query = query(students, orderBy(searchField, "asc"), where('examCenterName', '==', examCenterName), where(searchField, ">=", studentSearchValue), where(searchField, "<=", studentSearchValue + "\uf8ff"), limit(20));
  // }
  // if (lastDoc && studentSearchValue) {
  //   //* for search time with last doc
  //   _query = query(students, orderBy(searchField, "asc"), where('examCenterName', '==', examCenterName), startAfter(lastDoc), where(searchField, ">=", studentSearchValue), where(searchField, "<=", studentSearchValue + "\uf8ff"), limit(20));
  // }
  let getStudents = await getDocs(_query);
  console.log(getStudents)
  return getStudents;
}

async function getStudentById(studentId) {
  console.log('studentId: ', studentId);
  const studentRef = doc(getFirestore(), 'students', studentId);
  return await getDoc(studentRef)
}

async function getEligibleStudentById(subExamId, examId, studentId) {
  console.log('studentId: ', studentId);
  console.log('examId: ', examId);
  const studentRef = doc(getFirestore(), `exams/${examId}/subExam/${subExamId}/meritStudents`, studentId);
  return await getDoc(studentRef)
}

async function updateStudentData(subExamId, examId, studentId, obj) {
  // const studentRef = doc(getFirestore(), 'exams/' + examId + '/hallticket', studentId);
  const meritRef = doc(getFirestore(), 'exams/' + examId + '/subExam/' + subExamId + '/meritStudents', studentId);
  // const eligible = doc(getFirestore(), 'exams/' + examId + '/EligibleStudents', studentId);
  // await updateDoc(studentRef, obj)
  // await updateDoc(eligible, obj)
  return await updateDoc(meritRef, obj)
}

async function setStudent(subExamId, examId, obj, _studentId) {
  const meritRef = doc(getFirestore(), 'exams/' + examId + '/subExam/' + subExamId + '/meritStudents', _studentId);
  return await setDoc(meritRef, obj)
}

async function addStudent(subExamId, examId, obj) {
  const meritRef = collection(getFirestore(), 'exams/' + examId + '/subExam/' + subExamId + '/meritStudents');
  return await addDoc(meritRef, obj)
}

async function updateFingerprintTemplateToStudent(studentId, obj) {
  const studentRef = doc(getFirestore(), 'students/', studentId);
  return await updateDoc(studentRef, obj)
}
async function SetStudentToLocal(StudentObj) {
  console.log(StudentObj);
  localStorage.setItem("student", JSON.stringify(StudentObj));
}
function getSelectedStudentDetails() {
  const _student = localStorage.getItem("student");
  return JSON.parse(_student);
}
function UpdateLocalStudent(obj) {
  const localStudent = getSelectedStudentDetails();
  const _student = obj;
  Object.keys(_student).forEach(ele => {
    localStudent[ele] = _student[ele];
  });
  SetStudentToLocal(localStudent);
}
async function updateStudent(postBody, docID, _examDocId, subExamId) {

  // const collectionRef = doc(getFirestore(), "students", docID);
  const studentRef = doc(getFirestore(), `exams/${_examDocId}/subExam/${subExamId}/meritStudents`, docID)

  return await updateDoc(studentRef, postBody)
}

async function getAllStudents(examId, subExamId, examCenterName) {
  const studentRef = collection(getFirestore(), 'exams/' + examId + '/subExam/' + subExamId + '/meritStudents');
  let allStudents = await getDocs(query(studentRef, where('examCenterName', '==', examCenterName), where('isWildCardEntry', '==', true)));
  let allStudents1 = await getDocs(query(studentRef, where('examCenterName', '==', examCenterName), where('isVerified', '==', true)));
  let allStudents2 = await getDocs(query(studentRef, where('examCenterName', '==', examCenterName), where('isGroundNameChanged', '==', true)));
  let uniqueArr = uniqBy([...allStudents.docs, ...allStudents1.docs, ...allStudents2.docs], "id")
  console.log(uniqueArr)
  return uniqueArr;
}

async function getAllStudentsFromMainCollection(examId) {
  const studentRef = collection(getFirestore(), 'students/');
  let allStudents = await getDocs(query(studentRef));
  return allStudents;
}

async function getStudentByQr(examId, subExamId, applicant) {
  const student = collection(getFirestore(), 'exams/' + examId + '/subExam/' + subExamId + '/meritStudents');
  return await getDocs(query(student, where('Applicant', '==', applicant)))
}

export { getStudentsCheck, getStudents, setStudent, getStudentById, getStudentByQr, addStudent, getAllStudents, getAllStudentsFromMainCollection, getEligibleStudentById, updateStudentData, updateFingerprintTemplateToStudent, SetStudentToLocal, getSelectedStudentDetails, UpdateLocalStudent, updateStudent }