import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import left_top from '../../assets/images/log-in/left-top.png';
import left from '../../assets/images/log-in/left.png';
import right from '../../assets/images/log-in/right.png';
import Paper from '@mui/material/Paper';
// import Button from '@mui/material/Button';
import React, { useEffect, useRef, useState } from 'react';
import cssClasses from "./login.module.css";
import { useNavigate } from 'react-router-dom';
import { getAdmins, getUserData, loginWithFirebase, saveUser, signout } from '../../services/Firebase';
import { errorToast, successToast } from '../../services/Toast';
import Button from '../../Subcomponent/Button';
import { DB } from '../../db';

const useStyles = makeStyles((theme) => ({
    space_wrapper: {
        width: "33%",
        height: "100%",
        display: "inline-block",
        verticalAlign: "top",
        overflow: "hidden",
    },
    signin_wrapper: {
        width: "25%",
        height: "100%",
        display: "inline-block",
        verticalAlign: "top",
        textAlign: "left",
    },
    left_img: {
        position: "relative",
        display: "inline-block",
        height: "100%",
        width: "100%",
    },
    left_top: {
        position: "absolute",
        bottom: "30%",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: "5",
    },
    left_bottom: {
        bottom: "23%",
        left: "50%",
        transform: "translateX(-50%)",
        position: "absolute",
    },
    right_img: {
        display: "inline-block",
        position: "relative",
        width: "100%",
        height: "100%",
        "& img": {
            position: "absolute",
            bottom: "60%",
            left: "50%",
            transform: "translateX(-50%)",
        },
    },
    paper: {
        position: "relative",
        height: "62%",
        top: "50%",
        transform: "translateY(-50%)",
        background: "#FFFFFF !important",
        boxShadow: "0px 1.5rem 3.5rem rgb(60 66 87 / 8%) !important",
        borderRadius: "1.2rem !important",
        padding: "3.2rem 4rem",
    },
    page_title: {
        display: "inline-block",
        width: "100%",
        color: "#101828",
        fontSize: "2.4rem",
        lineHeight: "3.2rem",
        fontFamily: "Inter-SemiBold",
        marginBottom: "2.4rem",
        textAlign: "center",
    },
    form_wrap: {
        display: "inline-block",
        width: "100%",
        color: "#344054",
        fontSize: "1.4rem",
        lineHeight: "2rem",
        fontFamily: "Inter-Medium",
    },
    form_row: {
        display: "inline-block",
        width: "100%",
        marginBottom: "2rem",
    },
    form_label: {
        display: "inline-block",
        width: "100%",
        marginBottom: "0.6rem",
    },
    form_input: {
        display: "inline-block",
        width: "100%",
        "& input": {
            display: "inline-block",
            width: "100%",
            outline: "none",
            padding: "1rem 1.4rem",
            fontSize: "1.6rem",
            lineHeight: "2.4rem",
            border: "1px solid #D0D5DD",
            boxShadow: "0px 1px 2px rgb(16 24 40 / 5%)",
            borderRadius: "0.8rem",
            fontFamily: "Inter-Regular",
            color: "#667085",
        },
        "& ::placeholder": {
            fontSize: "1.6rem",
            lineHeight: "2.4rem",
            fontFamily: "Inter-Regular",
            color: "#667085",
        },
    },
    forgot_password: {
        display: "inline-block",
        width: "100%",
        color: "#6941C6",
        textAlign: "right",
        fontFamily: "Inter-Medium",
        cursor: "pointer",
    },
    submit: {
        display: "inline-block !important",
        width: "100% !important",
        background: "#7F56D9 !important",
        border: "1px solid #7F56D9 !important",
        boxShadow: "0px 1px 2px rgb(16 24 40 / 5%) !important",
        borderRadius: "0.8rem !important",
        padding: "1rem !important",
        fontSize: "1.6rem !important",
        lineHeight: "2.4rem !important",
        fontFamily: "Inter-Medium !important",
    },
    para: {
        display: "inline-block",
        width: "100%",
        textAlign: "center",
        color: "#667085",
        margin: "0",
        "& span": {
            display: "inline-block",
            color: "#6941C6",
            marginLeft: "0.4rem",
            cursor: "pointer",
        },
    },
    error_msg: {
        color: "red",
    },
    wrapperLogo: {
        display: "inline-block",
        width: "100%",
    },
    logo: {
        width: "100%",
        height: "16rem",
        objectFit: "contain",
        padding: "0rem 2rem",
    },
    titleImage: {
        width: "100%",
        objectFit: "contain",
    },

}));
function LogIn() {

    const navigate = useNavigate();
    const classList = useStyles();
    const email = useRef();
    const password = useRef();
    const [emailErrorMsg, setEmailErrorMsg] = useState("");
    const [passwordErrorMsg, setPasswordErrorMsg] = useState("");

    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState(true);

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    useEffect(() => {
        if (email) {
            email.current.focus();
        }
        const handler = (e) => {
            if (e.code === "NumpadEnter" || e.keyCode === 13 || e.code === "Enter") {
                firebaseLogin();
            }
        };
        window.addEventListener('keydown', handler, false);
        return () => window.removeEventListener('keydown', handler, false);
    }, [email])
    function validateEmail(event) {
        if (email.current.value === "") {
            setEmailErrorMsg("Invalid email!");
            setIsEmailValid(false);
        } else {
            setIsEmailValid(true);
            setEmailErrorMsg("");
        }
    }
    function validatePassword(event) {
        if (password.current.value === "") {
            setPasswordErrorMsg("Invalid password!");
            setIsPasswordValid(false);
        } else {
            setPasswordErrorMsg("");
            setIsPasswordValid(true);
        }
    }

    const firebaseLogin = () => {
        setIsLoadingButton(true);
        validateEmail({ target: { value: email.current.value } });
        validatePassword({ target: { value: password.current.value } });

        if (
            isEmailValid &&
            isPasswordValid &&
            password.current.value !== "" &&
            email.current.value !== ""
        ) {
            if (window.navigator.onLine) {
                loginWithFirebase(email.current.value, password.current.value).then((res) => {
                    console.log(res.user);
                    // saveUser(res.user);
                    getUserData(res.user.uid).then(res => {
                        if (res.isActive) {
                            successToast("Login Successfully.");
                            setIsLoadingButton(false);
                            // console.log(Object.keys(res.tabAccess).filter(f=>res.tabAccess[f].length));
                            navigate("/home/student-verify");
                        } else {
                            errorToast("Admin inactive");
                            setIsLoadingButton(false);
                            signout().then(() => {
                                navigate('/');
                            });
                        }
                    }).catch(err => {
                        console.log(err);
                        setIsLoadingButton(false);
                    })
                }).catch((error) => {
                    console.log(error.code);
                    setIsLoadingButton(false);
                    if (error.code === "auth/wrong-password") {
                        errorToast("Please check the Password");
                    }
                    if (error.code === "auth/user-not-found") {
                        errorToast("Please check the Email");
                    }
                    if (error.code === "auth/email-already-in-use") {
                        errorToast("Email Already in Use");
                    }
                    if (error.code === "auth/invalid-email") {
                        errorToast("Please enter valid email.");
                    }
                    if (error.code === "auth/configuration-not-found") {
                        errorToast("User not found.");
                    }
                });
            } else {
                DB.loggedInUser.get("loggedInUser").then((resp) => {
                    if (email.current.value == resp.data.email) {
                        localStorage.setItem("user", JSON.stringify(resp.data));
                        saveUser(resp.data)
                        navigate("/home/student-verify");
                    } else {
                        setIsLoadingButton(false);
                        errorToast("If you want to login with different user please connect to internet")
                    }
                })
            }
        } else {
            setIsLoadingButton(false);
        }
    };
    return (
        <Box sx={{ bgcolor: "#F7FAFC", height: "100%", textAlign: "center" }}>
            {/* <Box sx={{ bgcolor: '#F7FAFC', height: '100%' }} /> */}
            <CssBaseline />
            <Box className={classList.space_wrapper}>
                <div className={classList.left_img}>
                    <img src={left_top} alt="" className={classList.left_top} />
                    <img src={left} alt="" className={classList.left_bottom} />
                </div>
            </Box>
            <Box className={classList.signin_wrapper}>
                <Paper className={classList.paper}>
                    {/* <img className={classList.titleImage} src={title} alt="" /> */}
                    <div className={classList.wrapperLogo}>
                        {/* <img className={classList.logo} src={logo1} alt="" /> */}
                        {/* <img className={classList.logo} src={logo} alt="" /> */}
                    </div>
                    <div className={classList.page_title}>Sign in to your account</div>

                    <div className={classList.form_wrap}>
                        <div className={classList.form_row}>
                            <div className={classList.form_label}>Email</div>
                            <div className={classList.form_input}>
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    ref={email}
                                    onChange={validateEmail}
                                    id={"try"}
                                />
                            </div>
                            {!isEmailValid && (
                                <div className={classList.error_msg}>{emailErrorMsg}</div>
                            )}
                        </div>
                        <div className={classList.form_row}>
                            <div className={classList.form_label}>Password</div>
                            <div className={classList.form_input}>
                                <input
                                    type="password"
                                    placeholder="Enter your password"
                                    ref={password}
                                    onChange={validatePassword}
                                />
                            </div>
                            {!isPasswordValid && (
                                <div className={classList.error_msg}>{passwordErrorMsg}</div>
                            )}
                        </div>
                        {/* <div className={classList.form_row}>
                                <div className={classList.forgot_password} onClick={forgetPassword}>
                                    Forgot password
                                </div>
                            </div> */}
                        <div className={classList.form_row}>
                            {/* <Link to='/home'> */}
                            {/* <Button
                                variant="contained"
                                className={classList.submit}
                                onClick={firebaseLogin}>
                                Sign in
                            </Button> */}

                            <Button
                                style={{ color: "#fff", backgroundColor: "#7F56D9" }}
                                btnName={"Sign In"}
                                clicked={firebaseLogin}
                                isLoading={isLoadingButton}
                                disable={isLoadingButton}
                            />

                            {/* </Link> */}
                        </div>
                        {/* <div className={classList.form_row}>
                                <p className={classList.para}>Don’t have an account?<span>Sign up</span></p>
                            </div> */}
                    </div>
                    <div className={cssClasses.subTitle}>Powered by Ranga Enterprises</div>
                </Paper>
            </Box>
            <Box className={classList.space_wrapper}>
                <div className={classList.right_img}>
                    <img src={right} alt="" />
                </div>
            </Box>
        </Box>
    );
}
export default LogIn;