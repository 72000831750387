import Auth from "./auth";
import isLoading from "./isLoading";

import { combineReducers } from "redux"
import ACTIVE_MENU from "./activeMenu";
import faceNet from "./faceNetStore";

const rootReducer = combineReducers({
    Auth:Auth,
    IsLoading:isLoading,
    ActiveMenu: ACTIVE_MENU,
    faceNet: faceNet
});

export default rootReducer;