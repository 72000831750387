import React, { useState , useEffect } from 'react';

//*MUI
import { makeStyles } from '@material-ui/core/styles';
import {  Box ,  Modal } from '@material-ui/core';
import LinearProgress from '@mui/material/LinearProgress';

//*files
// import UploadIcon from "../assets/images/UploadIcon.svg";

export default function ProgressImagesUpload({open, handleClose,totalImagesUploading,uploadingImages,title}) {
    
    const useStyles = makeStyles((theme) =>({
        ButtonStyle:{
            width:"70px",
            marginLeft:"auto",
            marginRight:"2rem",
            marginBottom:"2rem",
        },
        ProgressStyle:{
          backgroundColor:"#F9FAFB",
          padding:"2rem",
          border:"1px solid #EAECF0",
          margin:"2rem 0", 
        },
        Title:{
          fontFamily:"Inter-Medium",
          fontStyle:"normal",
          lineHeight:"2.4rem",
          fontSize:"1.6rem",
          color:"#344054",
          padding:"1.5rem 0rem 0.9rem 3rem",
          display:"flex",
        },
        gridChild:{
            display:"flex",
            flexDirection:"column",
            padding:"0 0.9rem",
        },
        TextStyle:{
            fontFamily:"Inter-Medium",
            fontSize:"1.5rem",
            fontWeight:"800",
            lineHeight:"2.4rem",
            color:"#101828",
            marginBottom:"1rem"
        },
        numberStyle:{
            fontFamily:"Inter-Medium",
            fontSize:"1.4rem",
            fontWeight:"100",
            lineHeight:"2rem",
            color:"#667085",
        }, 
        UploadIcons:{
            margin:"0.3rem 0.5rem",  
        },
        LinearP:{
            '& .MuiLinearProgress-bar': {
                backgroundColor: "#6941c6",
              },
        }
        
       
    }));
      
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius:"6px",
        outline: 0, 
      };
      // useEffect(() => {
      //   let per = (uploadingImages/totalImagesUploading) * 100;
      //   setProgress(per);
      //   if(uploadingImages===totalImagesUploading){
      //     handleClose();
      //   }
      // }, [uploadingImages])
      
    const [progress, setProgress] = useState(0);

     const classes = useStyles();
  return (
     <Modal
        open={open}
        aria-describedby="modal-modal-description"
        disableEnforceFocus
        >
       <Box sx={style}>
            <div className={classes.ProgressStyle}>
                     <div className={classes.gridChild}>
                         {/* <span className={classes.TextStyle}> Uploading Image {uploadingImages} of {totalImagesUploading}</span> */}
                         <span className={classes.TextStyle}> {title}: {uploadingImages} %</span>
                         <div className={classes.numberStyle}> 
                         <LinearProgress variant="determinate" className={classes.LinearP} sx={{backgroundColor:"#d9d2e9"}} value={uploadingImages}/>
                         </div>
                     </div>
                </div>                 
    </Box>
     </Modal>
  )
}

