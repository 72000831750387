import React,{useState,useEffect,useCallback} from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "../../Subcomponent/Button";
import cssClasses from "./Qr_Bar_modal.module.css"
import cameraNotFoundImage from "../../assets/images/cameraNotFound.jpg"
import crossImage from "../../assets/images/x.svg"
import CircularProgress from '@mui/material/CircularProgress';
import Dropdown from "../../Subcomponent/Dropdown";
import { isNativePlatform } from "../../services/androidImports";
import { errorToast } from "../../services/Toast";

export default function QrAndBarcodeScanner({ open, handleClose , getCode }) {

  let _isNativePlatform = isNativePlatform();

  const [isLoading, setIsLoading] = useState(true);
  const [isCameraFound, setIsCameraFound] = useState(true);
  const [deviceId, setDeviceId] = React.useState({});
  const [devices, setDevices] = React.useState([]);

  const handleDevices = useCallback( mediaDevices => setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),[setDevices]); 
  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  },[handleDevices]);

  useEffect(() => {
    // console.log(devices);
    // console.log(devices.map(m=>({label:m.label,value:m.deviceId})));
    if(devices.length > 0){
      setDeviceId(devices[0].deviceId)
    }
  }, [devices])

  const cameraError = async (err) => {
    console.log(err);
    const {state} = await window.navigator.permissions.query({name:'camera'});
    if (state === "denied") {
      errorToast("Please allow to access your camera")
    }
    setIsLoading(false);
    setIsCameraFound(false);
  }
  const cameraFound = (res) =>{
    if (devices.map(m=>({label:m.label,value:m.deviceId})).some(s=>s.label===""||s.value==="")) {
      navigator.mediaDevices.enumerateDevices().then(handleDevices); 
      setIsCameraFound(res);
      setIsLoading(false);
    }else{
      setIsCameraFound(res);
      setIsLoading(false);
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={[
        {
          ".MuiBackdrop-root":{
            background: "rgba(193, 201, 210, 0.7)"
          }
        }
      ]}
      disableAutoFocus={true}
    >
      <Box className={cssClasses.modalWrapper}>

        <div className={cssClasses.header}>
          <h1>Scan QR or BarCode</h1>
          <img src={crossImage} alt="" style={{cursor:"pointer"}} onClick={handleClose}/>
        </div>

        <div className={cssClasses.mainContainer}>
        {isLoading && <div className={cssClasses.Loader}><CircularProgress /></div>}
        {isCameraFound &&
          <>
            {!isLoading && !_isNativePlatform &&
              <Dropdown
                list={devices.map(m=>({label:m.label,value:m.deviceId}))}
                selectedValue={deviceId}
                onChangeSelect={(value) => {setDeviceId(value)}}
              />
            }
            <p></p>
            { _isNativePlatform && 
              <BarcodeScannerComponent
                // width={500}
                // height={500}
                onError={cameraError}
                onUpdate={(err, result) => {
                  cameraFound(true);
                  if (result) getCode(result.text);
                }}
                videoConstraints={{facingMode: { exact: "environment" }}}
              />
            }
            { !_isNativePlatform && 
              <BarcodeScannerComponent
                // width={500}
                // height={500}
                onError={cameraError}
                onUpdate={(err, result) => {
                  cameraFound(true);
                  if (result) getCode(result.text);
                }}
                videoConstraints={{ deviceId: deviceId } }
              />
            }
            
          </>
        }
        {
          !isCameraFound && !isLoading  && <img src={cameraNotFoundImage} alt="" style={{width: "100%"}} />
        }
        </div>

        <div className={cssClasses.footer}>
          {/* <div><Button style={{ color: "#7F56D9", backgroundColor: "#ffffff" }} btnName={"Download PDF"} clicked={handleClose} /></div> */}
          <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Cancel"} clicked={handleClose} /></div>
        </div>
      </Box>
    </Modal>
  );
}
