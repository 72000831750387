/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState, useRef } from 'react'
import cssClasses from './AttendanceProcess.module.css'
import Button from "../../Subcomponent/Button";
import Card from '@mui/material/Card';
import { DB } from '../../db';
import ScanImg from '../../assets/images/scan.png'
import img from '../../assets/images/download.png'
import Webcam from "react-webcam";
import { errorToast, successToast } from "../../services/Toast"
import biometric from '../../assets/images/biometric.svg'
import { CaptureFinger, VerifyFinger, GetMFS100Info } from '../../assets/mfs100-9.0.2.6';
import { useNavigate, useParams } from "react-router-dom"
import { SetStudentToLocal } from "../../services/StudentService";
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import CameraIcon from '@mui/icons-material/Camera';
import IconButton from '@mui/material/IconButton';

//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Preprocess } from '../../Facenet/Preprocess'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import '../../assets/swiper.css'
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { Facenet } from '../../Facenet/Facenet';

export default function AttendanceProcess({ studentId, nextStudent, isVisible }) {
    let facenetObj = useSelector((state) => {
        return state.faceNet[JSON.parse(localStorage.getItem('examCenter')).id]
    });
    const naviagte = useNavigate();
    let params = useParams();
    let examShortName = params.examShortName;
    let subExamName = params.subExamName;
    let examCenterName = params.examCenterName;
    const photoRef = React.useRef(null);
    const docRef = React.useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hideFingerprint, setHideFingerprint] = useState(false);
    const [isCameraFound, setIsCameraFound] = useState(true);
    const [deviceId, setDeviceId] = React.useState({});
    const [devices, setDevices] = React.useState([]);
    const [student, setStudent] = useState(null)
    const [studentPhotoArr, setStudentPhotoArr] = useState([])
    const [studentIdArr, setStudentIdArr] = useState([])
    const [matchedFingerprint, setMatchedFingerprint] = useState('')
    const [subExam, setSubExam] = useState(null)
    const [errMsg, setErrMsg] = useState('')
    const [successMsg, setSuccessMsg] = useState('')
    const [successMsg1, setSuccessMsg1] = useState('')
    let [counter, setCounter] = useState(0)
    const _student = useRef(student)
    const [allowTab, setAllowTab] = useState(true)
    const allowTabRef = useRef(allowTab)
    const _matchedFingerprint = useRef(matchedFingerprint)
    const [failedMatch, setFailedMatch] = useState(false)
    const [functionTime, setFunctionTime] = useState(false)
    const sadPress = useKeyPress("Tab");
    useEffect(() => {
        if (sadPress) {
            console.log(sadPress)
            console.log(functionTime)
            if (sadPress > functionTime) {
                tabPress()
            } else {
                console.log("omit")
            }
        }
    }, [sadPress])


    useEffect(() => {
        console.log(hideFingerprint)
    }, [hideFingerprint])


    // const [exam, setExam] = useState(null)
    const handleDevices = useCallback(mediaDevices => setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")), [setDevices]);

    const cameraError = async (err) => {
        console.log(err);
        const { state } = await window.navigator.permissions.query({ name: 'camera' });
        if (state === "denied") {
            errorToast("Please allow to access your camera")
        }
        setIsLoading(false);
        setIsCameraFound(false);
    }

    const cameraFound = (res) => {
        if (devices.map(m => ({ label: m.label, value: m.deviceId })).some(s => s.label === "" || s.value === "")) {
            navigator.mediaDevices.enumerateDevices().then(handleDevices);
            setIsCameraFound(res.active);
        } else {
            setIsCameraFound(res.active);
            setIsLoading(false);
        }
    }

    useEffect(async () => {
        setMatchedFingerprint('')
        setSuccessMsg('')
        setErrMsg('')
        setCounter(0)
        setStudentPhotoArr([])
        setStudentIdArr([])
        setStudent(null)
        _student.current = null
        setHideFingerprint(false)
        setFailedMatch(false)
        let _subExam = JSON.parse(localStorage.getItem('subExam'))
        console.log(_subExam.index)
        // if (_subExam.index > 0) {
        //     await DB.allSubExams.where({ index: _subExam.index - 1, examDocId: JSON.parse(localStorage.getItem('exam')).id }).toArray().then(async (exam) => {
        //         console.log(exam)
        //         await DB.students.get(`${exam[0].id}_${studentId.split('_')[1]}`).then((student) => {
        //             if (student && !student?.isVerified) {
        //                 setErrMsg("Student Not Registered In Previous Exam")
        //             }
        //         })
        //     })
        // }
        setSubExam(_subExam)
        // setExam(JSON.parse(localStorage.getItem('exam')))
        if (studentId && studentId !== '') {
            DB.students.get(studentId).then((resp) => {
                console.log(resp)
                _student.current = { ...resp }
                setStudent(resp)
                if (resp.isVerified === true) {
                    setSuccessMsg(`Candidate Registered`)
                    // removeTabIndex()
                } else {
                    console.log("index added")
                    // addTabIndex(1)
                }
                DB.base64.where({ id: `${JSON.parse(localStorage.getItem('exam')).name}_${studentId.split('_')[1]}`, examName: JSON.parse(localStorage.getItem('exam')).name }).toArray().then((res) => {
                    console.log('%c base64 response', 'color:green;font-size:20px')
                    console.log(res)
                    if (res.length > 0) {
                        let pre = { ...resp }
                        if (res[0]?.fingerprint1Base64 || (pre?.fingerprint1Base64 && pre?.fingerprint1Base64 !== '')) {
                            console.log(res, pre)
                            setHideFingerprint(true)
                        } else {
                            setHideFingerprint(false)
                        }
                        pre.fingerprint1Base64 = pre?.fingerprint1Base64 ? pre?.fingerprint1Base64 : res[0]?.fingerprint1Base64
                        pre.fingerprint2Base64 = pre?.fingerprint2Base64 ? pre?.fingerprint2Base64 : res[0]?.fingerprint2Base64
                        pre.fingerprintTemplate1 = pre?.fingerprintTemplate1 ? pre?.fingerprintTemplate1 : res[0]?.fingerprintTemplate1
                        pre.fingerprintTemplate2 = pre?.fingerprintTemplate2 ? pre?.fingerprintTemplate2 : res[0]?.fingerprintTemplate2
                        //     return { ...pre }
                        // })
                        _student.current = { ..._student.current, ...pre }
                        setStudent((obj) => { return { ...obj, ...pre } })
                        pre = null
                        Object.keys(res[0]).forEach((ele) => {
                            if (ele !== subExamName) {
                                if (res[0][ele]) {
                                    if (res[0][ele]["realTimePhoto"]) {
                                        setStudentPhotoArr((pre) => [...pre, { examName: ele, image: res[0][ele]["realTimePhoto"] }])
                                    }
                                    if (res[0][ele]["idCardImage"]) {
                                        setStudentIdArr((pre) => [...pre, { examName: ele, image: res[0][ele]["idCardImage"] }])
                                    }
                                }
                            }
                        })
                    } else {
                        // _student.current = { ..._student.c }
                        // setStudent(resp)
                    }
                })
            })
        }
    }, [studentId])

    useEffect(() => {
        _student.current = { ...student }
        console.log(_student.current)
        _matchedFingerprint.current = matchedFingerprint
    }, [student, matchedFingerprint])


    useEffect(async () => {
        // let mfsInfo = await GetMFS100Info()
        // console.log(mfsInfo)
        // if (mfsInfo.data.ErrorDescription = "MFS100 not Found") {
        //     setErrMsg('Fingerprint machine not connected')
        // }
        console.log("cuurentTabINdex", document.activeElement.tabIndex)
        document.getElementById('1').focus()
        // document.addEventListener('keyup', tabPress);
        console.log("cuurentTabINdex", document.activeElement.tabIndex)
        return () => {
            document.removeEventListener('keyup', (event) => { console.log(event) })
            setStudentPhotoArr([])
            setStudentIdArr([])
            setStudent(null)
            setMatchedFingerprint(null)
            setSuccessMsg(null)
            setSuccessMsg1(null)
            setErrMsg(null)
            setSubExam(null)
            setMatchedFingerprint(null)
            docRef.current = null
            photoRef.current = null
            _matchedFingerprint.current = null
            _student.current = null
        }
    }, [])

    // function tabPress() {
    //     console.log('---------failed match--------', failedMatch)
    //     const currentStudent = _student.current
    //     console.log(allowTabRef.current)
    //     if (!allowTabRef.current) {
    //         return false
    //     }
    //     console.log("document.activeElement.tabIndex", document.activeElement.tabIndex)
    //     if (currentStudent) {
    //         if ((document.activeElement.tabIndex == 2) || (document.activeElement.tabIndex == 6 && !JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression && !JSON.parse(localStorage.getItem('subExam')).isStoreIdentityDocument) || (document.activeElement.tabIndex == 5 && !JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression && JSON.parse(localStorage.getItem('subExam')).isStoreIdentityDocument)) {
    //             console.log("real photo")
    //             console.log("currentStudent", currentStudent)
    //             if ((!currentStudent.realTimeBase64 || currentStudent.realTimeBase64 == '')) {
    //                 removeTabIndex()
    //                 let photo = photoRef.current.getScreenshot()
    //                 const img = document.createElement("img");
    //                 img.src = photo
    //                 img.id = 'image'
    //                 if (facenetObj) {
    //                     facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data').then((resp) => {
    //                         console.log(resp)
    //                         if (resp === 'No Face Detected') {
    //                             // setStudent((pre) => {
    //                             //     pre.realTimeBase64 = photo
    //                             //     console.log(pre)
    //                             //     if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 == '') && (!currentStudent.fingerprintTemplate1 || currentStudent.fingerprintTemplate1 == '') && JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression) {
    //                             //         console.log("first finger")
    //                             //         getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
    //                             //     }
    //                             //     return { ...pre }
    //                             // })
    //                             currentStudent.realTimeBase64 = photo
    //                             setStudent(currentStudent)
    //                             if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 == '') && (!currentStudent.fingerprintTemplate1 || currentStudent.fingerprintTemplate1 == '') && JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression) {
    //                                 console.log("first finger")
    //                                 getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
    //                             }
    //                             else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression) {
    //                                 addTabIndex(4)
    //                                 console.log("match finger")
    //                                 console.log(_matchedFingerprint.current)
    //                                 matchFingerprint()
    //                             } else if (JSON.parse(localStorage.getItem('subExam')).isStoreIdentityDocument) {
    //                                 addTabIndex(5)
    //                             }
    //                         } else {
    //                             // setStudent((pre) => {
    //                             //     pre.realTimeBase64 = resp
    //                             //     console.log(pre)
    //                             //     if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 == '') && (!currentStudent.fingerprintTemplate1 || currentStudent.fingerprintTemplate1 == '') && JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression) {
    //                             //         console.log("first finger")
    //                             //         getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
    //                             //     }
    //                             //     return { ...pre }
    //                             // })
    //                             currentStudent.realTimeBase64 = resp
    //                             setStudent(currentStudent)
    //                             if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 == '') && (!currentStudent.fingerprintTemplate1 || currentStudent.fingerprintTemplate1 == '') && JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression) {
    //                                 console.log("first finger")
    //                                 getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
    //                             }
    //                             else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression) {
    //                                 addTabIndex(4)
    //                                 console.log("match finger")
    //                                 console.log(_matchedFingerprint.current)
    //                                 matchFingerprint()
    //                             } else if (JSON.parse(localStorage.getItem('subExam')).isStoreIdentityDocument) {
    //                                 addTabIndex(5)
    //                             }
    //                         }
    //                     })
    //                 } else {
    //                     facenetObj = new Facenet('', '')
    //                     facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data').then((resp) => {
    //                         console.log(resp)
    //                         if (resp === 'No Face Detected') {
    //                             // setStudent((pre) => {
    //                             //     pre.realTimeBase64 = photo
    //                             //     console.log(pre)
    //                             //     if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 == '') && (!currentStudent.fingerprintTemplate1 || currentStudent.fingerprintTemplate1 == '') && JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression) {
    //                             //         console.log("first finger")
    //                             //         getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
    //                             //     }
    //                             //     return { ...pre }
    //                             // })
    //                             currentStudent.realTimeBase64 = photo
    //                             setStudent(currentStudent)
    //                             if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 == '') && (!currentStudent.fingerprintTemplate1 || currentStudent.fingerprintTemplate1 == '') && JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression) {
    //                                 console.log("first finger")
    //                                 getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
    //                             } else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression) {
    //                                 addTabIndex(4)
    //                                 console.log("match finger")
    //                                 console.log(_matchedFingerprint.current)
    //                                 matchFingerprint()
    //                             } else if (JSON.parse(localStorage.getItem('subExam')).isStoreIdentityDocument) {
    //                                 addTabIndex(5)
    //                             }
    //                         } else {
    //                             // setStudent((pre) => {
    //                             //     pre.realTimeBase64 = resp
    //                             //     console.log(pre)
    //                             //     if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 == '') && (!currentStudent.fingerprintTemplate1 || currentStudent.fingerprintTemplate1 == '') && JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression) {
    //                             //         console.log("first finger")
    //                             //         getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
    //                             //     }
    //                             //     return { ...pre }
    //                             // })
    //                             currentStudent.realTimeBase64 = resp
    //                             setStudent(currentStudent)
    //                             if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 == '') && (!currentStudent.fingerprintTemplate1 || currentStudent.fingerprintTemplate1 == '') && JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression) {
    //                                 console.log("first finger")
    //                                 getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
    //                             }
    //                             else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression) {
    //                                 addTabIndex(4)
    //                                 console.log("match finger")
    //                                 console.log(_matchedFingerprint.current)
    //                                 matchFingerprint()
    //                             } else if (JSON.parse(localStorage.getItem('subExam')).isStoreIdentityDocument) {
    //                                 addTabIndex(5)
    //                             }
    //                         }
    //                     })
    //                 }
    //             } else {
    //                 if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 == '') && (!currentStudent.fingerprintTemplate1 || currentStudent.fingerprintTemplate1 == '') && JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression) {
    //                     console.log("first finger")
    //                     getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
    //                 }
    //                 else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression) {
    //                     addTabIndex(4)
    //                     console.log("match finger")
    //                     console.log(_matchedFingerprint.current)
    //                     matchFingerprint()
    //                 } else if (JSON.parse(localStorage.getItem('subExam')).isStoreIdentityDocument) {
    //                     addTabIndex(5)
    //                 }
    //             }

    //         }
    //         // else if (event.key == 'Tab' && (!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 == '') && (!currentStudent.fingerprintTemplate1 || currentStudent.fingerprintTemplate1 == '') && document.activeElement.tabIndex == 3) {
    //         //     event.preventDefault()
    //         //     getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
    //         // }
    //         else if ((!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 == '') && (!currentStudent.fingerprintTemplate2 || currentStudent.fingerprintTemplate2 == '') && document.activeElement.tabIndex == 3 && JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression && errMsg === '') {
    //             console.log("second finger")
    //             getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
    //         } else if (_matchedFingerprint.current == '' && document.activeElement.tabIndex == 4 && !failedMatch && JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression) {
    //             console.log("match finger")
    //             console.log(_matchedFingerprint.current)
    //             matchFingerprint()
    //         } else if (((!currentStudent.idCardBase64 || currentStudent.idCardBase64 == '') && document.activeElement.tabIndex == 6 && JSON.parse(localStorage.getItem('subExam')).isStoreIdentityDocument)) {
    //             console.log("id card")
    //             // setStudent((pre) => {
    //             //     pre.idCardBase64 = docRef.current.getScreenshot()
    //             //     console.log('id', pre)
    //             //     return { ...pre }
    //             // })
    //             currentStudent.idCardBase64 = docRef.current.getScreenshot()
    //             setStudent(currentStudent)
    //         } else if (document.activeElement.tabIndex == 0) {
    //             if (currentStudent.realTimeBase64 !== '' && !JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression && !JSON.parse(localStorage.getItem('subExam')).isStoreIdentityDocument) {
    //                 saveData()
    //             } else if (currentStudent.realTimeBase64 !== '' && JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression && matchedFingerprint !== '') {
    //                 saveData()
    //             } else if (currentStudent.realTimeBase64 !== '' && !JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression && JSON.parse(localStorage.getItem('subExam')).isStoreIdentityDocument && currentStudent.idCardBase64 !== '') {
    //                 saveData()
    //             } else {
    //                 document.getElementById('1').focus()
    //             }
    //         } else if (document.activeElement.tabIndex == 5 && failedMatch) {
    //             console.log("match finger")
    //             console.log(_matchedFingerprint.current)
    //             matchFingerprint()
    //         } else if (document.activeElement.tabIndex == 4 && errMsg !== '') {
    //             console.log("second finger")
    //             getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
    //         } else if (document.activeElement.tabIndex == 3 && errMsg !== '') {
    //             addTabIndex(2)
    //             console.log("second finger")
    //             getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
    //         }
    //         // else if (document.activeElement.tabIndex == 7 || (document.activeElement.tabIndex == 6 && !JSON.parse(localStorage.getItem('subExam')).isStoreIdentityDocument) || (document.activeElement.tabIndex == 6 && JSON.parse(localStorage.getItem('subExam')).isStoreIdentityDocument && !subExam?.isStoreThumbImpression)) {
    //         //     saveData()
    //         // }
    //     } else {
    //     }
    // }

    function tabPress() {
        // removeTabIndex()
        // addTabIndex(1)
        console.log('%c allowTabRef.current', 'color:green; font-size:20px')
        // console.log(allowTabRef.current)
        // if (!allowTabRef.current) {
        //     return false
        // }
        let currentStudent = _student.current
        if (Object.keys(currentStudent).length > 0 && (!currentStudent?.isVerified || currentStudent?.isVerified === 'FALSE')) {
            if (counter === 0) {
                if (!currentStudent.realTimeBase64 || currentStudent.realTimeBase64 === '') {
                    let photo = photoRef.current.getScreenshot()
                    const img = document.createElement("img");
                    img.src = photo
                    img.id = 'image'
                    if (facenetObj) {
                        facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data').then((resp) => {
                            console.log(resp)
                            if (resp === 'No Face Detected') {
                                // setStudent((pre) => {
                                //     pre.realTimeBase64 = photo
                                //     return { ...pre }
                                // })
                                currentStudent.realTimeBase64 = photo
                                setStudent(currentStudent)
                                if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && subExam?.isStoreThumbImpression && (!matchedFingerprint || matchedFingerprint === '')) {
                                    setCounter(3)
                                    matchFingerprint()
                                } else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                                    setCounter(2)
                                    getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
                                } else if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 === '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                                    getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
                                    setCounter(1)
                                } else {
                                    setCounter(4)
                                }
                            } else {
                                // setStudent((pre) => {
                                //     pre.realTimeBase64 = resp
                                //     return { ...pre }
                                // })
                                currentStudent.realTimeBase64 = resp
                                setStudent(currentStudent)
                                if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && subExam?.isStoreThumbImpression && (!matchedFingerprint || matchedFingerprint === '')) {
                                    setCounter(3)
                                    matchFingerprint()
                                } else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                                    setCounter(2)
                                    getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
                                } else if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 === '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                                    setCounter(1)
                                    getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
                                } else {
                                    setCounter(4)
                                }
                            }
                        })
                    } else {
                        facenetObj = new Facenet('', '')
                        facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data').then((resp) => {
                            console.log(resp)
                            if (resp === 'No Face Detected') {
                                // setStudent((pre) => {
                                //     pre.realTimeBase64 = photo
                                //     return { ...pre }
                                // })
                                currentStudent.realTimeBase64 = photo
                                setStudent(currentStudent)
                                if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && subExam?.isStoreThumbImpression && (!matchedFingerprint || matchedFingerprint === '')) {
                                    setCounter(3)
                                    matchFingerprint()
                                } else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                                    setCounter(2)
                                    getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
                                } else if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 === '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                                    setCounter(1)
                                    getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
                                } else {
                                    setCounter(4)
                                }
                            } else {
                                // setStudent((pre) => {
                                //     pre.realTimeBase64 = resp
                                //     return { ...pre }
                                // })
                                currentStudent.realTimeBase64 = resp
                                setStudent(currentStudent)
                                if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && subExam?.isStoreThumbImpression && (!matchedFingerprint || matchedFingerprint === '')) {
                                    setCounter(3)
                                    matchFingerprint()
                                } else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                                    setCounter(2)
                                    getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
                                } else if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 === '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                                    setCounter(1)
                                    getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
                                } else {
                                    setCounter(4)
                                }
                            }
                        })
                    }
                } else {
                    if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && subExam?.isStoreThumbImpression && (!matchedFingerprint || matchedFingerprint === '')) {
                        setCounter(3)
                        matchFingerprint()
                    } else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                        setCounter(2)
                        getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
                    } else if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 === '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                        setCounter(1)
                        getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
                    } else {
                        setCounter(4)
                    }
                }
            } else if (counter === 1) {
                getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
            } else if (counter === 2) {
                if (currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '')
                    getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
            } else if (counter === 3) {
                if (currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '' && currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '')
                    matchFingerprint()
            } else if (counter === 4) {
                currentStudent.idCardBase64 = docRef.current.getScreenshot()
                setStudent(currentStudent)
            }
        }
    }

    function goToProforma() {
        SetStudentToLocal(student)
        saveData()
        naviagte(`/home/student-verify/${examShortName}/allSubExams/${subExamName}/allExamCenters/${examCenterName}/details`);
    }

    function captureStudentPhoto() {
        let currentStudent = _student.current
        if (currentStudent.realTimeBase64 && currentStudent.realTimeBase64 !== '') {
            // setStudent((pre) => {
            //     pre.realTimeBase64 = ''
            //     return { ...pre }
            // })
            currentStudent.realTimeBase64 = ''
            setStudent(currentStudent)
            setCounter(0)
        } else {
            let photo = photoRef.current.getScreenshot()
            const img = document.createElement("img");
            img.src = photo
            img.id = 'image'
            if (facenetObj) {
                facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data').then((resp) => {
                    console.log(resp)
                    if (resp === 'No Face Detected') {
                        // setStudent((pre) => {
                        //     pre.realTimeBase64 = photo
                        //     return { ...pre }
                        // })
                        currentStudent.realTimeBase64 = photo
                        setStudent(currentStudent)
                        if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && subExam?.isStoreThumbImpression && (!matchedFingerprint || matchedFingerprint === '')) {
                            setCounter(3)
                            matchFingerprint()
                        } else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                            setCounter(2)
                            getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
                        } else if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 === '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                            setCounter(1)
                            getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
                        } else {
                            setCounter(4)
                        }
                    } else {
                        // setStudent((pre) => {
                        //     pre.realTimeBase64 = resp
                        //     return { ...pre }
                        // })
                        currentStudent.realTimeBase64 = resp
                        setStudent(currentStudent)
                        if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && subExam?.isStoreThumbImpression && (!matchedFingerprint || matchedFingerprint === '')) {
                            setCounter(3)
                            matchFingerprint()
                        } else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                            setCounter(2)
                            getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
                        } else if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 === '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                            setCounter(1)
                            getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
                        } else {
                            setCounter(4)
                        }
                    }
                })
            } else {
                facenetObj = new Facenet('', '')
                facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data').then((resp) => {
                    console.log(resp)
                    if (resp === 'No Face Detected') {
                        // setStudent((pre) => {
                        //     pre.realTimeBase64 = photo
                        //     return { ...pre }
                        // })
                        currentStudent.realTimeBase64 = photo
                        setStudent(currentStudent)
                        if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && subExam?.isStoreThumbImpression && (!matchedFingerprint || matchedFingerprint === '')) {
                            setCounter(3)
                            matchFingerprint()
                        } else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                            setCounter(2)
                            getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
                        } else if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 === '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                            setCounter(1)
                            getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
                        } else {
                            setCounter(4)
                        }
                    } else {
                        // setStudent((pre) => {
                        //     pre.realTimeBase64 = resp
                        //     return { ...pre }
                        // })
                        currentStudent.realTimeBase64 = resp
                        setStudent(currentStudent)
                        if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && subExam?.isStoreThumbImpression && (!matchedFingerprint || matchedFingerprint === '')) {
                            setCounter(3)
                            matchFingerprint()
                        } else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                            setCounter(2)
                            getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
                        } else if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 === '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                            setCounter(1)
                            getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
                        } else {
                            setCounter(4)
                        }
                    }
                })
            }
            // var preprocess = new Preprocess()
            // preprocess.loadPreprocesser('/facenet-data').then(() => {
            //     console.log('model loaded');
            //     const img = document.createElement("img");
            //     img.src = pre.realTimeBase64
            //     img.id = 'image'
            //     preprocess.preprocessImage(img).then((resp) => {
            //         console.log("preprocess Response:", resp)
            //     })
            // });
            // pre.realTimePhotoCaptureTime = new Date()
        }
    }

    function captureStudentDocument() {
        let currentStudent = _student.current
        if (currentStudent.idCardBase64 && currentStudent.idCardBase64 !== '') {
            // setStudent((pre) => {
            //     pre.idCardBase64 = ''
            //     return { ...pre }
            // })
            currentStudent.idCardBase64 = ''
            setStudent(currentStudent)
            setCounter(4)
        } else {
            // setStudent((pre) => {
            //     pre.idCardBase64 = docRef.current.getScreenshot()
            //     // pre.idCardCaptureTime = new Date()
            //     return { ...pre }
            // })
            currentStudent.idCardBase64 = docRef.current.getScreenshot()
            setStudent(currentStudent)
        }
    }

    function captureFingertprint(key, temp) {
        let currentStudent = _student.current
        console.log(currentStudent)
        if (currentStudent[key] && currentStudent[key] !== '') {
            setStudent((pre) => {
                pre[key] = ''
                pre[temp] = ''
                if (temp === 'fingerprintTemplate2') {
                    setCounter(2)
                } else {
                    setCounter(1)
                }
                return { ...pre }
            })
            getFingerprint(key, temp)
        } else {
            getFingerprint(key, temp)
        }
    }

    function getFingerprint(key, temp) {
        // document.removeEventListener('keyup', tabPress)
        // allowTabRef.current = false
        let activeIndex = document.activeElement.tabIndex
        console.log(activeIndex)
        // removeTabIndex()
        setTimeout(() => {
            for (let i = 0; i < 4; i++) {
                console.log(i)
                const fingerprint = CaptureFinger(10, 4)
                console.log(fingerprint)
                if ((fingerprint.data?.ErrorDescription === 'MFS100 not Found') || (fingerprint.err === 'Error while calling service')) {
                    setErrMsg('Fingerprint machine not connected')
                    setFunctionTime(new Date().getTime())
                    // addTabIndex(1)
                    // allowTabRef.current = true
                } else {
                    if (fingerprint.data.Quality > 70) {
                        console.log("in >70")
                        if (temp === 'fingerprintTemplate1' && _student.current && _student.current.fingerprintTemplate2) {
                            const isMatched = VerifyFinger(fingerprint.data.IsoTemplate, _student.current.fingerprintTemplate2)
                            if (isMatched.data.Status) {
                                setErrMsg('Fingerprint already Registered,please use different finger')
                                console.log("Inside Function", new Date())
                                setFunctionTime(new Date().getTime())
                                // addTabIndex(1)
                                // allowTabRef.current = true
                            } else {
                                console.log("2nd set")
                                let pre = _student.current
                                console.log("2nd set")
                                pre[key] = "data:image/bmp;base64," + fingerprint.data.BitmapData
                                pre[temp] = fingerprint.data.IsoTemplate
                                // pre.fingerprintCaptureTime = new Date()
                                setStudent({ ...pre })
                                setErrMsg("")
                                console.log("Inside Function", new Date())
                                setFunctionTime(new Date().getTime())
                                // allowTabRef.current = true
                                // addTabIndex(1)
                                // document.addEventListener('keyup', tabPress);
                                if (student?.fingerprint2Base64 && student?.fingerprint2Base64 !== '') {
                                    setCounter(3)
                                } else {
                                    setCounter(2)
                                }
                                break;
                            }
                        } else if (temp === 'fingerprintTemplate2' && _student.current && _student.current.fingerprintTemplate1) {
                            const isMatched = VerifyFinger(fingerprint.data.IsoTemplate, _student.current.fingerprintTemplate1)
                            if (isMatched.data.Status) {
                                setErrMsg('Fingerprint already Registered,please use different finger')
                                console.log("Inside Function", new Date())
                                setFunctionTime(new Date().getTime())
                                // addTabIndex(1)
                                // allowTabRef.current = true
                            } else {
                                console.log("2nd else set")
                                let pre = _student.current
                                console.log("2nd set")
                                pre[key] = "data:image/bmp;base64," + fingerprint.data.BitmapData
                                pre[temp] = fingerprint.data.IsoTemplate
                                // pre.fingerprintCaptureTime = new Date()
                                setStudent({ ...pre })
                                setErrMsg("")
                                console.log("Inside Function", new Date())
                                setFunctionTime(new Date().getTime())
                                // allowTabRef.current = true
                                // addTabIndex(1)
                                // document.addEventListener('keyup', tabPress);
                                setCounter(3)
                                break;
                            }
                        } else {
                            let pre = _student.current
                            pre[key] = "data:image/bmp;base64," + fingerprint.data.BitmapData
                            pre[temp] = fingerprint.data.IsoTemplate
                            // pre.fingerprintCaptureTime = new Date()
                            setStudent({ ...pre })
                            setErrMsg("")
                            console.log("Inside Function", new Date())
                            setFunctionTime(new Date().getTime())
                            // allowTabRef.current = true
                            // addTabIndex(1)
                            // document.addEventListener('keyup', tabPress);
                            if (temp === 'fingerprintTemplate2') {
                                setCounter(3)
                            } else {
                                setCounter(2)
                            }
                            break;
                        }
                    } else {
                        setErrMsg("Please place finger appropriately and try again!")
                        if (i == 3 && temp === 'fingerprintTemplate2') {
                            setCounter(2)
                        } else {
                            setCounter(1)
                        }
                        console.log("Inside Function", new Date())
                        setFunctionTime(new Date().getTime())
                        // allowTabRef.current = true
                        // addTabIndex(1)
                    }
                    // document.addEventListener('keyup', tabPress);
                }
            }
        }, 800)
    }

    function verifyFingerprint() {
        if (matchedFingerprint === '') {
            matchFingerprint()
        } else {
            setMatchedFingerprint('')
            matchFingerprint()
        }
    }

    function matchFingerprint() {
        allowTabRef.current = false
        setTimeout(async () => {
            for (let i = 0; i < 4; i++) {
                const fingerprint = await CaptureFinger(10, 4)
                if ((fingerprint.data?.ErrorDescription === 'MFS100 not Found') || (fingerprint.err === 'Error while calling service')) {
                    setErrMsg('Fingerprint machine not connected')
                    setFunctionTime(new Date().getTime())
                    addTabIndex(1)
                    allowTabRef.current = true
                    setFailedMatch(false)
                    break;
                } else {
                    const isMatched = await VerifyFinger(fingerprint.data.IsoTemplate, _student.current.fingerprintTemplate1)
                    if (isMatched.data.Status) {
                        addTabIndex(1)
                        allowTabRef.current = true
                        if (subExam?.isStoreIdentityDocument)
                            setCounter(4)
                        setMatchedFingerprint("data:image/bmp;base64," + fingerprint.data.BitmapData)
                        setSuccessMsg1(`Student Verified : ${_student.current.Applicant}`)
                        setFailedMatch(false)
                        setErrMsg("")
                        setFunctionTime(new Date().getTime())
                        break;
                    } else {
                        const isMatched2 = await VerifyFinger(fingerprint.data.IsoTemplate, _student.current.fingerprintTemplate2)
                        if (isMatched2.data.Status) {
                            addTabIndex(1)
                            allowTabRef.current = true
                            if (subExam?.isStoreIdentityDocument)
                                setCounter(4)
                            setMatchedFingerprint("data:image/bmp;base64," + fingerprint.data.BitmapData)
                            setSuccessMsg1(`Student Verified : ${_student.current.Applicant}`)
                            setFailedMatch(false)
                            setFunctionTime(new Date().getTime())
                            setErrMsg("")
                            break;
                        } else {
                            addTabIndex(1)
                            allowTabRef.current = true
                            setMatchedFingerprint('')
                            setFunctionTime(new Date().getTime())
                            setFailedMatch(true); setHideFingerprint(false)
                            setErrMsg("Fingerprint Not Matched,Please try again or click on Recapture to capture fingerprint again!")
                        }
                    }
                }
            }
        }, 500)
    }

    useEffect(() => {
        if (!student?.isVerified || student?.isVerified === 'FALSE') {
            if (matchedFingerprint !== '') {
                if (subExam?.isStoreIdentityDocument) {
                    addTabIndex(5)
                } else {
                    saveData()
                }
            }
            if (!subExam?.isStoreIdentityDocument && !subExam?.isStoreThumbImpression && student && student.realTimeBase64 && student?.realTimeBase64 !== '') {
                saveData()
            }
            if (!subExam?.isStoreThumbImpression && subExam?.isStoreIdentityDocument && student && student.realTimeBase64 && student?.realTimeBase64 !== '' && student.idCardBase64 && student?.idCardBase64 !== '') {
                saveData()
            }
            if (subExam?.isStoreThumbImpression && subExam?.isStoreIdentityDocument && matchedFingerprint !== '' && student && student.realTimeBase64 && student?.realTimeBase64 !== '' && student.idCardBase64 && student?.idCardBase64 !== '') {
                saveData()
            }
        }
    }, [matchedFingerprint, student])

    function validateAttendance() {
        if (!student?.realTimeBase64 || student?.realTimeBase64 === '') {
            setErrMsg("Student Photo not captured")
            return false
        } else if ((!student?.fingerprintTemplate1 || student?.fingerprintTemplate1 === '') && subExam.isStoreThumbImpression) {
            setErrMsg("Fingerprint 1 not captured")
            return false
        } else if ((!student?.fingerprintTemplate2 || student?.fingerprintTemplate2 === '') && subExam.isStoreThumbImpression) {
            setErrMsg("Fingerprint 2 not captured")
            return false
        } else if ((matchedFingerprint === '') && subExam.isStoreThumbImpression) {
            setErrMsg("Fingerprint not matched")
            return false
        } else {
            return true
        }
    }

    function saveData() {
        let flag = validateAttendance()
        if (flag) {
            DB.students.update(student.id, { ...student, isEdit: "true", isVerified: true, attendanceDateTime: Date.now() }).then((resp) => {
                const element = {
                    id: `${JSON.parse(localStorage.getItem('exam')).name}_${student.id.split('_')[1]}`,
                    [subExamName]: {
                        fingerprintImage1: student.fingerprint1Base64,
                        fingerprintImage2: student.fingerprint2Base64,
                        realTimePhoto: student.realTimeBase64,
                        idCardImage: student.idCardBase64 ? student.idCardBase64 : ''
                    },
                    fingerprintTemplate1: student.fingerprintTemplate1,
                    fingerprintTemplate2: student.fingerprintTemplate2,
                    fingerprint1Base64: student.fingerprint1Base64,
                    fingerprint2Base64: student.fingerprint2Base64,
                    examName: student.examName
                }
                DB.base64.update(element.id, element).then(resp => {
                    if (resp === 0) {
                        DB.base64.add(element).then(resp => {
                            setErrMsg('')
                            nextStudent('face', { ...student, isEdit: "true", isVerified: true, attendanceDateTime: Date.now() });
                            setStudent(null)
                        })
                    } else {
                        setErrMsg('')
                        nextStudent('face', { ...student, isEdit: "true", isVerified: true, attendanceDateTime: Date.now() });
                        setStudent(null)
                    }
                }).catch(err => {
                    // if (err.toString().includes('No document to update')) {
                    //     DB.students.add(element).then(resp => {
                    //         setErrMsg('')
                    //         nextStudent('face', { ...student, isEdit: "true", isVerified: true, attendanceDateTime: Date.now() });
                    //     })
                    // } else {
                    console.log("err", err);
                    // }
                    // dismissLoader();
                })
            })
        }
    }

    function removeTabIndex() {
        console.log("in remove")
        // document.getElementById('1').tabIndex = -1
        // if (JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression) {
        //     document.getElementById('2').tabIndex = -1
        //     document.getElementById('3').tabIndex = -1
        //     document.getElementById('4').tabIndex = -1
        // }
        // if (JSON.parse(localStorage.getItem('subExam')).isStoreIdentityDocument) {
        //     document.getElementById('5').tabIndex = -1
        // }
        // document.getElementById('6').tabIndex = -1
    }

    function addTabIndex(index) {
        // allowTabRef.current = true
        // console.log(index)
        // setTimeout(() => {
        //     document.getElementById('1').tabIndex = 1
        //     if (JSON.parse(localStorage.getItem('subExam')).isStoreThumbImpression) {

        //         document.getElementById('2').tabIndex = 2
        //         document.getElementById('3').tabIndex = 3
        //         document.getElementById('4').tabIndex = 4
        //     }
        //     if (JSON.parse(localStorage.getItem('subExam')).isStoreIdentityDocument) {
        //         document.getElementById('5').tabIndex = 5
        //     }
        //     document.getElementById('6').tabIndex = 6
        //     document.getElementById(index.toString()).focus()
        // }, 500);
    }

    return (
        <div id='main' className={cssClasses.mainContainer} style={{ pointerEvents: student?.isVerified === true ? "none" : "unset", visibility: isVisible ? "hidden" : "unset", height: isVisible ? "0" : "100%" }}>
            <div className={cssClasses.header}>
                <Box display={'flex'}>
                    <div>
                        <Button disable={student?.isVerified === true ? true : false} tabIndex={-1} style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Face Recognition"} clicked={() => { nextStudent('face'); setStudent(null) }} />
                    </div>
                    <div style={{ marginLeft: "1rem" }}>
                        <Button disable={student?.isVerified === true ? true : false} tabIndex={-1} style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Scan Qr Code"} clicked={() => { nextStudent('qr'); setStudent(null) }} />
                    </div>
                </Box>
                <Box>
                    {successMsg ?
                        <p style={{ fontSize: "4rem", color: "green", textAlign: "center" }}>{successMsg}</p>
                        :
                        <p style={{ fontSize: "2rem", color: "red" }}>{errMsg}</p>
                    }
                </Box>
                {subExam?.isProforma &&
                    <Box>
                        {student && !student.isSubmitted &&
                            < Button tabIndex={-1} style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Go To Proforma"} clicked={goToProforma} />
                        }
                    </Box>
                }
            </div>
            <div style={{ overflowY: "auto" }}>
                <div style={{ width: "85%", margin: "0 auto", padding: "1rem 0" }}>
                    <Card id='1' tabIndex={-1} defaultChecked={true} style={{ padding: "1rem", borderRadius: "1rem", marginBottom: "1rem", background: "#fcfcfd", outline: counter === 0 ? '2px solid black' : 'none' }}>
                        {/* <div className={cssClasses.cardTitle}>
                            <p>Student Photo</p>
                        </div> */}
                        {/* <div style={{ display: "flex", fontSize: "1.4rem", justifyContent: "space-around" }}>
                            <div>
                                <p>Previous Exam Photos</p>
                            </div>
                            <div>
                                <p>Capture Live Photo</p>
                            </div>
                        </div> */}
                        <div className={cssClasses.cardInfo}>
                            <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                {studentPhotoArr.length === 0 ?
                                    <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                                        <div style={{ fontSize: "1.4rem", textAlign: "center" }}>
                                            <p>Previous Exam Photos</p>
                                        </div>
                                        <div style={{ textAlign: "center", fontSize: "2rem" }}>
                                            <div>
                                                <p>No Data Available</p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className={cssClasses.halfDiv}>
                                        <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                            <div style={{ fontSize: "1.4rem", textAlign: "center", marginBottom: "2rem" }}>
                                                <p>Previous Exam Photos</p>
                                            </div>
                                            <div>
                                                <Swiper
                                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                                    spaceBetween={10}
                                                    slidesPerView={studentPhotoArr.length > 1 ? 2 : 1}
                                                    navigation
                                                    pagination={{ clickable: true }}
                                                >
                                                    {studentPhotoArr.map((ele) => (
                                                        <>
                                                            <SwiperSlide style={{ textAlign: "center" }}><img className={cssClasses.swiperImg} src={ele.image} alt="" />
                                                                <p style={{ fontSize: "1.4rem" }}>{ele.examName}</p>
                                                            </SwiperSlide>
                                                            {/* <SwiperSlide><img className={cssClasses.swiperImg} src={img} alt="" /></SwiperSlide>
                                                        <SwiperSlide><img className={cssClasses.swiperImg} src={ScanImg} alt="" /></SwiperSlide>
                                                        <SwiperSlide><img className={cssClasses.swiperImg} src={img} alt="" /></SwiperSlide> */}
                                                        </>
                                                    ))
                                                    }
                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className={cssClasses.halfDiv1}>
                                    <Card className={cssClasses.captureCard}>
                                        {/* {student && (student.realTimeBase64 && student.realTimeBase64 !== '') && */}
                                        <div style={{ position: "relative", display: student && (student.realTimeBase64 && student.realTimeBase64 !== '') ? "unset" : "none" }}>
                                            <img className={cssClasses.capturedImg} style={{ objectFit: "none" }} src={student?.realTimeBase64} alt="" />
                                            <IconButton tabIndex={-1} className={cssClasses.cameraIcon} aria-label="delete" size="small" onClick={() => { captureStudentPhoto(); document.getElementById('1').focus() }}>
                                                <FlipCameraAndroidIcon tabIndex={-1} style={{ color: "rgba(0,0,0,0.5)", width: "3rem", height: "3rem" }} />
                                            </IconButton>
                                        </div>
                                        {/* } */}
                                        {/* {student && (!student.realTimeBase64 || student.realTimeBase64 === '') && */}
                                        <div style={{ position: "relative", display: student && (!student.realTimeBase64 || student.realTimeBase64 === '') ? "unset" : "none" }}>
                                            <Webcam
                                                audio={false}
                                                ref={photoRef}
                                                screenshotFormat="image/jpeg"
                                                className={cssClasses.videoCamera}
                                                onUserMediaError={cameraError}
                                                onUserMedia={cameraFound}
                                                videoConstraints={{ deviceId: deviceId }}
                                            />
                                            <IconButton tabIndex={-1} className={cssClasses.cameraIcon} aria-label="delete" size="small" onClick={() => { captureStudentPhoto(); document.getElementById('1').focus() }}>
                                                <CameraIcon tabIndex={-1} style={{ color: "rgba(0,0,0,0.5)", width: "3rem", height: "3rem" }} />
                                            </IconButton>
                                        </div>
                                        {/* } */}
                                        {/* {(student && (student.realTimeBase64 && student.realTimeBase64 !== '')) &&
                                            <div>
                                                <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Recapture"} clicked={captureStudentPhoto} />
                                            </div>
                                        } */}
                                        {/* {(student && (!student.realTimeBase64 || student.realTimeBase64 == '')) &&
                                            <div>
                                                <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Capture"} clicked={captureStudentPhoto} />
                                            </div>
                                        } */}
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </Card>
                    {subExam?.isStoreThumbImpression &&
                        <div>
                            <Card tabIndex={-1} style={{ padding: "1rem", borderRadius: "1rem", marginBottom: "1rem", background: "#fcfcfd" }}>
                                {/* <div className={cssClasses.cardTitle}>
                                <p>Bio Metric</p>
                            </div> */}
                                <div style={{ display: "flex", fontSize: "1.4rem", justifyContent: "space-around" }}>
                                    <div>
                                        <p>Fingerprint 1</p>
                                    </div>
                                    <div>
                                        <p>Fingerprint 2</p>
                                    </div>
                                    <div>
                                        <p>Match Finger</p>
                                    </div>
                                </div>
                                <div className={cssClasses.cardInfo}>
                                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <div className={cssClasses.halfDiv1}>
                                            <Card id='2' tabIndex={-1} className={cssClasses.captureCard} style={{ outline: counter === 1 ? '2px solid black' : 'none' }}>
                                                {!hideFingerprint ?
                                                    <div className={cssClasses.captureCard}>
                                                        {student && (student.fingerprint1Base64 && student.fingerprint1Base64 !== '') &&
                                                            <img className={cssClasses.fingerprintImg} src={student.fingerprint1Base64} alt="" />
                                                        }
                                                        {student && (!student.fingerprint1Base64 || student.fingerprint1Base64 === '') &&
                                                            <img className={cssClasses.fingerprintImg} src={biometric} alt="" />
                                                        }
                                                        {(student && (student.fingerprint1Base64 && student.fingerprint1Base64 !== '')) &&
                                                            <div>
                                                                <div className={cssClasses.buttonWrapper} style={{ pointerEvents: (errMsg === 'Fingerprint machine not connected' || !failedMatch) ? "none" : "unset", opacity: (errMsg === 'Fingerprint machine not connected' || !failedMatch) ? "0.5" : "unset" }}>
                                                                    <div className={cssClasses.button} onClick={() => { captureFingertprint('fingerprint1Base64', 'fingerprintTemplate1'); setCounter(1) }}>
                                                                        <p className={cssClasses.btnText}>Recapture</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {(student && (!student.fingerprint1Base64 || student.fingerprint1Base64 === '')) &&
                                                            <div>
                                                                <div className={cssClasses.buttonWrapper} style={{ pointerEvents: (errMsg === 'Fingerprint machine not connected' || !student.realTimeBase64 || student.realTimeBase64 == '') ? "none" : "unset", opacity: (errMsg === 'Fingerprint machine not connected' || !student.realTimeBase64 || student.realTimeBase64 == '') ? "0.5" : "unset" }}>
                                                                    <div className={cssClasses.button} onClick={() => { captureFingertprint('fingerprint1Base64', 'fingerprintTemplate1'); setCounter(2) }}>
                                                                        <p className={cssClasses.btnText}>Capture</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    :
                                                    <div>
                                                        <p style={{ fontSize: "1.8rem", color: "green" }}>Completed</p>
                                                    </div>
                                                }
                                            </Card>
                                        </div>
                                        <div className={cssClasses.halfDiv1}>
                                            <Card id='3' tabIndex={-1} className={cssClasses.captureCard} style={{ outline: counter === 2 ? '2px solid black' : 'none' }}>
                                                {!hideFingerprint ?
                                                    <div className={cssClasses.captureCard}>
                                                        {student && (student.fingerprint2Base64 && student.fingerprint2Base64 !== '') &&
                                                            <img className={cssClasses.fingerprintImg} src={student.fingerprint2Base64} alt="" />
                                                        }
                                                        {student && (!student.fingerprint2Base64 || student.fingerprint2Base64 === '') &&
                                                            <img className={cssClasses.fingerprintImg} src={biometric} alt="" />
                                                        }
                                                        {(student && (student.fingerprint2Base64 && student.fingerprint2Base64 !== '')) &&
                                                            <div>
                                                                <div className={cssClasses.buttonWrapper} style={{ pointerEvents: (errMsg === 'Fingerprint machine not connected' || !failedMatch) ? "none" : "unset", opacity: (errMsg === 'Fingerprint machine not connected' || !failedMatch) ? "0.5" : "unset" }}>
                                                                    <div className={cssClasses.button} onClick={() => { captureFingertprint('fingerprint2Base64', 'fingerprintTemplate2'); document.getElementById('3').focus() }}>
                                                                        <p className={cssClasses.btnText}>Recapture</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {(student && (!student.fingerprint2Base64 || student.fingerprint2Base64 === '')) &&
                                                            <div>
                                                                <div className={cssClasses.buttonWrapper} style={{ pointerEvents: (errMsg === 'Fingerprint machine not connected' || !student.fingerprint1Base64 || student.fingerprint1Base64 == '') ? "none" : "unset", opacity: (errMsg === 'Fingerprint machine not connected' || !student.fingerprint1Base64 || student.fingerprint1Base64 == '') ? "0.5" : "unset" }}>
                                                                    <div className={cssClasses.button} onClick={() => { captureFingertprint('fingerprint2Base64', 'fingerprintTemplate2'); document.getElementById('3').focus() }}>
                                                                        <p className={cssClasses.btnText}>Capture</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    :
                                                    <div>
                                                        <p style={{ fontSize: "1.8rem", color: "green" }}>Completed</p>
                                                    </div>
                                                }
                                            </Card>
                                        </div>
                                        <div className={cssClasses.halfDiv1}>
                                            <Card id='4' tabIndex={-1} className={cssClasses.captureCard} style={{ outline: counter === 3 ? '2px solid black' : 'none' }}>
                                                {student && (matchedFingerprint && matchedFingerprint !== '') &&
                                                    <img className={cssClasses.fingerprintImg} src={matchedFingerprint} alt="" />
                                                }
                                                {student && (!matchedFingerprint || matchedFingerprint === '') &&
                                                    <img className={cssClasses.fingerprintImg} src={biometric} alt="" />
                                                }
                                                {/* {(student && (matchedFingerprint && matchedFingerprint !== '')) &&
                                            <div>
                                                <div className={cssClasses.buttonWrapper}>
                                                    <div className={cssClasses.button} onClick={() => verifyFingerprint()}>
                                                        <p className={cssClasses.btnText}>Re-match</p>
                                                    </div>
                                                </div>
                                            </div>

                                        } */}
                                                {(student && (!matchedFingerprint || matchedFingerprint === '')) &&
                                                    <div>
                                                        <div className={cssClasses.buttonWrapper} style={{ pointerEvents: (errMsg === 'Fingerprint machine not connected' || (!student?.fingerprint1Base64 && !student?.fingerprint2Base64)) ? "none" : "unset", opacity: (errMsg === 'Fingerprint machine not connected' || (!student?.fingerprint1Base64 && !student?.fingerprint2Base64)) ? "0.5" : "unset" }}>
                                                            <div className={cssClasses.button} onClick={() => { verifyFingerprint(); document.getElementById('4').focus() }}>
                                                                <p className={cssClasses.btnText}>Match</p>
                                                            </div>
                                                        </div>
                                                        {/* {errMsg === "Fingerprint Not Matched,Please try again!" &&
                                                            <div className={cssClasses.buttonWrapper} style={{ pointerEvents: (errMsg === 'Fingerprint machine not connected' || (!student?.fingerprint1Base64 && !student?.fingerprint2Base64)) ? "none" : "unset", opacity: (errMsg === 'Fingerprint machine not connected' || (!student?.fingerprint1Base64 && !student?.fingerprint2Base64)) ? "0.5" : "unset" }}>
                                                                <div className={cssClasses.button} onClick={() => { setFailedMatch(true); setHideFingerprint(false) }}>
                                                                    <p className={cssClasses.btnText}>Re - register</p>
                                                                </div>
                                                            </div>
                                                        } */}
                                                    </div>
                                                }
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div >
                    }
                    {
                        subExam?.isStoreIdentityDocument &&
                        <Card id='5' tabIndex={-1} style={{ padding: "1rem", borderRadius: "1rem", marginBottom: "1rem", background: "#fcfcfd", outline: counter === 4 ? "2px solid black" : 'none' }}>
                            {/* <div className={cssClasses.cardTitle}>
                                <p>Documentation</p>
                            </div> */}
                            {/* <div style={{ display: "flex", fontSize: "1.4rem", justifyContent: "space-around" }}>
                                <div>
                                    <p>Capture Document</p>
                                </div>
                            </div> */}
                            <div className={cssClasses.cardInfo}>
                                <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                    {studentIdArr.length === 0 ?
                                        <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                                            <div style={{ fontSize: "1.4rem", textAlign: "center" }}>
                                                <p>Previous Exam Documents</p>
                                            </div>
                                            <div style={{ textAlign: "center", fontSize: "2rem" }}>
                                                <div>
                                                    <p>No Data Available</p>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className={cssClasses.halfDiv}>
                                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                                <div style={{ fontSize: "1.4rem", textAlign: "center", marginBottom: "2rem" }}>
                                                    <p>Previous Exam Documents</p>
                                                </div>
                                                <div>
                                                    <Swiper
                                                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                                                        spaceBetween={10}
                                                        slidesPerView={studentIdArr.length > 1 ? 2 : 1}
                                                        navigation
                                                        pagination={{ clickable: true }}
                                                    >
                                                        {studentIdArr.map((ele) => (
                                                            <>
                                                                <SwiperSlide style={{ textAlign: "center" }}><img className={cssClasses.swiperImg} src={ele.image} alt="" />
                                                                    <p style={{ fontSize: "1.4rem" }}>{ele.examName}</p>
                                                                </SwiperSlide>
                                                                {/* <SwiperSlide style={{textAlign:"center"}}><img className={cssClasses.swiperImg} src={img} alt="" /></SwiperSlide>
                                                <SwiperSlide style={{textAlign:"center"}}><img className={cssClasses.swiperImg} src={ScanImg} alt="" /></SwiperSlide>
                                                <SwiperSlide style={{textAlign:"center"}}><img className={cssClasses.swiperImg} src={img} alt="" /></SwiperSlide> */}
                                                            </>
                                                        ))
                                                        }
                                                    </Swiper>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className={cssClasses.halfDiv1}>
                                        <Card className={cssClasses.captureCard}>
                                            {/* {student && (student.idCardBase64 && student.idCardBase64 !== '') && */}
                                            <div style={{ position: "relative", display: student && (student.idCardBase64 && student.idCardBase64 !== '') ? "unset" : "none" }}>
                                                <img className={cssClasses.capturedImg} src={student?.idCardBase64} alt="" />
                                                <IconButton className={cssClasses.cameraIcon} aria-label="delete" size="small" onClick={() => { captureStudentDocument(); document.getElementById('5').focus() }}>
                                                    <FlipCameraAndroidIcon style={{ color: "rgba(0,0,0,0.5)", width: "3rem", height: "3rem" }} />
                                                </IconButton>
                                            </div>
                                            {/* } */}
                                            {/* {student && (!student.idCardBase64 || student.idCardBase64 === '') && */}
                                            <div style={{ position: "relative", display: student && (!student.idCardBase64 || student.idCardBase64 === '') ? "unset" : "none" }}>
                                                <Webcam
                                                    audio={false}
                                                    ref={docRef}
                                                    screenshotFormat="image/jpeg"
                                                    className={cssClasses.videoCamera}
                                                    onUserMediaError={cameraError}
                                                    onUserMedia={cameraFound}
                                                    videoConstraints={{ deviceId: deviceId }}
                                                />
                                                <IconButton className={cssClasses.cameraIcon} aria-label="delete" size="small" onClick={() => { captureStudentDocument(); document.getElementById('5').focus() }}>
                                                    <CameraIcon style={{ color: "rgba(0,0,0,0.5)", width: "3rem", height: "3rem" }} />
                                                </IconButton>
                                            </div>
                                            {/* } */}
                                            {/* {(student && (student.idCardBase64 && student.idCardBase64 !== '')) &&
                                            <div>
                                                <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Recapture"} clicked={captureStudentDocument} />
                                            </div>
                                        }
                                        {(student && (!student.idCardBase64 || student.idCardBase64 == '')) &&
                                            <div>
                                                <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Capture"} clicked={captureStudentDocument} />
                                            </div>
                                        } */}
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    }
                </div >
            </div >
            <div className={cssClasses.footer}>
                <Box style={{ width: "60%" }}>
                    {/* {successMsg1 && matchedFingerprint !== '' &&
                        <p style={{ fontSize: "3rem", color: "red", textAlign: "center" }}>Click ENTER TAB to Register -----{`>`}</p>
                    } */}
                </Box>
                <div>
                    <Button disable={student?.isVerified === true && matchedFingerprint === '' ? true : false} id={'6'} tabIndex={-1} style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Mark Attendance & Next Student"} clicked={() => { saveData() }} />
                </div>
            </div>
        </div >
    )
}

function useKeyPress(targetKey) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(0);
    // If pressed key is our target key then set to true
    function downHandler(e) {
        console.log("Event time -------->", new Date(Date(e.timestamp)).getTime())
        // setEventTime(new Date(Date(e.timestamp)).getTime())
        if (e.key === targetKey) {
            setKeyPressed(new Date(Date(e.timestamp)).getTime());
            // setTimeout(() => {
            //     setKeyPressed(new Date(Date(e.timestamp)).getTime());
            // }, 500)
        }
    }
    // If released key is our target key then set to false
    // const upHandler = ({ key }) => {
    //     if (key === targetKey) {
    //         setKeyPressed(false);
    //     }
    // };
    // Add event listeners
    useEffect(() => {
        document.addEventListener("keyup", downHandler);
        // document.addEventListener("keyup", upHandler);
        // Remove event listeners on cleanup
        return () => {
            document.removeEventListener("keyup", downHandler);
            // document.removeEventListener("keyup", upHandler);
        };
    }, []); // Empty array ensures that effect is only run on mount and unmount
    return keyPressed;
}
