/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Fragment, useEffect, useState } from 'react';
import { getFilesFromAWS } from '../../services/AwsService';
import { getSelectedStudentDetails } from '../../services/StudentService';
import Modal from "@mui/material/Modal";
import cssClasses from "./StudentPhotos.module.css";
import close from "../../assets/images/cross.svg";
import { DB } from "../../db"
import { Box } from '@mui/material';
export default function StudentPhotos({ open, handleClose }) {
    const [allPhotos, setAllPhotos] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState({});
    useEffect(() => {
        const student = getSelectedStudentDetails();
        setSelectedStudent(student);
        getPhotosFromAWS();
    }, []);
    function getPhotosFromAWS() {
        const student = getSelectedStudentDetails();
        console.log("student.id => ", student.id);
        DB.base64.get(`${JSON.parse(localStorage.getItem('exam')).name}_${student.id.split('_')[1]}`).then(res => {
            let _photos = { ...res };
            delete _photos.id;
            delete _photos.examName;
            delete _photos.fingerprint1Base64;
            delete _photos.fingerprint2Base64;
            delete _photos.fingerprintTemplate1;
            delete _photos.fingerprintTemplate2;
            console.log(res);
            setAllPhotos(_photos)
        })
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={[
                {
                    ".MuiBackdrop-root": {
                        background: "rgba(193, 201, 210, 0.7)"
                    }
                }
            ]}
            disableAutoFocus={true}
        >
            <div className={cssClasses.wrapper}>
                <div className={cssClasses.modalHeader}>
                    <div className={cssClasses.modalTitle}>
                        {selectedStudent["Full Name"]} 's Photos
                    </div>
                    <div className={cssClasses.modalClose}>
                        <img src={close} alt="Close" onClick={handleClose} />
                    </div>
                </div>
                <div className={cssClasses.modalBody}>
                    {
                        Object.keys(allPhotos).map((subExamName, index) => (
                            <Box marginBottom={"2rem"} key={`SubExam_${subExamName}_${index}`}>
                                <h1 style={{ textTransform: "capitalize", textAlign: "left" }}>{subExamName}</h1>
                                <Box display={"flex"} flexDirection={"row"}>
                                    {
                                        Object.keys(allPhotos[subExamName]).map((photoKeys, photoIndex) => (
                                            <Fragment key={`SubExam_${subExamName}_${index}_Photo_${photoIndex}`}>
                                                {allPhotos[subExamName][photoKeys] &&
                                                    <Box display={"flex"} flexDirection={"column"} margin={"0 1rem"} justifyContent={"space-between"}>
                                                        <div className={cssClasses.img}>
                                                            <img src={allPhotos[subExamName][photoKeys]} alt="Photo" />
                                                        </div>
                                                        <div className={cssClasses.examName}>
                                                            {photoKeys}
                                                        </div>
                                                    </Box>
                                                }
                                            </Fragment>
                                        ))
                                    }
                                </Box>
                            </Box>
                        ))
                    }
                </div>
            </div>
        </Modal>
    )
}