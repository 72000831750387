/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import html2pdf from "html2pdf.js";

import cssClasses from "./KsisePDF.module.css";

import menImage from "../../assets/images/man (1).png";
import womenImage from "../../assets/images/woman (1).png";

import JsBarcode from "jsbarcode";
import QRCode from "qrcode";
import { dismissLoader, presentLoader } from "../../services/loaderService";
import { errorToast } from "../../services/Toast";

const download = () => {
    const element = document.getElementById("rsiPdf");
    const opt = {
        margin: [0, 0],
        filename: "invoice.pdf",
        html2canvas: {
            useCORS: true,
            scale: 2,
        },
        image: { type: "jpg", quality: 0.5 },
        jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
        pagebreak: { avoid: ".avoid" },
    };

    html2pdf()
        .from(element)
        .set(opt)
        .save()
        .then((res) => {

        })
        .catch((err) => {

        });
};
 
export default function KsisePDF({ Student, setObject }) {

    const [firstImage, setFirstImage] = useState(null)

    const [qr_Code, setQr_Code] = useState("");
    const [bar_Code, setBar_Code] = useState("");
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [realTimePhoto, setRealTimePhoto] = useState(null);

    // useEffect(async () => {
    //   const url = `${Student.Applicant}.jpg`;
    //   getBase64FromImageUrl("https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=https://storage.googleapis.com/hts-cloud-storage-testing/"+url);
    // });

    useEffect(async () => {
        console.log(Student);
        let barCode = generateApplicantBarCode(Student?.Applicant);
        let qrCode = await generateQrCode(Student?.Applicant)
        // await getBase64FromImageUrl("https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url="+process.env.REACT_APP_bucketAccessRootPath+Student?.Photo);
        await getBase64FromImageUrl("profile","https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url="+process.env.REACT_APP_bucketAccessRootPath+Student?.Photo);
        if(Student?.realTimePhoto && Student?.realTimePhoto !== ""){
          await getBase64FromImageUrl("RealTime","https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url="+process.env.REACT_APP_bucketAccessRootPath+Student?.realTimePhoto);
        }
        console.log(process.env.REACT_APP_bucketAccessRootPath+Student?.Photo);
        setBar_Code(barCode);
        setQr_Code(qrCode);
      }, [Student]);

      function generateApplicantBarCode(appId) {
        const canvas = document.createElement("canvas");
        JsBarcode(canvas, appId.toString(), {
          lineColor: "#000000",
          width: 3,
          height: 70,
          background: "#ffffff",
          displayValue: false,
        });
        return canvas.toDataURL();
      }  

      function generateQrCode(appId) {
        return new Promise((resolve, reject) => {
          var opts = {
            margin: 1,
          };
          QRCode.toDataURL(appId.toString(), opts).then((url) => {
            resolve(url);
          }).catch((err) => {
            reject(err);
          });
        });
      }

      function getBase64FromImageUrl(setType,url) {
        console.log("url ==> ",url);
        var img = new Image();
        img.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          var dataURL = canvas.toDataURL("image/jpeg");
          // console.log(dataURL);
          if(setType === "profile"){
            setProfilePhoto(dataURL);
          } else {
            setRealTimePhoto(dataURL);
          }
        };

        // img.onerror = function () {
        //     if(setType === "profile"){
        //         setProfilePhoto("Error");
        //       } else {
        //         setRealTimePhoto(Student.realTimeBase64);
        //       }
        // }
        
        img.crossOrigin = '';
        img.crossOrigin='anonymous';
        img.src = url;
      }

    useEffect(() => {
        if ((Student?.realTimePhoto && Student?.realTimePhoto!=="") ? (qr_Code !== "" && bar_Code !== "" && profilePhoto && realTimePhoto) : (qr_Code !== "" && bar_Code !== "" && profilePhoto)) {
        // if (qr_Code !== "" && bar_Code !== "" && profilePhoto && realTimePhoto) {
            console.log({qr_Code, bar_Code, profilePhoto});
          var id = "rsiPdf";
          const element = document.getElementById(id);
          const opt = {
            margin: [0.1, 0.1],
            filename: `${Student["Full Name"]} Result Sheet.pdf`,
            html2canvas: {
              useCORS: true,
              allowTaint: true,
              scale: 1,
            },
            image: { type: "jpg", quality: 0.5 },
            jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
            pagebreak: { avoid: ".avoid" },
          };
          presentLoader();
          html2pdf().from(element).set(opt).save().then((res) => {
            console.log(res);
            dismissLoader();
            // setObject();
          }).catch((err) => {
            errorToast("Something went wrong! Try again")
            console.log(err);
            dismissLoader();
            // setObject();
          });
        } else
        if((Student?.Photo ==="" || !Student?.Photo) && qr_Code !== "" && bar_Code !== "" ) {
            var id = "rsiPdf";
          const element = document.getElementById(id);
          const opt = {
            margin: [0.1, 0.1],
            filename: `${Student["Full Name"]} Result Sheet.pdf`,
            html2canvas: {
              useCORS: true,
              allowTaint: true,
              scale: 1,
            },
            image: { type: "jpg", quality: 0.5 },
            jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
            pagebreak: { avoid: ".avoid" },
          };
          presentLoader();
          html2pdf().from(element).set(opt).save().then((res) => {
            console.log(res);
            dismissLoader();
            // setObject();
          }).catch((err) => {
            errorToast("Something went wrong! Try again")
            console.log(err);
            dismissLoader();
            // setObject();
          });
        }
      }, [qr_Code,bar_Code,profilePhoto,realTimePhoto])

    return (
        <>
            {/* style={{ display: "none" }} */}
            <div style={{ display: "none" }}>
                <div id="rsiPdf">
                    <div className={cssClasses.headerPDF}>

                        <div className={cssClasses.leftHeader}>
                            <div>
                                <div style={{ fontSize: "11px" }}>
                                    <p><b>PROFORMA–I & II</b></p>
                                    <div className={cssClasses.photoWrapper}>
                                        <div><img src={profilePhoto} alt={""} /></div>
                                        <div>
                                          <img 
                                            src={Student.realTimePhoto} 
                                            alt={""} 
                                            onError={(e) => { e.target.onerror = null; e.target.src = Student.realTimeBase64 }}
                                          />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p style={{ fontSize: "10px", }}><b><u>KARNATAKA STATE POLICE</u></b></p>
                                    <p style={{ fontSize: "11px" }}><b><u>Recruitment of PSI (KSISF) – 2021-22</u></b></p>
                                    <p style={{ fontSize: "10px", }}><b>RESULT SHEET OF PHYSICAL STANDARD TEST (PST) & ENDURANCE TEST (ET) & PHYSICAL EFFICIENCY TEST (PET)</b></p>
                                    {/* <p style={{ fontSize: "13px", }}><b>PHYSICAL EFFICIENCY TEST (PET)</b></p> */}
                                    <p style={{ fontSize: "11px", }}><b>Notification No. 18/Rect-2/2021-22, Dated: 10.02.2020</b></p>
                                    <p style={{ fontSize: "10px" }}>(in Triplicate : 1 for the Candidate,  1 for the Committee & 1 for the Recruitment Section)</p>
                                    <div className={cssClasses.barcodeWrapper}>
                                        <div>
                                            <img style={{ width: '200px' }} src={bar_Code} alt="" />
                                            {/* <img style={{ width: '200px' }} src={Student.DistrictBarCode} alt="" /> */}
                                        </div>
                                        <span>
                                            <img src={qr_Code} alt="" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p style={{ marginBottom: "2px",fontSize: "11px" }}>Sl.No. <u>{Student.SNO}</u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Name of the Candidate : <u>{Student["Full Name"]}</u></p>
                                <p style={{ fontSize: "11px" }}>Appn. No. <u>{Student.Applicant}</u> &nbsp;&nbsp;&nbsp;  Chest No. : <u>{Student.chest_no}</u>  &nbsp;&nbsp;&nbsp; Gender :  <u>{Student.Gender}</u> </p>
                            </div>
                        </div>

                        <div>
                            <table style={{ fontSize: "11px", fontWeight: 'bold', borderCollapse: 'collapse', height: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td>Unit&nbsp;:</td>
                                        <td colSpan={5}>{Student.City}</td>
                                    </tr>
                                    <tr>
                                        <td>Venue&nbsp;:</td>
                                        <td colSpan={5}>{Student.examCenterName}</td>
                                    </tr>
                                    <tr>
                                        <td>Date&nbsp;:</td>
                                        <td colSpan={5}>{Student.examDate}</td>
                                    </tr>

                                    <tr>
                                        <td>Applied&nbsp;as&nbsp;a:</td>
                                        <td style={{ textAlign: "center" }}>Direct</td>
                                        <td style={{ textAlign: "center" }}>Exservicemen</td>
                                        {/* <td style={{textAlign:"center"}}>Exs-C</td>
                  <td style={{textAlign:"center"}}>Tribal</td> */}
                                        <td style={{ textAlign: "center" }}>Inservice</td>
                                    </tr>
                                    <tr>
                                        <td>(✔&nbsp;in&nbsp;appr.&nbsp;Box)</td>
                                        <td style={{ textAlign: "center" }}>{Student.AppliedAs === "Direct" ? "✔" : ""}</td>
                                        <td style={{ textAlign: "center" }}>{Student.AppliedAs === "Exservicemen" ? "✔" : ""}</td>
                                        {/* <td style={{textAlign:"center"}}>{Student.AppliedAs==="Exs-C" ? "✔" : ""}</td>
                  <td style={{textAlign:"center"}}>{Student.AppliedAs==="Tribal" ? "✔" : ""}</td> */}
                                        <td style={{ textAlign: "center" }}>{Student.AppliedAs === "Inservice" ? "✔" : ""}</td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className={cssClasses.proforma2Wrapper}>
                        {/* <p><b>PROFORMA–I<b><u>ENDURANCE TEST</u></b> :  (<u>Please conduct all the tests in Chronological Order</u>)</b></p> */}
                        <p><u><b>PHYSICAL STANDARD TEST</b></u></p>
                        {/* {Student.AppliedAs !== "Inservice" && */}
                        <table style={{ textAlign: "left", "borderCollapse": "collapse" }}>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: "center" }}>Sl. No</th>
                                    <th style={{ textAlign: "center" }} >Event</th>
                                    {/* <th style={{ textAlign: "center" }} rowSpan={2}>Qualifying Time/Distance</th> */}
                                    <th style={{ textAlign: "center" }} >Physical Standard required</th>
                                    {/* <th rowSpan={2}>Performance Time/Distance</th> */}
                                    <th>Result</th>
                                    <th><b>Result QUA*/ NOQ*</b></th>
                                    <th>Signature of the Candidate</th>
                                    <th style={{ width: "10%" }} >Signature of the Officer Conducting the test</th>
                                    <th>Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: "center" }}>1</td>
                                    <td><b>Height</b></td>
                                    <td>170 Cms (Men & Exs Child) 168 Cms, (Inservice) 158 Cms (Women & Exs Child), 157 Cms (Inser) Not applicable for Exserviceman</td>
                                    <td style={{ textAlign: "center" }}>_____________Cms. <br /> (Compulsorily&nbsp;mention&nbsp;Exs.&nbsp;Height)</td>
                                    <td style={{ textAlign: "center" }}><span className={cssClasses.colorWhiteText}>rough text</span></td>
                                    <td><span className={cssClasses.colorWhiteText}>rough text</span></td>
                                    <td><span className={cssClasses.colorWhiteText}>rough text</span></td>
                                    <td style={{ textAlign: "center" }}><span className={cssClasses.colorWhiteText}>rough text</span></td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>2</td>
                                    <td>Chest Girth<b/></td>
                                    <td><p>86 Cms when fully expanded</p><p>(min, expansion 5 Cms)</p></td>
                                    <td style={{ textAlign: "center" }}><p>Fully&nbsp;Expanded&nbsp;___________&nbsp;Cms.</p><p>Expansion&nbsp;___________&nbsp;Cms.</p></td>
                                    <td style={{ textAlign: "center" }}><span className={cssClasses.colorWhiteText}>rough text</span></td>
                                    <td style={{ textAlign: "center" }}><span className={cssClasses.colorWhiteText}>rough text</span></td>
                                    <td style={{ textAlign: "center" }}><span className={cssClasses.colorWhiteText}>rough text</span></td>
                                    <td style={{ textAlign: "center" }}><span className={cssClasses.colorWhiteText}>rough text</span></td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "center" }}>3</td>
                                    <td>Weight<b/></td>
                                    <td><p>45 Kgs. (Womwn (Incl. Exc_Child))</p></td>
                                    <td style={{ textAlign: "center" }}><p>  ___________&nbsp;Kgs.</p></td>
                                    <td style={{ textAlign: "center" }}><span className={cssClasses.colorWhiteText}>rough text</span></td>
                                    <td style={{ textAlign: "center" }}><span className={cssClasses.colorWhiteText}>rough text</span></td>
                                    <td style={{ textAlign: "center" }}><span className={cssClasses.colorWhiteText}>rough text</span></td>
                                    <td style={{ textAlign: "center" }}><span className={cssClasses.colorWhiteText}>rough text</span></td>
                                </tr>
                            </tbody>
                        </table>
                        {/* } */}
                        {/* {Student.AppliedAs === "Inservice" &&
                            <div className={cssClasses.radioWrapper}>
                                <div><b>Status : </b></div>
                                <div><div className={cssClasses.CheckBox}>{Student.PST.isQualified === "true" ? "✔" : ""}</div> Qualified</div>
                                <div><div className={cssClasses.CheckBox}>{Student.PST.isQualified === "false" ? "✔" : ""}</div> Not-Qualified</div>
                            </div>
                        } */}

                    </div>

                    <div className={cssClasses.proforma1Wrapper}>
                        <p><b><u>ENDURANCE TEST :</u></b></p>
                        {/* {Student.AppliedAs !== "Inservice" && */}
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                            <thead>
                                <tr>
                                    <th><b>Sl. No.</b></th>
                                    <th><b>Event</b></th>
                                    <th><b>Qualifying Time/Distance</b></th>
                                    <th><b>Performance Time/Distance</b></th>
                                    <th><b>Result QUA*/ NOQ*</b></th>
                                    <th><b>Signature of the Candidate</b></th>
                                    <th><b>Signature of the Officer Conducting the test</b></th>
                                    <th><b>Remarks</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><b>1</b></td>
                                    <td><p><b>1600&nbsp;mtrs.&nbsp;-&nbsp;Men&nbsp;&&nbsp;Exs_Child</b></p><p><b>400&nbsp;mtrs.&nbsp;-&nbsp;Inservice,&nbsp;Exservicemen</b></p><p><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Women&nbsp;(Exs_Child)</b></p></td>
                                    <td><p>06.30 Mins. (Men & Exs_Child),</p><p>2 Mins. (Inservice, Women (incl Exs_Child), Exs)</p><p>02.30 Mins. (Inservice Women)</p></td>
                                    <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                    <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                    <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                    <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                    <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                </tr>
                            </tbody>
                        </table>
                        {/* } */}

                        <div className={cssClasses.proforma1Wrapper}>
                          <p><b><u>PHYSICAL EFFICIENCY TEST :</u></b></p>
                          {/* {Student.AppliedAs !== "Inservice" && */}
                          <table style={{ borderCollapse: "collapse", width: "100%" }}>
                              <thead>
                                  <tr>
                                      <th><b>Sl. No.</b></th>
                                      <th><p><b>Event</b></p><p><b>SI(KSISF)</b></p></th>
                                      <th><p><b>Qualifying Time/Distance</b></p><p><b>SI(KSISF)</b></p></th>
                                      <th><b>Performance Time/Distance</b></th>
                                      <th><b>Result QUA*/ NOQ*</b></th>
                                      <th><b>Signature of the Candidate</b></th>
                                      <th><b>Signature of the Officer Conducting the test</b></th>
                                      <th><b>Remarks</b></th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td rowSpan={2}><b>1</b></td>
                                      <td rowSpan={2}> <p><b>Long&nbsp;Jump</b></p> <p><b>or</b></p> <p><b>High&nbsp;Jump</b></p> </td>

                                      <td>
                                        <p>3.80&nbsp;mtrs.&nbsp;-&nbsp;Men</p>
                                        <p>3.20&nbsp;mtrs.&nbsp;-&nbsp;Inservice</p>
                                        <p>2.50&nbsp;mtrs.&nbsp;-&nbsp;Women&nbsp;&&nbsp;Exs</p>
                                        <p>2.00&nbsp;mtrs.&nbsp;-&nbsp;Inservice Women</p>
                                      </td>
                                      <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                      <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                      <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                      <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                      <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                  </tr>
                                  <tr>
                                    <td style={{textAlign: "left"}}>
                                      <p>1.20&nbsp;mtrs.&nbsp;-&nbsp;Men</p>
                                      <p>1.00&nbsp;mtrs.&nbsp;-&nbsp;Inservice</p>
                                      <p>0.90&nbsp;mtrs.&nbsp;-&nbsp;Women&nbsp;&&nbsp;Exs</p>
                                      <p>0.80&nbsp;mtrs.&nbsp;-&nbsp;Inservice Women</p>
                                    </td>
                                    <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                    <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                    <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                    <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                    <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                  </tr>
                                  <tr>
                                      <td><b>2</b></td>
                                      <td> <p><b>Short Put</b></p> <p><b>(7.26 Kgs.(Men) & (Inservice) / 4 Kgs, (Women. Ins_women & Exs))</b></p> </td>

                                      <td>
                                        <p>5.60&nbsp;mtrs.&nbsp;-&nbsp;Men</p>
                                        <p>4.50&nbsp;mtrs.&nbsp;-&nbsp;Inservice</p>
                                        <p>3.75&nbsp;mtrs.&nbsp;-&nbsp;Women&nbsp;&&nbsp;Exs</p>
                                        <p>3.50&nbsp;mtrs.&nbsp;-&nbsp;Inservice Women</p>
                                      </td>
                                      <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                      <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                      <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                      <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                      <td><span className={cssClasses.colorWhiteText}>rough_text</span></td>
                                  </tr>
                              </tbody>
                          </table>
                        </div>

                        <div style={{ marginBottom: "3px", marginTop: "3px" }}>
                            <div style={{ width: "70%", display: "inline-block", verticalAlign: "top" }}>
                                <p><b>NOTE:</b>  <b>*</b>  QUA- Qualified,    <b>*</b> NOQ – Not Qualified.</p>
                                <p><b>*</b>  Candidate has to qualify in all the test of PST, ET & PET. Long Jump or High Jump & Shot Put each will have maximum 3 chances.</p>
                                <p><b>*</b>  The candidate should bring this Result Sheet for the written exam if he is found qualified in the PST, ET & PET.</p>
                                <p><b>*</b> All corrections overwriting should be authenticated with full signature with rubber stamp by the Chairman & IGP/DIGP.</p>
                                <p><b>*</b> In High Jump performance column mention candidate jump is <b>clear or not clear.</b></p>
                            </div>
                            <div style={{ width: "30%", display: "inline-block", textAlign: "center", verticalAlign: "bottom" }}>
                                <p><b>Qualified / Not Qualified</b></p>
                                <p style={{ textAlign: "center" }}><b>(Qualified candidate marked in Green ink & disqualified in red ink)</b></p>
                            </div>
                        </div>
                        <div>
                            <div style={{ width: "48%", display: "inline-block", verticalAlign: "middle" }}>
                                <p style={{ textDecoration: "underline", fontSize: "12px" }}><b>DECLARATION</b></p>
                                <p>I hereby declare that all the above tests were conducted to my satisfaction & found it
                                    it is correct.  I agree with the results recorded therein & received copy of the result sheet
                                </p>
                            </div>
                            <div style={{ width: "20%", display: "inline-block", verticalAlign: "middle" }}>
                                <div style={{ border: "1px solid black", width: "100%", height: "45px", marginLeft: '5px' }}>

                                </div>
                            </div>
                        </div>
                        <div>
                            <div style={{ width: "25%", display: "inline-block" }}>
                                <p>Name :</p>
                            </div>
                            <div style={{ width: "25%", display: "inline-block" }}>
                                <p>Signature of the Candidate :</p>
                            </div>
                            <div style={{ width: "25%", display: "inline-block" }}>
                                <p>Left Thumb Impression of the candidate</p>
                            </div>
                            <div style={{ width: "25%", display: "inline-block", textAlign: "center", verticalAlign: "middle" }}>
                                <p><b>(Signature of the Chairman of the ET & PST Committee)</b></p>
                                <p style={{ textAlign: "center" }}><b>with Office Seal</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <button onClick={download}>Download</button> */}
        </>
    );
}
