import React, { useCallback, useEffect, useRef, useState } from "react";

//* MUI imports
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from '@mui/material/Autocomplete';
import UploadImageModal from "../../Modals/UploadImage/UploadImageModal";
import { experimentalStyled as styled } from '@mui/material/styles';
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import debounce from "lodash.debounce";
import Styles from "./FormEdit.module.css";
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import AvTimerIcon from '@mui/icons-material/AvTimer';
// import Header from "../Header/Header.jsx";
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import ReactTimeAgo from 'react-time-ago';
//* Sub component
import TabsMenu from "../../Subcomponent/TabsMenu";
import Dropdown from "../../Subcomponent/Dropdown";
import Button from "../../Subcomponent/Button";
import IDCardPreview from "../../Modals/IDCard/ID_CardPreview";
import KsisePDF from "../PDF/KsisePDF";
import QrAndBarcodeScanner from "../../Modals/Scanner/QrAndBarcodeScanner";
import HomeIcon from "../../assets/images/general-info.svg";
import ArrowRightIcon from "../../assets/images/right-caret.svg";
import menImage from "../../assets/images/man.png";
import womenImage from "../../assets/images/woman (1).png";

import { getSelectedStudentDetails, updateStudentData } from "../../services/StudentService";
import { errorToast, successToast } from "../../services/Toast";
import { useNavigate, useParams } from "react-router-dom";
import { dismissLoader, presentLoader } from "../../services/loaderService";
import Paper from '@mui/material/Paper';

import StudentPhotos from "../../Modals/StudentPhotos/StudentPhotos";

import { useDispatch, useSelector } from "react-redux";
import { DB } from "../../db"
import { updateStudentToIndexDB } from "../../services/IndexDBService";

import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import QRCode from "qrcode";
import PdfViewerModal from "../../Modals/PdfViewerModal/PdfViewerModal";
import { Card, IconButton } from "@mui/material";
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import Webcam from "react-webcam";
import { Facenet } from '../../Facenet/Facenet';
import CameraIcon from '@mui/icons-material/Camera';
import ConfirmationAlert from "../../Subcomponent/ConfirmationAlert";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  helperText: {
    fontSize: "1.2rem !important",
  },
  input: {
    fontSize: "1.6rem !important",
    marginTop: "0.6rem",
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #D0D5DD !important",
    boxSizing: "border-box",
    boxShadow: "0px 1px 2px rgb(16 24 40 / 5%)",
    borderRadius: "0.8rem",
    "& input": {
      padding: "1rem 1.4rem !important",
    },
    "&.Mui-focused": {
      // border: "unset",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #D6BBFB !important",
        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF",
      },
    },
    "&.MuiSelect-outlined": {
      border: "1px solid #D0D5DD !important",
    },
    "& fieldSet": {
      border: "none",
    },
    "&:hover": {
      // border:"none",
      "& .MuiOutlinedInput-notchedOutline": {
        // border: '1px solid #D0D5DD !important'
      },
    },
    "& p": {
      fontSize: "1.4rem",
    },
    "&.MuiSelect-select": {
      border: "1px solid #D6BBFB !important",
    },
  },
  inputMenu: {
    "& .MuiAutocomplete-inputRoot": {
      fontSize: "1.6rem !important",
      padding: "1rem 1.4rem !important",
      marginTop: "0.6rem",
      "& >div:firstOfType": {
        border: "none !important",
      }
    },
    "& .MuiAutocomplete-input": {
      padding: "0 !important",
    },
    "& .Mui-focused": {
      border: "1px solid #D6BBFB !important",
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      }
    },
    "& :hover": {
      border: "none !important",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #D0D5DD !important",
      }
    }
  },
  dropdown: {
    "& :hover": {
      border: "1px solid #d6bbfb !important",
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff"
    }
  },
  DateField: {
    "& input": {
      fontSize: "2rem",
    },
  },
  '& .MuiAutocomplete-root .MuiOutlinedInput-root .MuiFormControl-root-MuiTextField-root>div:firstOfType': {
    border: "none !important",
    "&:hover": {
      border: "1px solid #d6bbfb !important",
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff"
    },
    "&:focus": {
      border: "1px solid #d6bbfb !important",
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff"
    }
  },
}));

const mainTabs = [
  { text: "PROFORMA I", value: "PROFORMA I" },
  { text: "PROFORMA II", value: "PROFORMA II" },
];
const mainTabsForRSI = [
  { text: "PROFORMA I", value: "RSI PROFORMA I" },
  { text: "PROFORMA II", value: "RSI PROFORMA II" },
];
const TabsForIn_Service = [
  { text: "ET", value: "ET" },
  { text: "PST", value: "PST" },
];
const chipStyle = {
  fontSize: "5.4rem",  // ! change font size here for text
  textTransform: 'capitalize',
  padding: "1rem",
  height: "auto",
  "& .MuiChip-icon": {
    fontSize: '6rem',  // ! change font size here for icon
  }
}
const chipStatusStyle = {
  fontSize: "3rem",  // ! change font size here for text
  textTransform: 'capitalize',
  padding: "1rem",
  height: "auto",
  "& .MuiChip-icon": {
    fontSize: '3rem',  // ! change font size here for icon
  }
}
const pendingChip = {
  // border: "1px solid #EEF4FF",
  color: "#E49B0F",
  background: "#FFFDD0",
}
const qualifiedChip = {
  // border: "1px solid #ECFDF3",
  color: "#027A48",
  background: "#ECFDF3",
}
const notQualifiedChip = {
  // border: "1px solid #FFF6ED",
  color: "#C4320A",
  background: "#FFF6ED",
}
const idCardsList = ["Aadhar card", "Driving licence", "Pan card"];

const radioButtonStyle = {
  ".MuiFormControlLabel-label": {
    fontSize: "1.5rem",
  },
};
const radioButtonBigStyle = {
  ".MuiFormControlLabel-label": {
    fontSize: "2rem",
  },
};
export default function FormEdit() {
  TimeAgo.setDefaultLocale(en.locale);
  TimeAgo.addLocale(en)
  const classes = useStyles();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  let params = useParams();
  let examShortName = params.examShortName;
  let subExamName = params.subExamName;
  let examCenterName = params.examCenterName;
  const photoRef = useRef(null);
  const docRef = useRef(null);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine)
  const [openAlert, setOpenAlert] = useState(false)
  const [selectedValue, setSelectedValue] = useState('')
  const [qa_bar_codeScannerMOdalToggle, setQa_bar_codeScannerMOdalToggle] = useState(false);
  const [studentObject, setStudentObject] = useState({
    SNO: "",
    "Full Name": "",
    Photo: "",
    Applicant: "",
    Gender: "",
    checkingOfficer: "",
    checkingOfficerDesignation: "",
    AppliedAs: "",
    chest_no: "",
    examCenterName: "",
    ET_IsQualified: false,
    ET_height_height: "",
    ET_height_result: "",
    ET_height_remarks: "",
    PST_IsQualified: false,
    Status: "",
    idCardImage: "",
    realTimePhoto: "",
    uploadedForm: "",
    isUploadedForm: false,
    lastUpdateAt: "",
    isSubmitted: false,
  });
  const [helperText, setHelperText] = useState({
    isSl_noFilled: "",
    isNameFilled: "",
    isApplication_noFilled: "",
    isTradeFilled: "",
    isChest_noFilled: "",
    isGenderFilled: "",
    isCheckingOfficerFilled: "",
    isCheckingOfficerDesignationFilled: "",
    isHeightFilled: "",
    isHeightResultFilled: "",
    isChestFullyExpandedFilled: "",
    isChestExpandedFilled: "",
    isChestResultFilled: "",
    isRunningSecondsFilled: "",
    isRunningMarksFilled: "",
    isShotPutDistanceFilled: "",
    isShotPutMarksFilled: "",
    isLongJumpDistanceFilled: "",
    isLongJumpMarksFilled: "",
    isDistrictFilled: "",
    isVenueFilled: "",
    isDateFilled: "",
    isApplied_as_aFilled: "",
    isStatusFilled: "",
    isETHeightFilled: "",
    isETHeightResultFilled: "",
    is_ET_Qualified_Filled: "",
    is_PST_Qualified_Filled: "",

    // * for rsi(car/dar)
    isRsiHeightFilled: "isRsiHeightFilled",
    isRsiHeightResultFilled: "isRsiHeightResultFilled",
    isRsiChestFullyExpandedFilled: "isRsiChestFullyExpandedFilled",
    isRsiChestExpandedFilled: "isRsiChestExpandedFilled",
    isRsiChestResultFilled: "isRsiChestResultFilled",

    isRsiRunningPerformanceFilled: "isRsiRunningPerformanceFilled",
    isRsiRunningResultFilled: "isRsiRunningResultFilled",
    isRsiRunningRemarkFilled: "isRsiRunningRemarkFilled",

    isRsiLongJumpPerformanceFilled: "isRsiLongJumpPerformanceFilled",
    isRsiLongJumpResultFilled: "isRsiLongJumpResultFilled",
    isRsiLongJumpRemarkFilled: "isRsiLongJumpRemarkFilled",

    isRsiHighJumpPerformanceFilled: "isRsiHighJumpPerformanceFilled",
    isRsiHighJumpResultFilled: "isRsiHighJumpResultFilled",
    isRsiHighJumpRemarkFilled: "isRsiHighJumpRemarkFilled",

    isRsiShotPutPerformanceFilled: "isRsiShotPutPerformanceFilled",
    isRsiShotPutResultFilled: "isRsiShotPutResultFilled",
    isRsiShotPutRemarkFilled: "isRsiShotPutRemarkFilled",
  });

  const [selectedMainTabs, setSelectedMainTabs] = useState("PROFORMA I");
  const [selectedMainTabsForRSI, setSelectedMainTabsForRSI] = useState("RSI PROFORMA I");
  const [selectedIn_serviceTab, setSelectedIn_serviceTab] = useState("ET");

  const [openIdCardPreview, setOpenIdCardPreview] = useState(false);
  const [officersList, setOfficersList] = useState([]);
  const [checkingOfficerDesignationList, setCheckingOfficerDesignationList] = useState([]);
  const [groundList, setGroundList] = useState([])
  const [groundLoading, setGroundLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  let [totalQualifiedCount, setTotalQualifiedCount] = useState(0);
  let [totalNotQualifiedCount, setTotalNotqualifiedCount] = useState(0);

  const [examDetails, setExamDetails] = useState(null);
  const [subExamDetails, setSubExamDetails] = useState(null)
  const [examCenterDetails, setExamCenterDetails] = useState(null)
  const [objectForPDF, setObjectForPDF] = useState(null);

  const [viewStudentDocsModalToggle, setViewStudentDocsModalToggle] = useState(false);
  const [subExamId, setSubExamId] = useState(null)
  const [faceRecognitionModalToggle, setFaceRecognitionModalToggle] = useState(false);

  const [pdfViewerToggle, setPdfViewerToggle] = useState(false);

  const examDocId = ""

  const [cameraModalToggle, setCameraModalToggle] = useState(false);
  const cameraModalOpen = () => setCameraModalToggle(true);
  const cameraModalClose = () => setCameraModalToggle(false);

  const [photoCameraModalToggle, setPhotoCameraModalToggle] = useState(false);
  const photCameraModalOpen = () => setPhotoCameraModalToggle(true);
  const photCameraModalClose = () => setPhotoCameraModalToggle(false);

  let facenetObj = useSelector((state) => {
    return state.faceNet ? state.faceNet[JSON.parse(localStorage.getItem('examCenter')).id] : null
  });
  const [deviceId, setDeviceId] = React.useState({});
  const [devices, setDevices] = React.useState([]);

  useEffect(() => {
    let _exam = JSON.parse(localStorage.getItem("exam"));
    if (_exam) {
      setExamDetails(_exam);
    } else {
      errorToast("Please Select Exam.");
      navigate("/home/student-verify")
    }

    let _subExam = JSON.parse(localStorage.getItem("subExam"));
    if (_subExam) {
      setSubExamId(_subExam.id);
      setSubExamDetails(_subExam);
      // setIsProforma(_subExam.isProforma);
    } else {
      errorToast("Please Select Exam.");
      navigate("/home/student-verify")
    }

    let _examCenter = JSON.parse(localStorage.getItem("examCenter"));
    if (_examCenter) {
      setExamCenterDetails(_examCenter);
    } else {
      errorToast("Please Select Exam.");
      navigate("/home/student-verify")
    }
  }, []);

  useEffect(() => {
    if (examDetails && subExamId && examCenterDetails) {
      getStudentFromLocalStorage();
      getGroundListsFromServer();
    }
  }, [examDetails, subExamId, examCenterDetails])

  function IdPhotoClicked(file) {
    // console.log(file)
    let pre = { ...studentObject }
    pre.idCardBase64 = file
    setStudentObject({ ...pre })
    autoSave(pre, examDocId, subExamId)
    // uploadImageToServer(file, "idCardImage", "ID card upload Successfully.");
  }

  function passPhotoClicked(file) {
    // console.log(file)
    let pre = { ...studentObject }
    pre.realTimeBase64 = file
    setStudentObject({ ...pre })
    autoSave(pre, examDocId, subExamId)
    // uploadImageToServer(file, "realTimePhoto", "Photo upload Successfully.");
  }


  function getStudentFromLocalStorage() {   //* Changed
    const _student = getSelectedStudentDetails();
    console.log('_student.id: ', _student.id);
    presentLoader();
    DB.students.get(_student.id).then(res => {
      getOfficersListsFromServer();
      getCheckingOfficerDesignationListFromServer();
      getTotalStudentsCount();
      Object.keys(res).forEach((ele) => {
        setStudentObject((pre) => {
          pre[ele] = res[ele];
          return { ...pre };
        });
      });
      dismissLoader();
      // navigate
    }).catch(err => {
      console.log(err);
      errorToast("Student not found.");
      // errorToast("Something went wrong! Try again");
      dismissLoader();
    })
  }

  async function getGroundListsFromServer() {   //* Changed
    let _ExamCenter = await DB.examCenters.toArray();
    const allGroundNames = _ExamCenter.map(m => m.examCenterName)
    console.log("allGroundNames => ", allGroundNames);
    if (allGroundNames && allGroundNames !== 'null') {
      setGroundLoading(false);
      setGroundList(allGroundNames);
    }
  }

  async function getOfficersListsFromServer() {   //* Changed
    DB.students.where("checkingOfficer").noneOf([""]).toArray().then(res => {
      const officers = res.map(m => ({ label: m.checkingOfficer }))
      const uniqueObjArray = [
        ...new Map(officers.map((item) => [item["label"], item])).values(),
      ];
      setOfficersList([...uniqueObjArray]);
      console.log('officers: ', uniqueObjArray);
    }).catch(err => {
      console.log(err);
    })
  }

  async function getCheckingOfficerDesignationListFromServer() {   //* Changed
    DB.students.where("checkingOfficerDesignation").noneOf([""]).toArray().then(resp => {
      const officers = resp.map(m => ({ label: m.checkingOfficerDesignation }));
      const uniqueObjArray = [...new Map(officers.map((item) => [item["label"], item])).values(),];
      setCheckingOfficerDesignationList([...uniqueObjArray]);
      // console.log('checkingOfficerDesignation: ', uniqueObjArray);
    }).catch(err => {
      console.log(err);
    })
  }

  async function getTotalStudentsCount() {   //* Changed
    // getStudentsCount(subExamId, _examDocId).then((res) => {
    //   console.log('res: ', res);
    //   setTotalCount(res.total);
    //   setTotalQualifiedCount(res.qualified);
    //   setTotalNotqualifiedCount(res.notQualified);
    // });

    const studentCount = {
      qualified: 0,
      notQualified: 0,
      total: 0
    };

    let _Students = await DB.students.where({ examID: examDetails.id, subExamID: subExamId, examCenterID: examCenterDetails.id }).toArray();

    if (_Students.length > 0) {
      _Students.forEach((elem) => {
        studentCount.total++;
        if (elem.Status === 'qualified') {
          studentCount.qualified++;
        }
        if (elem.Status === 'not qualified') {
          studentCount.notQualified++;
        }
      });
      setTotalCount(studentCount.total);
      setTotalQualifiedCount(studentCount.qualified);
      setTotalNotqualifiedCount(studentCount.notQualified);
    }
  }

  const afterScanQR_Bar_code = (result) => {
    /// Result
    console.log(result);
    setQa_bar_codeScannerMOdalToggle(false);
    getDataRepeat(result);
  };

  function getDataRepeat(ID_provided) {   //* Changed
    const _student = getSelectedStudentDetails();
    let _UID = "";
    if (ID_provided !== "" && ID_provided) {
      _UID = `${subExamId}_${ID_provided}`;
    } else {
      _UID = _student?.id;
    }
    presentLoader();
    DB.students.get(_UID).then(res => {
      localStorage.setItem("student", JSON.stringify({ id: res.id }));
      getOfficersListsFromServer();
      getCheckingOfficerDesignationListFromServer();
      getTotalStudentsCount();
      Object.keys(res).forEach((ele) => {
        setStudentObject((pre) => {
          pre[ele] = res[ele];
          return { ...pre };
        });
      });
      dismissLoader();
      // navigate
    }).catch(err => {
      console.log(err);
      errorToast("Student not found.");
      // errorToast("Something went wrong! Try again");
      dismissLoader();
    })
  }

  function ModalResp(e) {
    if (e === 'confirmed') {
      // console.log(studentObject)
      updateStudentData(subExamId, examDetails.id, studentObject.id.split('_')[1], { examCenterName: selectedValue, isGroundNameChanged: true }).then((resp) => {
        console.log(resp)
        DB.students.where({ id: studentObject.id }).delete().then((res) => {
          console.log(res)
          setStudentObject((preValue) => {
            preValue.examCenterName = selectedValue;
            return { ...preValue };
          });
          setOpenAlert(false)
          navigate(`/home/student-verify/${examShortName}/allSubExams/${subExamName}/allExamCenters/${examCenterName}/attendance`)
        })
      })
    }
  }

  const autoSave = useRef(   //* Changed
    debounce((e, _examDocId, _subExamId) => {
      console.log({ e, _examDocId, _subExamId });
      updateToDb(e, _examDocId, _subExamId, true);
    }, 2000)
  ).current;

  function updateToDb(studentObject, _examDocId, _subExamId, hideToast = false) {   //* Changed
    let _studentPostData = { ...studentObject };
    _studentPostData.lastUpdateAt = new Date().getTime();

    updateStudentToIndexDB(studentObject.id, studentObject).then((_studentRes) => {
      // updateStudent(_studentPostData, studentObject.id, _examDocId, _subExamId).then((_studentRes) => {
      // console.log("_studentRes",_studentRes);
      setStudentObject((pre) => {
        pre.lastUpdateAt = _studentPostData.lastUpdateAt;
        return { ...pre };
      });
      dismissLoader();
      if (!hideToast) {
        successToast("Candidate data updated successfully.");
      }
    }).catch((err) => {
      console.log(err);
      dismissLoader();
      errorToast("Something went wrong! Try again");
    });
  }

  function validateForm() {
    var form1 = true;
    var proforma1TabIsValid = true;
    var proforma2TabIsValid = true;
    var ET_Tab_isValid = true;
    var PST_Tab_isValid = true;
    //* upper
    if (studentObject["Full Name"] === "") {
      form1 = false;
      setHelperText((preValue) => {
        preValue.isNameFilled = "Please Enter name.";
        return { ...preValue };
      });
    }
    // if (studentObject.SNO === "") {
    //   form1 = false;
    //   setHelperText((preValue) => {
    //     preValue.isSl_noFilled = "Please Enter sl no.";
    //     return { ...preValue };
    //   });
    // }
    if (studentObject.Applicant === "") {
      form1 = false;
      setHelperText((preValue) => {
        preValue.isApplication_noFilled = "Please Enter application number.";
        return { ...preValue };
      });
    }
    // if (studentObject.trade === "" && studentObject.examType !== "rsi(car/dar)") {
    //   form1 = false;
    //   setHelperText((preValue) => {
    //     preValue.isTradeFilled = "Please Enter Trade.";
    //     return { ...preValue };
    //   });
    // }
    if (studentObject.chest_no === "") {
      form1 = false;
      setHelperText((preValue) => {
        preValue.isChest_noFilled = "Please Enter chest number.";
        return { ...preValue };
      });
    }
    if (studentObject.Gender === "") {
      form1 = false;
      setHelperText((preValue) => {
        preValue.isGenderFilled = "Please Enter gender.";
        return { ...preValue };
      });
    }
    if (studentObject.checkingOfficer === "" || studentObject.checkingOfficer === null || studentObject.checkingOfficer === undefined) {
      form1 = false;
      setHelperText((preValue) => {
        preValue.isCheckingOfficerFilled = "Please Enter checking officer name.";
        return { ...preValue };
      });
    }
    if (studentObject.checkingOfficerDesignation === "") {
      form1 = false;
      setHelperText((preValue) => {
        preValue.isCheckingOfficerDesignationFilled = "Please Enter checking officer designation.";
        return { ...preValue };
      });
    }

    //* lower
    // if (studentObject.ExamDistrict === "") {
    //   form1 = false;
    //   setHelperText((preValue) => {
    //     preValue.isDistrictFilled = "Please Enter district.";
    //     return { ...preValue };
    //   });
    // }
    if (studentObject.examCenterName === "") {
      form1 = false;
      setHelperText((preValue) => {
        preValue.isVenueFilled = "Please Enter venue.";
        return { ...preValue };
      });
    }
    if (studentObject.ExamDate === "") {
      form1 = false;
      setHelperText((preValue) => {
        preValue.ExamDate = "Please Enter date.";
        return { ...preValue };
      });
    }
    if (studentObject.AppliedAs === "") {
      form1 = false;
      setHelperText((preValue) => {
        preValue.isApplied_as_aFilled = "Please choose applied as.";
        return { ...preValue };
      });
    }
    if (studentObject.Status === "") {
      form1 = false;
      setHelperText((preValue) => {
        preValue.isStatusFilled = "Please choose Status.";
        return { ...preValue };
      });
    }

    // //* ET && PST
    // if (studentObject.AppliedAs === "Inservice") {
    //   //* ET
    //   if (studentObject.ET.height.height === "") {
    //     form1 = false;
    //     setHelperText((preValue) => {
    //       preValue.isETHeightFilled = "Please Enter Height";
    //       return { ...preValue };
    //     });
    //   }
    //   if (studentObject.ET.height.result === "") {
    //     form1 = false;
    //     setHelperText((preValue) => {
    //       preValue.isETHeightResultFilled = "Please Enter Result";
    //       return { ...preValue };
    //     });
    //   }
    //   if (studentObject.ET_IsQualified === "") {
    //     form1 = false;
    //     setHelperText((preValue) => {
    //       preValue.is_ET_Qualified_Filled = "Please select status.";
    //       return { ...preValue };
    //     });
    //   }
    //   if (
    //     studentObject.ET.height.height === "" ||
    //     studentObject.ET.height.result === "" ||
    //     studentObject.ET_IsQualified === ""
    //   ) {
    //     ET_Tab_isValid = false;
    //     form1 = false;
    //   }

    //   //* PST
    //   if (studentObject.PST_IsQualified === "") {
    //     form1 = false;
    //     setHelperText((preValue) => {
    //       preValue.is_PST_Qualified_Filled = "Please select status.";
    //       return { ...preValue };
    //     });
    //   }
    //   if (studentObject.PST_IsQualified === "") {
    //     PST_Tab_isValid = false;
    //     form1 = false;
    //   }
    // }

    //* images
    // realTimePhoto : studentObject.realTimePhoto,
    // idCardImage : studentObject.idCardImage,
    // selectedID_Card : studentObject.selectedID_Card,
    // uploadedForm : studentObject.uploadedForm,


    // if (studentObject.realTimePhoto === "") {
    //   form1 = false;
    //   errorToast("Please Capture Photo.");
    //   return ({ flag: form1, proforma1TabIsValid: proforma1TabIsValid, proforma2TabIsValid: proforma2TabIsValid, ET_Tab_isValid: ET_Tab_isValid, PST_Tab_isValid: PST_Tab_isValid });
    // }
    // if (studentObject.idCardImage === "") {
    //   form1 = ({ flag: form1, proforma1TabIsValid: proforma1TabIsValid, proforma2TabIsValid: proforma2TabIsValid, ET_Tab_isValid: ET_Tab_isValid, PST_Tab_isValid: PST_Tab_isValid });
    //   errorToast("Please ID card Photo.");
    //   return false;
    // }
    if (!studentObject.isUploadedForm) {
      form1 = false;
      errorToast("Please upload scanned sheet.");
      return ({ flag: form1, proforma1TabIsValid: proforma1TabIsValid, proforma2TabIsValid: proforma2TabIsValid, ET_Tab_isValid: ET_Tab_isValid, PST_Tab_isValid: PST_Tab_isValid });
    }
    return ({
      flag: form1,
      proforma1TabIsValid: proforma1TabIsValid,
      proforma2TabIsValid: proforma2TabIsValid,
      ET_Tab_isValid: ET_Tab_isValid,
      PST_Tab_isValid: PST_Tab_isValid,
    });
  }

  async function SaveStudent() {
    let flag = validateForm();
    console.log(flag);
    if (flag.flag) {
      //* Update to the Firebase
      presentLoader();
      setStudentObject((pre) => {
        pre.isSubmitted = true;
        return pre;
      });
      let _post_studentObject = { ...studentObject };
      _post_studentObject.isSubmitted = true;
      updateToDb(_post_studentObject, examDocId, subExamId, false);
    } else {
      if (studentObject.AppliedAs === "Inservice") {
        if (!flag.ET_Tab_isValid) {
          setSelectedIn_serviceTab("ET");
          return;
        }
        if (!flag.PST_Tab_isValid) {
          setSelectedIn_serviceTab("PST");
          return;
        }
      } else {
        if (!flag.proforma1TabIsValid) {
          setSelectedMainTabs("PROFORMA I");
          return;
        }
        if (!flag.proforma2TabIsValid) {
          setSelectedMainTabs("PROFORMA II");
          return;
        }
      }
    }
  }

  function fileSelectForForm(e) {  //* Changed
    let _file = e.target.files[0];
    if (!_file) {
      return;
    }
    if (!_file.type.includes("pdf")) {
      errorToast("Please upload PDF formate only.");
      return;
    }
    getBase64(_file).then(data => {
      let postBody = {
        uploadedForm64: data,
        isUploadedForm: true,
      }
      updateStudentToIndexDB(studentObject.id, postBody).then((_studentRes) => {
        if (_studentRes) {
          let _studentObject = Object.assign({}, studentObject);
          _studentObject.uploadedForm64 = data;
          _studentObject.isUploadedForm = true;
          setStudentObject({ ..._studentObject });
        } else {
          console.log({ _studentRes });
          errorToast("Something went wrong! Try again")
        }
      }).catch(err => {
        console.log({ err });
        errorToast("Something went wrong! Try again")
      });
    });
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  function DownloadPDF() {
    startPdfDownload(subExamId, studentObject, examCenterDetails.City);
  }

  function viewPhotos() {
    console.log("view Photos Clicked");
    setViewStudentDocsModalToggle(true);
  }

  function goBack(pageString) {
    if (pageString === "home") {
      navigate("/home/student-verify");
    }
    if (pageString === "allStudents") {
      navigate(`/home/student-verify/${examShortName}/allSubExams/${subExamName}/allExamCenters/${examCenterName}/allStudents`);
    }
    if (pageString === "attendance") {
      navigate(`/home/student-verify/${examShortName}/allSubExams/${subExamName}/allExamCenters/${examCenterName}/attendance`);
    }

  }

  const handleDevices = useCallback(mediaDevices => setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")), [setDevices]);

  const cameraError = async (err) => {
    console.log(err);
    const { state } = await window.navigator.permissions.query({ name: 'camera' });
    if (state === "denied") {
      errorToast("Please allow to access your camera")
    }
    // setIsLoading(false);
    // setIsCameraFound(false);
  }
  const cameraFound = (res) => {
    if (devices.map(m => ({ label: m.label, value: m.deviceId })).some(s => s.label === "" || s.value === "")) {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
      // setIsCameraFound(res.active);
    } else {
      // setIsCameraFound(res.active);
      // setIsLoading(false);
    }
  }

  function captureStudentPhoto() {
    let photo = photoRef.current.getScreenshot();
    const img = document.createElement("img");
    img.src = photo;
    img.id = 'image';

    if (facenetObj) {
      facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data').then((resp) => {
        console.log(resp)
        if (resp === 'No Face Detected') {
          let pre = { ...studentObject }
          pre.realTimeBase64 = photo
          setStudentObject({ ...pre })
          autoSave(pre, examDocId, subExamId)
        } else {
          let pre = { ...studentObject }
          pre.realTimeBase64 = resp
          setStudentObject({ ...pre })
          autoSave(pre, examDocId, subExamId)
        }
      })
    } else {
      facenetObj = new Facenet('', '');
      facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data').then((resp) => {
        console.log(resp)
        if (resp === 'No Face Detected') {
          let pre = { ...studentObject }
          pre.realTimeBase64 = photo
          setStudentObject({ ...pre })
          autoSave(pre, examDocId, subExamId)
        } else {
          let pre = { ...studentObject }
          pre.realTimeBase64 = resp
          setStudentObject({ ...pre })
          autoSave(pre, examDocId, subExamId)
        }
      })
    }
  }

  function captureStudentIdCardPhoto() {
    let photo = docRef.current.getScreenshot();

    let pre = { ...studentObject }
    pre.idCardBase64 = photo
    setStudentObject({ ...pre })
    autoSave(pre, examDocId, subExamId)
  }

  useEffect(() => {
    window.addEventListener('online', (e) => { setIsOnline(true) })
    window.addEventListener('offline', (e) => { setIsOnline(false) })
    return () => {
      window.addEventListener('offline')
      window.removeEventListener('online')
    }
  }, [])

  return (
    <>
      <Box className={Styles.siteWrapper}>
        <Box>
          <Box className={Styles.headerWrapper}>
            <div className={Styles.breadcrumb}>
              <img src={HomeIcon} style={{ cursor: "pointer" }} onClick={() => goBack("home")} alt="" />
              <img className={Styles.breadcrumbArrowImg} src={ArrowRightIcon} alt="" />
              <div style={{ cursor: "pointer" }} onClick={() => goBack("allStudents")}>All Students</div>
              <img className={Styles.breadcrumbArrowImg} src={ArrowRightIcon} alt="" />
              <div style={{ cursor: "pointer" }} onClick={() => goBack("attendance")}>Attendance</div>
              <img className={Styles.breadcrumbArrowImg} src={ArrowRightIcon} alt="" />
              <div>
                {studentObject.Applicant}
                <div style={{ marginLeft: "1rem" }} className={Styles.headerStatusChip}>
                  {
                    studentObject.Status === "pending" ? <Chip icon={<AvTimerIcon style={{ color: pendingChip.color }} />} label={studentObject.Status} size={"medium"} sx={[{ ...chipStyle, ...pendingChip }]} /> :
                      studentObject.Status === "qualified" ? <Chip icon={<DoneIcon style={{ color: qualifiedChip.color }} />} label={studentObject.Status} size={"medium"} sx={[{ ...chipStyle, ...qualifiedChip }]} /> :
                        <Chip icon={<CloseIcon style={{ color: notQualifiedChip.color }} />} label={studentObject.Status} size={"medium"} sx={[{ ...chipStyle, ...notQualifiedChip }]} />
                  }
                </div>
              </div>
            </div>
            <div className={Styles.lowerStatusChip}>
              <div style={{ marginLeft: "1rem" }}>
                {
                  studentObject.Status === "pending" ? <Chip icon={<AvTimerIcon style={{ color: pendingChip.color }} />} label={studentObject.Status} size={"medium"} sx={[{ ...chipStyle, ...pendingChip }]} /> :
                    studentObject.Status === "qualified" ? <Chip icon={<DoneIcon style={{ color: qualifiedChip.color }} />} label={studentObject.Status} size={"medium"} sx={[{ ...chipStyle, ...qualifiedChip }]} /> :
                      <Chip icon={<CloseIcon style={{ color: notQualifiedChip.color }} />} label={studentObject.Status} size={"medium"} sx={[{ ...chipStyle, ...notQualifiedChip }]} />
                }
              </div>
            </div>
            <div className={Styles.headerButtonWrapper}>
              {/* <div>
                <Button
                  style={{ color: "#FFFFFF", backgroundColor: "#7F56D9" }}
                  // btnIcon={scan}
                  btnName={"Start Attendance"}
                  clicked={() => navigate(`/home/student-verify/${examShortName}/allSubExams/${subExamName}/allExamCenters/${examCenterName}/attendance`)}
                />
              </div> */}
              {/* <div>
                <Button
                  style={{ color: "#7F56D9", backgroundColor: "#FFFFFF" }}
                  btnIcon={scan}
                  btnName={"Scan"}
                  clicked={() => setQa_bar_codeScannerMOdalToggle(true)}
                />
              </div> */}
              {/* <div>
                <Button
                  clicked={openFaceDetectionModal}
                  btnName={"Face Detection"}
                  style={{ color: "#FFFFFF", backgroundColor: "#7F56D9" }}
                />
              </div> */}
              {/* <div style={{fontSize:"1.5rem"}}>{studentObject.uploadedForm !== "" ? "Uploaded" : ""}</div> */}
              <div>
                <Button
                  style={{ color: "#7F56D9", backgroundColor: "#ffffff" }}
                  btnName={"Download PDF"}
                  clicked={DownloadPDF}
                />
              </div>
              <div>
                <Button
                  style={{ color: "#7F56D9", backgroundColor: "#ffffff" }}
                  btnName={
                    studentObject.isUploadedForm
                      ? "Replace sheet"
                      : "Upload scanned sheet"
                  }
                  clicked={() => {
                    document.getElementById("RefForInputForm").click();
                  }}
                />
              </div>
              <div>
                <Button
                  disable={
                    !studentObject.isUploadedForm
                    // || !studentObject.isSubmitted
                  }
                  style={{ color: "#7F56D9", backgroundColor: "#ffffff" }}
                  btnName={"View Sheet"}
                  clicked={() => {
                    if (window.navigator.onLine && studentObject?.uploadedForm && studentObject.uploadedForm !== "") {
                      window.open(studentObject.uploadedForm);
                    } else {
                      // errorToast("Please check your internet connectivity.");
                      setPdfViewerToggle(true)
                      // console.log(studentObject.uploadedForm64)
                    }
                  }}
                />
              </div>
              {(examDetails && examDetails?.isProforma) &&
                <div>
                  <Button
                    style={{ color: "#7F56D9", backgroundColor: "#ffffff" }}
                    btnName={"View Photos"}
                    clicked={viewPhotos}
                  />
                </div>
              }
              <div>
                <Button
                  style={{ color: "#ffffff", backgroundColor: "#7F56D9" }}
                  btnName={studentObject.isSubmitted ? "Re-submit" : "Submit"}
                  clicked={SaveStudent}
                />
              </div>
              <div hidden>
                <input
                  type="file"
                  name=""
                  id="RefForInputForm"
                  onChange={fileSelectForForm}
                  accept="application/pdf"
                />
              </div>
            </div>
          </Box>
          <Box display={"flex"} flexDirection={"row"} margin={"0 1rem 1rem 1rem"} alignItems={"center"} justifyContent={"space-between"}>
            {studentObject.lastUpdateAt !== "" && (
              <>
                <Box display={"flex"} flexDirection={"row"} >
                  <div className={Styles.lastUpdatedAtText}>Last Updated : </div>
                  <div className={Styles.dateText}>
                    {/* {lastUpdated} */}
                    <ReactTimeAgo date={studentObject.lastUpdateAt} locale="en-US" timeStyle="round-minute" />
                  </div>
                </Box>
              </>
            )}
            <Box>
              <div className={Styles.formSubmittedText} style={{ marginLeft: "2rem" }}>Total Students: {totalCount}</div>
              <div className={Styles.formSubmittedText}> Qualified: {totalQualifiedCount}</div>
              <div className={Styles.formSubmittedText}> Not qualified: {totalNotQualifiedCount}</div>
            </Box>
          </Box>

          <Box className={Styles.pageWrapper}>
            {/* Header 📑  */}
            <Box className={Styles.formWrapper}>
              <div className={Styles.upperFormWrapper}>
                <Box>
                  <div className={Styles.inputDiv}>
                    <label>
                      {/* SNo  : <span>{studentObject.SNO} </span> */}
                      SNo  : <span>{studentObject.Applicant} </span>
                    </label>
                  </div>
                  <div className={Styles.inputDiv}>
                    <label>
                      Application No. : <span>{studentObject.Applicant}</span>
                    </label>
                  </div>
                  <div className={Styles.inputDiv}>
                    <label>
                      Roll No. : <span>{studentObject?.rollNo || "--"}</span>
                    </label>
                  </div>
                  <div className={Styles.inputDiv}>
                    <label>
                      Room No. : <span>{studentObject?.roomNo || "--"}</span>
                    </label>
                  </div>
                  <div className={Styles.inputDiv}>
                    <label>
                      Date  : <span>{studentObject.examDate}</span>
                    </label>
                  </div>
                  <div className={Styles.inputDiv}>
                    <label>
                      Name : <span>{studentObject["Full Name"]}</span>
                    </label>
                  </div>
                  <div className={Styles.inputDiv}>
                    <label>
                      Gender : <span> {studentObject.Gender}</span>
                    </label>
                  </div>
                  <div className={Styles.inputDiv}>
                    <label>
                      City: <span>{examCenterDetails?.City ? examCenterDetails?.City : ""}</span>
                    </label>
                  </div>
                  <div className={Styles.inputDiv}>
                    <label>
                      Venue: <span>{studentObject.examCenterName}</span>
                    </label>
                  </div>
                </Box>
                <Box className={Styles.formAndPhotoWrapper}>
                  {/* <div className={Styles.upperFormWrapper}> */}
                  {/* {studentObject.examType !== "rsi(car/dar)" &&
                    <div className={Styles.inputDiv}>
                      <label>
                        Trade : Water Carrier / Sweeper{" "}
                        <span style={{ color: "red" }}> *</span>
                      </label>
                      <TextField
                        InputProps={{ className: classes.input }}
                        FormHelperTextProps={{
                          classes: {
                            root: classes.helperText,
                          },
                        }}
                        value={studentObject.trade}
                        onChange={(newValue) => {
                          setStudentObject((preValue) => {
                            preValue.trade = newValue.target.value;
                            return { ...preValue };
                          });
                          setHelperText((prevalue) => {
                            prevalue.isTradeFilled = "";
                            return { ...prevalue };
                          });
                          autoSave(studentObject, examDocId, subExamId);
                        }}
                        className={classes.textField}
                        error={helperText.isTradeFilled !== ""}
                        id="outlined-basic"
                        sx={[
                          {
                            "&>div:firstOfType": {
                              border:
                                helperText.isTradeFilled !== ""
                                  ? "1px solid red !important"
                                  : "1px solid #D0D5DD !important",
                            },
                            width: "100%",
                          },
                        ]}
                        helperText={helperText.isTradeFilled}
                        variant="outlined"
                      />
                    </div>
                  } */}
                  <div className={Styles.inputDiv}>
                    <label>
                      Chest No.<span style={{ color: "red" }}> *</span>
                    </label>
                    <TextField
                      InputProps={{ className: classes.input }}
                      // inputProps={{ min: 0 }}
                      FormHelperTextProps={{
                        classes: {
                          root: classes.helperText,
                        },
                      }}

                      value={studentObject.chest_no}
                      onChange={(newValue) => {

                        setStudentObject((preValue) => {
                          preValue.chest_no = newValue.target.value;
                          return { ...preValue };
                        });
                        setHelperText((prevalue) => {
                          prevalue.isChest_noFilled = "";
                          return { ...prevalue };
                        });
                        autoSave(studentObject, examDocId, subExamId);

                      }}
                      className={classes.textField}
                      error={helperText.isChest_noFilled !== ""}
                      id="outlined-basic"
                      sx={[
                        {
                          "&> .MuiOutlinedInput-root": {
                            border:
                              helperText.isChest_noFilled !== ""
                                ? "1px solid red !important"
                                : "1px solid #D0D5DD !important",
                          },
                          width: "100%",
                        },
                      ]}
                      helperText={helperText.isChest_noFilled}
                      variant="outlined"
                    />
                  </div>

                  <div className={Styles.inputDiv}>
                    <label>
                      Checking Officer Name <span style={{ color: "red" }}> *</span>
                    </label>
                    <Autocomplete
                      disablePortal={false}
                      value={studentObject.checkingOfficer}
                      // id="combo-box-demo"
                      onInputChange={(event, newValue, reason) => {
                        if (newValue !== studentObject.checkingOfficer) {
                          autoSave(studentObject, examDocId, subExamId);
                        }
                        setStudentObject((preValue) => {
                          preValue.checkingOfficer = newValue;
                          return { ...preValue };
                        });
                        setHelperText((prevalue) => {
                          prevalue.isCheckingOfficerFilled = "";
                          return { ...prevalue };
                        });
                      }}
                      options={officersList}
                      className={classes.inputMenu}
                      getOptionLabel={(option) => option.label || studentObject.checkingOfficer}
                      ListboxProps={{
                        sx: { fontSize: "1.6rem" },
                      }}
                      renderInput={(params) =>
                        <TextField {...params}
                          // InputProps={{ className: classes.inputMenu }}
                          FormHelperTextProps={{
                            classes: {
                              root: classes.helperText,
                            },
                          }}
                          className={classes.textField}
                          error={helperText.isCheckingOfficerFilled !== ""}
                          id="outlined-basic"
                          sx={[
                            {
                              "&>div:firstOfType": {
                                border:
                                  helperText.isCheckingOfficerFilled !== ""
                                    ? ""
                                    : "",
                              },
                              width: "100%",
                            },
                          ]}
                          helperText={helperText.isCheckingOfficerFilled}
                          variant="outlined"
                        />
                      }
                    />
                  </div>

                  <div className={Styles.inputDiv}>
                    <label>
                      Checking Officer Post / Designation{" "}
                      <span style={{ color: "red" }}> *</span>
                    </label>
                    <Autocomplete
                      disablePortal={false}
                      value={studentObject.checkingOfficerDesignation}
                      // id="combo-box-demo"
                      onInputChange={(event, newValue, reason) => {
                        if (newValue !== studentObject.checkingOfficerDesignation) {
                          autoSave(studentObject, examDocId, subExamId);
                        }
                        setStudentObject((preValue) => {
                          preValue.checkingOfficerDesignation = newValue;
                          return { ...preValue };
                        });
                        setHelperText((prevalue) => {
                          prevalue.isCheckingOfficerDesignationFilled = "";
                          return { ...prevalue };
                        });
                      }}
                      options={checkingOfficerDesignationList}
                      className={classes.inputMenu}
                      // open={true}
                      getOptionLabel={(option) => option.label || studentObject.checkingOfficerDesignation}

                      ListboxProps={{
                        sx: { fontSize: "1.6rem" },
                      }}
                      renderInput={(params) =>
                        <TextField {...params}
                          FormHelperTextProps={{
                            classes: {
                              root: classes.helperText,
                            },
                          }}
                          className={classes.textField}
                          error={helperText.isCheckingOfficerDesignationFilled !== ""}
                          id="outlined-basic"
                          sx={[
                            {
                              "&>div:firstOfType": {
                                border:
                                  helperText.isCheckingOfficerDesignationFilled !== ""
                                    ? ""
                                    : "",
                              },
                              width: "100%",
                            },
                          ]}
                          helperText={helperText.isCheckingOfficerDesignationFilled}
                          variant="outlined"
                        />
                      }
                    />
                  </div>
                  <div className={Styles.buttonAndRadioWrapper}>
                    <FormControl style={{ fontSize: "1.5rem" }}>
                      <FormLabel
                        style={{ fontSize: "1.5rem", color: "#000000" }}
                        id="demo-radio-buttons-group-label"
                      >
                        Type of Candidate <span style={{ color: "red" }}> *</span>:{" "}
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={studentObject.AppliedAs}
                        name="radio-buttons-group"
                        onChange={(newValue) => {
                          setStudentObject((preValue) => {
                            preValue.AppliedAs = newValue.target.value;
                            return { ...preValue };
                          });
                          setHelperText((pre) => {
                            pre.isApplied_as_aFilled = "";
                            return { ...pre };
                          });
                          if (studentObject.ET_IsQualified === "true" && studentObject.PST_IsQualified === "true" && studentObject.AppliedAs === "Inservice") {
                            setStudentObject((preValue) => {
                              preValue.Status = "qualified";
                              return { ...preValue };
                            });
                          }
                          autoSave(studentObject, examDocId, subExamId);
                        }}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <FormControlLabel
                          value="Direct"
                          sx={[radioButtonStyle]}
                          control={<Radio size="medium" />}
                          label="Direct"
                        />
                        <FormControlLabel
                          value="Exs."
                          sx={[radioButtonStyle]}
                          control={<Radio size="medium" />}
                          label="Exs."
                        />
                        <FormControlLabel
                          value="Exs-C"
                          sx={[radioButtonStyle]}
                          control={<Radio size="medium" />}
                          label="Exs-C"
                        />
                        <FormControlLabel
                          value="Tribal"
                          sx={[radioButtonStyle]}
                          control={<Radio size="medium" />}
                          label="Tribal"
                        />
                      </RadioGroup>
                    </FormControl>
                    <p>{helperText.isApplied_as_aFilled}</p>

                  </div>

                  <div className={Styles.inputDiv}>
                    <label>
                      Ground <span style={{ color: "red" }}> *</span>
                    </label>
                    <Dropdown
                      helperText={isOnline ? '' : 'Internet Connectivity required for this functionality'}
                      disabled={isOnline ? false : true}
                      list={groundList}

                      // disabled={true}
                      selectedValue={studentObject.examCenterName}
                      keyField={"label"}
                      loading={groundLoading}
                      onChangeSelect={(value) => {
                        setOpenAlert(true)
                        setSelectedValue(value)
                      }}
                    />
                  </div>
                  {/* </div> */}
                </Box>

              </div>
              <div>
                {/* <div className={Styles.wrapperProfileImage}>
                  <div>
                    <img
                      className={Styles.ProfileImage}
                      src={studentObject.Photo64 ? studentObject.Photo64 : menImage}
                      alt={studentObject["Full Name"] + " photo"}
                      onError={(e) => { e.target.onerror = null; e.target.src = menImage }}
                    />
                  </div>
                  <div className={Styles.realTimePhoto}>
                    <div>
                      {(studentObject.realTimePhoto !== "" || studentObject?.realTimeBase64) ?
                        <div className={Styles.imageContainer}>
                          <img
                            className={Styles.ProfileImage}
                            // src={process.env.REACT_APP_bucketAccessRootPath + studentObject.realTimePhoto}
                            src={studentObject.realTimeBase64}
                            // onError={(e) => { e.target.onerror = null; e.target.src = studentObject.realTimeBase64 }}
                            alt=""
                          />
                          <div className={Styles.overlay}></div>
                          <Tooltip
                            title={<h1>Recapture</h1>}
                            style={{ fontSize: "2rem" }}
                            className={Styles.reCaptureImage}
                          >
                            <CameraAltIcon
                              style={{
                                width: "40px",
                                height: "40px",
                                color: "#ffffff",
                                cursor: "pointer",
                              }}
                              onClick={photCameraModalOpen}
                            />
                          </Tooltip>
                        </div>
                        :
                        <div className={Styles.addPhoto}>
                          <Tooltip
                            title={<h1>Capture</h1>}
                            style={{ fontSize: "2rem" }}
                          >
                            <CameraAltIcon
                              style={{
                                width: "40px",
                                height: "40px",
                                color: "#ffffff",
                                cursor: "pointer",
                              }}
                              onClick={photCameraModalOpen}
                            />
                          </Tooltip>
                        </div>
                      }
                    </div>
                  </div>
                </div> */}

                <div className={Styles.wrapperProfileImage}>
                  <div>
                    <img
                      className={Styles.ProfileImage}
                      src={studentObject.Photo64 ? studentObject.Photo64 : menImage}
                      alt={studentObject["Full Name"] + " photo"}
                      onError={(e) => { e.target.onerror = null; e.target.src = menImage }}
                    />
                  </div>

                  <Card className={Styles.captureCard}>
                    {/* {student && (student.realTimeBase64 && student.realTimeBase64 !== '') && */}
                    <div style={{ position: "relative", display: (studentObject.realTimeBase64 && studentObject.realTimeBase64 !== '') ? "unset" : "none" }}>
                      <img className={Styles.capturedImg} style={{ objectFit: "none" }} src={studentObject?.realTimeBase64} alt="" />
                      <IconButton tabIndex={-1} className={Styles.cameraIcon} aria-label="delete" size="small" onClick={() => { setStudentObject({ ...studentObject, realTimeBase64: '' }) }}>
                        <FlipCameraAndroidIcon tabIndex={-1} style={{ color: "rgba(0,0,0,0.5)", width: "3rem", height: "3rem" }} />
                      </IconButton>
                    </div>
                    {/* } */}
                    {/* {studentObject && (!studentObject.realTimeBase64 || studentObject.realTimeBase64 === '') && */}
                    <div style={{ position: "relative", display: (!studentObject.realTimeBase64 || studentObject.realTimeBase64 === '') ? "unset" : "none" }}>
                      <Webcam
                        audio={false}
                        ref={photoRef}
                        screenshotFormat="image/jpeg"
                        className={Styles.videoCamera}
                        onUserMediaError={cameraError}
                        onUserMedia={cameraFound}
                        videoConstraints={{ deviceId: deviceId }}
                      />
                      <IconButton tabIndex={-1} className={Styles.cameraIcon} aria-label="delete" size="small" onClick={() => { captureStudentPhoto(); }}>
                        <CameraIcon tabIndex={-1} style={{ color: "rgba(0,0,0,0.5)", width: "3rem", height: "3rem" }} />
                      </IconButton>
                    </div>
                    {/* } */}
                    {/* {(student && (student.realTimeBase64 && student.realTimeBase64 !== '')) &&
                      <div>
                        <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Recapture"} clicked={captureStudentPhoto} />
                        </div>
                    } */}
                    {/* {(student && (!student.realTimeBase64 || student.realTimeBase64 == '')) &&
                        <div>
                        <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Capture"} clicked={captureStudentPhoto} />
                      </div>
                    } */}
                  </Card>
                </div>


                {subExamDetails?.isStoreIdentityDocument &&
                  // <div className={Styles.rightContainer}>
                  //   <div className={Styles.idCardWrapper} style={{ marginTop: "1rem" }}>
                  //     <p>
                  //       Id Card
                  //       {/* <span style={{ color: "red" }}> *</span>{" "} */}
                  //     </p>
                  //     {/* <Dropdown
                  //     list={idCardsList}
                  //     selectedValue={studentObject.selectedID_Card}
                  //     onChangeSelect={(value) => {
                  //       setStudentObject((preValue) => {
                  //         preValue.selectedID_Card = value;
                  //         return { ...preValue };
                  //       });
                  //       autoSave(studentObject,examDocId);
                  //     }}
                  //   /> */}
                  //     <div>
                  //       <div className={Styles.captureContainer}>
                  //         {(studentObject.idCardImage !== "" || studentObject?.idCardBase64) ?
                  //           <div className={Styles.imageContainer}>
                  //             {/* <img src={process.env.REACT_APP_bucketAccessRootPath + studentObject.idCardImage}  */}
                  //             <img src={studentObject.idCardBase64}
                  //               // onError={(e) => { e.target.onerror = null; e.target.src = studentObject.idCardBase64}}
                  //               alt="" />
                  //             <div className={Styles.overlay}></div>
                  //             <Tooltip
                  //               title={<h1>Capture</h1>}
                  //               style={{ fontSize: "2rem" }}
                  //               className={Styles.reCaptureImage}
                  //             >
                  //               <CameraAltIcon
                  //                 style={{
                  //                   width: "40px",
                  //                   height: "40px",
                  //                   color: "#ffffff",
                  //                   cursor: "pointer",
                  //                 }}
                  //                 onClick={cameraModalOpen}
                  //               />
                  //             </Tooltip>
                  //           </div>
                  //           :
                  //           <div>
                  //             <Tooltip
                  //               title={<h1>Capture</h1>}
                  //               style={{ fontSize: "2rem" }}
                  //               className={Styles.reCaptureImage}
                  //             >
                  //               <CameraAltIcon
                  //                 style={{
                  //                   width: "40px",
                  //                   height: "40px",
                  //                   color: "#ffffff",
                  //                   cursor: "pointer",
                  //                 }}
                  //                 onClick={cameraModalOpen}
                  //               />
                  //             </Tooltip>
                  //           </div>
                  //         }
                  //       </div>
                  //       <div>
                  //         <Button
                  //           style={{ color: "#7F56D9", backgroundColor: "#ffffff" }}
                  //           btnName={"Preview"}
                  //           clicked={() => {
                  //             if (studentObject.idCardBase64 !== "") {
                  //               setOpenIdCardPreview(true);
                  //             }
                  //           }}
                  //         />
                  //         {/* {studentObject.idCardImage !== "" ? (
                  //         <Button
                  //           style={{
                  //             color: "#7F56D9",
                  //             backgroundColor: "#ffffff",
                  //           }}
                  //           btnName={"Preview"}
                  //           clicked={() => {
                  //             if (studentObject.idCardImage !== "") {
                  //               setOpenIdCardPreview(true);
                  //             }
                  //           }}
                  //         />
                  //       ) : (
                  //         ""
                  //       )} */}
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>

                  <div style={{ marginTop: "2rem" }}>
                    <Card className={Styles.captureCard}>
                      {/* {student && (student.idCardBase64 && student.idCardBase64 !== '') && */}
                      <div style={{ position: "relative", display: (studentObject.idCardBase64 && studentObject.idCardBase64 !== '') ? "unset" : "none" }}>
                        <img className={Styles.capturedImg} style={{ objectFit: "none", width: "24rem" }} src={studentObject?.idCardBase64} alt="" />
                        <IconButton tabIndex={-1} className={Styles.cameraIcon} aria-label="delete" size="small" onClick={() => { setStudentObject({ ...studentObject, idCardBase64: '' }) }}>
                          <FlipCameraAndroidIcon tabIndex={-1} style={{ color: "rgba(0,0,0,0.5)", width: "3rem", height: "3rem" }} />
                        </IconButton>
                      </div>
                      {/* } */}
                      {JSON.parse(localStorage.getItem('subExam')).isStoreIdentityDocument &&
                        <div style={{ position: "relative", display: (!studentObject.idCardBase64 || studentObject.idCardBase64 === '') ? "unset" : "none" }}>
                          <Webcam
                            audio={false}
                            ref={docRef}
                            screenshotFormat="image/jpeg"
                            className={Styles.videoCamera}
                            style={{ width: "24rem" }}
                            onUserMediaError={cameraError}
                            onUserMedia={cameraFound}
                            videoConstraints={{ deviceId: deviceId }}
                          />
                          <IconButton tabIndex={-1} className={Styles.cameraIcon} aria-label="delete" size="small" onClick={() => { captureStudentIdCardPhoto(); }}>
                            <CameraIcon tabIndex={-1} style={{ color: "rgba(0,0,0,0.5)", width: "3rem", height: "3rem" }} />
                          </IconButton>
                        </div>
                      }
                      {/* {(student && (student.idCardBase64 && student.idCardBase64 !== '')) &&
                        <div>
                          <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Recapture"} clicked={captureStudentPhoto} />
                      </div>
                      } */}
                      {/* {(student && (!student.idCardBase64 || student.idCardBase64 == '')) &&
                        <div>
                          <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Capture"} clicked={captureStudentPhoto} />
                    </div>
                      } */}
                    </Card>
                  </div>
                }
              </div>
            </Box>

            {(studentObject.AppliedAs === "Exservicemen" || studentObject.AppliedAs === "Exs") &&
              <Grid spacing={1}>
                <Grid container spacing={0}>
                  <div className={Styles.heightWrapperDiv}>
                    <p className={Styles.subtitle}>1. Height</p>
                    <div className={Styles.heightItemsWrapper}>
                      <div className={Styles.heightItems}>
                        <label>
                          Height (in cms)
                          <span style={{ color: "red" }}> *</span>
                        </label>
                        <TextField
                          InputProps={{ className: classes.input }}
                          inputProps={{ min: 0, max: 350 }}
                          type="Number"
                          FormHelperTextProps={{
                            classes: {
                              root: classes.helperText,
                            },
                          }}
                          value={studentObject.ET_height_height}
                          onChange={(newValue) => {
                            if (newValue.target.value > 350) {
                              newValue.preventDefault();
                            } else if (newValue.target.value < 0) {
                              setStudentObject((preValue) => {
                                preValue.ET.height.height =
                                  "";
                                return { ...preValue };
                              });
                            } else {
                              setStudentObject((preValue) => {
                                preValue.ET_height_height = newValue.target.value;
                                return { ...preValue };
                              });
                              setHelperText((prevalue) => {
                                prevalue.isETHeightFilled = "";
                                return { ...prevalue };
                              });
                              autoSave(studentObject, examDocId, subExamId);
                            }
                          }}
                          className={classes.textField}
                          error={helperText.isETHeightFilled !== ""}
                          id="outlined-basic"
                          sx={[
                            {
                              "&>div:firstOfType": {
                                border:
                                  helperText.isETHeightFilled !== ""
                                    ? "1px solid red !important"
                                    : "1px solid #D0D5DD !important",
                              },
                              width: "100%",
                            },
                          ]}
                          helperText={helperText.isETHeightFilled}
                          variant="outlined"
                        />
                      </div>
                      <div className={Styles.heightItems}>
                        <label>
                          Result QUA*/ NOQ*
                          <span style={{ color: "red" }}> *</span>
                        </label>
                        <TextField
                          InputProps={{ className: classes.input }}
                          FormHelperTextProps={{
                            classes: {
                              root: classes.helperText,
                            },
                          }}
                          value={studentObject.ET_height_result}
                          onChange={(newValue) => {
                            setStudentObject((preValue) => {
                              preValue.ET_height_result = newValue.target.value;
                              return { ...preValue };
                            });
                            setHelperText((prevalue) => {
                              prevalue.isETHeightResultFilled = "";
                              return { ...prevalue };
                            });
                            autoSave(studentObject, examDocId, subExamId);
                          }}
                          className={classes.textField}
                          error={helperText.isETHeightResultFilled !== ""}
                          id="outlined-basic"
                          sx={[
                            {
                              "&>div:firstOfType": {
                                border:
                                  helperText.isETHeightResultFilled !== ""
                                    ? "1px solid red !important"
                                    : "1px solid #D0D5DD !important",
                              },
                              width: "100%",
                            },
                          ]}
                          helperText={helperText.isETHeightResultFilled}
                          variant="outlined"
                        />
                      </div>
                      <div className={Styles.heightItems}>
                        <label>Remarks</label>
                        <textarea
                          className={Styles.textarea}
                          value={studentObject.ET_height_remarks}
                          onChange={(newValue) => {
                            setStudentObject((preValue) => {
                              preValue.ET_height_remarks = newValue.target.value;
                              return { ...preValue };
                            });
                          }}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            }


            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={2} sm={4} md={4} >
                <Item>
                  <div className={Styles.proforma1}>
                    <div className={Styles.statusRadioWrapper}>
                      <FormControl style={{ fontSize: "1.5rem" }}>
                        <FormLabel
                          style={{ fontSize: "1.5rem", color: "#000000" }}
                          id="demo-radio-buttons-group-label"
                        >
                          ET Status <span style={{ color: "red" }}> *</span>:{" "}
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          value={studentObject.ET_IsQualified}
                          name="radio-buttons-group"
                          onChange={(newValue) => {
                            setStudentObject((preValue) => {
                              preValue.ET_IsQualified = newValue.target.value;
                              if (preValue.ET_IsQualified === "true" && preValue.PST_IsQualified === "true") {
                                preValue.Status = "qualified";
                                // setTotalQualifiedCount(totalQualifiedCount++);
                              } else {
                                preValue.Status = "not qualified";
                                // setTotalNotqualifiedCount(totalNotQualifiedCount++);

                              }
                              return { ...preValue };
                            });
                            setHelperText((pre) => {
                              pre.is_ET_Qualified_Filled = "";
                              return { ...pre };
                            });
                            autoSave(studentObject, examDocId, subExamId);
                          }}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <FormControlLabel
                            value={"true"}
                            sx={[radioButtonBigStyle]}
                            control={<Radio size="medium" />}
                            label="Qualified"
                          />
                          <FormControlLabel
                            value={"false"}
                            sx={[radioButtonBigStyle]}
                            control={<Radio size="medium" />}
                            label="Not-Qualified"
                          />
                        </RadioGroup>
                      </FormControl>
                      <p>{helperText.is_ET_Qualified_Filled}</p>
                    </div>
                  </div>
                </Item>
              </Grid>
              {(studentObject.ET_IsQualified === true || studentObject.ET_IsQualified == "true") &&
                <Grid item xs={4} sm={4} md={4} >
                  <Item>
                    <div className={Styles.proforma2}>
                      <div className={Styles.statusRadioWrapper}>
                        <FormControl style={{ fontSize: "1.5rem" }}>
                          <FormLabel
                            style={{ fontSize: "1.5rem", color: "#000000" }}
                            id="demo-radio-buttons-group-label"
                          >
                            PST Status <span style={{ color: "red" }}> *</span>:{" "}
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={studentObject.PST_IsQualified}
                            name="radio-buttons-group"
                            onChange={(newValue) => {
                              setStudentObject((preValue) => {
                                preValue.PST_IsQualified = newValue.target.value;
                                if (preValue.ET_IsQualified === "true" && preValue.PST_IsQualified === "true") {
                                  preValue.Status = "qualified";
                                  // setTotalQualifiedCount(totalQualifiedCount++);
                                } else {
                                  preValue.Status = "not qualified";
                                  // setTotalNotqualifiedCount(totalNotQualifiedCount++);
                                }
                                return { ...preValue };
                              });
                              setHelperText((pre) => {
                                pre.is_PST_Qualified_Filled = "";
                                return { ...pre };
                              });

                              autoSave(studentObject, examDocId, subExamId);
                            }}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <FormControlLabel
                              value={"true"}
                              sx={[radioButtonBigStyle]}
                              control={<Radio size="medium" />}
                              label="Qualified"
                            />
                            <FormControlLabel
                              value={"false"}
                              sx={[radioButtonBigStyle]}
                              control={<Radio size="medium" />}
                              label="Not-Qualified"
                            />
                          </RadioGroup>
                        </FormControl>
                        <p>{helperText.is_PST_Qualified_Filled}</p>
                      </div>
                    </div>
                  </Item>
                </Grid>
              }
              <Grid item xs={4} sm={4} md={4} >
                <Item>
                  <div className={Styles.statusRadioWrapper}>
                    {/* disabled={studentObject.AppliedAs==="Inservice"} */}
                    {/* <FormControl style={{ fontSize: "1.5rem" }} disabled={true}>
                      <FormLabel
                        style={{ fontSize: "1.5rem", color: "#000000" }}
                        id="demo-radio-buttons-group-label"
                      >
                        Result Status<span style={{ color: "red" }}> *</span>
                      </FormLabel> */}
                    <div style={{ display: "inline-block", width: "30%", fontSize: "1.5rem", color: "#000000" }}>Result Status</div>
                    <div style={{ width: "70%", display: "inline-block" }}>
                      {
                        studentObject.Status === "pending" ? <Chip icon={<AvTimerIcon style={{ color: pendingChip.color }} />} label={studentObject.Status} size={"medium"} sx={[{ ...chipStatusStyle, ...pendingChip }]} /> :
                          studentObject.Status === "qualified" ? <Chip icon={<DoneIcon style={{ color: qualifiedChip.color }} />} label={studentObject.Status} size={"medium"} sx={[{ ...chipStatusStyle, ...qualifiedChip }]} /> :
                            <Chip icon={<CloseIcon style={{ color: notQualifiedChip.color }} />} label={studentObject.Status} size={"medium"} sx={[{ ...chipStatusStyle, ...notQualifiedChip }]} />
                      }
                    </div>
                  </div>
                </Item>
              </Grid>
            </Grid>
            <Box style={{ marginTop: "2rem" }}>
              <div className={Styles.wrapperCompanyLogo}>
                <div className={Styles.poweredByText}>
                  Powered by Ranga Enterprises
                </div>
                {/* <img className={Styles.logo} src={logo} alt="" /> */}
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
      <UploadImageModal
        open={cameraModalToggle}
        handleClose={cameraModalClose}
        picture={IdPhotoClicked}
      />

      {/* //* Modals for Photo*/}
      <UploadImageModal
        open={photoCameraModalToggle}
        handleClose={photCameraModalClose}
        picture={passPhotoClicked}
      />
      {/* Id card preview */}
      <IDCardPreview
        open={openIdCardPreview}
        handleClose={() => setOpenIdCardPreview(false)}
        src={studentObject.idCardBase64}
      />
      <QrAndBarcodeScanner
        open={qa_bar_codeScannerMOdalToggle}
        handleClose={() => setQa_bar_codeScannerMOdalToggle(false)}
        getCode={afterScanQR_Bar_code}
      />
      {/* <PDF Student={studentObject} /> */}
      {/* {objectForPDF &&
        <RsiPDF setObject={() => { setObjectForPDF(null) }} Student={objectForPDF} />
      } */}
      {objectForPDF &&
        <KsisePDF setObject={() => { setObjectForPDF(null) }} Student={objectForPDF} />
      }
      {viewStudentDocsModalToggle &&
        <StudentPhotos
          open={viewStudentDocsModalToggle}
          handleClose={() => { setViewStudentDocsModalToggle(false) }}
        />
      }
      <PdfViewerModal open={pdfViewerToggle} url={studentObject?.uploadedForm64} handleClose={() => setPdfViewerToggle(false)} />
      {openAlert &&
        <ConfirmationAlert confirm={(e) => { ModalResp(e) }} buttonTitle={'Cancel'} returnRespButton={'Continue'} close={() => setOpenAlert(false)} open={openAlert} title={"Warning"} subTitle={"Ground name will be changed and student Data will be deleted from your machine! Do you want to continue"} />
      }
    </>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  // textAlign: 'center',
  color: theme.palette.text.secondary,
}));

async function startPdfDownload(_subExamDocID, studentObject, examCenterCity) {
  presentLoader();
  DB.pdfBase64.get(`${_subExamDocID}_rawResultSheet`).then(async (res) => {
    if (!res) {
      errorToast("PDF not found.");
      dismissLoader();
      return;
    }
    const tickSvgPath = "M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 ";
    let pdfBase64 = res?.base64URI;
    console.log({ pdfBase64 });

    let arrayBuffer = _base64ToArrayBuffer(pdfBase64);
    console.log({ arrayBuffer });

    const pdfDocument = await PDFDocument.load(arrayBuffer);
    const helveticaFont = await pdfDocument.embedFont(StandardFonts.Helvetica);
    const pages = pdfDocument.getPages();
    const firstPage = pages[0];
    const { width, height } = firstPage.getSize();

    //* for Admin Side photo
    if (studentObject?.Photo64 && studentObject?.Photo64 !== "") {
      const photoBase64 = studentObject.Photo64.includes("data:image") ? studentObject.Photo64 : `data:image/png;base64,${studentObject.Photo64}`;
      try {
        const pdfImage = await pdfDocument.embedPng(photoBase64);
        firstPage.drawImage(pdfImage, "png", {
          x: 32,
          y: firstPage.getHeight() - 98,
          width: 75,
          height: 60
        });
      } catch (error) {
        if (error === "The input is not a PNG file!") {
          const pdfImage = await pdfDocument.embedJpg(photoBase64);
          firstPage.drawImage(pdfImage, {
            x: 32,
            y: firstPage.getHeight() - 98,
            width: 75,
            height: 60
          });
        }
      }
    }

    //* for real time photo
    if (studentObject?.realTimeBase64 && studentObject?.realTimeBase64 !== "") {
      const photoBase64 = studentObject.realTimeBase64.includes("data:image") ? studentObject.realTimeBase64 : `data:image/png;base64,${studentObject.realTimeBase64}`;
      try {
        const pdfImage = await pdfDocument.embedPng(photoBase64);
        firstPage.drawImage(pdfImage, "png", {
          x: 113.5,
          y: firstPage.getHeight() - 98,
          width: 75,
          height: 60
        });
      } catch (error) {
        if (error === "The input is not a PNG file!") {
          const pdfImage = await pdfDocument.embedJpg(photoBase64);
          firstPage.drawImage(pdfImage, {
            x: 113.5,
            y: firstPage.getHeight() - 98,
            width: 75,
            height: 60
          });
        }
      }
    }

    firstPage.drawText((studentObject?.SNO && studentObject?.SNO !== "") ? studentObject?.SNO : studentObject.Applicant, {
      x: 62,
      y: firstPage.getHeight() - 121,
      size: 9,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(studentObject["Full Name"], {
      x: 205,
      y: firstPage.getHeight() - 121,
      size: 9,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });


    firstPage.drawText(studentObject.Applicant, {
      x: 95,
      y: firstPage.getHeight() - 141,
      size: 9,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });


    firstPage.drawText((studentObject["rollNo"] && studentObject["rollNo"] !== "") ? studentObject["rollNo"] : "", {
      x: 220,
      y: firstPage.getHeight() - 141,
      size: 9,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });


    firstPage.drawText((studentObject?.chest_no && studentObject?.chest_no !== "") ? studentObject?.chest_no : "", {
      x: 365,
      y: firstPage.getHeight() - 141,
      size: 9,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText((studentObject?.Gender && studentObject?.Gender !== "") ? studentObject?.Gender : studentObject?.gender, {
      x: 490,
      y: firstPage.getHeight() - 141,
      size: 9,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText((examCenterCity && examCenterCity !== "") ? examCenterCity : "", {
      x: width - 210,
      y: height - 40,
      size: 10,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText(studentObject?.examCenterName ? studentObject?.examCenterName : "", {
      x: width - 215,
      y: height - 60,
      size: 10,
      lineHeight: 11,
      font: helveticaFont,
      color: rgb(0, 0, 0),
      maxWidth: 180,
      wordBreak: [' ', '-']
    });

    firstPage.drawText(studentObject?.examDate ? studentObject?.examDate : "", {
      x: width - 210,
      y: height - 95,
      size: 10,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });



    if (studentObject?.AppliedAs === "Direct" || studentObject?.AppliedAs === "Exs." || studentObject?.AppliedAs === "Exs-C" || studentObject?.AppliedAs === "Tribal") {
      firstPage.drawSvgPath(tickSvgPath, {
        x: studentObject?.AppliedAs === "Direct" ? width - 180 : studentObject?.AppliedAs === "Exs." ? width - 140 : studentObject?.AppliedAs === "Exs-C" ? width - 100 : width - 60,
        y: height - 130,
        scale: 0.5,
        color: rgb(0, 0, 0),
      });
    }

    let qrCode = await generateQrCode(studentObject?.Applicant);
    try {
      console.log("In Try");
      const pdfImage = await pdfDocument.embedPng(qrCode);
      firstPage.drawImage(pdfImage, {
        x: width - 95,
        y: 10,
        width: 75,
        height: 75
      });
    } catch (error) {
      console.log("In error");
      if (error === "The input is not a PNG file!") {
        const pdfImage = await pdfDocument.embedJpg(qrCode);
        firstPage.drawImage(pdfImage, {
          x: 100,
          y: 100,
          width: 100,
          height: 100
        });
      }
    }

    const pdfBytes = await pdfDocument.save();
    let readyPdfBase64 = uint8ToBase64(pdfBytes);
    const readyPdfBase64WithPrefix = "data:application/pdf;base64," + readyPdfBase64;
    console.log({ readyPdfBase64WithPrefix });
    const a = document.createElement('a');
    a.href = readyPdfBase64WithPrefix;
    a.download = `${studentObject["Full Name"]} Result Sheet.pdf`;
    a.click();

    dismissLoader();
  }).catch(err => {
    console.log(err);
    errorToast("Something went wrong! Try again");
    dismissLoader();
  })
}

function _base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

const uint8ToBase64 = (arr) => btoa(Array(arr.length).fill("").map((_, i) => String.fromCharCode(arr[i])).join(""));

function generateQrCode(appId) {
  return new Promise((resolve, reject) => {
    var opts = {
      margin: 1,
    };
    QRCode.toDataURL(appId.toString(), opts).then((url) => {
      resolve(url);
    }).catch((err) => {
      reject(err);
    });
  });
}

//! images
//? Photo64
//? realTimeBase64

//! text
//* venue       examCenterName
//* date        examDate
//* Tick        AppliedAs
//* n o c       Full Name
//* Applicant   Applicant
//* Gender      gender Gender
//* chest_no    chest_no