import { getFirestore, arrayUnion, getDoc, doc, setDoc, updateDoc } from "firebase/firestore";

async function setAdminLog(examDocId, adminDocId, adminInfo, postBody) {
  let createPostBody = {...postBody};
  createPostBody.logDate = new Date().toDateString();
  createPostBody.logTime = new Date().toLocaleTimeString();
  let adminRef = doc(getFirestore(), `exams/${examDocId}/admins`,adminDocId);

  new Promise((resolve, reject) => {
    getDoc(adminRef).then(res=>{
      if (res.exists()) {
        updateDoc(adminRef, {dataLogs: arrayUnion(createPostBody)}).then(result => {
          resolve(result);
        })
      } else {
        setDoc(adminRef, {dataLogs: [createPostBody],name: adminInfo.name, email: adminInfo.email}).then(result2=>{
          resolve(result2);
        })
      }
    })
  })
}

// async function createAdminLog(examDocId, adminDocId, adminInfo, postBody) {
//   let createPostBody = {...postBody};
//   createPostBody.logDate = new Date().toDateString();
//   createPostBody.logTime = new Date().toLocaleTimeString();
//   let adminRef = doc(getFirestore(), `exams/${examDocId}/admins`,adminDocId);
//   console.log({dataLogs: [createPostBody],name: adminInfo.name, email: adminInfo.email});
//   return await setDoc(adminRef, {dataLogs: [createPostBody],name: adminInfo.name, email: adminInfo.email});
// }

export { setAdminLog }