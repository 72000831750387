import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import cssClasses from "./Loading.module.css";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Modal from "@mui/material/Modal";
import { useSelector } from 'react-redux';
const dialogStyle = {
  "& .MuiDialog-container":{
    background: "rgba(193, 201, 210, 0.7)"
  }
}
export default function Loading() {
  const isLoading = useSelector((state) => state.IsLoading.isLoading);
  return (
		<Dialog 
      sx={[dialogStyle]} 
      // onClose={() => {setOpen(false)}} 
      open={isLoading}
    >
			<div className={cssClasses.wrapper}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CircularProgress />
          <p style={{margin: '0',fontSize: '2rem', marginLeft:"1rem"}}>Loading...</p>
        </Box>
			</div>
		</Dialog>
	);
}
