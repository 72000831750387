import React,{useState} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "../../Subcomponent/Button";
import cssClasses from "./ID_CardPreview.module.css"
import crossImage from "../../assets/images/x.svg";
import CircularProgress from '@mui/material/CircularProgress';

export default function ID_CardPreview({ open, handleClose , src}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={[
        {
          ".MuiBackdrop-root":{
            background: "rgba(193, 201, 210, 0.7)"
          }
        }
      ]}
      disableAutoFocus={true}
    >
      <Box className={cssClasses.modalWrapper}>
        <div className={cssClasses.header}>
          <h1>Id Card Preview</h1>
          <img src={crossImage} alt="" style={{cursor:"pointer"}} onClick={handleClose}/>
        </div>

        <div className={cssClasses.mainContainer}>
          <img src={src}/>
        </div>

        <div className={cssClasses.footer}>
          {/* <div><Button style={{ color: "#7F56D9", backgroundColor: "#ffffff" }} btnName={"Download PDF"} clicked={handleClose} /></div> */}
          <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Cancel"} clicked={handleClose} /></div>
        </div>
      </Box>
    </Modal>
  );
}
