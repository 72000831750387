import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import TextField from "@mui/material/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { padding } from '@mui/system';

//* node modules
import debounce from 'lodash.debounce';

const useStyles = makeStyles((theme) => ({
    textField: {
        background: '#FFFFFF',
        border: '1px solid #D0D5DD !important',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        borderRadius: '0.8rem',
        padding: "0 !important",
        width: "100%"
    },
    input: {
        "&.Mui-focused": {
            // border: "unset",
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #D0D5DD !important'
            }
        },
        "&:hover": {
            // border:"none",
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #D0D5DD !important'
            }
        },
        '& input': {
            padding: '1rem 1.4rem !important',
            lineHeight: '2.4rem',
            fontSize: '1.6rem',
            paddingLeft: "0 !important"
        }
    }
}));

export default function SearchBar({ placeholder, search, min = false, id = '', idDebounce = true, tabIndex }) {
    const classList = useStyles();

    const debouncedFetchData = debounce((e) => {
        search(e.target.value);
    }, 500);

    return (
        <TextField tabIndex={tabIndex} className={classList.textField}
            id={id + "input-with-icon-textfield"}
            placeholder={placeholder}
            // onKeyUp={(e) => {
            //     if (e.key === 'Enter') {
            //         search(e.target.value);
            //     }
            // }}
            onChange={(e) => {
                // if (e.target.value === '') {
                //     search(e.target.value)
                // }
                if (min && e.target.value.length <= min) {
                    if (idDebounce) {
                        debouncedFetchData(e);
                    } else {
                        search(e.target.value);
                    }
                }
                if (!min) {
                    if (idDebounce) {
                        debouncedFetchData(e);
                    } else {
                        search(e.target.value);
                    }
                }
            }}
            InputProps={{
                className: classList.input,
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon style={{ color: "#667085", width: "2rem", height: "2rem" }} />
                    </InputAdornment>
                ),
            }}
            // inputProps={{maxLength:min}}
            variant="outlined"
        />
    )
}
