import * as tf from "@tensorflow/tfjs";
import {
  detectAllFaces,
  detectSingleFace,
  loadSsdMobilenetv1Model,
  SsdMobilenetv1Options,
  nets
} from "@vladmandic/face-api";
// import * as cv from "@techstark/opencv-js"
/**
 * Define a custom layer.
 *
 * This layer performs the following simple operation:
 *   output = input * (x ^ alpha);
 * - x is a trainable scalar weight.
 * - alpha is a configurable constant.
 *
 * This custom layer is written in a way that can be saved and loaded.
 */
class LambdaLayer extends tf.layers.Layer {
  constructor(config) {
    super(config);
    this.scale = config.scale;
  }

  /**
   * call() contains the actual numerical computation of the layer.
   *
   * It is "tensor-in-tensor-out". I.e., it receives one or more
   * tensors as the input and should produce one or more tensors as
   * the return value.
   *
   * Be sure to use tidy() to avoid WebGL memory leak.
   */
  call(input) {
    return tf.mul(input[0], this.scale);
  }

  computeOutputShape(inputShape) {
    // console.log(inputShape)
    return inputShape;
  }
  /**
   * getConfig() generates the JSON object that is used
   * when saving and loading the custom layer object.
   */
  getConfig() {
    const config = super.getConfig();
    Object.assign(config, { scale: this.scale });
    return config;
  }

  /**
   * The static className getter is required by the
   * registration step (see below).
   */
  static get className() {
    return "LambdaLayer";
  }
}

export class Facenet {
  constructor(classNames, classEmbeddings) {
    tf.serialization.registerClass(LambdaLayer);
    this.classNames = classNames;
    this.progress = 0;
    this.classEmbeddings = classEmbeddings;
    this.makeImageBright = false;
    this.optionsSSDMobileNet = new SsdMobilenetv1Options({ minConfidence: 0.2 });
    this.faceApiModelLoaded = false;
  }

  async loadModel(folderPath, modelUrl) {
    if (modelUrl.trim() === '') {
      modelUrl = folderPath + "/model/model.json"
    }
    await nets.ssdMobilenetv1.loadFromUri(window.location.hostname === '' ? folderPath + "build/facenet-data/face_api_model" : folderPath + "/face_api_model");
    this.faceApiModelLoaded = true;
    // this.facenetModel = await tf.loadLayersModel(folderPath + "/facenetModel/model.json");
    this.facenetModel = await tf.loadLayersModel(window.location.hostname === '' ? folderPath + "build/facenet-data/facenetModel/model.json" : folderPath + "/facenetModel/model.json");
    // await nets.ssdMobilenetv1.loadFromUri(folderPath + "/face_api_model");
    this.model = await tf.loadLayersModel(modelUrl, {
      onProgress: (fraction) => {
        this.progress = fraction * 100 - 1
      }
    });
    const warmUpResult = this.model.predict(tf.zeros([1, 160, 160, 3]))
    warmUpResult.dataSync()
    warmUpResult.dispose()
    this.progress = 100;
  }

  getProgress() {
    return this.progress;
  }

  l2_normalize(x) {
    console.log(x)
    return tf.div(x, tf.sqrt(tf.sum(tf.mul(x, x))))
  }

  predict(image) {
    return tf.tidy(() => {
      let result = this.model.predict(image);
      console.log(tf.max(result.as1D()).arraySync())
      let index = tf.argMax(result.as1D()).arraySync();
      if (this.classEmbeddings) {
        let classEmbedding = this.l2_normalize(tf.expandDims(tf.tensor(this.classEmbeddings[index][0]), 0));
        let currentImageEmbedding = this.l2_normalize(this.facenetModel.predict(image))
        let distance = tf.norm(tf.sub(currentImageEmbedding, classEmbedding));
        distance = distance.arraySync();
        console.log("distance:", distance);
        if (tf.max(result.as1D()).arraySync() >= 0.7 && distance < 0.8) {
          return this.classNames[index];
        } else {
          return "Face not found in database"
        }
      } else {
        if (tf.max(result.as1D()).arraySync() >= 0.7) {
          return this.classNames[index];
        } else {
          return "Face not found in database"
        }
      }
    })
  }

  normalizeImage(image) {
    return tf.tidy(() => {
      let mean = tf.mean(image);
      let std = tf.moments(image).variance.sqrt();
      return tf.div(tf.sub(image, mean), std);
    })
  }

  resizeWithPadding(image) {
    return tf.tidy(() => {
      // console.log(image.shape[0],image.shape[1]);
      let factor_0 = 160 / image.shape[0];
      let factor_1 = 160 / image.shape[1];
      let factor = Math.min(factor_0, factor_1);
      image = tf.image.resizeBilinear(image, [
        Math.round(factor * image.shape[0]),
        Math.round(factor * image.shape[1]),
      ]);
      let diff_0 = 160 - image.shape[0];
      let diff_1 = 160 - image.shape[1];
      let paddings = [
        [Math.floor(diff_0 / 2), diff_0 - Math.floor(diff_0 / 2)],
        [Math.floor(diff_1 / 2), diff_1 - Math.floor(diff_1 / 2)],
        [0, 0],
      ];
      image = tf.pad(image, paddings);
      if (image.shape[0] === 160 && image.shape[1] === 160) {
        return image;
      } else {
        return tf.image.resizeBilinear(image, [160, 160]);
      }
    })
  }

  async cropImage(image, folderPath) {
    if (!this.faceApiModelLoaded) {
      await nets.ssdMobilenetv1.loadFromUri(window.location.hostname === '' ? folderPath + "build/facenet-data/face_api_model" : folderPath + "/face_api_model");
      this.faceApiModelLoaded = true;
    }
    tf.engine().startScope()
    let detections = await detectSingleFace(image, this.optionsSSDMobileNet);

    if (detections) {
      console.log(detections.box.width, detections.box.height)
      var canvas = document.createElement("canvas");
      canvas.width = detections.box.width + 100;
      canvas.height = detections.box.height + 100;
      let context = canvas.getContext("2d");
      context.drawImage(
        image,
        detections.box.topLeft.x - 50,
        detections.box.topLeft.y - 50,
        detections.box.width + 100,
        detections.box.height + 100,
        0,
        0,
        detections.box.width + 100,
        detections.box.height + 100
      );
      tf.engine().endScope()
      return canvas.toDataURL();

    } else {
      tf.engine().endScope()
      return "No Face Detected";

    }

  }

  async runFaceDetection(image, redo) {
    console.time('runFaceDetection');
    console.time('startScope');
    tf.engine().startScope()
    console.timeEnd('startScope');
    console.time('detectSingleFace');
    let detections = await detectSingleFace(image, this.optionsSSDMobileNet);
    console.timeEnd('detectSingleFace');
    console.log(detections);
    if (detections) {
      console.time('imageCropAndArray');
      var canvas = document.createElement("canvas");
      canvas.width = detections.box.width;
      canvas.height = detections.box.height;
      let context = canvas.getContext("2d");
      context.drawImage(
        image,
        detections.box.topLeft.x,
        detections.box.topLeft.y,
        detections.box.width,
        detections.box.height,
        0,
        0,
        detections.box.width,
        detections.box.height
      );
      let imageArray = tf.browser.fromPixels(canvas);
      console.timeEnd('imageCropAndArray');
      console.time('resizeWithPadding');
      imageArray = this.resizeWithPadding(imageArray);
      console.timeEnd('resizeWithPadding');
      // const canvas2 = document.createElement("canvas");
      // canvas2.width = imageArray.shape.width;
      // canvas2.height = imageArray.shape.height;
      // await tf.browser.toPixels(imageArray.cast("int32"), canvas2);
      console.time('normalizeImage');
      imageArray = this.normalizeImage(imageArray);
      console.timeEnd('normalizeImage');
      console.time('expandDims');
      imageArray = tf.expandDims(imageArray, 0);
      console.timeEnd('expandDims');
      console.time('predict');

      let result = this.predict(imageArray);
      console.timeEnd('predict');
      // tf.disposeVariables();
      console.time('endScope');
      tf.engine().endScope()
      console.timeEnd('endScope');
      console.timeEnd('runFaceDetection');
      return result;
      // return this.predict(imageArray);
      // } 
      // else if (redo && !detections) {
      //   let src = cv.imread(image);
      //   let mat = new cv.Mat();
      //   let M = cv.Mat.eye(3, 3, cv.CV_32FC1)
      //   let anchor = new cv.Point(-1, -1);
      //   cv.filter2D(src, mat, cv.CV_8U, M, anchor, 0, cv.BORDER_DEFAULT);
      //   const canvas2 = document.createElement("canvas");
      //   cv.imshow(canvas2, mat);
      //   src.delete();
      //   M.delete();
      //   console.log(detections);
      //   console.log(image);
      //   let result = await this.runFaceDetection(canvas2, false)
      //   if (result !== "No Face detected") {
      //     console.log(result)
      //   } else {
      //     console.log("No Face detected")
      //   }
      //   return canvas2.toDataURL("image/png");
    } else {
      console.timeEnd('runFaceDetection');
      return "No Face detected"
    }

  }
}