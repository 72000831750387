import React, { useEffect, useState } from 'react'
import Button from '../../Subcomponent/Button'
import SearchBar from '../../Subcomponent/SearchBar'
import Styles from './AllExam.module.css'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux'
import actions from '../../Redux Store/actions'
import { getAllExamCenters, getExamByDocID, getExams, getSubExamByDocID } from '../../services/ExamService'
import defaultImage from '../../assets/images/default-exam-img.svg'
import { errorToast } from '../../services/Toast'
import CacheModal from '../../Modals/CacheModal/CacheModal'
import { DB } from '../../db'
import { dismissLoader, presentLoader } from '../../services/loaderService'
import TestModal from '../../Modals/TestModal/TestModal'

// * import Service

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
}));

export default function AllExam() {

    const dispatch = useDispatch();
    const loginUser = useSelector((state) => state.Auth.user);

    const [exams, setExams] = useState([])
    const [allExams, setAllExams] = useState([])
    const [searchText, setSearchText] = useState("")
    const [lastObj, setLastObj] = useState(null);
    const [openCacheModal, setOpenCacheModal] = useState(false);
    const [openTestModal, setOpenTestModal] = useState(false);

    useEffect(() => {
        dispatch(actions.activeMenu('exams'));
        // getExamByDocID() 
        if (loginUser) {
            console.log(loginUser);
            if (loginUser?.examAccess?.length > 0) {
                DB.allExams.toArray().then((resp) => {
                    if (resp.length > 0) {
                        DB.allExams.toArray().then((resp) => {
                            console.log(resp)
                            setAllExams([...resp]);
                            setExams([...resp]);
                        })
                    } else {
                        syncExams()
                    }
                })
            } else {
                //! 
                errorToast("You don't have access to any exam!");
                setAllExams([]);
                setExams([]);
            }
        }
    }, [dispatch, loginUser])


    function syncExams() {
        if (window.navigator.onLine) {
            presentLoader()
            if (allExams.length > 0) {
                DB.allExams.clear().then((response) => {
                    DB.allSubExams.clear().then((resp) => {
                        DB.allExamCenters.clear().then((resp) => {
                            Promise.all(loginUser?.examAccess.map(m => getExamByDocID(m.value))).then(async res => {
                                let _examList = res.filter(f => f.success).map(m => m.data);
                                console.log(_examList);
                                for (const ele of _examList) {
                                    let base64 = await getBase64FromImageUrl(ele)
                                    ele.image64 = base64.dataURL
                                }
                                setAllExams([..._examList]);
                                setExams([..._examList]);
                                DB.allExams.bulkAdd(_examList).then((res) => {
                                    loginUser.examAccess.forEach((ele, index) => {
                                        if (ele.examCenterList.length > 0) {
                                            getAllExamCenters(ele.value).then((examcenterRes) => {
                                                DB.allExamCenters.bulkAdd(examcenterRes.docs.map(m => ({ ...m.data(), id: `${ele.value}_${m.id}`, examDocId: ele.value })));
                                            })
                                        }
                                        if (ele.subExamList.length > 0) {
                                            Promise.all(ele.subExamList.map(m => getSubExamByDocID(ele.value, m.value))).then(re => {
                                                let _subExamList = re.filter(f => f.success).map(m => m.data);
                                                DB.allSubExams.bulkAdd(_subExamList).then((r) => {
                                                    if (index === loginUser.examAccess.length - 1) {
                                                        dismissLoader()
                                                    }
                                                })
                                            })
                                        } else {
                                            if (index === loginUser.examAccess.length - 1) {
                                                dismissLoader()
                                            }
                                        }
                                    })
                                })
                            })
                        }).catch((err) => {
                            Promise.all(loginUser?.examAccess.map(m => getExamByDocID(m.value))).then(async res => {
                                let _examList = res.filter(f => f.success).map(m => m.data);
                                console.log(_examList);
                                for (const ele of _examList) {
                                    let base64 = await getBase64FromImageUrl(ele)
                                    ele.image64 = base64.dataURL
                                }
                                setAllExams([..._examList]);
                                setExams([..._examList]);
                                DB.allExams.bulkAdd(_examList).then((res) => {
                                    loginUser.examAccess.forEach((ele, index) => {
                                        if (ele.examCenterList.length > 0) {
                                            getAllExamCenters(ele.value).then((examcenterRes) => {
                                                DB.allExamCenters.bulkAdd(examcenterRes.docs.map(m => ({ ...m.data(), id: `${ele.value}_${m.id}`, examDocId: ele.value })));
                                            })
                                        }
                                        if (ele.subExamList.length > 0) {
                                            Promise.all(ele.subExamList.map(m => getSubExamByDocID(ele.value, m.value))).then(re => {
                                                let _subExamList = re.filter(f => f.success).map(m => m.data);
                                                DB.allSubExams.bulkAdd(_subExamList).then((r) => {
                                                    if (index === loginUser.examAccess.length - 1) {
                                                        dismissLoader()
                                                    }
                                                })
                                            })
                                        } else {
                                            if (index === loginUser.examAccess.length - 1) {
                                                dismissLoader()
                                            }
                                        }
                                    })
                                })
                            })
                        })
                    }).catch((err) => {
                        Promise.all(loginUser?.examAccess.map(m => getExamByDocID(m.value))).then(async res => {
                            let _examList = res.filter(f => f.success).map(m => m.data);
                            console.log(_examList);
                            for (const ele of _examList) {
                                let base64 = await getBase64FromImageUrl(ele)
                                ele.image64 = base64.dataURL
                            }
                            setAllExams([..._examList]);
                            setExams([..._examList]);
                            DB.allExams.bulkAdd(_examList).then((res) => {
                                loginUser.examAccess.forEach((ele, index) => {
                                    if (ele.examCenterList.length > 0) {
                                        getAllExamCenters(ele.value).then((examcenterRes) => {
                                            DB.allExamCenters.bulkAdd(examcenterRes.docs.map(m => ({ ...m.data(), id: `${ele.value}_${m.id}`, examDocId: ele.value })));
                                        })
                                    }
                                    if (ele.subExamList.length > 0) {
                                        Promise.all(ele.subExamList.map(m => getSubExamByDocID(ele.value, m.value))).then(re => {
                                            let _subExamList = re.filter(f => f.success).map(m => m.data);
                                            DB.allSubExams.bulkAdd(_subExamList).then((r) => {
                                                if (index === loginUser.examAccess.length - 1) {
                                                    dismissLoader()
                                                }
                                            })
                                        })
                                    } else {
                                        if (index === loginUser.examAccess.length - 1) {
                                            dismissLoader()
                                        }
                                    }
                                })
                            })
                        })
                    })
                }).catch((err) => {
                    Promise.all(loginUser?.examAccess.map(m => getExamByDocID(m.value))).then(async res => {
                        let _examList = res.filter(f => f.success).map(m => m.data);
                        console.log(_examList);
                        for (const ele of _examList) {
                            let base64 = await getBase64FromImageUrl(ele)
                            ele.image64 = base64.dataURL
                        }
                        setAllExams([..._examList]);
                        setExams([..._examList]);
                        DB.allExams.bulkAdd(_examList).then((res) => {
                            loginUser.examAccess.forEach((ele, index) => {
                                if (ele.examCenterList.length > 0) {
                                    getAllExamCenters(ele.value).then((examcenterRes) => {
                                        DB.allExamCenters.bulkAdd(examcenterRes.docs.map(m => ({ ...m.data(), id: `${ele.value}_${m.id}`, examDocId: ele.value })));
                                    })
                                }
                                if (ele.subExamList.length > 0) {
                                    Promise.all(ele.subExamList.map(m => getSubExamByDocID(ele.value, m.value))).then(re => {
                                        let _subExamList = re.filter(f => f.success).map(m => m.data);
                                        DB.allSubExams.bulkAdd(_subExamList).then((r) => {
                                            if (index === loginUser.examAccess.length - 1) {
                                                dismissLoader()
                                            }
                                        })
                                    })
                                } else {
                                    if (index === loginUser.examAccess.length - 1) {
                                        dismissLoader()
                                    }
                                }
                            })
                        })
                    })
                })
            } else {
                if (loginUser?.examAccess && loginUser?.examAccess.length > 0) {
                    Promise.all(loginUser?.examAccess.map(m => getExamByDocID(m.value))).then(async res => {
                        let _examList = res.filter(f => f.success).map(m => m.data);
                        console.log(_examList);
                        for (const ele of _examList) {
                            let base64 = await getBase64FromImageUrl(ele)
                            ele.image64 = base64.dataURL
                        }
                        setAllExams([..._examList]);
                        setExams([..._examList]);
                        DB.allExams.bulkAdd(_examList).then((res) => {
                            loginUser.examAccess.forEach((ele, index) => {
                                if (ele.examCenterList.length > 0) {
                                    getAllExamCenters(ele.value).then((examcenterRes) => {
                                        DB.allExamCenters.bulkAdd(examcenterRes.docs.map(m => ({ ...m.data(), id: `${ele.value}_${m.id}`, examDocId: ele.value })));
                                    })
                                }
                                if (ele.subExamList.length > 0) {
                                    Promise.all(ele.subExamList.map(m => getSubExamByDocID(ele.value, m.value))).then(re => {
                                        let _subExamList = re.filter(f => f.success).map(m => m.data);
                                        DB.allSubExams.bulkAdd(_subExamList).then((r) => {
                                            if (index === loginUser.examAccess.length - 1) {
                                                dismissLoader()
                                            }
                                        })
                                    })
                                } else {
                                    if (index === loginUser.examAccess.length - 1) {
                                        dismissLoader()
                                    }
                                }
                            })
                        })
                    })
                } else {
                    errorToast("You don't have access to any exam!")
                    dismissLoader()
                }
            }
        } else {
            errorToast("No Internet Connectivity")
        }
    }

    function searchExam(e) {
        if (e === '') {
            setExams([...allExams])
        } else {
            // setSearchText(e.target.value.toLowecase())
            setExams([])
            // let a = allExams.filter((exam) => { return exam.examNameForSearch.includes(e.toLowerCase()) })
            setExams([...allExams.filter((exam) => { return exam.examNameForSearch.includes(e.toLowerCase()) })])
        }
    }

    function getBase64FromImageUrl(obj) {
        return new Promise((resolve, reject) => {
            var img = new Image();
            img.onload = function () {
                var canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL("image/jpeg");
                // 
                // console.log("dataURL",dataURL);
                resolve({ ...obj, dataURL: dataURL })
            };
            img.onerror = function (err) {
                console.log(err);
                resolve({ ...obj, dataURL: "" })
            }
            img.crossOrigin = '';
            img.crossOrigin = 'anonymous'
            // img.src = "https://cors-anywhere.herokuapp.com/" + process.env.REACT_APP_bucketAccessRootPath + obj.url;
            // img.src = "https://cors-anywhere-hts.herokuapp.com/" + obj.image;
            img.src = "https://cors-anywhere-dnio.onrender.com/" + obj.image;
            // img.src = obj.image;
        })
    }

    const navigate = useNavigate();
    function goToExamDetails(examShortName, examObject) {
        localStorage.setItem('modelUrl', 'online')
        localStorage.setItem("exam", JSON.stringify(examObject));
        dispatch(actions.FaceNet(null));
        navigate('/home/student-verify/' + examShortName + '/allSubExams')
    }
    const examCard = exams.map((exam, index) => {
        return (

            <Grid item xs={4} sm={4} md={4} lg={4} key={"examCard" + index}>
                <Item>
                    <div className={Styles.examCard} key={exam.key}>
                        <div className={Styles.examCardFirstRow}>
                            <div className={Styles.paddingDiv}>
                                <div className={Styles.imageWrapper}>
                                    <div className={Styles.imageSection}>
                                        {exam.image === '' ?
                                            <img src={defaultImage} alt="" />
                                            :
                                            <img src={exam.image64} onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }} alt="" />
                                        }
                                    </div>
                                </div>
                                <div className={Styles.nameWrapper}>
                                    <div>
                                        <p className={Styles.examName}>{exam.name}</p>
                                        <p className={Styles.label}>{exam.examShortName}</p>
                                    </div>
                                    <div style={{ marginLeft: "1rem" }}>
                                        <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Start\xa0Now"} clicked={() => { goToExamDetails(exam.examShortName, exam) }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={Styles.paddingDiv}>
                            <div className={Styles.info}>
                                <div className={Styles.examDetailsWrapper}>
                                    <p className={Styles.inputLabel}>Exam Fee</p>
                                    <p className={Styles.inputValue}>₹ {exam.fee}</p>
                                </div>
                                <div className={Styles.examDetailsWrapper}>
                                    <p className={Styles.inputLabel}>Exam Date</p>
                                    <p className={Styles.inputValue}>{exam.examDate}</p>
                                </div>
                                <div className={Styles.examDetailsWrapper}>
                                    <p className={Styles.inputLabel}>Payment type</p>
                                    <p className={Styles.inputValue}>{exam.paymentType === "Both" ? "Online/Offline" : exam.paymentType}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Item>
            </Grid>

        )
    })

    return (
        <div className={Styles.examContainer}>
            <div className={Styles.titleContainer}>
                <div className={Styles.firstRow}>
                    <div className={Styles.title}>
                        <p>All Exam</p>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div className={Styles.searchBar}>
                            <SearchBar placeholder={'Search for Exam'} search={searchExam} />
                        </div>
                        <div className={Styles.searchBar} style={{ marginLeft: "1rem" }}>
                            <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Sync\xa0Exams"} clicked={syncExams} />
                        </div>
                        {JSON.parse(localStorage.getItem('user')).email === 'admin@gmail.com' &&
                            <div className={Styles.searchBar} style={{ marginLeft: "1rem" }}>
                                <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Clear\xa0Cache"} clicked={() => { setOpenCacheModal(true) }} />
                            </div>
                        }
                        <div style={{ marginLeft: "2rem" }} className={Styles.searchBar}>
                            <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Test Attendance Process"} clicked={() => setOpenTestModal(true)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={Styles.examWrapper}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={{ xs: 2, md: 3, lg: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 16 }}>
                        {examCard}
                    </Grid>
                </Box>
            </div>
            {/* {exams.length >= 9 &&
                <div style={{ display: "flex", "justifyContent": "flex-end",marginTop:"2rem" }}>
                    <div>
                        <Button style={{ color: "#000", backgroundColor: "#fff" }} btnName={"Load More"} clicked={getExamsFromFirebase} />
                    </div>
                </div>
            } */}
            {openCacheModal &&
                <CacheModal open={openCacheModal} handleClose={() => { setOpenCacheModal(false) }} />
            }
            {openTestModal &&
                <TestModal open={openTestModal} handleClose={() => { setOpenTestModal(false) }} />
            }
        </div>
    )
}
