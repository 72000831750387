import React from "react";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import cssClasses from "./ConfirmationAlert.module.css";
import Close from "../Components/Icons/Close";
import Button from "./Button";
const useStyles = makeStyles((theme) => ({
  wrapperIcons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-around",
    marginBottom: "2rem",
    position:'relative'
  },
  closeIcon: {
    cursor: "pointer",
    position:'absolute',
    right: "0rem"
  },
  title: {
    fontFamily: "Inter-Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "2.4rem",
    lineHeight: "3.2rem",
    color: "#202124",
    marginBottom: "0.8rem",
  },
  subTitle: {
    fontFamily: "Inter-Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    color: "#667085",
    marginBottom: "3.2rem",
  },
  wrapperButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    columnGap: "1.2rem",
  },
}));
//buttonTitle=cancel ,returnResButton=save ,confirm has value 'rejected'and 'confirmed
export default function ConfirmationAlert({
  open,
  close,
  icon,
  title,
  subTitle,
  confirm,
  buttonTitle='',
  returnRespButton,
}) {
  const classes = useStyles();
  function Confirmed() {
    confirm("confirmed");
  }
  function handleClose() {
    close();
  }
  function Rejected(){
    confirm("rejected");
    close();
  }
  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={[
        {
          ".MuiBackdrop-root": {
            background: "rgba(52, 64, 84, 0.7)",
          },
        },
      ]}
      disableAutoFocus={true}
    >
      <Box className={cssClasses.modalWrapper}>
        <div className={classes.wrapperIcons}>
          {icon}
          <span className={classes.closeIcon} onClick={handleClose}>
            <Close width={"1.2rem"} height={"1.2rem"} />
          </span>
        </div>
        <div className={classes.title}>{title}</div>
        <div className={classes.subTitle}>{subTitle}</div>
        <div className={classes.wrapperButtons}>
          {buttonTitle!=='' && (
            <Button
              style={{
                color: "#344054",
                backgroundColor: "#ffffff",
                fontSize: "1.6rem",
                lineHeight: "2.4rem",
              }}
              btnName={buttonTitle}
              clicked={Rejected}
            />
          )}
          {returnRespButton && (
            <Button
              style={{
                color: "#ffffff",
                backgroundColor: "rgb(127, 86, 217)",
                fontSize: "1.6rem",
                lineHeight: "2.4rem",
              }}
              btnName={returnRespButton}
              clicked={Confirmed}
            />
          )}
        </div>
      </Box>
    </Modal>
  );
}
