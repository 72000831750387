import { addDoc, collection, doc, getDoc, getDocs, getFirestore, deleteDoc, query, where, serverTimestamp, updateDoc, startAfter, limit, orderBy } from "firebase/firestore";
import { errorToast } from "./Toast";

function getAllExams() {
    return new Promise((resolve, reject) => {
        const examsref = collection(getFirestore(), 'exams');
        getDocs(query(examsref, orderBy("name"), limit(9))).then((resp) => {
            resolve(resp)
        }).catch((err) => {
            console.log(err);
            errorToast("Something went wrong please try again later")
        })
    })
}

function addExam(examForm) {
    return new Promise((resolve, reject) => {
        const examsref = collection(getFirestore(), 'exams');
        examForm.createdAt = serverTimestamp()
        examForm.lastUpdatedAt = serverTimestamp()
        addDoc(examsref, examForm).then((resp) => {
            resolve(resp)
        })
    })
}

function updateExamDetails(examForm, docID) {
    console.log(examForm, docID);
    return new Promise((resolve, reject) => {
        const examsRef = doc(getFirestore(), 'exams', docID);
        examForm.lastUpdatedAt = serverTimestamp();
        updateDoc(examsRef, examForm).then((resp) => {
            resolve(resp)
        }).catch((err) => {
            reject(err);
            console.log(err);
            errorToast(err)
        })
    })
}

function addExamSchedule(id, schedule) {
    return new Promise((resolve, reject) => {
        const examsref = collection(getFirestore(), 'exams/' + id + '/subExam');
        schedule.createdAt = serverTimestamp();
        schedule.lastUpdatedAt = serverTimestamp();
        addDoc(examsref, schedule).then((resp) => {
            resolve(resp)
        }).catch(err => {
            console.log(err);
            reject(err);
        })
    })
}
function updateExamSchedule(examId, docID, schedule) {
    return new Promise((resolve, reject) => {
        const examsRef = doc(getFirestore(), `exams/${examId}/subExam/${docID}`);
        schedule.createdAt = serverTimestamp();
        schedule.lastUpdatedAt = serverTimestamp();
        updateDoc(examsRef, schedule).then((resp) => {
            resolve(resp)
        }).catch(err => {
            console.log(err);
            reject(err);
        })
    })
}

function deleteSubExamSchedule(examDocID, docID) {
    return new Promise((resolve, reject) => {
        const examsRef = doc(getFirestore(), `exams/${examDocID}/subExam/${docID}`);
        deleteDoc(examsRef).then((resp) => {
            resolve(resp)
        }).catch(err => {
            console.log(err);
            reject(err);
            errorToast("Something went wrong please try again later")
        })
    })
}

function getExamByExamShortName(examShortName) {
    return new Promise((resolve, reject) => {
        const examsref = collection(getFirestore(), 'exams');
        getDocs(query(examsref, where("examShortName", '==', examShortName))).then((resp) => {
            resolve(resp)
        }).catch(err => {
            reject(err);
        })
    })
}

function getAllExamCenters(examId) {
    return new Promise((resolve, reject) => {
        const examsref = collection(getFirestore(), 'exams/' + examId + '/ExamCenters');
        getDocs(examsref).then((resp) => {
            resolve(resp)
        }).catch(err => {
            reject(err);
        })
    })
}

function getAllSubExams(examId) {
    return new Promise((resolve, reject) => {
        const examsref = collection(getFirestore(), 'exams/' + examId + '/subExam');
        getDocs(examsref).then((resp) => {
            resolve(resp)
        }).catch(err => {
            reject(err);
        })
    })
}

function getExamScheduleById(id) {
    return new Promise((resolve, reject) => {
        const examsref = collection(getFirestore(), 'exams/' + id + '/subExam');
        getDocs(examsref).then((resp) => {
            resolve(resp)
        }).catch((err) => {
            errorToast("Something went wrong please try again later")
        })
    })
}

function getExamByName(examName) {
    return new Promise((resolve, reject) => {
        const examsref = collection(getFirestore(), 'exams');
        getDocs(query(examsref, where("examShortName", '==', examName))).then((resp) => {
            resolve(resp)
        })
    })
}

function deleteExam(examName) {
    return new Promise((resolve, reject) => {
        const examsref = collection(getFirestore(), 'exams');
        deleteDoc(query(examsref, where("examShortName", '==', examName))).then((resp) => {
            resolve(resp)
        })
    })
}

function getExams(studentSearchValue, lastDoc) {
    return new Promise((resolve, reject) => {
        const examsref = collection(getFirestore(), 'exams');
        getDocs(query(examsref, orderBy("examNameForSearch"), startAfter(lastDoc), where("examNameForSearch", ">=", studentSearchValue), where("examNameForSearch", "<=", studentSearchValue + "\uf8ff"), limit(9))).then((resp) => {
            resolve(resp)
        }).catch((err) => {
            console.log('err: ', err);
        })
    })
}

async function getExamCenterFromId(examId, examCenterName) {
    return new Promise((resolve, reject) => {
        const examsref = collection(getFirestore(), 'exams/' + examId + '/ExamCenters');
        getDocs(query(examsref, where("examCenterName", '==', examCenterName))).then((resp) => {
            resolve(resp)
        }).catch((err) => {
            console.log('err: ', err);
        })
    })
}
function setSelectedSubExam(subexam) {
    localStorage.setItem('selectedSubExam', subexam);
}
function getSelectedSubExam() {
    return localStorage.getItem('selectedSubExam');
}

async function getSubexamByName(examId, name) {
    const examsref = collection(getFirestore(), 'exams/' + examId + '/subExam')
    return await getDocs(query(examsref, where('examName', '==', name)))
}

async function getExamByDocID(examDocId) {
    return new Promise((resolve, reject) => {
        const examRef = doc(getFirestore(), "exams", examDocId);
        getDoc(examRef).then(res => {
            resolve({ success: res.exists(), data: { ...res.data(), id: res.id } });
        }).catch(err => {
            console.log(err);
            resolve({ success: false, data: {}, id: examDocId });
        })
    });
}

async function getSubExamByDocID(examDocId, subExamDocId) {
    return new Promise((resolve, reject) => {
        const subExamRef = doc(getFirestore(), `exams/${examDocId}/subExam/`, subExamDocId);
        getDoc(subExamRef).then(res => {
            resolve({ success: res.exists(), data: { ...res.data(), id: res.id, examDocId: examDocId } });
        }).catch(err => {
            console.log(err);
            resolve({ success: false, data: {}, id: examDocId });
        })
    });
}

async function getExamCenterByDocID(examDocId, examCenterDocId) {
    return new Promise((resolve, reject) => {
        const subExamRef = doc(getFirestore(), `exams/${examDocId}/ExamCenters/`, examCenterDocId);
        getDoc(subExamRef).then(res => {
            resolve({ success: res.exists(), data: { ...res.data(), id: res.id } });
        }).catch(err => {
            console.log(err);
            resolve({ success: false, data: {}, id: examDocId });
        })
    });
}

async function updateExamCenter(examDocId, examCenterDocId, data) {
    return new Promise((resolve, reject) => {
        const subExamRef = doc(getFirestore(), `exams/${examDocId}/ExamCenters/`, examCenterDocId);
        updateDoc(subExamRef, data).then(res => {
            resolve(res);
        }).catch(err => {
            console.log(err);
            resolve(err);
        })
    });
}

export { getAllExams, addExam, deleteExam, getSubexamByName, getExamCenterByDocID, updateExamCenter, getExamCenterFromId, updateExamDetails, getAllSubExams, getExamByName, getAllExamCenters, deleteSubExamSchedule, getExamByExamShortName, addExamSchedule, updateExamSchedule, getExamScheduleById, getExams, getSelectedSubExam, setSelectedSubExam, getExamByDocID, getSubExamByDocID }
