import * as actionTypes from "../actionType/action-type";

const initialState = null

const FACE_NET = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.FACE_NET:
            return setObject(state, payload);
        default:
            return state;
    }
};

const setObject = (state, payload) => {
    let newState = {
        ...state, ...payload
    };
    state = Object.assign({}, state, newState);
    return newState;
};

export default FACE_NET;
