import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import { DB } from "../db"
import actions from '../Redux Store/actions'
import { useDispatch } from "react-redux";
import { errorToast } from '../services/Toast';
import CloseIcon from '@mui/icons-material/Close';
import { dismissLoader, presentLoader } from '../services/loaderService';

export default function WarningAlert({ currentSubExam, SubExamName, handleClose, openAlert, goToExamCenter }) {

    const dispatch = useDispatch()

    function clearIndexedDB() {
        // DB.students.clear().then(() => {
        //     if (window.navigator.onLine) {
        //         DB.facenetArr.clear()
        //         DB.base64.clear()
        //         dispatch(actions.FaceNet(null));
        //         goToExamCenter(currentSubExam)
        //     } else {
        //         errorToast("Offline data not downloaded for this subExam! Please connect to internet and try again")
        //     }
        // })
        localStorage.setItem('modelUrl', 'online')
        goToExamCenter(currentSubExam)
    }

    async function replicateStudents() {
        localStorage.setItem('modelUrl', 'local')
        let _exam = JSON.parse(localStorage.getItem('exam'))
        let _subExam = JSON.parse(localStorage.getItem('subExam'))
        let _Students = await DB.students.where({ examID: _exam.id, subExamID: _subExam.id }).toArray();
        _Students.forEach(obj => {

            let currentSubExamHistory = {
                subExamName: obj.subExamName,
                fingerprintImage1: obj?.fingerprint1Base64 ? `exam-management/${obj.id.split('_')[1]}/fingerprint/${obj.examID}_${obj.examName}_${obj.subExamName}_${obj.id.split('_')[1]}_fingerprint1.png` : "",
                fingerprintImage2: obj?.fingerprint2Base64 ? `exam-management/${obj.id.split('_')[1]}/fingerprint/${obj.examID}_${obj.examName}_${obj.subExamName}_${obj.id.split('_')[1]}_fingerprint2.png` : "",
                realTimePhoto: obj?.realTimeBase64 ? `exam-management/${obj.id.split('_')[1]}/photos/${obj.examID}_${obj.examName}_${obj.subExamName}_${obj.id.split('_')[1]}_realTimePhoto.png` : "",
                idCardImage: obj?.idCardBase64 ? `exam-management/${obj.id.split('_')[1]}/idcard/${obj.examID}_${obj.examName}_${obj.subExamName}_${obj.id.split('_')[1]}_idCardImage.png` : "",
                attendanceDateTime: obj?.attendanceDateTime ? obj.attendanceDateTime : ""
            }
            if (obj.hasOwnProperty("previousSubExamHistory") && Array.isArray(obj.previousSubExamHistory)) {
                obj.previousSubExamHistory.push(currentSubExamHistory);
            } else {
                obj.previousSubExamHistory = [currentSubExamHistory];
            }

            let base64Obj = {
                id: obj.id.split('_')[1],
                [obj.subExamName]: {
                    realTimePhoto: obj.realTimeBase64,
                    idCardImage: obj.idCardBase64,
                    fingerprintImage1: obj.fingerprint1Base64,
                    fingerprintImage2: obj.fingerprint2Base64
                }
            }

            updateBase64Object(base64Obj)

            obj.id = `${currentSubExam.id}_${obj.id.split('_')[1]}`
            obj.subExamID = currentSubExam.id
            obj.subExamName = currentSubExam.examName
            delete obj.ET;
            delete obj.attendanceDateTime;
            delete obj.ET_IsQualified;
            delete obj.ET_height_height;
            delete obj.ET_height_remarks;
            delete obj.ET_height_result;
            delete obj.PST_IsQualified;
            delete obj.PST;
            delete obj.chest_no;
            delete obj.dataURL;
            delete obj.examType;
            delete obj.idCardBase64;
            delete obj.idCardImage;
            delete obj.invoice;
            delete obj.lastUpdateAt;
            delete obj.name;
            delete obj.realTimeBase64;
            delete obj.realTimePhoto;
            delete obj.selectedID_Card;
            delete obj.showInGrid;
            delete obj.uploadedForm;
            delete obj.checkingOfficer;
            delete obj.checkingOfficerDesignation;
            delete obj.isSubmitted;
            obj.Status = "pending"
            obj.isVerified = false
        });
        presentLoader()
        DB.students.bulkAdd(_Students).then(resp => {
            goToExamCenter(currentSubExam)
            dismissLoader()
        })
    }

    const updateBase64Object = (studentObject) => new Promise((resolve, reject) => {
        studentObject.id = `${JSON.parse(localStorage.getItem('exam')).name}_${studentObject.id}`
        studentObject.examName = JSON.parse(localStorage.getItem('exam')).name
        DB.base64.update(studentObject.id, studentObject).then(res => {
            if (res) {
                resolve(res);
            } else {
                DB.base64.add(studentObject).then(res2 => {
                    resolve(res2);
                }).catch(error => {
                    reject(error);
                })
            }
        }).catch(err => {
            if (err.toString().includes("Cannot convert undefined or null to object")) {
                DB.base64.add(studentObject).then(res => {
                    resolve(res);
                }).catch(error => {
                    reject(error);
                })
            } else {
                reject(err);
            }
        })
    })

    return (
        <div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openAlert}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <WarningIcon style={{ color: "rgba(246,178,27)", marginRight: "1rem", width: "2rem", height: "2rem" }} />
                        <p style={{ margin: "0", fontSize: "2rem" }}>Warning</p>
                    </div>
                    <CloseIcon style={{ color: "red", marginRight: "1rem", width: "2rem", height: "2rem" }} onClick={handleClose} />
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <p style={{ fontSize: "1.4rem" }}>
                            {/* Locally saved data of {SubExamName} exam not uploaded! If you continue all local saved data will be deleted */}
                            How to get students for this exam ?
                        </p>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button style={{ fontSize: "1.4rem" }} autoFocus onClick={clearIndexedDB}>
                        Get from Online Database
                    </Button>
                    <Button style={{ fontSize: "1.4rem" }} autoFocus onClick={replicateStudents}>
                        Use Students from previous exam
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
