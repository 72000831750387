import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//* images
import uploadIconBlack from "../../assets/images/uploadIconBlack.svg";
import addIcon from "../../assets/images/add icon.svg";

//* import component
import Applicants from "../../Components/Applicants/Applicants";

//* import sub-component
import Button from "../../Subcomponent/Button";

//* import store
import { useDispatch } from 'react-redux';
import actions from '../../Redux Store/actions';

//* Import css
import cssClasses from "./StudentsScreen.module.css";

export default function StudentsScreen() {
  let params = useParams();
  let examCenterName = params.examCenterName;
  let examShortName = params.examShortName;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.activeMenu('student-verify'));
  }, [dispatch])
  

  const fileSelectForUploadStudentData = (e) => {
    var _file = e.target.files[0];
    console.log({ fileSelectForUploadExamCenter: _file });
  }

  return (
    <>
      <div className={cssClasses.pageWrapper}>
        <div className={cssClasses.headerWrapper}>
          <div className={cssClasses.headingWrapper}>
            <p>Exams of Students</p>
            <p></p>
          </div>
          <div className={cssClasses.headerButtonWrapper} style={{ display: "none" }}>
            <div>
              <Button style={{ color: "#344054", backgroundColor: "#ffffff" }} btnIcon={uploadIconBlack} btnName={"Upload\xa0Student\xa0Data"} clicked={() => { document.getElementById("fileSelectForUploadStudentData").click(); }} />
              <input hidden type="file" name="" id="fileSelectForUploadStudentData" onChange={fileSelectForUploadStudentData} />
            </div>
            <div>
              <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnIcon={addIcon} btnName={"Add\xa0Student"} clicked={() => { }} />
            </div>
          </div>
        </div>

        <div className={cssClasses.breaker}></div>

        <Applicants examCenterName={examCenterName} examShortName={examShortName} />

      </div>
    </>
  )
}
