/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"

//* import MUI
import { Box, Typography, Divider } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';

//* import Images
import arrowLeftImage from "../../assets/images/arrow-left.svg";
import man from "../../assets/images/man.png";
import woman from "../../assets/images/woman.png";

//* import css
import cssClasses from "./AttendancePage.module.css";

//* import sub-component
import SearchBar from "../../Subcomponent/SearchBar";
import ProgressImagesUpload from "../../Modals/ProgressImagesUpload/ProgressImagesUpload";

import AttendanceProcess from "../../Components/AttendanceProcess/AttendanceProcess"
import VerifyStudent from "../../Components/VerifyStudent/VerifyStudent"

//* import Service 
import { errorToast } from "../../services/Toast";
import { DB } from "../../db"
import * as _ from 'lodash';

//* import NPM
import axios from "axios"
import { useDispatch, useSelector } from "react-redux";
import actions from '../../Redux Store/actions'
import { Facenet } from '../../Facenet/Facenet'; //get Facenet.js
import { dismissLoader, presentLoader } from "../../services/loaderService";
import { SetStudentToLocal } from "../../services/StudentService";

const { ipcRenderer } = window.location.hostname === '' ? window.require('electron') : { ipcRenderer: null }

const dataGridStyles = {
  fontWeight: "400",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  color: "#667085",
  fontFamily: 'Inter-Medium',

  '& div[data-colindex="1"]': {
    color: "#101828",
    fontWeight: "500",
  },
  '& 	.MuiDataGrid-columnHeaders': {
    background: "#F9FAFB",
  },
  '& .MuiDataGrid-columnSeparator': {
    display: "none"
  },
  "& .MuiDataGrid-checkboxInput": {
    "& svg": {
      width: "2rem",
      height: "2rem",
      background: "#FFFFFF",
    }
  },
  "& .MuiTablePagination-displayedRows": {
    display: "none"
  },
  "& [title='Go to previous page']:after": {
    content: "'Previous'",
    marginLeft: "1rem",
    paddingRight: '1rem'
  },
  "& [title='Go to next page']:before": {
    content: "'Next'",
    marginRight: "1rem",
    paddingRight: '1rem'
  },
  "& .MuiDataGrid-iconButtonContainer": {
    visibility: "hidden !important"
  },
  "&  .MuiDataGrid-row ": {
    maxHeight: "7.2rem !important",
    minHeight: "7.2rem !important",
  },
  "& .MuiDataGrid-cell": {
    padding: "1.6rem",
    minHeight: "7.2rem !important",
    maxHeight: "7.2rem !important",
    outline: 'none !important',
  },
  "& .MuiButtonBase-root": {
    background: '#FFFFFF',
    border: '1px solid #D0D5DD',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    margin: '0 1rem'
  },
  "& .MuiTablePagination-actions": {
    margin: '0 2rem',
  }
}
function AttendancePage() {
  const naviagte = useNavigate();
  let params = useParams();
  let examShortName = params.examShortName;
  let subExamName = params.subExamName;
  let examCenterName = params.examCenterName;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [verifiedStudentsCount, setVerifiedStudentsCount] = useState(0)
  const [totalStudentsCount, setTotalStudentsCount] = useState(0)
  const [submittedStudentsCount, setSubmittedStudentsCount] = useState(0)
  const [examDetails, setExamDetails] = useState(null);
  const [subExamDetails, setSubExamDetails] = useState(null);
  const [examCenterDetails, setExamCenterDetails] = useState(null);
  const [students, setStudents] = useState([])
  const [applicantsList, setApplicantsList] = useState([]);
  const [dataGridLoading, setDataGridLoading] = useState(false);
  const [isProforma, setIsProforma] = useState(false);

  const [selectedStudent, setSelectedStudent] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const [modelProgress, setModelProgress] = useState(0);
  const [openModelProgress, setOpenModelProgress] = useState(false);

  const [currentTab, setCurrentTab] = useState('face')

  let facenetObj = useSelector((state) => {
    return state.faceNet
  });

  useEffect(async () => {
    presentLoader()
    let _exam = JSON.parse(localStorage.getItem("exam"));
    if (_exam) {
      setExamDetails(_exam);
    } else {
      errorToast("Please Select Exam.");
      navigate("/home/student-verify")
    }
    let _subExam = JSON.parse(localStorage.getItem("subExam"));
    if (_subExam) {
      setSubExamDetails(_subExam);
    } else {
      errorToast("Please Select Exam.");
      navigate("/home/student-verify")
    }
    let _examCenter = JSON.parse(localStorage.getItem("examCenter"));
    if (_examCenter) {
      setExamCenterDetails(_examCenter);
    } else {
      errorToast("Please Select Exam.");
      navigate("/home/student-verify")
    }
    // let _students = await DB.students.where({ examID: _exam.id, subExamID: _subExam.id, examCenterCity: _examCenter.City }).toArray()
    let _students = await DB.students.where({ examID: _exam.id, subExamID: _subExam.id, examCenterName: _examCenter.examCenterName }).toArray()
    console.log("check", _students)
    setStudents(convertFirebaseObjToDataGridObj(_students, _exam?.examShortName))
    // let s = _students.filter(function (friend) { return friend.isVerified === true && friend.examID === _exam.id && friend.subExamID === _subExam.id && friend.examCenterCity === _examCenter.City })
    let s = _students.filter(function (friend) { return friend.isVerified === true && friend.examID === _exam.id && friend.subExamID === _subExam.id && friend.examCenterName === _examCenter.examCenterName })
    s = s.map((m) => ({
      Applicant: m.Applicant,
      isVerified: m.isVerified,
      id: m.id,
      email: m.email,
      UID: m.UID,
      Status: m.Status,
      Photo64: m.Photo64,
      Gender: m.Gender,
      "Full Name": m["Full Name"],
      City: m.City,
      AppliedAs: m.AppliedAs,
      "rollNo": m["rollNo"],
      examCenterName: m.examCenterName,
      examName: m.examName,
      attendanceDateTime: m.attendanceDateTime
    }))
    let a = _.orderBy(s, ['attendanceDateTime'], ['desc']);
    getCount(_students, _exam, _subExam, _examCenter)
    setApplicantsList(convertFirebaseObjToDataGridObj(a, _exam?.examShortName))
    dismissLoader()
    return () => {
      applicantsList.forEach((ele, index) => {
        applicantsList[index] = null
        if (index === applicantsList.length - 1) {
          setApplicantsList(null)
        }
      })
      students.forEach((ele, index) => {
        students[index] = null
        if (index === students.length - 1) {
          setStudents([])
        }
      })
      setVerifiedStudentsCount(0)
      setTotalStudentsCount(0)
      setSubmittedStudentsCount(0)
      setExamCenterDetails(null)
      setSubExamDetails(null)
      setExamCenterDetails(null)
      setSelectedStudent(null)
    }
  }, []);

  function searchStudents(searchText) {
    setSelectedStudent(null);
    if (searchText.length > 0) {
      // console.log("searchText -> ",searchText);
      // DB.students.where('Applicant').endsWithAnyOfIgnoreCase([searchText]).toArray().then(res=>{
      // let res = students.filter(function (friend) { return friend.Applicant.includes(searchText) && friend.examID === examDetails.id && friend.subExamID === subExamDetails.id && friend.examCenterCity === examCenterDetails.City })
      let res = students.filter(function (friend) { return friend.Applicant.includes(searchText) && friend.examID === examDetails.id && friend.subExamID === subExamDetails.id && friend.examCenterName === examCenterDetails.examCenterName })
      console.log(res)
      if (res.length === 1) {
        setSelectedStudent({ ...res[0] });
        // let resp = students.filter(function (friend) { return friend.isVerified === true && friend.examID === examDetails.id && friend.subExamID === subExamDetails.id && friend.examCenterCity === examCenterDetails.City })
        let resp = students.filter(function (friend) { return friend.isVerified === true && friend.examID === examDetails.id && friend.subExamID === subExamDetails.id && friend.examCenterName === examCenterDetails.examCenterName })
        resp = resp.map((m) => ({
          Applicant: m.Applicant,
          isVerified: m.isVerified,
          id: m.id,
          email: m.email,
          UID: m.UID,
          Status: m.Status,
          Photo64: m.Photo64,
          Gender: m.Gender,
          "Full Name": m["Full Name"],
          City: m.City,
          AppliedAs: m.AppliedAs,
          "rollNo": m["rollNo"],
          examCenterName: m.examCenterName,
          examName: m.examName,
          attendanceDateTime: m.attendanceDateTime
        }))
        let a = _.orderBy(resp, ['attendanceDateTime'], ['desc']);
        setApplicantsList(convertFirebaseObjToDataGridObj(a, examDetails?.examShortName))
      } else {
        res = res.map((m) => ({
          Applicant: m.Applicant,
          isVerified: m.isVerified,
          id: m.id,
          email: m.email,
          UID: m.UID,
          Status: m.Status,
          Photo64: m.Photo64,
          Gender: m.Gender,
          "Full Name": m["Full Name"],
          City: m.City,
          AppliedAs: m.AppliedAs,
          "rollNo": m["rollNo"],
          examCenterName: m.examCenterName,
          examName: m.examName,
          attendanceDateTime: m.attendanceDateTime
        }))
        setApplicantsList(convertFirebaseObjToDataGridObj(res, examDetails?.examShortName));
        setSelectedStudent(null);
      }
    } else if (searchText === "") {
      console.log(examDetails.id, subExamDetails.id);
      // let s = students.filter(function (friend) { return friend.isVerified === true && friend.examID === examDetails.id && friend.subExamID === subExamDetails.id && friend.examCenterCity === examCenterDetails.City })
      let s = students.filter(function (friend) { return friend.isVerified === true && friend.examID === examDetails.id && friend.subExamID === subExamDetails.id && friend.examCenterName === examCenterDetails.examCenterName })
      s = s.map((m) => ({
        Applicant: m.Applicant,
        isVerified: m.isVerified,
        id: m.id,
        email: m.email,
        UID: m.UID,
        Status: m.Status,
        Photo64: m.Photo64,
        Gender: m.Gender,
        "Full Name": m["Full Name"],
        City: m.City,
        AppliedAs: m.AppliedAs,
        "rollNo": m["rollNo"],
        examCenterName: m.examCenterName,
        examName: m.examName,
        attendanceDateTime: m.attendanceDateTime
      }))
      let a = _.orderBy(s, ['attendanceDateTime'], ['desc']);
      setApplicantsList(convertFirebaseObjToDataGridObj(a, examDetails?.examShortName))
    }
  }

  useEffect(() => {
    let path = ''
    if (examCenterDetails && ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id)) || (facenetObj && facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id) && !facenetObj[JSON.parse(localStorage.getItem('examCenter')).id]?.model))) {
      if (window.location.hostname === '') {
        ipcRenderer.send("getPath", "");
        ipcRenderer.on("path", (event, arg) => {
          console.log("path", arg)
          path = arg
          ipcRenderer.removeListener("path", () => { })
        })
      }
      let apiPath = ''
      let modelUrl = ''
      let embeddingsPath = ''
      DB.modelUrl.get(examCenterDetails.id).then((resp) => {
        if (resp && resp?.modelUrl == 'local') {
          axios.get('http://127.0.0.1:5000/checkIfRunning').then((resp) => {
            apiPath = `http://127.0.0.1:5000/getClassNames?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`
            embeddingsPath = `http://127.0.0.1:5000/getClassEmbeddings?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`
            modelUrl = `${path}models/${examDetails.id}/${examCenterDetails.id}/model.json`
            // setOpenModelProgress(true)
            axios.get(apiPath).then(async (response) => {
              axios.get(embeddingsPath).then(async (resp) => {
                console.log('get request:', response)
                let classNames = []
                let classEmbeddings = []
                if (apiPath == examCenterDetails.classNamesJson) {
                  classNames = response.data
                  classEmbeddings = resp.data
                } else {
                  classNames = response.data.classNames
                  classEmbeddings = resp.data.classEmbeddings
                }
                console.log(classNames)
                const facenet = new Facenet(classNames, classEmbeddings);
                DB.facenetArr.add({ id: examCenterDetails.id, classNamesJson: classNames, classEmbeddings: classEmbeddings })
                // DB.facenetArr.add({ id: examCenterDetails.id, data: classEmbeddings })
                const progressInterval = setInterval(() => {
                  const progress = facenet.getProgress();
                  console.log('progress: ', Math.floor(progress))
                  setModelProgress(Math.floor(progress));
                  if (progress === 100) {
                    clearInterval(progressInterval);
                    setOpenModelProgress(false);
                  }
                }, 1000);
                setOpenModelProgress(true);
                // if (examCenterDetails.modelUrl) {
                facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                  // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                  // setErrMsg('')
                  console.log('model loaded');
                  // setFaceNetObj(facenet)
                  // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                  dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                })
                // .catch((err) => {
                //   setErrMsg('Model Not generated')
                //   if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                //     const img = document.createElement("img");
                //     img.src = ''
                //     img.id = 'image'
                //     facenetObj = new Facenet('', '')
                //     facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                //     dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                //   }
                // })
              })
              // .catch((err) => {
              //   setErrMsg('Model Not generated')
              //   if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
              //     const img = document.createElement("img");
              //     img.src = ''
              //     img.id = 'image'
              //     facenetObj = new Facenet('', '')
              //     facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
              //     dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
              //   }
              // })
              // } else {
              //   errorToast('Model Not generated,Please generate model before face recognition')
              // }
            }).catch((err) => {
              apiPath = examCenterDetails.classNamesJson
              embeddingsPath = examCenterDetails.classEmbeddings
              modelUrl = examCenterDetails.modelUrl
              if (window.navigator.onLine) {
                axios.get(apiPath).then(async (response) => {
                  axios.get(embeddingsPath).then(async (resp) => {
                    console.log('get request:', response)
                    let classNames = []
                    let classEmbeddings = []
                    if (apiPath == examCenterDetails.classNamesJson) {
                      classNames = response.data
                      classEmbeddings = resp.data
                    } else {
                      classNames = response.data.classNames
                      classEmbeddings = resp.data.classEmbeddings
                    }
                    console.log(classNames)
                    const facenet = new Facenet(classNames, classEmbeddings);
                    DB.facenetArr.add({ id: examCenterDetails.id, classNamesJson: classNames, classEmbeddings: classEmbeddings })
                    // DB.facenetArr.add({ id: examCenterDetails.id, data: classEmbeddings })
                    const progressInterval = setInterval(() => {
                      const progress = facenet.getProgress();
                      console.log('progress: ', Math.floor(progress))
                      setModelProgress(Math.floor(progress));
                      if (progress === 100) {
                        clearInterval(progressInterval);
                        // setOpenModelProgress(false);
                      }
                    }, 1000);
                    setOpenModelProgress(true);
                    if (window.location.hostname === '') {
                      axios.get('http://127.0.0.1:5000/checkIfRunning').then((resp) => {
                        axios.post(`http://127.0.0.1:5000/downloadModelToLocalFileSystem?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`, { downloadFiles: examCenterDetails.downloadFiles }).then((re) => {
                          if (re.data === "Done") {
                            DB.modelUrl.add({ id: examCenterDetails.id, modelUrl: "local" })
                            facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                              // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                              // setErrMsg('')
                              setOpenModelProgress(false);
                              console.log('model loaded');
                              // setFaceNetObj(facenet)
                              // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                              dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                            }).catch((err) => {
                              // setErrMsg('Model Not generated')
                              if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                                setOpenModelProgress(false);
                                const img = document.createElement("img");
                                img.src = ''
                                img.id = 'image'
                                facenetObj = new Facenet('', '')
                                facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                                dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                              }
                            })
                          }
                        })
                      }).catch((err) => {
                        ipcRenderer.send("startPranilExe", "");
                        ipcRenderer.on("exeSuccessful", (event, arg) => {
                          console.log("exeSuccessful", arg)
                          if (arg == 'done') {
                            axios.post(`http://127.0.0.1:5000/downloadModelToLocalFileSystem?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`, { downloadFiles: examCenterDetails.downloadFiles }).then((re) => {
                              if (re.data === "Done") {
                                DB.modelUrl.add({ id: examCenterDetails.id, modelUrl: "local" })
                                facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                                  // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                                  // setErrMsg('')
                                  setOpenModelProgress(false);
                                  console.log('model loaded');
                                  // setFaceNetObj(facenet)
                                  // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                                  dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                                }).catch((err) => {
                                  // setErrMsg('Model Not generated')
                                  setOpenModelProgress(false);
                                  if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                                    const img = document.createElement("img");
                                    img.src = ''
                                    img.id = 'image'
                                    facenetObj = new Facenet('', '')
                                    facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                                    dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                                  }
                                })
                              }
                            })
                          }
                        })
                      })
                    } else {
                      facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                        // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                        // setErrMsg('')
                        setOpenModelProgress(false);
                        console.log('model loaded');
                        // setFaceNetObj(facenet)
                        // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                        dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                      }).catch((err) => {
                        setOpenModelProgress(false);
                        // errorToast("Models folder might have been deleted,please recreate model")
                        if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                          const img = document.createElement("img");
                          img.src = ''
                          img.id = 'image'
                          facenetObj = new Facenet('', '')
                          facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                          dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                        }
                      })
                    }
                  }).catch((err) => {
                    setOpenModelProgress(false);
                    // errorToast("Models folder might have been deleted,please recreate model")
                    if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                      const img = document.createElement("img");
                      img.src = ''
                      img.id = 'image'
                      facenetObj = new Facenet('', '')
                      facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                      dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                    }
                  })
                  // } else {
                  //   errorToast('Model Not generated,Please generate model before face recognition')
                  // }
                }).catch((err) => {
                  setOpenModelProgress(false);
                  // errorToast("Models folder might have been deleted,please recreate model")
                  if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                    const img = document.createElement("img");
                    img.src = ''
                    img.id = 'image'
                    facenetObj = new Facenet('', '')
                    facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                    dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                  }
                })
              } else {
                setOpenModelProgress(false);
                if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                  const img = document.createElement("img");
                  img.src = ''
                  img.id = 'image'
                  const facenet = new Facenet('', '')
                  facenet.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                  dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                  // errorToast("Models folder might have been deleted,please recreate model or if model was uploaded then connect to internet and try again")
                }
                // DB.facenetArr.get(examCenterDetails.id).then((resp) => {
                //   // DB.facenetArr.get("classEmbeddings").then((res) => {
                //   console.log(resp)
                //   if (!resp) {
                //     setErrMsg('Model Not generated')
                //     const img = document.createElement("img");
                //     img.src = ''
                //     img.id = 'image'
                //     const facenet = new Facenet('', '')
                //     facenet.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                //     dispatch(actions.FaceNet(facenet));
                //   } else {
                //     presentLoader()
                //     const facenet = new Facenet(resp.classNamesJson, resp.classEmbeddings);
                //     facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                //       // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                //       console.log('model loaded');
                //       // setFaceNetObj(facenet)
                //       // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                //       dispatch(actions.FaceNet(facenet));
                //       dismissLoader()
                //     });
                //   }
                //   // })
                // })
              }
            })
          }).catch((err) => {
            setOpenModelProgress(true)
            ipcRenderer.send("startPranilExe", "");
            ipcRenderer.on("exeSuccessful", (event, arg) => {
              console.log("exeSuccessful", arg)
              if (arg == 'done') {
                apiPath = `http://127.0.0.1:5000/getClassNames?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`
                embeddingsPath = `http://127.0.0.1:5000/getClassEmbeddings?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`
                modelUrl = `../../../models/${examDetails.id}/${examCenterDetails.id}/model.json`
                axios.get(apiPath).then(async (response) => {
                  axios.get(embeddingsPath).then(async (resp) => {
                    console.log('get request:', response)
                    let classNames = []
                    let classEmbeddings = []
                    if (apiPath == examCenterDetails.classNamesJson) {
                      classNames = response.data
                      classEmbeddings = resp.data
                    } else {
                      classNames = response.data.classNames
                      classEmbeddings = resp.data.classEmbeddings
                    }
                    console.log(classNames)
                    const facenet = new Facenet(classNames, classEmbeddings);
                    DB.facenetArr.add({ id: examCenterDetails.id, classNamesJson: classNames, classEmbeddings: classEmbeddings })
                    // DB.facenetArr.add({ id: examCenterDetails.id, data: classEmbeddings })
                    const progressInterval = setInterval(() => {
                      const progress = facenet.getProgress();
                      console.log('progress: ', Math.floor(progress))
                      setModelProgress(Math.floor(progress));
                      if (progress === 100) {
                        clearInterval(progressInterval);
                        setOpenModelProgress(false);
                      }
                    }, 1000);
                    // setOpenModelProgress(true);
                    // if (examCenterDetails.modelUrl) {
                    facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                      // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                      // setErrMsg('')
                      console.log('model loaded');
                      // setFaceNetObj(facenet)
                      // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                      dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                    })
                    // .catch((err) => {
                    //   setErrMsg('Model Not generated')
                    //   if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                    //     const img = document.createElement("img");
                    //     img.src = ''
                    //     img.id = 'image'
                    //     facenetObj = new Facenet('', '')
                    //     facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                    //     dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                    //   }
                    // })
                  })
                  // .catch((err) => {
                  //   setErrMsg('Model Not generated')
                  //   if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                  //     const img = document.createElement("img");
                  //     img.src = ''
                  //     img.id = 'image'
                  //     facenetObj = new Facenet('', '')
                  //     facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                  //     dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                  //   }
                  // })
                  // } else {
                  //   errorToast('Model Not generated,Please generate model before face recognition')
                  // }
                }).catch((err) => {
                  apiPath = examCenterDetails.classNamesJson
                  embeddingsPath = examCenterDetails.classEmbeddings
                  modelUrl = examCenterDetails.modelUrl
                  if (window.navigator.onLine) {
                    axios.get(apiPath).then(async (response) => {
                      axios.get(embeddingsPath).then(async (resp) => {
                        console.log('get request:', response)
                        let classNames = []
                        let classEmbeddings = []
                        if (apiPath == examCenterDetails.classNamesJson) {
                          classNames = response.data
                          classEmbeddings = resp.data
                        } else {
                          classNames = response.data.classNames
                          classEmbeddings = resp.data.classEmbeddings
                        }
                        console.log(classNames)
                        const facenet = new Facenet(classNames, classEmbeddings);
                        DB.facenetArr.add({ id: examCenterDetails.id, classNamesJson: classNames, classEmbeddings: classEmbeddings })
                        // DB.facenetArr.add({ id: examCenterDetails.id, data: classEmbeddings })
                        const progressInterval = setInterval(() => {
                          const progress = facenet.getProgress();
                          console.log('progress: ', Math.floor(progress))
                          setModelProgress(Math.floor(progress));
                          if (progress === 100) {
                            clearInterval(progressInterval);
                            // setOpenModelProgress(false);
                          }
                        }, 1000);
                        // setOpenModelProgress(true);
                        if (window.location.hostname === '') {
                          axios.get('http://127.0.0.1:5000/checkIfRunning').then((resp) => {
                            axios.post(`http://127.0.0.1:5000/downloadModelToLocalFileSystem?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`, { downloadFiles: examCenterDetails.downloadFiles }).then((re) => {
                              if (re.data === "Done") {
                                DB.modelUrl.add({ id: examCenterDetails.id, modelUrl: "local" })
                                facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                                  // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                                  // setErrMsg('')
                                  setOpenModelProgress(false);
                                  console.log('model loaded');
                                  // setFaceNetObj(facenet)
                                  // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                                  dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                                }).catch((err) => {
                                  setOpenModelProgress(false);
                                  // setErrMsg('Model Not generated')
                                  if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                                    const img = document.createElement("img");
                                    img.src = ''
                                    img.id = 'image'
                                    facenetObj = new Facenet('', '')
                                    facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                                    dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                                  }
                                })
                              }
                            })
                          }).catch((err) => {
                            ipcRenderer.send("startPranilExe", "");
                            ipcRenderer.on("exeSuccessful", (event, arg) => {
                              console.log("exeSuccessful", arg)
                              if (arg == 'done') {
                                axios.post(`http://127.0.0.1:5000/downloadModelToLocalFileSystem?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`, { downloadFiles: examCenterDetails.downloadFiles }).then((re) => {
                                  if (re.data === "Done") {
                                    DB.modelUrl.add({ id: examCenterDetails.id, modelUrl: "local" })
                                    facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                                      // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                                      // setErrMsg('')
                                      setOpenModelProgress(false);
                                      console.log('model loaded');
                                      // setFaceNetObj(facenet)
                                      // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                                      dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                                    }).catch((err) => {
                                      // setErrMsg('Model Not generated')
                                      setOpenModelProgress(false);
                                      if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                                        const img = document.createElement("img");
                                        img.src = ''
                                        img.id = 'image'
                                        facenetObj = new Facenet('', '')
                                        facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                                        dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                                      }
                                    })
                                  }
                                })
                              }
                            })
                          })
                        } else {
                          facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                            // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                            // setErrMsg('')
                            setOpenModelProgress(false);
                            console.log('model loaded');
                            // setFaceNetObj(facenet)
                            // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                            dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                          }).catch((err) => {
                            setOpenModelProgress(false);
                            // errorToast("Models folder might have been deleted,please recreate model")
                            if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                              const img = document.createElement("img");
                              img.src = ''
                              img.id = 'image'
                              facenetObj = new Facenet('', '')
                              facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                              dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                            }
                          })
                        }
                      }).catch((err) => {
                        setOpenModelProgress(false);
                        // errorToast("Models folder might have been deleted,please recreate model")
                        if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                          const img = document.createElement("img");
                          img.src = ''
                          img.id = 'image'
                          facenetObj = new Facenet('', '')
                          facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                          dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                        }
                      })
                      // } else {
                      //   errorToast('Model Not generated,Please generate model before face recognition')
                      // }
                    }).catch((err) => {
                      setOpenModelProgress(false);
                      // errorToast("Models folder might have been deleted,please recreate model")
                      if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                        const img = document.createElement("img");
                        img.src = ''
                        img.id = 'image'
                        facenetObj = new Facenet('', '')
                        facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                        dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                      }
                    })
                  } else {
                    setOpenModelProgress(false);
                    if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                      const img = document.createElement("img");
                      img.src = ''
                      img.id = 'image'
                      const facenet = new Facenet('', '')
                      facenet.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                      dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                      // errorToast("Models folder might have been deleted,please recreate model or if model was uploaded then connect to internet and try again")
                    }
                    // DB.facenetArr.get(examCenterDetails.id).then((resp) => {
                    //   // DB.facenetArr.get("classEmbeddings").then((res) => {
                    //   console.log(resp)
                    //   if (!resp) {
                    //     setErrMsg('Model Not generated')
                    //     const img = document.createElement("img");
                    //     img.src = ''
                    //     img.id = 'image'
                    //     const facenet = new Facenet('', '')
                    //     facenet.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                    //     dispatch(actions.FaceNet(facenet));
                    //   } else {
                    //     presentLoader()
                    //     const facenet = new Facenet(resp.classNamesJson, resp.classEmbeddings);
                    //     facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                    //       // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                    //       console.log('model loaded');
                    //       // setFaceNetObj(facenet)
                    //       // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                    //       dispatch(actions.FaceNet(facenet));
                    //       dismissLoader()
                    //     });
                    //   }
                    //   // })
                    // })
                  }
                })
              }
            })
          })
          // .catch((err) => {
          //   setErrMsg('Model Not generated')
          //   if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
          //     const img = document.createElement("img");
          //     img.src = ''
          //     img.id = 'image'
          //     facenetObj = new Facenet('', '')
          //     facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
          //     dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
          //   }
          // })
        } else {
          apiPath = examCenterDetails.classNamesJson
          embeddingsPath = examCenterDetails.classEmbeddings
          modelUrl = examCenterDetails.modelUrl
          if (window.navigator.onLine) {
            axios.get(apiPath).then(async (response) => {
              axios.get(embeddingsPath).then(async (resp) => {
                // setOpenModelProgress(true)
                console.log('get request:', response)
                let classNames = []
                let classEmbeddings = []
                if (apiPath == examCenterDetails.classNamesJson) {
                  classNames = response.data
                  classEmbeddings = resp.data
                } else {
                  classNames = response.data.classNames
                  classEmbeddings = resp.data.classEmbeddings
                }
                console.log(classNames)
                const facenet = new Facenet(classNames, classEmbeddings);
                DB.facenetArr.add({ id: examCenterDetails.id, classNamesJson: classNames, classEmbeddings: classEmbeddings })
                // DB.facenetArr.add({ id: examCenterDetails.id, data: classEmbeddings })
                const progressInterval = setInterval(() => {
                  const progress = facenet.getProgress();
                  console.log('progress: ', Math.floor(progress))
                  setModelProgress(Math.floor(progress));
                  if (progress === 100) {
                    clearInterval(progressInterval);
                    // setOpenModelProgress(false);
                  }
                }, 1000);
                setOpenModelProgress(true);
                if (window.location.hostname === '') {
                  axios.get('http://127.0.0.1:5000/checkIfRunning').then((resp) => {
                    axios.post(`http://127.0.0.1:5000/downloadModelToLocalFileSystem?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`, { downloadFiles: examCenterDetails.downloadFiles }).then((re) => {
                      if (re.data === "Done") {
                        DB.modelUrl.add({ id: examCenterDetails.id, modelUrl: "local" })
                        facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                          // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                          // setErrMsg('')
                          setOpenModelProgress(false);
                          console.log('model loaded');
                          // setFaceNetObj(facenet)
                          // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                          dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                        }).catch((err) => {
                          setOpenModelProgress(false);
                          // errorToast('Model Not generated')
                          if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                            const img = document.createElement("img");
                            img.src = ''
                            img.id = 'image'
                            facenetObj = new Facenet('', '')
                            facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                            dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                          }
                        })
                      }
                    })
                  }).catch((err) => {
                    ipcRenderer.send("startPranilExe", "");
                    ipcRenderer.on("exeSuccessful", (event, arg) => {
                      console.log("exeSuccessful", arg)
                      if (arg == 'done') {
                        axios.post(`http://127.0.0.1:5000/downloadModelToLocalFileSystem?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`, { downloadFiles: examCenterDetails.downloadFiles }).then((re) => {
                          if (re.data === "Done") {
                            DB.modelUrl.add({ id: examCenterDetails.id, modelUrl: "local" })
                            facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                              // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                              // setErrMsg('')
                              setOpenModelProgress(false);
                              console.log('model loaded');
                              // setFaceNetObj(facenet)
                              // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                              dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                            }).catch((err) => {
                              setOpenModelProgress(false);
                              // errorToast('Model Not generated')
                              if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                                const img = document.createElement("img");
                                img.src = ''
                                img.id = 'image'
                                facenetObj = new Facenet('', '')
                                facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                                dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                              }
                            })
                          }
                        })
                      }
                    })
                  })
                } else {
                  facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                    // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                    // setErrMsg('')
                    setOpenModelProgress(false);
                    console.log('model loaded');
                    // setFaceNetObj(facenet)
                    // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                    dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                  }).catch((err) => {
                    setOpenModelProgress(false);
                    // errorToast('Model Not generated')
                    if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                      const img = document.createElement("img");
                      img.src = ''
                      img.id = 'image'
                      facenetObj = new Facenet('', '')
                      facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                      dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                    }
                  })
                }
              }).catch((err) => {
                setOpenModelProgress(false);
                // errorToast('Model Not generated')
                if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                  const img = document.createElement("img");
                  img.src = ''
                  img.id = 'image'
                  facenetObj = new Facenet('', '')
                  facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                  dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                }
              })
              // } else {
              //   errorToast('Model Not generated,Please generate model before face recognition')
              // }
            }).catch((err) => {
              setOpenModelProgress(false);
              // errorToast('Model Not generated')
              if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                const img = document.createElement("img");
                img.src = ''
                img.id = 'image'
                facenetObj = new Facenet('', '')
                facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
              }
            })
          } else {
            setOpenModelProgress(false);
            if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
              const img = document.createElement("img");
              img.src = ''
              img.id = 'image'
              const facenet = new Facenet('', '')
              facenet.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
              dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
              errorToast("Internet required to load model first time")
            }
            // DB.facenetArr.get(examCenterDetails.id).then((resp) => {
            //   // DB.facenetArr.get("classEmbeddings").then((res) => {
            //   console.log(resp)
            //   if (!resp) {
            //     setErrMsg('Model Not generated')
            //     const img = document.createElement("img");
            //     img.src = ''
            //     img.id = 'image'
            //     const facenet = new Facenet('', '')
            //     facenet.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
            //     dispatch(actions.FaceNet(facenet));
            //   } else {
            //     presentLoader()
            //     const facenet = new Facenet(resp.classNamesJson, resp.classEmbeddings);
            //     facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
            //       // facenet.loadModel('/facenet-data', modelUrl).then(() => {
            //       console.log('model loaded');
            //       // setFaceNetObj(facenet)
            //       // localStorage.setItem("facenetObj", JSON.stringify(facenet));
            //       dispatch(actions.FaceNet(facenet));
            //       dismissLoader()
            //     });
            //   }
            //   // })
            // })
          }
        }
      })
    } else {
      // setFaceNetObj(facenetObj)
      console.log(facenetObj)
    }
  }, [dispatch, examCenterDetails, facenetObj])


  const applicantColumns = [
    {
      field: 'Verify', headerName: 'Verify', sortable: false, width: 75,
      renderCell: (params) => {
        function onClick() {
          setSelectedStudent(null);
          // let s = students.filter(function (friend) { return friend.isVerified === true && friend.examID === examDetails.id && friend.subExamID === subExamDetails.id && friend.examCenterCity === examCenterDetails.City })
          let s = students.filter(function (friend) { return friend.isVerified === true && friend.examID === examDetails.id && friend.subExamID === subExamDetails.id && friend.examCenterName === examCenterDetails.examCenterName })
          s = s.map((m) => ({
            Applicant: m.Applicant,
            isVerified: m.isVerified,
            id: m.id,
            email: m.email,
            UID: m.UID,
            Status: m.Status,
            Photo64: m.Photo64,
            Gender: m.Gender,
            "Full Name": m["Full Name"],
            City: m.City,
            AppliedAs: m.AppliedAs,
            "rollNo": m["rollNo"],
            examCenterName: m.examCenterName,
            examName: m.examName,
            attendanceDateTime: m.attendanceDateTime
          }))
          let a = _.orderBy(s, ['attendanceDateTime'], ['desc']);
          setApplicantsList(convertFirebaseObjToDataGridObj(a, examDetails?.examShortName))
          setSelectedStudent(params.row);
        }
        if (params.row.isVerified !== true)
          return (<Typography fontSize={"1.4rem"} lineHeight={"2.0rem"} color={"#6941C6"} fontWeight={500} onClick={onClick} style={{ cursor: "pointer" }}>Verify</Typography>)
      }
    },
    {
      field: 'View Profile', headerName: 'View Profile', sortable: false, width: 100,
      renderCell: (params) => {
        function onClick() {
          delete params.row.fileName
          SetStudentToLocal(params.row)
          navigate('/home/student-verify/' + examShortName + '/allSubExams/' + subExamName + '/allExamCenters/' + examCenterName + '/details');
        }
        // if (JSON.parse(localStorage.getItem('subExam')).isProforma && !params.row.isSubmitted)
        return (<Typography fontSize={"1.4rem"} lineHeight={"2.0rem"} color={"#6941C6"} fontWeight={500} onClick={onClick} style={{ cursor: "pointer" }}>View Profile</Typography>)
      }
    },
    {
      field: 'fileName', headerName: 'Student Name', width: 200, sortable: false,
      renderCell: (params) => (
        <div className={cssClasses.imageCellWrapper}>
          <img src={(params.row?.Photo64 && params.row?.Photo64 !== '') ? params.row.Photo64 : man} alt="" onError={(e) => { e.target.onerror = null; e.target.src = man; }} />
          <div>
            <p>{params.value.name}</p>
            <p>{params.value.email}</p>
          </div>
        </div>
      )
    },
    {
      field: 'isVerified', headerName: 'Verification Status', width: 150, sortable: false,
      renderCell: (params) => {
        return <p className={params.row[params.field] === true ? cssClasses.doneButton : cssClasses.verifyButton}>{params.row[params.field] === true ? "Done" : "Pending"}</p>;
      },
    },
    { field: 'City', headerName: 'City', sortable: false, width: 150 },
    { field: 'Gender', headerName: 'Gender', sortable: false, width: 200 },
  ];

  const onVerifyClick = (_applicant) => {
    let _student = Object.assign({}, _applicant);
    delete _student.fileName;
    console.log(_student);
    // setSelectedStudent(_student);
  }

  function sendFaceID(studentId) {
    console.log("studentId", studentId);
    DB.students.get(studentId).then((resp) => {
      // setApplicantsList([resp]);
      setSelectedStudent(resp);
    })
  }
  function sendQrResult(ApplicantId) {
    console.log("ApplicantId => ", ApplicantId);
    // DB.students.filter(function (friend) { return friend.Applicant === ApplicantId && friend.examID === examDetails.id && friend.subExamID === subExamDetails.id && friend.examCenterCity === examCenterDetails.City }).toArray().then(res => {
    DB.students.filter(function (friend) { return friend.Applicant === ApplicantId && friend.examID === examDetails.id && friend.subExamID === subExamDetails.id && friend.examCenterName === examCenterDetails.examCenterName }).toArray().then(res => {
      if (res.length === 0) {
        errorToast("Applicant not found.");
      } else {
        setSelectedStudent({ ...res[0] })
      }
    }).catch(err => {
      console.log(err);
    })
  }

  function goBack() {
    if (!selectedStudent) {
      naviagte(`/home/student-verify/${examShortName}/allSubExams/${subExamName}/allExamCenters/${examCenterName}/allStudents`);
    } else {
      // setApplicantsList([]);
      setSelectedStudent(null)
      setSearchValue('')
      document.getElementById('studentSearchinput-with-icon-textfield').value = ''
    }
  }

  async function nextStudent(page, student) {
    if (student) {
      let index = students.findIndex((f) => f.id === student.id)
      // setApplicantsList((pre) => convertFirebaseObjToDataGridObj([...pre, student]))
      if (index !== -1) {
        setStudents((pre) => { pre[index] = student; return [...pre] })
        setVerifiedStudentsCount((pre) => pre + 1)
        // let s = students.filter(function (friend) { return friend.isVerified === true && friend.examID === examDetails.id && friend.subExamID === subExamDetails.id && friend.examCenterCity === examCenterDetails.City })
        let s = students.filter(function (friend) { return friend.isVerified === true && friend.examID === examDetails.id && friend.subExamID === subExamDetails.id && friend.examCenterName === examCenterDetails.examCenterName })
        s = s.map((m) => ({
          Applicant: m.Applicant,
          isVerified: m.isVerified,
          id: m.id,
          email: m.email,
          UID: m.UID,
          Status: m.Status,
          Photo64: m.Photo64,
          Gender: m.Gender,
          "Full Name": m["Full Name"],
          City: m.City,
          AppliedAs: m.AppliedAs,
          "rollNo": m["rollNo"],
          examCenterName: m.examCenterName,
          examName: m.examName,
          attendanceDateTime: m.attendanceDateTime
        }))
        let a = _.orderBy(s, ['attendanceDateTime'], ['desc']);
        setApplicantsList(convertFirebaseObjToDataGridObj(a, examDetails?.examShortName))
      }
    }
    setCurrentTab(page)
    setSelectedStudent(null)
    setSearchValue('')
    document.getElementById('studentSearchinput-with-icon-textfield').value = ''
    document.getElementById('studentSearchinput-with-icon-textfield').focus()
  }

  function getCount(students, examDetails, subExamDetails, examCenterDetails) {
    // setVerifiedStudentsCount(students.filter(function (friend) { return friend.examID === examDetails.id && friend.subExamID === subExamDetails.id && friend.examCenterCity === examCenterDetails.City && friend.isVerified === true }).length)
    setVerifiedStudentsCount(students.filter(function (friend) { return friend.examID === examDetails.id && friend.subExamID === subExamDetails.id && friend.examCenterName === examCenterDetails.examCenterName && friend.isVerified === true }).length)
    // setTotalStudentsCount(students.filter(function (friend) { return friend.examID === examDetails.id && friend.subExamID === subExamDetails.id && friend.examCenterCity === examCenterDetails.City }).length)
    setTotalStudentsCount(students.filter(function (friend) { return friend.examID === examDetails.id && friend.subExamID === subExamDetails.id && friend.examCenterName === examCenterDetails.examCenterName }).length)
    // setSubmittedStudentsCount(students.filter(function (friend) { return friend.examID === examDetails.id && friend.subExamID === subExamDetails.id && friend.examCenterCity === examCenterDetails.City && friend.isSubmitted }).length)
    setSubmittedStudentsCount(students.filter(function (friend) { return friend.examID === examDetails.id && friend.subExamID === subExamDetails.id && friend.examCenterName === examCenterDetails.examCenterName && friend.isSubmitted }).length)
  }

  const debounce = (func, wait) => {

    let timeout;

    return function executedFunction(...args) {

      const later = () => {

        timeout = null;
        func(...args);

      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);

    };

  };

  var returnedFunction = debounce(function () {
    console.log("inside func")
    setSearchValue(document.getElementById('studentSearchinput-with-icon-textfield').value)
    searchStudents(document.getElementById('studentSearchinput-with-icon-textfield').value)
  }, 500);

  return (
    <>
      <Box className={cssClasses.pageWrapper}>
        <Box className={cssClasses.leftPageWrapper}>
          <Box className={cssClasses.leftPageWrapperHeader}>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}><img onClick={goBack} src={arrowLeftImage} alt="" /> <Typography fontSize={"1.6rem"} ml={"2rem"} lineHeight={"2.4rem"} color={"#000000"}>Back&nbsp;to&nbsp;Dashboard</Typography></Box>
            <Box>
              <SearchBar tabIndex={-1} idDebounce={false} placeholder={"Enter Applicant Number"} id={'studentSearch'} search={returnedFunction} />
            </Box>
          </Box>
          <Box>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '2rem',
              padding: '2rem'
            }}>
              <p>Total : {totalStudentsCount}</p>
              <p>Verified : {verifiedStudentsCount}</p>
              {subExamDetails && subExamDetails.isProforma &&
                <p>Proforma Submitted : {submittedStudentsCount}</p>
              }
            </div>
          </Box>
          <Box flex={1} display={"flex"} flexDirection={"column"}>
            {/* {(((applicantsList.length >= 1 && searchValue === "" ) || (applicantsList.length > 1 && searchValue.length === 4 )) || applicantsList.length === 0) ? */}
            {/* {(((applicantsList.length > 0 && searchValue === "" ) || applicantsList.length === 0 || (applicantsList.length > 0 && searchValue.length !== 4 )) || (applicantsList.length > 1 && searchValue.length === 4 )) ? */}
            {selectedStudent === null ?
              <DataGrid
                rows={dataGridLoading ? [] : applicantsList}
                columns={applicantColumns}
                hideFooterPagination={false}
                hideFooter={false}
                pageSize={10}
                // page={pageMaintain}
                sx={[dataGridStyles]}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                disableSelectionOnClick
                disableVirtualization
                loading={dataGridLoading}
                rowsPerPageOptions={[10]}
              />
              :
              <Box flex={1} display={"flex"} flexDirection={"column"}>
                <Box className={cssClasses.viewProformaBasicDetails}>

                  <Box display={"flex"} flexDirection={"row"} flex={1} flexWrap={"wrap"}>
                    <Box>
                      <img src={selectedStudent?.Photo64 ? selectedStudent?.Photo64 : man} alt="" style={{ borderRadius: "8px", width: "19rem", height: "19rem" }} onError={(e) => { e.target.onerror = null; e.target.src = man; }} />
                    </Box>
                    <Box flex={1} ml={"2rem"}>
                      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography fontSize={"2.4rem"} lineHeight={"3rem"} color={"#000000"} fontWeight={500}>{selectedStudent["Full Name"] ? selectedStudent["Full Name"] : "-"}</Typography>
                        <Typography fontSize={"1.6rem"} ml={"2rem"} lineHeight={"2.4rem"} color={"#000000"}>{selectedStudent.examName}</Typography>
                      </Box>
                      <Box>
                        <Typography fontSize={"1.4rem"} lineHeight={"2.0rem"} color={"#667085"} m={"1rem 0"}><b>Email : </b>{selectedStudent.email}</Typography>
                        <Typography fontSize={"1.6rem"} lineHeight={"2.4rem"} color={"#344054"}><b>Applicant : </b>{selectedStudent.Applicant}</Typography>
                        <Typography fontSize={"1.6rem"} lineHeight={"2.4rem"} color={"#344054"}><b>City : </b>{selectedStudent.City}</Typography>
                        <Typography fontSize={"1.6rem"} lineHeight={"2.4rem"} color={"#344054"}><b>Exam Center : </b>{selectedStudent.examCenterName}</Typography>
                        <Typography fontSize={"1.6rem"} lineHeight={"2.4rem"} color={"#344054"}><b>Roll No. : </b>{selectedStudent["rollNo"] ? selectedStudent["rollNo"] : "-"}</Typography>
                        <Typography fontSize={"1.6rem"} lineHeight={"2.4rem"} color={"#344054"}><b>Room No. : </b>{selectedStudent["roomNo"] ? selectedStudent["roomNo"] : "-"}</Typography>
                        <Typography fontSize={"1.6rem"} lineHeight={"2.4rem"} color={"#344054"}><b>Type of Candidate : </b>{selectedStudent?.AppliedAs ? selectedStudent?.AppliedAs : "-"}</Typography>
                        <Typography fontSize={"1.6rem"} lineHeight={"2.4rem"} color={"#344054"}><b>Post/Designation : </b>{selectedStudent?.checkingOfficerDesignation ? selectedStudent?.checkingOfficerDesignation : "-"}</Typography>
                      </Box>
                    </Box>
                    <Box flex={"0 0 100%"}>
                      {(selectedStudent?.isVerified === true) && <Typography fontSize={"3rem"} textAlign={"center"} color={"green"}>Candidate Registered</Typography>}
                    </Box>
                  </Box>

                </Box>
                <Box margin={"2rem 0"}>
                  <Divider> <Typography fontSize={"1.4rem"} color={"rgb(0,0,0,0.5)"}>Verified Students</Typography> </Divider>
                </Box>

                <Box flex={1}>
                  <DataGrid
                    rows={dataGridLoading ? [] : applicantsList}
                    columns={applicantColumns}
                    hideFooterPagination={false}
                    hideFooter={false}
                    pageSize={10}
                    // page={pageMaintain}
                    sx={[dataGridStyles]}
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnSelector
                    disableDensitySelector
                    disableSelectionOnClick
                    disableVirtualization
                    loading={dataGridLoading}
                    rowsPerPageOptions={[10]}
                  />
                </Box>

              </Box>
            }
          </Box>

        </Box>
        <Box className={cssClasses.rightPageWrapper}>
          {/* {(((applicantsList.length > 0 && searchValue === "" ) || applicantsList.length === 0 || (applicantsList.length > 0 && searchValue.length !== 4 )) || (applicantsList.length > 1 && searchValue.length === 4 )) ? */}
          {facenetObj &&
            < VerifyStudent isVisible={selectedStudent !== null} currentTab={currentTab} setCurrentTab={setCurrentTab} sendFaceID={sendFaceID} sendQrResult={sendQrResult} facenetObj={facenetObj ? facenetObj[JSON.parse(localStorage.getItem('examCenter')).id] : null} />
          }
          {facenetObj && selectedStudent &&
            < AttendanceProcess isVisible={selectedStudent === null} studentId={selectedStudent?.id} nextStudent={nextStudent} />
          }
        </Box>
      </Box>


      <ProgressImagesUpload
        open={openModelProgress}
        title={"Loading Model"}
        handleClose={() => setOpenModelProgress(false)}
        uploadingImages={modelProgress}
      />
    </>
  );
}

function convertFirebaseObjToDataGridObj(_serverList, examShortName) {
  return _serverList.map(m => ({
    fileName: {
      img: m?.Photo64,
      name: m["Full Name"],
      email: m?.email || m?.Email,
      onErrorImg: man,
    },
    showInGrid: true,
    examName: examShortName ? examShortName : "",
    isVerified: false,
    ...m
  }))
}


export default AttendancePage;
