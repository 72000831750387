import React,{useState,useEffect,useCallback} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "../../Subcomponent/Button";
import cssClasses from "./PdfViewerModal.module.css";
import crossImage from "../../assets/images/x.svg";

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';


export default function PdfViewer({ open, handleClose , url }) {
 
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={[
        {
          ".MuiBackdrop-root":{
            background: "rgba(193, 201, 210, 0.7)"
          }
        }
      ]}
      disableAutoFocus={true}
    >
      <Box className={cssClasses.modalWrapper}>

        <div className={cssClasses.header}>
          <h1>Scan QR or BarCode</h1>
          <img src={crossImage} alt="" style={{cursor:"pointer"}} onClick={handleClose}/>
        </div>

        <div className={cssClasses.mainContainer}>
          <Document file={url} >
            <Page pageNumber={1} />
          </Document>
        </div>

        <div className={cssClasses.footer}>
          {/* <div><Button style={{ color: "#7F56D9", backgroundColor: "#ffffff" }} btnName={"Download PDF"} clicked={handleClose} /></div> */}
          <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Close"} clicked={handleClose} /></div>
        </div>
      </Box>
    </Modal>
  );
}
