import React, { Fragment, useEffect, useState } from 'react'

//* import MUI
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@mui/material/TextField";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

//* import sub component
import Button from "../../Subcomponent/Button";
import Dropdown from "../../Subcomponent/Dropdown"

//* import Images
import shuffleIcon from "../../assets/images/shuffle.svg";
import crossImage from "../../assets/images/cross.svg";
import uploadFileCloud from "../../assets/images/uploadFileCloud.svg";

//* import Css
import cssClasses from "./DataMergeModal.module.css";

//* import services
import { errorToast, successToast } from '../../services/Toast';

//* imports node_modules
import { differenceBy, xorBy, groupBy, sortBy } from "lodash"
import { DB } from '../../db';
import { Workbook } from 'exceljs';
import FileSaver from 'file-saver';
import { dismissLoader, presentLoader } from '../../services/loaderService';

var Minizip = require('minizip-asm.js');

const tableStyle = {
  "& .MuiTableCell-root": {
    color: "#000",
    fontSize: "1.4rem",
    fontFamily: '"Inter-Regular"'
  },
  "& .MuiTableHead-root .MuiTableCell-root": {
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    fontWeight: 600
  },
  "& .studentHeading": {
    fontWeight: 600
  },
  "& .StudentImage": {
    width: "50px",
    height: "50px"
  }
}

function DataMergeModal({ open, handleClose }) {

  const [mergeButtonShow, setMergeButtonShow] = useState(false);
  const [isCancelButtonDisable, setIsCancelButtonDisable] = useState(false);
  const [mergeStep, setMergeStep] = useState(0);

  const [selectedZip, setSelectedZip] = useState([]);
  const [uniqueStudents, setUniqueStudents] = useState([]);
  const [duplicateEntries, setDuplicateEntries] = useState([]);
  const [extractLoading, setExtractLoading] = useState(false);

  useEffect(() => {
    let flag = true;
    duplicateEntries.forEach(ele => {
      let oneRow = ele.filter(f => f.isSelected).length;
      if (!oneRow) {
        flag = false;
      }
    });
    setMergeButtonShow(flag);
  }, [duplicateEntries])


  async function fileSelectForMultiMeritList(event, merge) {

    let files = event.target.files;
    if (files.length > 0) {
      let zipFileLength = 0;
      files.forEach(ele => {
        console.log(ele)
        // if (ele.type === "application/zip") {
        zipFileLength++;
        // }
      })

      if (zipFileLength === files.length) {
        if (merge) {
          setSelectedZip(pre => [...pre, ...files])
        } else {
          setSelectedZip([...files]);
          setMergeStep(1);
        }
        console.log("Files => ", files);
      } else {
        errorToast("Please Select zip folder.");
      }
    }
  }
  async function addMoreZipFile() {
    let fileDocumnet = document.createElement("input");
    fileDocumnet.type = "file";
    fileDocumnet.accept = ".zip";
    fileDocumnet.multiple = true;
    fileDocumnet.click();
    fileDocumnet.onchange = (e) => fileSelectForMultiMeritList(e, true)
  }

  async function deleteFile(fileIndex) {
    let pre = [...selectedZip];
    pre.splice(fileIndex, 1)
    if (pre.length === 0) {
      setMergeStep(0);
    }
    setSelectedZip([...pre]);
  }

  async function processedToMergeZip() {
    setExtractLoading(true);
    Promise.all(selectedZip.map(m => extractZipFile(m))).then(res => {
      console.log("res=> ", res);

      const unique = xorBy(...res, 'id');
      console.log("uniqe => ", unique);
      setUniqueStudents([...unique]);

      let diff = differenceBy(res.flat(), unique, 'id')
      let group = groupBy(diff, 'id');
      const groupData = Object.keys(group).map((m) => [...group[m]]);
      console.log(groupData)
      // selected first row (requirement from client)
      for (let ele of groupData) {
        ele = sortBy(ele, 'attendanceDateTime', 'desc')
        ele[0].isSelected = true
      }
      setDuplicateEntries([...groupData]);

      console.log("groupData => ", groupData);

      if (groupData.length > 0) {
        setMergeStep(2);
      } else {
        setMergeStep(3);
        finalMergingData(unique, true);
      }

      setExtractLoading(false);

    }).catch(err => {
      console.log("err => ", err.toString());
      errorToast("Please provide a valid zip file");
      setExtractLoading(false);
    })
  }

  async function finalMergingData(unique, direct) {
    setMergeStep(3);
    let mergeData = [];
    duplicateEntries.forEach(element => {
      mergeData.push(...element.filter(f => f.isSelected));
    });

    mergeData = mergeData.map((m) => {
      let obj = { ...m };
      delete obj.isSelected;
      return obj;
    });

    let allMergedData = [];

    if (direct) {
      allMergedData = [...unique];
    } else {
      allMergedData = [...uniqueStudents, ...mergeData];
    }

    let base64Array = [];
    allMergedData.forEach((element, index) => {
      element.mergedStudent = true
      let obj = {
        id: element.id.includes("_") ? element.id.split("_")[1] : element.id,
        [element.subExamName]: {}
      }
      if (element.fingerprint1Base64 && element.fingerprint1Base64 !== "") {
        obj[element.subExamName]["fingerprintImage1"] = element.fingerprint1Base64;
      }
      if (element.fingerprint2Base64 && element.fingerprint2Base64 !== "") {
        obj[element.subExamName]["fingerprintImage2"] = element.fingerprint2Base64;
      }
      if (element.idCardBase64 && element.idCardBase64 !== "") {
        obj[element.subExamName]["idCardImage"] = element.idCardBase64;
      }
      if (element.realTimeBase64 && element.realTimeBase64 !== "") {
        obj[element.subExamName]["realTimePhoto"] = element.realTimeBase64;
      }
      base64Array.push(obj)
    })

    DB.students.bulkPut(allMergedData).then(res => {

      Promise.all(base64Array.map(m => updateBase64Object(m))).then((baseRes) => {
        successToast("Data merge and save successfully.");
        handleClose();
      }).catch(baseErr => {
        errorToast("Something went wrong, Please try again later!");
        handleClose();
      })
    }).catch(err => {
      errorToast("Something went wrong, Please try again later!");
      handleClose();
    })


  }

  async function downloadCandidateReportAsPerUnit() {
    presentLoader();
    // setStepperValueForResultSheet(0);
    // setStepperToggleForResultSheet(true);
    console.log("duplicate", duplicateEntries)
    let completeList = []
    let keys = []
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Data');
    for (const ele of duplicateEntries) {
      let _list = ele.map((m, index) => ({
        // UID: m.id,
        "sNo": (index + 1).toString(),
        "Applicant": m.Applicant,
        "Full Name": m["Full Name"],
        "Gender": m.Gender,
        // "rollNo": m["rollNo"] ? m["rollNo"] : '',
        // "MobileNo": m.phone,
        // "Attendance": (m.realTimeBase64 && m.realTimeBase64 !== "") ? "P" : "A",
        // "Qualified Status": m.Status,
        // [`Attendance_Date_Time`]: (m?.attendanceDateTime && m?.attendanceDateTime !== "") ? new Date(m.attendanceDateTime)?.toLocaleString() : "",
        // "CenterName": m.examCenterName,
        // "ExamName": m.subExamName,
        "AppPhoto": m?.Photo64 ? m.Photo64 : "",
        "LivePhoto": m?.realTimeBase64 ? m.realTimeBase64 : "",
        "FPPhoto1": m?.fingerprint1Base64 ? m.fingerprint1Base64 : "",
        "FPPhoto2": m?.fingerprint2Base64 ? m.fingerprint2Base64 : "",
        "idCardPhoto": m?.idCardBase64 ? m.idCardBase64 : "",
      }));
      completeList = [...completeList, ..._list]
    }

    keys = ["sNo", "Applicant", "Full Name", "Gender",
      //  "rollNo", "MobileNo", "Attendance", "Qualified Status", "Attendance_Date_Time", "CenterName", "ExamName",
      "AppPhoto", "LivePhoto", "FPPhoto1", "FPPhoto2", "idCardPhoto"];

    console.log("_list => ", completeList);

    // console.log("studentHistoryData",studentHistoryData);
    console.log({ keys });
    worksheet.properties.defaultColWidth = 30;
    // worksheet.properties.defaultRowHeight = 100;
    worksheet.addRow(keys);
    completeList.map((item) => {
      const temp = [];
      keys.map((ind) => {
        temp.push(item[ind]);
        return ind;
      });
      worksheet.addRow(temp);
      return item;
    });
    const row1 = worksheet.getRow(1);
    row1.model.cells.forEach((e) => {
      worksheet.getCell(e.address).font = { bold: true }
      worksheet.getCell(e.address).fill = { bgColor: { argb: "FF0000FF" } }
    })
    const row1Data = row1.model.cells.filter(f => {
      if (f.value.includes("AppPhoto") || f.value.includes("LivePhoto") || f.value.includes("FPPhoto1") || f.value.includes("FPPhoto2") || f.value.includes("idCardPhoto")) {
        return true;
      }
    }).map(m => ({ ...m, columnId: m.address.replace(/[^A-Za-z]/g, '') }))
    // console.log("row1",row1Data);

    let allImagesURLs = [];

    row1Data.forEach((rowData, rowIndex) => {
      let rawColumn = worksheet.getColumn(rowData.columnId);
      allImagesURLs.push(...rawColumn.values.slice(2).map((c, i) => ({
        url: c,
        cellId: `${rowData.columnId}${i + 2}`
      })));
    })

    worksheet.getRows(2, completeList.length + 1).forEach(ele => {
      row1Data.forEach(ele1 => {
        ele.getCell(ele1.columnId).value = "";
        ele.height = 100;
      })
    })

    console.log("allImagesURLs length => ", allImagesURLs);
    // setStepperValueForResultSheet(1);
    // setStepperValueForResultSheet(2);
    allImagesURLs.forEach((ele, index) => {
      if (ele.url && ele.url !== "") {
        const imageId = workbook.addImage({
          base64: ele.url,
          extension: 'png',
        });
        worksheet.addImage(imageId, `${ele.cellId}:${ele.cellId}`);
      }
    });

    //* Downloading Excel
    try {
      workbook.xlsx.writeBuffer().then((buffer) => {
        console.log("Downloading Start");
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', });
        FileSaver.saveAs(blob, `Merge Students.xlsx`);
        dismissLoader();
        // setStepperValueForResultSheet(3);
      }).catch((ee) => {
        errorToast("Something went wrong! Try again");
        // setStepperValueForResultSheet(0);
        // setStepperToggleForResultSheet(false);
        // setStepperPhotoProgressValueForResultSheet(0);
        dismissLoader();
        console.log("dd", ee)
      });
    } catch (e) {
      console.log(e);
      errorToast("Something went wrong! Try again");
      // setStepperValueForResultSheet(0);
      // setStepperToggleForResultSheet(false);
      dismissLoader();
    }
  }

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={[
        {
          ".MuiBackdrop-root": {
            background: "rgba(193, 201, 210, 0.7)"
          }
        }
      ]}
      disableAutoFocus={true}
    >
      <Box className={cssClasses.modalWrapper}>
        <div className={cssClasses.header}>
          {/* <img src={shuffleIcon} alt="" /> */}
          <h1>Merge Students</h1>
          {/* <img src={crossImage} alt="" style={{cursor:"pointer"}} onClick={handleClose}/> */}
        </div>

        <div className={cssClasses.mainContainer}>

          {(selectedZip.length === 0 && mergeStep === 0) &&
            <>
              <div className={cssClasses.uploadFileWrapper}>
                <img src={uploadFileCloud} alt="" onClick={() => { document.getElementById("fileSelectForMultiMeritList").click() }} />
                <p>Browse</p>
                <input
                  hidden
                  multiple
                  type="file"
                  name=""
                  id="fileSelectForMultiMeritList"
                  onChange={(event) => { fileSelectForMultiMeritList(event, false) }}
                  accept=".zip"
                />
              </div>
            </>
          }

          {(selectedZip.length > 0 && mergeStep === 1) &&
            <Box display={"flex"} flexDirection={"column"} >
              <Paper sx={{ width: '100%', overflow: 'hidden', padding: "2rem", flex: 1 }}>
                <Typography fontSize={"1.4rem"} fontWeight={600} >Selected Zips</Typography>

                <Box mt={"2rem"} flex={1} height={"50rem"} overflow={"auto"}>
                  {
                    extractLoading ?
                      <Box height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <CircularProgress style={{ color: "#7F56D9" }} />
                      </Box>
                      :
                      <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"}>
                        {
                          selectedZip.map((elementZip, zipIndex) => (
                            <Box position={"relative"} m={"2rem"} key={`zip_Folder_${zipIndex}`}>
                              <Paper style={{ padding: "1rem", height: "100%" }}>
                                <IconButton className={cssClasses.zipCrossIconButton} onClick={() => { deleteFile(zipIndex) }}>
                                  <CloseIcon sx={[{ color: "#000", width: "15px", height: "15px" }]} />
                                </IconButton>
                                <FolderZipIcon sx={[{ color: "#B692F6", width: "70px", height: "70px" }]} />
                                <Typography width={"70px"} fontSize={"1.2rem"} fontWeight={500} color={"#667085"} textAlign={"center"} style={{ wordWrap: "break-word" }}>{elementZip.name}</Typography>
                              </Paper>
                            </Box>
                          ))
                        }

                        <Box position={"relative"} m={"2rem"} key={`zip_Folder`}>
                          <Paper style={{ padding: "1rem", height: "100%", cursor: "pointer" }} onClick={addMoreZipFile}>
                            <AddIcon sx={[{ color: "#B692F6", width: "70px", height: "70px" }]} />
                            <Typography width={"70px"} fontSize={"1.2rem"} fontWeight={500} color={"#667085"} textAlign={"center"} style={{ wordWrap: "break-word" }}>Add Zip File</Typography>
                          </Paper>
                        </Box>
                      </Box>
                  }



                </Box>
              </Paper>
            </Box>
          }

          {(duplicateEntries.length > 0 && mergeStep === 2) &&
            <>
              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table" sx={[tableStyle]}>
                    <TableHead>
                      <TableRow>
                        <TableCell width={55}>Select</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Photo</TableCell>
                        <TableCell>Live Photo</TableCell>
                        <TableCell>Fingerprint 1</TableCell>
                        <TableCell>Fingerprint 2</TableCell>
                        <TableCell>Id Card</TableCell>
                        <TableCell>Attendance</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        duplicateEntries.map((item, index) => (
                          <Fragment key={`Parent_${index}`}>
                            <TableRow>
                              <TableCell colSpan={8} className="studentHeading">Name = {item[0]["Full Name"] ? item[0]["Full Name"] : ""}  Sub-Exam = {item[0].subExamName}</TableCell>
                            </TableRow>
                            {
                              duplicateEntries[index].map((element, elementIndex) => (
                                <TableRow key={`Chils_${index}_${elementIndex}`}>
                                  <TableCell>
                                    <input
                                      type="checkbox"
                                      checked={element?.isSelected}
                                      onChange={(e) => {
                                        let pre = [...duplicateEntries]
                                        pre[index].forEach((ele, eleIndex) => {
                                          ele["isSelected"] = false;
                                        })
                                        pre[index][elementIndex]["isSelected"] = e.target.checked;
                                        setDuplicateEntries([...pre])
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>{element["Full Name"] ? element["Full Name"] : ""}</TableCell>
                                  <TableCell><img src={element.Photo64} alt="" className='StudentImage' /></TableCell>
                                  <TableCell><img src={element.realTimeBase64} alt="" className='StudentImage' /></TableCell>
                                  <TableCell><img src={element.fingerprint2Base64} alt="" className='StudentImage' /></TableCell>
                                  <TableCell><img src={element.fingerprint2Base64} alt="" className='StudentImage' /></TableCell>
                                  <TableCell><img src={element.idCardBase64} alt="" className='StudentImage' /></TableCell>
                                  <TableCell>{element.isVerified === true ? "Present" : "Absent"}</TableCell>

                                </TableRow>
                              ))
                            }
                          </Fragment>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </>
          }

          {(mergeStep === 3) &&
            <Box>
              <Typography textAlign={"center"} fontSize={"1.5rem"} fontWeight={600} >Data Merging and Saving</Typography>
              <Box height={"50rem"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <CircularProgress style={{ color: "#7F56D9" }} />
              </Box>
            </Box>
          }


        </div>

        <div className={cssClasses.footer}>
          {/* <div><Button style={{ color: "#7F56D9", backgroundColor: "#ffffff" }} btnName={"Download PDF"} clicked={handleClose} /></div> */}
          <div><Button style={{ color: "#344054", backgroundColor: "#ffffff" }} btnName={"Cancel"} clicked={handleClose} disable={mergeStep === 3} /></div>

          {/* <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Done"} disable={true}/></div> */}

          {(selectedZip.length > 0 && mergeStep === 2) &&
            <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Download Excel"} clicked={() => { downloadCandidateReportAsPerUnit() }} /></div>
          }

          {(selectedZip.length > 0 && mergeStep === 2) &&
            <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Merge"} clicked={() => { finalMergingData([], false) }} disable={!mergeButtonShow} /></div>
          }

          {selectedZip.length > 0 && mergeStep === 1 &&
            <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Processed To Merge"} clicked={processedToMergeZip} disable={false} /></div>
          }
        </div>
      </Box>
    </Modal>
  )
}

export default DataMergeModal;

const extractZipFile = (file) => new Promise((resolve, reject) => {
  var fr = new FileReader();
  fr.onload = function (event) {
    try {
      var zip = new Minizip(new Uint8Array(event.target.result));
      let re = zip.extract("OfflineDataBase.json", { password: JSON.parse(localStorage.getItem('exam')).id })
      let finalarr = []
      sliceArray(re, finalarr)
      console.log(finalarr)
      // 131111274
      // 50000000
      let jsonstring = '';
      for (const ele of finalarr) {
        jsonstring = jsonstring + ele.toString()
      }
      resolve(JSON.parse(jsonstring));
    } catch (error) {
      console.log("error", file.name, error);
      reject(error);
    }

  }

  fr.readAsArrayBuffer(file);

})

function sliceArray(arr, finalarr) {
  let max = 50000000
  if (arr.length > max) {
    finalarr.push(arr.slice(0, max))
    arr = arr.slice(max + 1, arr.length)
    sliceArray(arr, finalarr)
  } else {
    finalarr.push(arr)
  }
}

function createChunkArray(arr) {
  return arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 50000000)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
}

const updateBase64Object = (studentObject) => new Promise((resolve, reject) => {
  studentObject.id = `${JSON.parse(localStorage.getItem('exam')).name}_${studentObject.id}`
  studentObject.examName = JSON.parse(localStorage.getItem('exam')).name
  DB.base64.update(studentObject.id, studentObject).then(res => {
    if (res) {
      resolve(res);
    } else {
      DB.base64.add(studentObject).then(res2 => {
        resolve(res2);
      }).catch(error => {
        reject(error);
      })
    }
  }).catch(err => {
    if (err.toString().includes("Cannot convert undefined or null to object")) {
      DB.base64.add(studentObject).then(res => {
        resolve(res);
      }).catch(error => {
        reject(error);
      })
    } else {
      reject(err);
    }
  })
})