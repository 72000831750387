import * as AWS from 'aws-sdk';
let s3 = null;
function initializeAWS() {
    AWS.config.update(
        {
            accessKeyId: process.env.REACT_APP_accessKeyId,
            secretAccessKey: process.env.REACT_APP_secretAccessKey,
            region: process.env.REACT_APP_region
        });
    AWS.config.apiVersions = {
        s3: '2006-03-01',
        // other service API versions
    };
    s3 = new AWS.S3({
        apiVersion: '2006-03-01',
        params: { Bucket: process.env.REACT_APP_bucketName },
    });
}
function uploadFilesAWS(files, successCB, errorCB, uploadProgressCB) {
    // function uploadFilesAWS(files) {
    if (s3 == null) {
        initializeAWS();
    }
    const filesData = [];
    let sizeTotal = 0;
    const loaded = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < files.length; i++) {
        filesData.push({ name: files[i].name, size: files[i].size });
        sizeTotal += files[i].size;
        loaded[files[i].name] = 0;
    }
    if (files.length === 0) {
        successCB({ result: 'No file to Uploaded' });
    }
    let uploadCount = 0;
    for (let i = 0, filesLen = files.length; i < filesLen; i++) {
        // tslint:disable-next-line: max-line-length
        const params = { Key: files[i].key, Body: files[i].arrayBuffer, Bucket: process.env.REACT_APP_bucketName, ACL: 'public-read', ContentEncoding: 'base64',content_type: files[i].type };
        // tslint:disable-next-line: only-arrow-functions
        (function (i, params) {
            // tslint:disable-next-line: only-arrow-functions
            s3.upload(params, function (err, data) {
                if (err) {
                    // alert('File Upload error');
                    errorCB({ err: err.toString() });
                }
                uploadCount++;
                if (files.length === uploadCount) {
                    successCB({ result: files.length + ' Files Uploaded' });
                }
                // tslint:disable-next-line: only-arrow-functions
            }).on('httpUploadProgress', function (e) {
                loaded[files[i].name] = e.loaded;
                let loadedTotal = 0;
                // tslint:disable-next-line: forin
                for (const j in loaded) {
                    loadedTotal += loaded[j];
                }
                const progress = Math.round(loadedTotal / sizeTotal * 100);
                uploadProgressCB({ uploadedPercent: progress });
            });
        })(i, params);
    }
}
function getFilesFromAWS(folderName) {
    // console.log('folderName: ', folderName);
    return new Promise((resolve, reject) => {
        const params = {
            Bucket: process.env.REACT_APP_bucketName,
            Prefix: process.env.REACT_APP_bucketRootKey + '/' + folderName + '/',
            Delimiter: '/'        // ? get only files (exclude archive folder)
        };
        console.log(params);
        if (s3 == null) {
            initializeAWS();
        }
        s3.listObjects(params, (err, data) => {
            if (err) {
                reject(err);
            }
            const allFiles = [];
            if (data) {
                let fileObj;
                console.log('data.Contents: ', data.Contents);
                data.Contents.forEach((file) => {
                    if (file.Size > 0) {
                        fileObj = {
                            fileUploadKey: file.Key,
                            fileUploadDate: file.LastModified,
                            fileUploadSize: file.Size,
                        };
                        allFiles.push(fileObj);
                    }
                });
            }
            resolve(allFiles);
        });
    });
}

function getALLFilesFromAWS(folderName) {
    // console.log('folderName: ', folderName);
    return new Promise((resolve, reject) => {
        const params = {
            Bucket: process.env.REACT_APP_bucketName,
            Prefix: process.env.REACT_APP_bucketRootKey + '/' + folderName + '/',
            // Delimiter: '/'        // ? get only files (exclude archive folder)
        };
        // console.log(params);
        if (s3 == null) {
            initializeAWS();
        }
        s3.listObjects(params, (err, data) => {
            if (err) {
                reject(err);
            }
            const allFiles = [];
            if (data) {
                let fileObj;
                // console.log('data.Contents: ', data.Contents);
                data.Contents.forEach((file) => {
                    if (file.Size > 0) {
                        fileObj = {
                            fileUploadKey: file.Key,
                            fileUploadDate: file.LastModified,
                            fileUploadSize: file.Size,
                        };
                        allFiles.push(fileObj);
                    }
                });
            }
            resolve(allFiles);
        });
    });
}


export { uploadFilesAWS, getFilesFromAWS, getALLFilesFromAWS }