import React, { useState, useEffect, useCallback, useRef } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "../../Subcomponent/Button";
import cssClasses from "./TestModal.module.css"
import crossImage from "../../assets/images/x.svg"
import { groupBy } from 'lodash'
import { dismissLoader, presentLoader } from "../../services/loaderService";
import { errorToast, successToast } from "../../services/Toast";
import { Card, IconButton } from "@mui/material";
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import { CaptureFinger, VerifyFinger, GetMFS100Info } from '../../assets/mfs100-9.0.2.6';
import CameraIcon from '@mui/icons-material/Camera';
import Webcam from "react-webcam";
import biometric from '../../assets/images/biometric.svg'

export default function TestModal({ open, handleClose }) {
    let [counter, setCounter] = useState(0)
    const [student, setStudent] = useState({
        realTimeBase64: '',
        fingerprint1Base64: '',
        fingerprint2Base64: '',
        idCardBase64: '',
    })
    const _student = useRef(student)
    const photoRef = React.useRef(null);
    const docRef = React.useRef(null);
    const [errMsg, setErrMsg] = useState('')
    const [functionTime, setFunctionTime] = useState(false)
    const [reflector, setReflector] = useState(0)
    const [matchedFingerprint, setMatchedFingerprint] = useState('')
    const [successMsg1, setSuccessMsg1] = useState('')
    const _matchedFingerprint = useRef(matchedFingerprint)
    const [allowTab, setAllowTab] = useState(true)
    const allowTabRef = useRef(allowTab)
    const [failedMatch, setFailedMatch] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [hideFingerprint, setHideFingerprint] = useState(false);
    const [isCameraFound, setIsCameraFound] = useState(true);
    const [deviceId, setDeviceId] = React.useState({});
    const [devices, setDevices] = React.useState([]);

    const sadPress = useKeyPress("Tab");

    useEffect(()=>{
        presentLoader()
    },[])

    useEffect(() => {
        if (sadPress) {
            console.log(sadPress)
            console.log(functionTime)
            if (sadPress > functionTime) {
                tabPress()
            } else {
                console.log("omit")
            }
        }
    }, [sadPress])

    function tabPress() {
        // removeTabIndex()
        // addTabIndex(1)
        console.log('%c allowTabRef.current', 'color:green; font-size:20px')
        // console.log(allowTabRef.current)
        // if (!allowTabRef.current) {
        //     return false
        // }
        let currentStudent = _student.current
        // if (Object.keys(currentStudent).length > 0 && (!currentStudent?.isVerified || currentStudent?.isVerified === 'FALSE')) {
        if (counter === 0) {
            if (!currentStudent.realTimeBase64 || currentStudent.realTimeBase64 === '') {
                let photo = photoRef.current.getScreenshot()
                const img = document.createElement("img");
                img.src = photo
                img.id = 'image'
                currentStudent.realTimeBase64 = photo
                setStudent(currentStudent)
                setCounter(1)
                getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
                // if (facenetObj) {
                //     facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data').then((resp) => {
                //         console.log(resp)
                //         if (resp === 'No Face Detected') {
                //             // setStudent((pre) => {
                //             //     pre.realTimeBase64 = photo
                //             //     return { ...pre }
                //             // })
                //             currentStudent.realTimeBase64 = photo
                //             setStudent(currentStudent)
                //             if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && subExam?.isStoreThumbImpression && (!matchedFingerprint || matchedFingerprint === '')) {
                //                 setCounter(3)
                //                 matchFingerprint()
                //             } else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                //                 setCounter(2)
                //                 getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
                //             } else if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 === '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                //                 getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
                //                 setCounter(1)
                //             } else {
                //                 setCounter(4)
                //             }
                //         } else {
                //             // setStudent((pre) => {
                //             //     pre.realTimeBase64 = resp
                //             //     return { ...pre }
                //             // })
                //             currentStudent.realTimeBase64 = resp
                //             setStudent(currentStudent)
                //             if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && subExam?.isStoreThumbImpression && (!matchedFingerprint || matchedFingerprint === '')) {
                //                 setCounter(3)
                //                 matchFingerprint()
                //             } else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                //                 setCounter(2)
                //                 getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
                //             } else if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 === '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                //                 setCounter(1)
                //                 getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
                //             } else {
                //                 setCounter(4)
                //             }
                //         }
                //     })
                // } else {
                //     facenetObj = new Facenet('', '')
                //     facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data').then((resp) => {
                //         console.log(resp)
                //         if (resp === 'No Face Detected') {
                //             // setStudent((pre) => {
                //             //     pre.realTimeBase64 = photo
                //             //     return { ...pre }
                //             // })
                //             currentStudent.realTimeBase64 = photo
                //             setStudent(currentStudent)
                //             if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && subExam?.isStoreThumbImpression && (!matchedFingerprint || matchedFingerprint === '')) {
                //                 setCounter(3)
                //                 matchFingerprint()
                //             } else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                //                 setCounter(2)
                //                 getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
                //             } else if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 === '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                //                 setCounter(1)
                //                 getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
                //             } else {
                //                 setCounter(4)
                //             }
                //         } else {
                //             // setStudent((pre) => {
                //             //     pre.realTimeBase64 = resp
                //             //     return { ...pre }
                //             // })
                //             currentStudent.realTimeBase64 = resp
                //             setStudent(currentStudent)
                //             if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && subExam?.isStoreThumbImpression && (!matchedFingerprint || matchedFingerprint === '')) {
                //                 setCounter(3)
                //                 matchFingerprint()
                //             } else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                //                 setCounter(2)
                //                 getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
                //             } else if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 === '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                //                 setCounter(1)
                //                 getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
                //             } else {
                //                 setCounter(4)
                //             }
                //         }
                //     })
                // }
            } else {
                // if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && subExam?.isStoreThumbImpression && (!matchedFingerprint || matchedFingerprint === '')) {
                //     setCounter(3)
                //     matchFingerprint()
                // } else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                //     setCounter(2)
                //     getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
                // } else if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 === '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
                setCounter(1)
                getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
                // } else {
                //     setCounter(4)
                // }
            }
        } else if (counter === 1) {
            getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
        } else if (counter === 2) {
            if (currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '')
                getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
        } else if (counter === 3) {
            if (currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '' && currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '')
                matchFingerprint()
        }
        // else if (counter === 4) {
        //     currentStudent.idCardBase64 = docRef.current.getScreenshot()
        //     setStudent(currentStudent)
        // }
        // }
    }

    function captureStudentPhoto() {
        let currentStudent = _student.current
        if (currentStudent.realTimeBase64 && currentStudent.realTimeBase64 !== '') {
            // setStudent((pre) => {
            //     pre.realTimeBase64 = ''
            //     return { ...pre }
            // })
            currentStudent.realTimeBase64 = ''
            setStudent(currentStudent)
            setCounter(0)
        } else {
            let photo = photoRef.current.getScreenshot()
            const img = document.createElement("img");
            img.src = photo
            img.id = 'image'
            currentStudent.realTimeBase64 = photo
            setStudent(currentStudent)
            setCounter(1)
            getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
            // if (facenetObj) {
            //     facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data').then((resp) => {
            //         console.log(resp)
            //         if (resp === 'No Face Detected') {
            //             // setStudent((pre) => {
            //             //     pre.realTimeBase64 = photo
            //             //     return { ...pre }
            //             // })
            //             currentStudent.realTimeBase64 = photo
            //             setStudent(currentStudent)
            //             if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && subExam?.isStoreThumbImpression && (!matchedFingerprint || matchedFingerprint === '')) {
            //                 setCounter(3)
            //                 matchFingerprint()
            //             } else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
            //                 setCounter(2)
            //                 getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
            //             } else if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 === '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
            //                 setCounter(1)
            //                 getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
            //             } else {
            //                 setCounter(4)
            //             }
            //         } else {
            //             // setStudent((pre) => {
            //             //     pre.realTimeBase64 = resp
            //             //     return { ...pre }
            //             // })
            //             currentStudent.realTimeBase64 = resp
            //             setStudent(currentStudent)
            //             if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && subExam?.isStoreThumbImpression && (!matchedFingerprint || matchedFingerprint === '')) {
            //                 setCounter(3)
            //                 matchFingerprint()
            //             } else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
            //                 setCounter(2)
            //                 getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
            //             } else if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 === '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
            //                 setCounter(1)
            //                 getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
            //             } else {
            //                 setCounter(4)
            //             }
            //         }
            //     })
            // } else {
            //     facenetObj = new Facenet('', '')
            //     facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data').then((resp) => {
            //         console.log(resp)
            //         if (resp === 'No Face Detected') {
            //             // setStudent((pre) => {
            //             //     pre.realTimeBase64 = photo
            //             //     return { ...pre }
            //             // })
            //             currentStudent.realTimeBase64 = photo
            //             setStudent(currentStudent)
            //             if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && subExam?.isStoreThumbImpression && (!matchedFingerprint || matchedFingerprint === '')) {
            //                 setCounter(3)
            //                 matchFingerprint()
            //             } else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
            //                 setCounter(2)
            //                 getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
            //             } else if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 === '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
            //                 setCounter(1)
            //                 getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
            //             } else {
            //                 setCounter(4)
            //             }
            //         } else {
            //             // setStudent((pre) => {
            //             //     pre.realTimeBase64 = resp
            //             //     return { ...pre }
            //             // })
            //             currentStudent.realTimeBase64 = resp
            //             setStudent(currentStudent)
            //             if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (currentStudent.fingerprint2Base64 && currentStudent.fingerprint2Base64 !== '') && subExam?.isStoreThumbImpression && (!matchedFingerprint || matchedFingerprint === '')) {
            //                 setCounter(3)
            //                 matchFingerprint()
            //             } else if ((currentStudent.fingerprint1Base64 && currentStudent.fingerprint1Base64 !== '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
            //                 setCounter(2)
            //                 getFingerprint('fingerprint2Base64', 'fingerprintTemplate2')
            //             } else if ((!currentStudent.fingerprint1Base64 || currentStudent.fingerprint1Base64 === '') && (!currentStudent.fingerprint2Base64 || currentStudent.fingerprint2Base64 === '') && subExam?.isStoreThumbImpression) {
            //                 setCounter(1)
            //                 getFingerprint('fingerprint1Base64', 'fingerprintTemplate1')
            //             } else {
            //                 setCounter(4)
            //             }
            //         }
            //     })
            // }
            // var preprocess = new Preprocess()
            // preprocess.loadPreprocesser('/facenet-data').then(() => {
            //     console.log('model loaded');
            //     const img = document.createElement("img");
            //     img.src = pre.realTimeBase64
            //     img.id = 'image'
            //     preprocess.preprocessImage(img).then((resp) => {
            //         console.log("preprocess Response:", resp)
            //     })
            // });
            // pre.realTimePhotoCaptureTime = new Date()
        }
    }

    function captureStudentDocument() {
        let currentStudent = _student.current
        if (currentStudent.idCardBase64 && currentStudent.idCardBase64 !== '') {
            // setStudent((pre) => {
            //     pre.idCardBase64 = ''
            //     return { ...pre }
            // })
            currentStudent.idCardBase64 = ''
            setStudent(currentStudent)
            setReflector(new Date().getTime())
        } else {
            // setStudent((pre) => {
            //     pre.idCardBase64 = docRef.current.getScreenshot()
            //     // pre.idCardCaptureTime = new Date()
            //     return { ...pre }
            // })
            currentStudent.idCardBase64 = docRef.current.getScreenshot()
            _student.current.idCardBase64 = docRef.current.getScreenshot()
            setStudent(currentStudent)
            setReflector(new Date().getTime())
        }
    }

    function captureFingertprint(key, temp) {
        let currentStudent = _student.current
        console.log(currentStudent)
        if (currentStudent[key] && currentStudent[key] !== '') {
            setStudent((pre) => {
                pre[key] = ''
                pre[temp] = ''
                if (temp === 'fingerprintTemplate2') {
                    setCounter(2)
                } else {
                    setCounter(1)
                }
                return { ...pre }
            })
            getFingerprint(key, temp)
        } else {
            getFingerprint(key, temp)
        }
    }

    function getFingerprint(key, temp) {
        // document.removeEventListener('keyup', tabPress)
        // allowTabRef.current = false
        let activeIndex = document.activeElement.tabIndex
        console.log(activeIndex)
        // removeTabIndex()
        setTimeout(() => {
            for (let i = 0; i < 4; i++) {
                console.log(i)
                const fingerprint = CaptureFinger(10, 6)
                console.log(fingerprint)
                if ((fingerprint.data?.ErrorDescription === 'MFS100 not Found') || (fingerprint.err === 'Error while calling service')) {
                    setErrMsg('Fingerprint machine not connected')
                    setFunctionTime(new Date().getTime())
                    // addTabIndex(1)
                    // allowTabRef.current = true
                } else {
                    if (fingerprint.data.Quality > 70) {
                        console.log("in >70")
                        if (temp === 'fingerprintTemplate1' && _student.current && _student.current.fingerprintTemplate2) {
                            const isMatched = VerifyFinger(fingerprint.data.IsoTemplate, _student.current.fingerprintTemplate2)
                            if (isMatched.data.Status) {
                                setErrMsg('Fingerprint already Registered,please use different finger')
                                console.log("Inside Function", new Date())
                                setFunctionTime(new Date().getTime())
                                // addTabIndex(1)
                                // allowTabRef.current = true
                            } else {
                                console.log("2nd set")
                                let pre = _student.current
                                console.log("2nd set")
                                pre[key] = "data:image/bmp;base64," + fingerprint.data.BitmapData
                                pre[temp] = fingerprint.data.IsoTemplate
                                // pre.fingerprintCaptureTime = new Date()
                                setStudent({ ...pre })
                                setErrMsg("")
                                console.log("Inside Function", new Date())
                                setFunctionTime(new Date().getTime())
                                // allowTabRef.current = true
                                // addTabIndex(1)
                                // document.addEventListener('keyup', tabPress);
                                if (student?.fingerprint2Base64 && student?.fingerprint2Base64 !== '') {
                                    setCounter(3)
                                } else {
                                    setCounter(2)
                                }
                                break;
                            }
                        } else if (temp === 'fingerprintTemplate2' && _student.current && _student.current.fingerprintTemplate1) {
                            const isMatched = VerifyFinger(fingerprint.data.IsoTemplate, _student.current.fingerprintTemplate1)
                            if (isMatched.data.Status) {
                                setErrMsg('Fingerprint already Registered,please use different finger')
                                console.log("Inside Function", new Date())
                                setFunctionTime(new Date().getTime())
                                // addTabIndex(1)
                                // allowTabRef.current = true
                            } else {
                                console.log("2nd else set")
                                let pre = _student.current
                                console.log("2nd set")
                                pre[key] = "data:image/bmp;base64," + fingerprint.data.BitmapData
                                pre[temp] = fingerprint.data.IsoTemplate
                                // pre.fingerprintCaptureTime = new Date()
                                setStudent({ ...pre })
                                setErrMsg("")
                                console.log("Inside Function", new Date())
                                setFunctionTime(new Date().getTime())
                                // allowTabRef.current = true
                                // addTabIndex(1)
                                // document.addEventListener('keyup', tabPress);
                                setCounter(3)
                                break;
                            }
                        } else {
                            let pre = _student.current
                            pre[key] = "data:image/bmp;base64," + fingerprint.data.BitmapData
                            pre[temp] = fingerprint.data.IsoTemplate
                            // pre.fingerprintCaptureTime = new Date()
                            setStudent({ ...pre })
                            setErrMsg("")
                            console.log("Inside Function", new Date())
                            setFunctionTime(new Date().getTime())
                            // allowTabRef.current = true
                            // addTabIndex(1)
                            // document.addEventListener('keyup', tabPress);
                            if (temp === 'fingerprintTemplate2') {
                                setCounter(3)
                            } else {
                                setCounter(2)
                            }
                            break;
                        }
                    } else {
                        setErrMsg("Please place finger appropriately and try again!")
                        if (i == 3 && temp === 'fingerprintTemplate2') {
                            setCounter(2)
                        } else {
                            setCounter(1)
                        }
                        console.log("Inside Function", new Date())
                        setFunctionTime(new Date().getTime())
                        // allowTabRef.current = true
                        // addTabIndex(1)
                    }
                    // document.addEventListener('keyup', tabPress);
                }
            }
        }, 800)
    }

    function verifyFingerprint() {
        if (matchedFingerprint === '') {
            matchFingerprint()
        } else {
            setMatchedFingerprint('')
            matchFingerprint()
        }
    }

    function matchFingerprint() {
        allowTabRef.current = false
        setTimeout(async () => {
            for (let i = 0; i < 4; i++) {
                const fingerprint = await CaptureFinger(10, 6)
                if ((fingerprint.data?.ErrorDescription === 'MFS100 not Found') || (fingerprint.err === 'Error while calling service')) {
                    setErrMsg('Fingerprint machine not connected')
                    setFunctionTime(new Date().getTime())
                    // addTabIndex(1)
                    allowTabRef.current = true
                    setFailedMatch(false)
                    break;
                } else {
                    const isMatched = await VerifyFinger(fingerprint.data.IsoTemplate, _student.current.fingerprintTemplate1)
                    if (isMatched.data.Status) {
                        // addTabIndex(1)
                        allowTabRef.current = true
                        // if (subExam?.isStoreIdentityDocument)
                        setCounter(4)
                        setMatchedFingerprint("data:image/bmp;base64," + fingerprint.data.BitmapData)
                        setSuccessMsg1(`Camera & Fingerprint Machine Working`)
                        setFailedMatch(false)
                        setErrMsg("")
                        setFunctionTime(new Date().getTime())
                        break;
                    } else {
                        const isMatched2 = await VerifyFinger(fingerprint.data.IsoTemplate, _student.current.fingerprintTemplate2)
                        if (isMatched2.data.Status) {
                            // addTabIndex(1)
                            allowTabRef.current = true
                            // if (subExam?.isStoreIdentityDocument)
                            setCounter(4)
                            setMatchedFingerprint("data:image/bmp;base64," + fingerprint.data.BitmapData)
                            setSuccessMsg1(`Camera & Fingerprint Machine Working`)
                            setFailedMatch(false)
                            setFunctionTime(new Date().getTime())
                            setErrMsg("")
                            break;
                        } else {
                            // addTabIndex(1)
                            allowTabRef.current = true
                            setMatchedFingerprint('')
                            setFailedMatch(true)
                            setHideFingerprint(false)
                            setFunctionTime(new Date().getTime())
                            setErrMsg("Fingerprint Not Matched,Please try again!")
                        }
                    }
                }
            }
        }, 500)
    }

    const cameraError = async (err) => {
        console.log(err);
        dismissLoader()
        setErrMsg("Camera not connected")
        const { state } = await window.navigator.permissions.query({ name: 'camera' });
        if (state === "denied") {
            errorToast("Please allow to access your camera")
        }
        setIsLoading(false);
        setIsCameraFound(false);
    }

    const handleDevices = useCallback(mediaDevices => setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")), [setDevices]);

    const cameraFound = (res) => {
        dismissLoader()
        if (devices.map(m => ({ label: m.label, value: m.deviceId })).some(s => s.label === "" || s.value === "")) {
            navigator.mediaDevices.enumerateDevices().then(handleDevices);
            setIsCameraFound(res.active);
        } else {
            setIsCameraFound(res.active);
            setIsLoading(false);
        }
    }

    return (
        <Modal open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={[
                {
                    ".MuiBackdrop-root": {
                        background: "rgba(193, 201, 210, 0.7)"
                    }
                }
            ]}
            disableAutoFocus={true}
        >
            <div className={cssClasses.mainContainer}>
                <div className={cssClasses.header}>
                    {/* <Box style={{ width: "60%" }}> */}
                    <p style={{ fontSize: "3rem", textAlign: "center" }}>Testing</p>
                    {errMsg &&
                        <p style={{ fontSize: "3rem", color: "red", textAlign: "center" }}>{errMsg}</p>
                    }
                    {successMsg1 &&
                        <p style={{ fontSize: "3rem", color: "green", textAlign: "center" }}>{successMsg1}</p>
                    }
                    {/* </Box> */}
                </div>
                <div style={{ overflowY: "auto" }}>
                    <div style={{ width: "85%", margin: "0 auto", padding: "1rem 0" }}>
                        <Card id='1' tabIndex={-1} defaultChecked={true} style={{ padding: "1rem", borderRadius: "1rem", marginBottom: "1rem", background: "#fcfcfd", outline: counter === 0 ? '2px solid black' : 'none' }}>
                            {/* <div className={cssClasses.cardTitle}>
                            <p>Student Photo</p>
                        </div> */}
                            {/* <div style={{ display: "flex", fontSize: "1.4rem", justifyContent: "space-around" }}>
                            <div>
                                <p>Previous Exam Photos</p>
                            </div>
                            <div>
                                <p>Capture Live Photo</p>
                            </div>
                        </div> */}
                            <div className={cssClasses.cardInfo}>
                                <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                    <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                                        <div style={{ fontSize: "1.4rem", textAlign: "center" }}>
                                            <p>Previous Exam Photos</p>
                                        </div>
                                        <div style={{ textAlign: "center", fontSize: "2rem" }}>
                                            <div>
                                                <p>No Data Available</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={cssClasses.halfDiv1}>
                                        <Card className={cssClasses.captureCard}>
                                            {/* {student && (student.realTimeBase64 && student.realTimeBase64 !== '') && */}
                                            <div style={{ position: "relative", display: student && (student.realTimeBase64 && student.realTimeBase64 !== '') ? "unset" : "none" }}>
                                                <img className={cssClasses.capturedImg} style={{ objectFit: "none" }} src={student?.realTimeBase64} alt="" />
                                                <IconButton tabIndex={-1} className={cssClasses.cameraIcon} aria-label="delete" size="small" onClick={() => { captureStudentPhoto(); document.getElementById('1').focus() }}>
                                                    <FlipCameraAndroidIcon tabIndex={-1} style={{ color: "rgba(0,0,0,0.5)", width: "3rem", height: "3rem" }} />
                                                </IconButton>
                                            </div>
                                            {/* // } */}
                                            {/* {student && (!student.realTimeBase64 || student.realTimeBase64 === '') && */}
                                            <div style={{ position: "relative", display: student && (!student.realTimeBase64 || student.realTimeBase64 === '') ? "unset" : "none" }}>
                                                <Webcam
                                                    audio={false}
                                                    ref={photoRef}
                                                    screenshotFormat="image/jpeg"
                                                    className={cssClasses.videoCamera}
                                                    onUserMediaError={cameraError}
                                                    onUserMedia={cameraFound}
                                                    videoConstraints={{ deviceId: deviceId }}
                                                />
                                                <IconButton tabIndex={-1} className={cssClasses.cameraIcon} aria-label="delete" size="small" onClick={() => { captureStudentPhoto(); document.getElementById('1').focus() }}>
                                                    <CameraIcon tabIndex={-1} style={{ color: "rgba(0,0,0,0.5)", width: "3rem", height: "3rem" }} />
                                                </IconButton>
                                            </div>
                                            {/* } */}
                                            {/* {(student && (student.realTimeBase64 && student.realTimeBase64 !== '')) &&
                                            <div>
                                                <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Recapture"} clicked={captureStudentPhoto} />
                                            </div>
                                        } */}
                                            {/* {(student && (!student.realTimeBase64 || student.realTimeBase64 == '')) &&
                                            <div>
                                                <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Capture"} clicked={captureStudentPhoto} />
                                            </div>
                                        } */}
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        {/* {subExam?.isStoreThumbImpression && */}
                        <div>
                            <Card tabIndex={-1} style={{ padding: "1rem", borderRadius: "1rem", marginBottom: "1rem", background: "#fcfcfd" }}>
                                {/* <div className={cssClasses.cardTitle}>
                                <p>Bio Metric</p>
                            </div> */}
                                <div style={{ display: "flex", fontSize: "1.4rem", justifyContent: "space-around" }}>
                                    <div>
                                        <p>Fingerprint 1</p>
                                    </div>
                                    <div>
                                        <p>Fingerprint 2</p>
                                    </div>
                                    <div>
                                        <p>Match Finger</p>
                                    </div>
                                </div>
                                <div className={cssClasses.cardInfo}>
                                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <div className={cssClasses.halfDiv1}>
                                            <Card id='2' tabIndex={-1} className={cssClasses.captureCard} style={{ outline: counter === 1 ? '2px solid black' : 'none' }}>
                                                {!hideFingerprint ?
                                                    <div className={cssClasses.captureCard}>
                                                        {student && (student.fingerprint1Base64 && student.fingerprint1Base64 !== '') &&
                                                            <img className={cssClasses.fingerprintImg} src={student.fingerprint1Base64} alt="" />
                                                        }
                                                        {student && (!student.fingerprint1Base64 || student.fingerprint1Base64 === '') &&
                                                            <img className={cssClasses.fingerprintImg} src={biometric} alt="" />
                                                        }
                                                        {(student && (student.fingerprint1Base64 && student.fingerprint1Base64 !== '')) &&
                                                            <div>
                                                                <div className={cssClasses.buttonWrapper} style={{ pointerEvents: (errMsg === 'Fingerprint machine not connected' || !failedMatch) ? "none" : "unset", opacity: (errMsg === 'Fingerprint machine not connected' || !failedMatch) ? "0.5" : "unset" }}>
                                                                    <div className={cssClasses.button} onClick={() => { captureFingertprint('fingerprint1Base64', 'fingerprintTemplate1'); setCounter(1) }}>
                                                                        <p className={cssClasses.btnText}>Recapture</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {(student && (!student.fingerprint1Base64 || student.fingerprint1Base64 === '')) &&
                                                            <div>
                                                                <div className={cssClasses.buttonWrapper} style={{ pointerEvents: (errMsg === 'Fingerprint machine not connected' || !student.realTimeBase64 || student.realTimeBase64 == '') ? "none" : "unset", opacity: (errMsg === 'Fingerprint machine not connected' || !student.realTimeBase64 || student.realTimeBase64 == '') ? "0.5" : "unset" }}>
                                                                    <div className={cssClasses.button} onClick={() => { captureFingertprint('fingerprint1Base64', 'fingerprintTemplate1'); setCounter(2) }}>
                                                                        <p className={cssClasses.btnText}>Capture</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    :
                                                    <div>
                                                        <p style={{ fontSize: "1.8rem", color: "green" }}>Completed</p>
                                                    </div>
                                                }
                                            </Card>
                                        </div>
                                        <div className={cssClasses.halfDiv1}>
                                            <Card id='3' tabIndex={-1} className={cssClasses.captureCard} style={{ outline: counter === 2 ? '2px solid black' : 'none' }}>
                                                {!hideFingerprint ?
                                                    <div className={cssClasses.captureCard}>
                                                        {student && (student.fingerprint2Base64 && student.fingerprint2Base64 !== '') &&
                                                            <img className={cssClasses.fingerprintImg} src={student.fingerprint2Base64} alt="" />
                                                        }
                                                        {student && (!student.fingerprint2Base64 || student.fingerprint2Base64 === '') &&
                                                            <img className={cssClasses.fingerprintImg} src={biometric} alt="" />
                                                        }
                                                        {(student && (student.fingerprint2Base64 && student.fingerprint2Base64 !== '')) &&
                                                            <div>
                                                                <div className={cssClasses.buttonWrapper} style={{ pointerEvents: (errMsg === 'Fingerprint machine not connected' || !failedMatch) ? "none" : "unset", opacity: (errMsg === 'Fingerprint machine not connected' || !failedMatch) ? "0.5" : "unset" }}>
                                                                    <div className={cssClasses.button} onClick={() => { captureFingertprint('fingerprint2Base64', 'fingerprintTemplate2'); document.getElementById('3').focus() }}>
                                                                        <p className={cssClasses.btnText}>Recapture</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {(student && (!student.fingerprint2Base64 || student.fingerprint2Base64 === '')) &&
                                                            <div>
                                                                <div className={cssClasses.buttonWrapper} style={{ pointerEvents: (errMsg === 'Fingerprint machine not connected' || !student.fingerprint1Base64 || student.fingerprint1Base64 == '') ? "none" : "unset", opacity: (errMsg === 'Fingerprint machine not connected' || !student.fingerprint1Base64 || student.fingerprint1Base64 == '') ? "0.5" : "unset" }}>
                                                                    <div className={cssClasses.button} onClick={() => { captureFingertprint('fingerprint2Base64', 'fingerprintTemplate2'); document.getElementById('3').focus() }}>
                                                                        <p className={cssClasses.btnText}>Capture</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    :
                                                    <div>
                                                        <p style={{ fontSize: "1.8rem", color: "green" }}>Completed</p>
                                                    </div>
                                                }
                                            </Card>
                                        </div>
                                        <div className={cssClasses.halfDiv1}>
                                            <Card id='4' tabIndex={-1} className={cssClasses.captureCard} style={{ outline: counter === 3 ? '2px solid black' : 'none' }}>
                                                {student && (matchedFingerprint && matchedFingerprint !== '') &&
                                                    <img className={cssClasses.fingerprintImg} src={matchedFingerprint} alt="" />
                                                }
                                                {student && (!matchedFingerprint || matchedFingerprint === '') &&
                                                    <img className={cssClasses.fingerprintImg} src={biometric} alt="" />
                                                }
                                                {/* {(student && (matchedFingerprint && matchedFingerprint !== '')) &&
                                            <div>
                                                <div className={cssClasses.buttonWrapper}>
                                                    <div className={cssClasses.button} onClick={() => verifyFingerprint()}>
                                                        <p className={cssClasses.btnText}>Re-match</p>
                                                    </div>
                                                </div>
                                            </div>

                                        } */}
                                                {(student && (!matchedFingerprint || matchedFingerprint === '')) &&
                                                    <div>
                                                        <div className={cssClasses.buttonWrapper} style={{ pointerEvents: (errMsg === 'Fingerprint machine not connected' || (!student?.fingerprint1Base64 && !student?.fingerprint2Base64)) ? "none" : "unset", opacity: (errMsg === 'Fingerprint machine not connected' || (!student?.fingerprint1Base64 && !student?.fingerprint2Base64)) ? "0.5" : "unset" }}>
                                                            <div className={cssClasses.button} onClick={() => { verifyFingerprint(); document.getElementById('4').focus() }}>
                                                                <p className={cssClasses.btnText}>Match</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        {/* } */}
                        {/* {
                            subExam?.isStoreIdentityDocument && */}
                        {/* <Card id='5' tabIndex={-1} style={{ padding: "1rem", borderRadius: "1rem", marginBottom: "1rem", background: "#fcfcfd", outline: counter === 4 ? "2px solid black" : 'none' }}>
                            {/* <div className={cssClasses.cardTitle}>
                                <p>Documentation</p>
                            </div> */}
                        {/* <div style={{ display: "flex", fontSize: "1.4rem", justifyContent: "space-around" }}>
                                <div>
                                    <p>Capture Document</p>
                                </div>
                            </div> */}
                        {/* <div className={cssClasses.cardInfo}>
                                <div style={{ width: "100%", display: "flex", alignItems: "center" }}> */}
                        {/* {studentIdArr.length === 0 ? */}
                        {/* <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                                        <div style={{ fontSize: "1.4rem", textAlign: "center" }}>
                                            <p>Previous Exam Documents</p>
                                        </div>
                                        <div style={{ textAlign: "center", fontSize: "2rem" }}>
                                            <div>
                                                <p>No Data Available</p>
                                            </div>
                                        </div>
                                    </div> */}
                        {/* } */}
                        {/* <div className={cssClasses.halfDiv1}>
                                        <Card className={cssClasses.captureCard}> */}
                        {/* {student && (student.idCardBase64 && student.idCardBase64 !== '') && */}
                        {/* <div style={{ position: "relative", display: (student.idCardBase64 && student.idCardBase64 !== '') ? "unset" : "none" }}>
                                                <img className={cssClasses.capturedImg} src={student?.idCardBase64} alt="" />
                                                <IconButton className={cssClasses.cameraIcon} aria-label="delete" size="small" onClick={() => { captureStudentDocument(); document.getElementById('5').focus() }}>
                                                    <FlipCameraAndroidIcon style={{ color: "rgba(0,0,0,0.5)", width: "3rem", height: "3rem" }} />
                                                </IconButton>
                                            </div> */}
                        {/* } */}
                        {/* {student && (!student.idCardBase64 || student.idCardBase64 === '') && */}
                        {/* <div style={{ position: "relative", display: (!student.idCardBase64 || student.idCardBase64 === '') ? "unset" : "none" }}>
                                                <Webcam
                                                    audio={false}
                                                    ref={docRef}
                                                    screenshotFormat="image/jpeg"
                                                    className={cssClasses.videoCamera}
                                                    onUserMediaError={cameraError}
                                                    onUserMedia={cameraFound}
                                                    videoConstraints={{ deviceId: deviceId }}
                                                />
                                                <IconButton className={cssClasses.cameraIcon} aria-label="delete" size="small" onClick={() => { captureStudentDocument(); document.getElementById('5').focus() }}>
                                                    <CameraIcon style={{ color: "rgba(0,0,0,0.5)", width: "3rem", height: "3rem" }} />
                                                </IconButton>
                                            </div> */}
                        {/* } */}
                        {/* {(student && (student.idCardBase64 && student.idCardBase64 !== '')) &&
                                            <div>
                                                <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Recapture"} clicked={captureStudentDocument} />
                                            </div>
                                        }
                                        {(student && (!student.idCardBase64 || student.idCardBase64 == '')) &&
                                            <div>
                                                <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Capture"} clicked={captureStudentDocument} />
                                            </div>
                                        } */}
                        {/* </Card> */}
                        {/* </div>
                                </div>
                            </div> */}
                        {/* </Card> */}
                        {/* } */}
                    </div >
                </div >
                <div className={cssClasses.footer}>
                    <Box style={{ width: "60%" }}>
                        {/* {successMsg1 && matchedFingerprint !== '' &&
                        <p style={{ fontSize: "3rem", color: "red", textAlign: "center" }}>Click ENTER TAB to Register -----{`>`}</p>
                    } */}
                    </Box>
                    {student.realTimeBase64 && student.realTimeBase64 !== '' && student.fingerprint1Base64 && student.fingerprint1Base64 !== '' && student.fingerprint2Base64 && student.fingerprint2Base64 !== '' && student.idCardBase64 && student.idCardBase64 !== '' &&
                        < div >
                            <Button id={'5'} tabIndex={-1} style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Complete Testing"} clicked={() => { handleClose() }} />
                        </div>
                    }
                    <div>
                        <Button id={'6'} tabIndex={-1} style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Cancel"} clicked={() => { handleClose() }} />
                    </div>
                    {successMsg1 !== '' &&
                        <div>
                            <Button id={'6'} tabIndex={-1} style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"OK"} clicked={() => { handleClose() }} />
                        </div>
                    }
                </div>
            </div>
        </Modal >
    )
}

function useKeyPress(targetKey) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(0);
    // If pressed key is our target key then set to true
    function downHandler(e) {
        console.log("Event time -------->", new Date(Date(e.timestamp)).getTime())
        // setEventTime(new Date(Date(e.timestamp)).getTime())
        if (e.key === targetKey) {
            setKeyPressed(new Date(Date(e.timestamp)).getTime());
            // setTimeout(() => {
            //     setKeyPressed(new Date(Date(e.timestamp)).getTime());
            // }, 500)
        }
    }
    // If released key is our target key then set to false
    // const upHandler = ({ key }) => {
    //     if (key === targetKey) {
    //         setKeyPressed(false);
    //     }
    // };
    // Add event listeners
    useEffect(() => {
        document.addEventListener("keyup", downHandler);
        // document.addEventListener("keyup", upHandler);
        // Remove event listeners on cleanup
        return () => {
            document.removeEventListener("keyup", downHandler);
            // document.removeEventListener("keyup", upHandler);
        };
    }, []); // Empty array ensures that effect is only run on mount and unmount
    return keyPressed;
}