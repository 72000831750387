/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { dismissLoader, presentLoader } from "../../services/loaderService";

//* images
import addIcon from "../../assets/images/add icon.svg";
import filterIcon from "../../assets/images/filter-icon.svg";
import crossArrow from "../../assets/images/cross arrow.svg";

//* import sub-component
import Button from "../../Subcomponent/Button";
import SearchBar from "../../Subcomponent/SearchBar";

//* Import css
import cssClasses from "./ExamCenter.module.css";

//* import MUI
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DataGrid } from '@mui/x-data-grid';
import { getAllExamCenters, updateExamCenter } from "../../services/ExamService";
import { useParams } from "react-router-dom";

import { DB } from "../../db"
import { errorToast, successToast } from "../../services/Toast";
import { useDispatch, useSelector } from "react-redux";
import actions from '../../Redux Store/actions'
import CreateModel from "../../Modals/CreateModel/CreateModel";

const toggleButtonStyle = {
  color: "#344054",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  textTransform: "unset",
  "&.Mui-selected": {
    background: "#F2F4F7 !important",
    color: "#1D2939 !important",
  }
};
const dataGridStyles = {
  fontWeight: "400",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  color: "#667085",
  fontFamily: 'Inter-Medium',

  '& div[data-colindex="1"]': {
    color: "#101828",
    fontWeight: "500",
  },
  '& 	.MuiDataGrid-columnHeaders': {
    background: "#F9FAFB",
  },
  '& .MuiDataGrid-columnSeparator': {
    display: "none"
  },
  "& .MuiDataGrid-checkboxInput": {
    "& svg": {
      width: "2rem",
      height: "2rem",
      background: "#FFFFFF",
    }
  },
  "& .MuiTablePagination-displayedRows": {
    display: "none"
  },
  "& [title='Go to previous page']:after": {
    content: "'Previous'",
    marginLeft: "1rem",
  },
  "& [title='Go to next page']:before": {
    content: "'Next'",
    marginRight: "1rem",
  },
  "& .MuiButtonBase-root": {
    borderRadius: '0'
  }
}

const { ipcRenderer } = window.location.hostname === '' ? window.require('electron') : { ipcRenderer: null }

export default function ExamCenter() {
  let params = useParams();
  let examShortName = params.examShortName;
  let subExamName = params.subExamName;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginUser = useSelector((state) => state.Auth.user);
  const [modelUrl, setModelUrl] = useState([])
  const [firstProcessing, setFirstProcessing] = useState(0)
  const [secondProcessing, setSecondProcessing] = useState(0)
  const [createModelOpen, setCreateModelOpen] = useState(false)
  const [createModelStepper, setCreateModelStepper] = useState(0)
  const columns = [
    { field: 'examCenterName', headerName: 'Exam Center Name', sortable: false, width: 250 },
    { field: 'City', headerName: 'City', sortable: false, width: 150 },
    { field: 'examCenterCode', headerName: 'Exam Center Code', sortable: false, width: 200 },
    { field: 'capacity', headerName: 'Capacity', sortable: false, width: 100 },
    // { field: 'examCenterLocation', headerName: 'Exam Center Location', sortable: false, width: 175 },
    // { field: 'examDate', headerName: 'Exam Date', sortable: false, width: 150 },
    // { field: 'id', headerName: '', sortable: false, width: 70 },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          let previousCenter = JSON.parse(localStorage.getItem('examCenter'))
          // if (previousCenter && params.row.id !== previousCenter?.id) {
          //   console.log("changed")
          //   dispatch(actions.FaceNet(null));
          // }
          localStorage.setItem("examCenter", JSON.stringify(params.row));
          navigate('/home/student-verify/' + examShortName + '/allSubExams/' + subExamName + '/allExamCenters/' + params.row.examCenterName + '/allStudents')
        };
        const createModel = (e) => {
          const subExam = JSON.parse(localStorage.getItem('subExam'))
          let ec = DB.examCenters.get(params.row.id)
          console.log(ec)
          // DB.students.where({ examID: examId, examCenterName: params.row.examCenterName }).toArray().then((resp) => {
          //   console.log(resp)
          //   let test = []
          //   resp.forEach(element => {
          //     if (element.realTimeBase64 && element.realTimeBase64 != '') {
          //       test.push({ id: element.id.split('_')[1], image: element.realTimeBase64.replace(/^data:image\/\w+;base64,/, "") })
          //     } else if (element.Photo64 && element.Photo64 !== '') {
          //       test.push({ id: element.id.split('_')[1], image: element.Photo64.replace(/^data:image\/\w+;base64,/, "") })
          //     }
          //   });
          //   console.log(test)
          //   presentLoader()
          //   axios.post('http://127.0.0.1:5000/createModel', test)
          //     .then(function (response) {
          //       console.log('It says: ', response.data);
          //       if (response.data.includes('Model creation started')) {
          //         successToast("Model Created successfully")
          //         dismissLoader()
          //       }
          //     })
          //     .catch(function (error) {
          //       console.log('error', error);
          //     });
          // })
        };

        return <div>
          <p onClick={onClick} style={{ color: "#6941C6", cursor: "pointer" }}>View Center</p>
          {/* {modelUrl && modelUrl == 'local' && !params.row.classNamesJson &&
            < p onClick={createModel} style={{ color: "#6941C6", cursor: "pointer" }}>Create Model</p>
          } */}
        </div >
      },
    },
    {
      field: 'create',
      headerName: 'Create',
      sortable: false,
      hide: (JSON.parse(localStorage.getItem('user')).isModelAccess) ? false : true,
      width: 150,
      renderCell: (params) => {
        const onClick = (e) => {
          const subExam = JSON.parse(localStorage.getItem('subExam'))
          DB.students.where({ examID: examId, examCenterName: params.row.examCenterName }).toArray().then((resp) => {
            console.log(resp)
            let test = []
            resp.forEach(element => {
              if (element.realTimeBase64 && element.realTimeBase64 != '') {
                test.push({ id: element.id.split('_')[1], image: element.realTimeBase64.replace(/^data:image\/\w+;base64,/, "") })
              } else if (element.Photo64 && element.Photo64 !== '') {
                test.push({ id: element.id.split('_')[1], image: element.Photo64.replace(/^data:image\/\w+;base64,/, "") })
              }
            });
            console.log(test)
            if (test.length > 0) {
              setCreateModelOpen(true)
              axios.get('http://127.0.0.1:5000/checkIfRunning').then((resp) => {
                axios.post(`http://127.0.0.1:5000/createModel?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${params.row.id}`, test)
                  .then(function (response) {
                    console.log('It says: ', response.data);
                    if (response.data.includes('Model creation started')) {
                      successToast("Model Created successfully")
                      DB.modelUrl.add({ id: params.row.id, modelUrl: "local" })
                      setModelUrl([])
                      dispatch(actions.FaceNet({ [params.row.id]: null }));
                      setCreateModelOpen(false)
                      setFirstProcessing(0)
                      setSecondProcessing(0)
                    } else if (response.data.includes("failed")) {
                      errorToast("Model Creation failed")
                      setCreateModelOpen(false)
                      setFirstProcessing(0)
                      setSecondProcessing(0)
                    }
                  })
                  .catch(function (error) {
                    console.log('error', error);
                    errorToast("Model Creation failed")
                    setCreateModelOpen(false)
                    setFirstProcessing(0)
                    setSecondProcessing(0)
                  });
                let interval = setInterval(() => {
                  axios.get('http://127.0.0.1:5000/checkModelCreationProgress').then((resp) => {
                    if (resp.data.message === 'Analyzing Images') {
                      setCreateModelStepper(0)
                      setFirstProcessing(Math.floor((resp.data.countProgress / test.length) * 100))
                    } else if (resp.data.message === 'Creating Model') {
                      setCreateModelStepper(1)
                      setSecondProcessing(Math.floor((resp.data.countProgress / resp.data.total) * 100))
                    } else if (resp.data.message === 'Saving Model') {
                      setCreateModelStepper(2)
                    } else {
                      clearInterval(interval)
                    }
                  })
                }, 20000);
              }).catch((err) => {
                ipcRenderer.send("startPranilExe", "");
                ipcRenderer.on("exeSuccessful", (event, arg) => {
                  console.log("exeSuccessful", arg)
                  if (arg == 'done') {
                    axios.post(`http://127.0.0.1:5000/createModel?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${params.row.id}`, test)
                      .then(function (response) {
                        console.log('It says: ', response.data);
                        if (response.data.includes('Model creation started')) {
                          successToast("Model Created successfully")
                          DB.modelUrl.add({ id: params.row.id, modelUrl: "local" })
                          setModelUrl([])
                          dispatch(actions.FaceNet({ [params.row.id]: null }));
                          setCreateModelOpen(false)
                          setFirstProcessing(0)
                          setSecondProcessing(0)
                        } else if (response.data.includes("failed")) {
                          errorToast("Model Creation failed")
                          setCreateModelOpen(false)
                          setFirstProcessing(0)
                          setSecondProcessing(0)
                        }
                      })
                      .catch(function (error) {
                        console.log('error', error);
                        errorToast("Model Creation failed")
                        setCreateModelOpen(false)
                        setFirstProcessing(0)
                        setSecondProcessing(0)
                      });
                    let interval = setInterval(() => {
                      axios.get('http://127.0.0.1:5000/checkModelCreationProgress').then((resp) => {
                        if (resp.data.message === 'Analyzing Images') {
                          setCreateModelStepper(0)
                          setFirstProcessing(Math.floor((resp.data.countProgress / test.length) * 100))
                        } else if (resp.data.message === 'Creating Model') {
                          setCreateModelStepper(1)
                          setSecondProcessing(Math.floor((resp.data.countProgress / resp.data.total) * 100))
                        } else if (resp.data.message === 'Saving Model') {
                          setCreateModelStepper(2)
                        } else {
                          clearInterval(interval)
                        }
                      })
                    }, 20000);
                  }
                })
              })
            } else {
              errorToast("No Photos Available for model creation")
            }
          })
        };
        return <p onClick={onClick} style={{ color: "#6941C6", cursor: "pointer" }}>{modelUrl?.find((f) => { return f.id === params.row.id })?.modelUrl === 'local' ? "Recreate Model" : "Create Model"}</p>;
      },
    },
    {
      field: 'Upload',
      headerName: 'Upload',
      sortable: false,
      hide: (JSON.parse(localStorage.getItem('user')).isModelAccess) ? false : true,
      width: 150,
      renderCell: (params) => {
        const onClick = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          if (window.navigator.onLine) {
            let _modelUrl = await DB.modelUrl.get(params.row.id)
            if (!_modelUrl || _modelUrl.modelUrl === 'local') {
              presentLoader()
              axios.get('http://127.0.0.1:5000/checkIfRunning').then((resp) => {
                axios.post("http://127.0.0.1:5000/uploadModel", {
                  "examId": JSON.parse(localStorage.getItem('exam')).id,
                  "testCenterId": params.row.id
                }).then((resp) => {
                  console.log(resp)
                  resp.data.modelStatus = 'model generated'
                  updateExamCenter(JSON.parse(localStorage.getItem('exam')).id, params.row.id, resp.data).then((res) => {
                    dismissLoader()
                    successToast("Model Uploaded successfully")
                  }).catch((er) => {
                    dismissLoader()
                    errorToast("Something went wrong please try again later")
                    console.log(er)
                  })
                }).catch((err) => {
                  dismissLoader()
                  console.log(err)
                  errorToast("Something went wrong please try again later")
                })
              }).catch((err) => {
                ipcRenderer.send("startPranilExe", "");
                ipcRenderer.on("exeSuccessful", (event, arg) => {
                  console.log("exeSuccessful", arg)
                  if (arg == 'done') {
                    axios.post("http://127.0.0.1:5000/uploadModel", {
                      "examId": JSON.parse(localStorage.getItem('exam')).id,
                      "testCenterId": params.row.id
                    }).then((resp) => {
                      console.log(resp)
                      resp.data.modelStatus = 'model generated'
                      updateExamCenter(JSON.parse(localStorage.getItem('exam')).id, params.row.id, resp.data).then((res) => {
                        dismissLoader()
                        successToast("Model Uploaded successfully")
                      }).catch((er) => {
                        dismissLoader()
                        errorToast("Something went wrong please try again later")
                        console.log(er)
                      })
                    }).catch((err) => {
                      dismissLoader()
                      console.log(err)
                      errorToast("Something went wrong please try again later")
                    })
                  }
                })
              })
            } else {
              errorToast("Model not generated for this exam center")
            }
          } else {
            errorToast("Internet connection required to upload model")
          }
        };

        return <div>
          <p onClick={onClick} style={{ color: "#6941C6", cursor: "pointer" }}>Upload Model</p>
          {/* {modelUrl && modelUrl == 'local' && !params.row.classNamesJson &&
            < p onClick={createModel} style={{ color: "#6941C6", cursor: "pointer" }}>Create Model</p>
          } */}
        </div >
      },
    },
  ]
  const [examCenterList, setExamCenterList] = useState([]);
  const [examId, setExamId] = useState(null)

  useEffect(async () => {
    if (modelUrl.length === 0) {
      let modelUrl = await DB.modelUrl.toArray()
      setModelUrl(modelUrl)
    }
  }, [modelUrl])


  useEffect(() => {
    if (loginUser) {
      let _exam = JSON.parse(localStorage.getItem("exam"));
      if (_exam) {
        setExamId(_exam.id);
      } else {
        errorToast("Please Select Exam.");
        navigate("/home/student-verify");
        return;
      }
      if (loginUser?.examAccess?.find(f => f?.value === _exam?.id)?.examCenterList?.filter(f => f.isAccess).length > 0) {
        DB.allExamCenters.where({ examDocId: _exam?.id }).toArray((resp) => {
          console.log(loginUser?.examAccess?.find(f => f?.value === _exam?.id))
          resp.forEach((e) => { e.id = e.id.split('_')[1] })
          DB.examCenters.clear().then(clearRes => {
            DB.examCenters.bulkAdd(resp);
            let _examCenterList = resp.filter(f => loginUser?.examAccess?.find(f => f?.value === _exam?.id)?.examCenterList.filter(f => f.isAccess).some(s => s.value === f.id));
            setExamCenterList([..._examCenterList]);
          }).catch((err) => {
            DB.examCenters.bulkAdd(resp);
            let _examCenterList = resp.filter(f => loginUser?.examAccess?.find(f => f?.value === _exam?.id)?.examCenterList.filter(f => f.isAccess).some(s => s.value === f.id));
            setExamCenterList([..._examCenterList]);
          })
        })
        // getAllExamCenters(_exam?.id).then((res) => {
        //   DB.examCenters.clear().then(clearRes => {
        //     DB.examCenters.bulkAdd(res.docs.map(m => ({ ...m.data(), id: m.id })));
        //     let _examCenterList = res.docs.map(m => ({ ...m.data(), id: m.id })).filter(f => loginUser?.examAccess?.find(f => f?.value === _exam?.id)?.examCenterList.filter(f => f.isAccess).some(s => s.value === f.id));
        //     setExamCenterList([..._examCenterList]);
        //   })
        // }).catch(err => {
        //   console.log("err", err);
        //   errorToast("Something went wrong,Please try again later");
        // })
      } else {
        errorToast("You don't have access to any exam centers!");
      }
    }
  }, [loginUser])

  const SearchHandler = (_value) => {
    console.log(_value);
  }

  function generateModels() {
    presentLoader()
    axios.post("http://192.168.2.62:5000/createModel", { "examId": examId }).then((resp) => {
      if (resp) {
        console.log(resp)
        dismissLoader()
      }
    })
  }

  return (
    <>
      <Box className={cssClasses.mainTabPanelWrapper}>
        <div className={cssClasses.mainTabPanelHeader}>
          <div>
            <p>Exam Centers</p>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
          </div>
          {/* <div>
            <div><Button  style={{ color: "#344054", backgroundColor: "#FFFFFF" }} btnIcon={crossArrow} btnName={"Move\xa0Students"} clicked={() => {setShiftStudentsModalToggle(true);}}/></div>
            <div><Button  style={{ color: "#FFFFFF", backgroundColor: "#7F56D9" }} btnIcon={addIcon} btnName={"Add\xa0Exam\xa0Center"}  clicked={() => {setAddExamCenterModalToggle(true);}}/></div>
          </div> */}
        </div>

        <div className={cssClasses.searchAndFilterWrapper}>
          {/* <div>
            <ToggleButtonGroup
              color="primary"
              value={'View All'}
              exclusive
              style={{filter: "drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))"}}
              // onChange={handleChange}
            >
              <ToggleButton value="View All" sx={[toggleButtonStyle]}>View All</ToggleButton>
              <ToggleButton value="By Exam date" sx={[toggleButtonStyle]}>By Exam date</ToggleButton>
              <ToggleButton value="By etc" sx={[toggleButtonStyle]}>By etc</ToggleButton>
            </ToggleButtonGroup>
          </div> */}
          {/* <div>
            <div><SearchBar placeholder={"Search"} search={SearchHandler} /></div>
            <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Generate Models"} clicked={generateModels} /></div>
          </div> */}
        </div>

        <div className={cssClasses.examCenterWrapper}>
          <DataGrid
            rows={examCenterList}
            columns={columns}
            hideFooterPagination={false}
            hideFooter={false}
            rowsPerPageOptions={[10]}
            pageSize={10}
            // checkboxSelection
            sx={[dataGridStyles]}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
            disableVirtualization
          />
        </div>

      </Box>

      {/* * for Exam Center Add */}
      {/* <AddExamCenter open={addExamCenterModalToggle} handleClose={() => {setAddExamCenterModalToggle(false);}} /> */}
      <CreateModel isOpenModal={createModelOpen} StepperValue={createModelStepper} firstProcessing={firstProcessing} secondProcessing={secondProcessing} handleClose={() => { setCreateModelOpen(false); }} />

      {/* <ShiftStudentsModal open={shiftStudentsModalToggle} handleClose={() => {setShiftStudentsModalToggle(false)}}/> */}
    </>
  )
}
