/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid'
//* images
import filterIcon from "../../assets/images/filter-icon.svg";
import moreVertical from "../../assets/images/more-vertical.svg";
import man from "../../assets/images/man.png";
import woman from "../../assets/images/woman.png";
import axios from 'axios'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ConstructionIcon from '@mui/icons-material/Construction';
import { findIndex, uniqBy } from 'lodash'
import SyncIcon from '@mui/icons-material/Sync';
import html2pdf from "html2pdf.js";

//* css
import cssClasses from "./Applicants.module.css";
import * as XLSX from 'xlsx'
//* import MUI
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Facenet } from '../../Facenet/Facenet'; //get Facenet.js
import { IconButton } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

//* import Sub-components
import Button from "../../Subcomponent/Button";
import SearchBar from "../../Subcomponent/SearchBar";
import { Workbook } from 'exceljs';
import * as fileSaver from 'file-saver';

//* import Services
import { getStudents, SetStudentToLocal, getAllStudents, getAllStudentsFromMainCollection, getStudentByQr, updateStudentData, addStudent, setStudent } from "../../services/StudentService";
import { getSelectedSubExam, getSubExamByDocID } from "../../services/ExamService";
import { getALLFilesFromAWS, uploadFilesAWS } from '../../services/AwsService'
import { errorToast, successToast } from "../../services/Toast";
import { dismissLoader, presentLoader } from "../../services/loaderService";
import { WriteFile, readFile } from '../../services/androidImports'
import actions from '../../Redux Store/actions'
import { setAdminLog } from "../../services/admin";
import { DB } from "../../db"

//* import Modal
import QrAndBarcodeScanner from "../../Modals/Scanner/QrAndBarcodeScanner";
import ProgressImagesUpload from "../../Modals/ProgressImagesUpload/ProgressImagesUpload";
import StudentPhotos from "../../Modals/StudentPhotos/StudentPhotos";
import DownloadStudentProgress from "../../Modals/DownloadStudentProgress/DownloadStudentProgress";
import DataMergeModal from "../../Modals/DataMergeModal/DataMergeModal";

import { Buffer } from 'buffer';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { offlineReportPdf } from "../../services/offlineReport";
import { PDFDocument, rgb } from "pdf-lib";
import { downloadReportPdf } from "../../services/downloadReport";

// var Minizip = require('../../services/miniZip.min.js');

var Minizip = require('minizip-asm.js');

const menuItem = {
  fontSize: "1.4rem",
  ".MuiSvgIcon-root": {
    width: '2rem',
    height: '2rem',
    marginRight: "1rem",
    color: '#6941C6'
  }
}

const toggleButtonStyle = {
  color: "#344054",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  textTransform: "unset",
  "&.Mui-selected": {
    background: "#F2F4F7 !important",
    color: "#1D2939 !important",
  }
};
const dataGridStyles = {
  fontWeight: "400",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  color: "#667085",
  fontFamily: 'Inter-Medium',

  '& div[data-colindex="1"]': {
    color: "#101828",
    fontWeight: "500",
  },
  '& 	.MuiDataGrid-columnHeaders': {
    background: "#F9FAFB",
  },
  '& .MuiDataGrid-columnSeparator': {
    display: "none"
  },
  "& .MuiDataGrid-checkboxInput": {
    "& svg": {
      width: "2rem",
      height: "2rem",
      background: "#FFFFFF",
    }
  },
  "& .MuiTablePagination-displayedRows": {
    display: "none"
  },
  "& [title='Go to previous page']:after": {
    content: "'Previous'",
    marginLeft: "1rem",
    paddingRight: '1rem'
  },
  "& [title='Go to next page']:before": {
    content: "'Next'",
    marginRight: "1rem",
    paddingRight: '1rem'
  },
  "& .MuiButtonBase-root": {
    borderRadius: '0'
  },
  "& .MuiDataGrid-iconButtonContainer": {
    visibility: "hidden !important"
  },
  "&  .MuiDataGrid-row ": {
    maxHeight: "7.2rem !important",
    minHeight: "7.2rem !important",
  },
  "& .MuiDataGrid-cell": {
    padding: "1.6rem",
    minHeight: "7.2rem !important",
    maxHeight: "7.2rem !important",
    outline: 'none !important',
  },
  "& .MuiButtonBase-root": {
    background: '#FFFFFF',
    border: '1px solid #D0D5DD',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    margin: '0 1rem'
  },
  "& .MuiTablePagination-actions": {
    margin: '0 2rem',
  }
}

const { ipcRenderer } = window.location.hostname === '' ? window.require('electron') : { ipcRenderer: null }


export default function Applicants({ examShortName, examCenterName }) {
  const params = useParams()
  const subExamName = params.subExamName
  const navigate = useNavigate();
  const loginUser = useSelector((state) => state.Auth.user);

  const [applicantsList, setApplicantsList] = useState([]);
  const [serverMetaDataList, setServerMetaDataList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageMaintain, setPageMaintain] = useState(0);
  const [studentSearchValue, setStudentSearchValue] = useState("");
  const [examCenterDetails, setExamCenterDetails] = useState(null)
  const [dataGridLoading, setDataGridLoading] = useState(true);
  const [searchField, setSearchField] = useState("Applicant");
  const [selectedStudent, setSelectedStudent] = useState({});
  const [faceRecognitionModalToggle, setFaceRecognitionModalToggle] = useState(false);
  const [examDetails, setExamDetails] = useState(null)
  const [scannerToggle, setScannerToggle] = useState(false);
  const [openModelProgress, setOpenModelProgress] = useState(false);
  const [modelProgress, setModelProgress] = useState(0);
  const [openPhotosModal, setOpenPhotosModal] = useState(false);
  const [studentsFromMainCollection, setStudentsFromMainCollection] = useState([])
  const [capturePhotoModalToggle, setCapturePhotoModalToggle] = useState(false)
  const [currentStudentId, setCurrentStudentId] = useState(null)
  const [subExamId, setSubExamId] = useState(null)
  const [searchPrefix, setSearchPrefix] = useState('')
  const [isProforma, setIsProforma] = useState(false)
  const dispatch = useDispatch();

  const [openUploadProgress, setOpenUploadProgress] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0);

  const [downloadStudentProgressModalToggle, setDownloadStudentProgressModalToggle] = useState(false);
  const [downloadStudentProgressValue, setDownloadStudentProgressValue] = useState(0);
  const [photosStatus, setPhotosStatus] = useState(false);
  const [fingerprintPhotosStatus, setFingerprintPhotosStatus] = useState(false);
  const [docsPhotosStatus, setDocsPhotosStatus] = useState(false);
  const [base64Status, setBase64Status] = useState(false);
  const [addStudentInLocalDb, setAddStudentInLocalDb] = useState(false)
  const [menuOpen, setMenuOpen] = useState(null)
  const [dataMergeModalToggle, setDataMergeModalToggle] = useState(false)
  const [verifiedStudentsCount, setVerifiedStudentsCount] = useState(0)
  const [totalStudentsCount, setTotalStudentsCount] = useState(0)
  const [submittedStudentsCount, setSubmittedStudentsCount] = useState(0)
  const [uploadedStudentsCount, setUploadedStudentsCount] = useState(0)
  const [subExamDetails, setSubExamDetails] = useState({})
  const [errMsg, setErrMsg] = useState("")
  const [studentsToUpload, setStudentsToUpload] = useState([])
  const open = Boolean(menuOpen);
  const handleClick = (event) => {
    setMenuOpen(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuOpen(null);
  };

  let facenetObj = useSelector((state) => {
    return state.faceNet
  });

  const applicantColumns = [
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 180,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );
          if (isProforma)
            return onClickViewCenter(thisRow);
          else
            return onClickViewPhotos(thisRow);
        };

        return (
          <>
            {
              isProforma && !params.row.isSubmitted && <p onClick={onClick} style={{ color: "#6941C6", cursor: "pointer" }}>
                View Profile
              </p>
            }
            {
              !isProforma && <p onClick={onClick} style={{ color: "#6941C6", cursor: "pointer" }}>
                View Photos
              </p>
            }
          </>
        );
      },
    },
    // {
    //   field: '', headerName: 'Capture Photo', width: 150, sortable: false,
    //   renderCell: (params) => {
    //     function openCaptureModal() {
    //       setCapturePhotoModalToggle(true)
    //       setCurrentStudentId(params.row)
    //     }
    //     return <p onClick={openCaptureModal} style={{ color: "#6941C6", cursor: "pointer" }}>
    //       Capture Photo
    //     </p>
    //   }
    // },
    {
      field: 'fileName', headerName: 'Student Name', width: 275, sortable: false,
      renderCell: (params) => {
        return (
          <div className={cssClasses.imageCellWrapper}>
            <img src={(params.row?.Photo64 && params.row?.Photo64 !== '') ? params.row.Photo64 : params.value.img} alt="" onError={(e) => { e.target.onerror = null; e.target.src = params.value.onErrorImg; }} />
            <div>
              <p>{params.value.name}</p>
              <p>{params.value.email}</p>
            </div>
          </div>
        )
      }
    },
    { field: 'Gender', headerName: 'Gender', sortable: false, width: 200 },
    { field: 'City', headerName: 'City', sortable: false, width: 200 },
    {
      field: 'isVerified',
      headerName: 'Verification Status',
      width: 500,
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api = params.api;
          const thisRow = {};

          api.getAllColumns().filter((c) => c.field !== '__check__' && !!c).forEach(
            (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
          );
          return onClickDoneApplicant(thisRow);
        };

        return <p onClick={onClick} className={params.value == true ? cssClasses.doneButton : cssClasses.verifyButton}>{params.value == true ? "Verified" : "Not Verified"}</p>;
      },
    },
  ];

  function getCount(students) {
    if (students.length > 0) {
      setVerifiedStudentsCount(students.filter(function (friend) { return friend.isVerified === true }).length)
      // students.filter(function (friend) { return friend.examID === examDetails.id && friend.subExamID === subExamDetails.id && friend.examCenterCity === examCenterDetails.City }).toArray().then((res) => {
      setTotalStudentsCount(students.length)
      // })
      setSubmittedStudentsCount(students.filter(function (friend) { return friend.isSubmitted }).length)
      getUploadedCount()
    }
  }

  async function getUploadedCount() {
    let arr = await DB.students.where({ subExamID: JSON.parse(localStorage.getItem('subExam')).id, isUploaded: "true" }).toArray()
    console.log(arr)
    if (arr.length > 0) {
      setUploadedStudentsCount(arr.length)
    }
  }


  const onClickDoneApplicant = (_applicant) => {
    console.log(_applicant);
    const _selectedStudent = serverMetaDataList.map(m => m.data()).find(f => f.Applicant === _applicant.studentID);
    console.log(_selectedStudent);
    setSelectedStudent(_selectedStudent);
    setFaceRecognitionModalToggle(true);
  }
  function onClickViewCenter(selectedStudent) {
    console.log('selectedStudent: ', selectedStudent);

    applicantsList.forEach((el) => {
      // console.log('el: ', el);
      if (el.id === selectedStudent.fileName.id) {
        // delete el.id;
        delete el.fileName;
        el.examDocId = examDetails.id;
        SetStudentToLocal(el);
      }
    });
    navigate('/home/student-verify/' + examShortName + '/allSubExams/' + getSelectedSubExam() + '/allExamCenters/' + examCenterName + '/details');
    // setStudentId(selectedStudent);
    // SetStudentToLocal();
  }
  function onClickViewPhotos(selectedStudent) {
    applicantsList.forEach((el) => {
      console.log('el: ', el);
      if (el.id === selectedStudent.fileName.id) {
        const student = { ...el };
        // delete student.id;
        delete student.fileName;
        SetStudentToLocal(student);
        setOpenPhotosModal(true);
      }
    });
  }
  function openScannerModal() {
    setScannerToggle(true);
  }

  function openFaceDetectionModal() {
    setFaceRecognitionModalToggle(true);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    getApplicants()
    return () => {
      applicantsList.forEach((ele, index) => {
        applicantsList[index] = null
        if (index === applicantsList.length - 1) {
          setApplicantsList(null)
        }
      })
      serverMetaDataList.forEach((ele, index) => {
        serverMetaDataList[index] = null
        if (index === serverMetaDataList.length - 1) {
          setServerMetaDataList(null)
        }
      })
      setExamCenterDetails(null)
      setStudentsFromMainCollection(null)
      setExamCenterDetails(null)
      setSelectedStudent(null)
      setExamDetails(null)
      setSubExamDetails(null)
      setSubExamId(null)
      setCurrentStudentId(null)
    }
  }, [])

  async function getApplicants() {
    let _exam = JSON.parse(localStorage.getItem("exam"));
    if (_exam) {
      setExamDetails(_exam);
    } else {
      errorToast("Please Select Exam.");
      navigate("/home/student-verify");
      return;
    }
    let _subExam = JSON.parse(localStorage.getItem("subExam"));
    if (_subExam) {
      setSubExamId(_subExam.id);
      setSubExamDetails(_subExam);
      setIsProforma(_subExam.isProforma);
    } else {
      errorToast("Please Select Exam.");
      navigate("/home/student-verify");
      return;
    }
    let _examCenter = JSON.parse(localStorage.getItem("examCenter"));
    if (_examCenter) {
      setExamCenterDetails(_examCenter);
    } else {
      errorToast("Please Select Exam.");
      navigate("/home/student-verify");
      return;
    }

    // ! where 
    // let _Students = await DB.students.toArray();
    // let _Students = await DB.students.where({ examID: _exam.id, subExamID: _subExam.id, examCenterCity: _examCenter.City }).toArray();
    let _Students = await DB.students.where({ examID: _exam.id, subExamID: _subExam.id, examCenterName: _examCenter.examCenterName }).toArray();
    _Students = _Students.map((m) => ({
      Applicant: m.Applicant,
      isVerified: m.isVerified,
      id: m.id,
      email: m.email,
      UID: m.UID,
      Status: m.Status,
      Photo64: m.Photo64,
      Gender: m.Gender,
      "Full Name": m["Full Name"],
      City: m.City,
    }))
    if (_Students.length === 0) {
      if (window.navigator.onLine) {
        DB.examCenters.filter(function (examCenters) { return examCenters.examCenterName === _examCenter.examCenterName }).toArray().then((examCentersRes) => {
          getDataFromServer(_subExam.id, searchField, null, studentSearchValue === "" ? undefined : searchPrefix + studentSearchValue, false, _exam.id, _examCenter.id, _subExam.examName, _examCenter.examCenterName, examCentersRes);
        })
      } else {
        cloneStudents()
      }
    } else {
      // getPhotoBase64(_Students.map(m=>m.id),_exam.id)
      getCount(_Students)
      setApplicantsList([...convertFirebaseObjToDataGridObj(_Students)]);
      setDataGridLoading(false);
    }
  }


  useEffect(() => {
    let path = ''
    if (examCenterDetails && ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id)) || (facenetObj && facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id) && !facenetObj[JSON.parse(localStorage.getItem('examCenter')).id]?.model))) {
      if (window.location.hostname === '') {
        ipcRenderer.send("getPath", "");
        ipcRenderer.on("path", (event, arg) => {
          console.log("path", arg)
          path = arg
          ipcRenderer.removeListener("path", () => { })
        })
      }
      let apiPath = ''
      let modelUrl = ''
      let embeddingsPath = ''
      DB.modelUrl.get(examCenterDetails.id).then((resp) => {
        if (resp && resp?.modelUrl == 'local') {
          axios.get('http://127.0.0.1:5000/checkIfRunning').then((resp) => {
            apiPath = `http://127.0.0.1:5000/getClassNames?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`
            embeddingsPath = `http://127.0.0.1:5000/getClassEmbeddings?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`
            modelUrl = `${path}models/${examDetails.id}/${examCenterDetails.id}/model.json`
            // setOpenModelProgress(true)
            axios.get(apiPath).then(async (response) => {
              axios.get(embeddingsPath).then(async (resp) => {
                console.log('get request:', response)
                let classNames = []
                let classEmbeddings = []
                if (apiPath == examCenterDetails.classNamesJson) {
                  classNames = response.data
                  classEmbeddings = resp.data
                } else {
                  classNames = response.data.classNames
                  classEmbeddings = resp.data.classEmbeddings
                }
                console.log(classNames)
                const facenet = new Facenet(classNames, classEmbeddings);
                DB.facenetArr.add({ id: examCenterDetails.id, classNamesJson: classNames, classEmbeddings: classEmbeddings })
                // DB.facenetArr.add({ id: examCenterDetails.id, data: classEmbeddings })
                const progressInterval = setInterval(() => {
                  const progress = facenet.getProgress();
                  console.log('progress: ', Math.floor(progress))
                  setModelProgress(Math.floor(progress));
                  if (progress === 100) {
                    clearInterval(progressInterval);
                    setOpenModelProgress(false);
                  }
                }, 1000);
                setOpenModelProgress(true);
                // if (examCenterDetails.modelUrl) {
                facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                  // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                  // setErrMsg('')
                  console.log('model loaded');
                  // setFaceNetObj(facenet)
                  // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                  dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                })
                // .catch((err) => {
                //   setErrMsg('Model Not generated')
                //   if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                //     const img = document.createElement("img");
                //     img.src = ''
                //     img.id = 'image'
                //     facenetObj = new Facenet('', '')
                //     facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                //     dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                //   }
                // })
              })
              // .catch((err) => {
              //   setErrMsg('Model Not generated')
              //   if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
              //     const img = document.createElement("img");
              //     img.src = ''
              //     img.id = 'image'
              //     facenetObj = new Facenet('', '')
              //     facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
              //     dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
              //   }
              // })
              // } else {
              //   errorToast('Model Not generated,Please generate model before face recognition')
              // }
            }).catch((err) => {
              apiPath = examCenterDetails.classNamesJson
              embeddingsPath = examCenterDetails.classEmbeddings
              modelUrl = examCenterDetails.modelUrl
              if (window.navigator.onLine) {
                axios.get(apiPath).then(async (response) => {
                  axios.get(embeddingsPath).then(async (resp) => {
                    console.log('get request:', response)
                    let classNames = []
                    let classEmbeddings = []
                    if (apiPath == examCenterDetails.classNamesJson) {
                      classNames = response.data
                      classEmbeddings = resp.data
                    } else {
                      classNames = response.data.classNames
                      classEmbeddings = resp.data.classEmbeddings
                    }
                    console.log(classNames)
                    const facenet = new Facenet(classNames, classEmbeddings);
                    DB.facenetArr.add({ id: examCenterDetails.id, classNamesJson: classNames, classEmbeddings: classEmbeddings })
                    // DB.facenetArr.add({ id: examCenterDetails.id, data: classEmbeddings })
                    const progressInterval = setInterval(() => {
                      const progress = facenet.getProgress();
                      console.log('progress: ', Math.floor(progress))
                      setModelProgress(Math.floor(progress));
                      if (progress === 100) {
                        clearInterval(progressInterval);
                        // setOpenModelProgress(false);
                      }
                    }, 1000);
                    setOpenModelProgress(true);
                    if (window.location.hostname === '') {
                      axios.get('http://127.0.0.1:5000/checkIfRunning').then((resp) => {
                        axios.post(`http://127.0.0.1:5000/downloadModelToLocalFileSystem?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`, { downloadFiles: examCenterDetails.downloadFiles }).then((re) => {
                          if (re.data === "Done") {
                            DB.modelUrl.add({ id: examCenterDetails.id, modelUrl: "local" })
                            facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                              // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                              // setErrMsg('')
                              setOpenModelProgress(false);
                              console.log('model loaded');
                              // setFaceNetObj(facenet)
                              // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                              dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                            }).catch((err) => {
                              // setErrMsg('Model Not generated')
                              if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                                setOpenModelProgress(false);
                                const img = document.createElement("img");
                                img.src = ''
                                img.id = 'image'
                                facenetObj = new Facenet('', '')
                                facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                                dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                              }
                            })
                          }
                        })
                      }).catch((err) => {
                        ipcRenderer.send("startPranilExe", "");
                        ipcRenderer.on("exeSuccessful", (event, arg) => {
                          console.log("exeSuccessful", arg)
                          if (arg == 'done') {
                            axios.post(`http://127.0.0.1:5000/downloadModelToLocalFileSystem?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`, { downloadFiles: examCenterDetails.downloadFiles }).then((re) => {
                              if (re.data === "Done") {
                                DB.modelUrl.add({ id: examCenterDetails.id, modelUrl: "local" })
                                facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                                  // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                                  // setErrMsg('')
                                  setOpenModelProgress(false);
                                  console.log('model loaded');
                                  // setFaceNetObj(facenet)
                                  // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                                  dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                                }).catch((err) => {
                                  // setErrMsg('Model Not generated')
                                  setOpenModelProgress(false);
                                  if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                                    const img = document.createElement("img");
                                    img.src = ''
                                    img.id = 'image'
                                    facenetObj = new Facenet('', '')
                                    facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                                    dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                                  }
                                })
                              }
                            })
                          }
                        })
                      })
                    } else {
                      facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                        // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                        // setErrMsg('')
                        setOpenModelProgress(false);
                        console.log('model loaded');
                        // setFaceNetObj(facenet)
                        // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                        dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                      }).catch((err) => {
                        setOpenModelProgress(false);
                        // errorToast("Models folder might have been deleted,please recreate model")
                        setErrMsg('Model Not generated')
                        if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                          const img = document.createElement("img");
                          img.src = ''
                          img.id = 'image'
                          facenetObj = new Facenet('', '')
                          facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                          dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                        }
                      })
                    }
                  }).catch((err) => {
                    setOpenModelProgress(false);
                    // errorToast("Models folder might have been deleted,please recreate model")
                    setErrMsg('Model Not generated')
                    if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                      const img = document.createElement("img");
                      img.src = ''
                      img.id = 'image'
                      facenetObj = new Facenet('', '')
                      facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                      dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                    }
                  })
                  // } else {
                  //   errorToast('Model Not generated,Please generate model before face recognition')
                  // }
                }).catch((err) => {
                  setOpenModelProgress(false);
                  // errorToast("Models folder might have been deleted,please recreate model")
                  setErrMsg('Model Not generated')
                  if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                    const img = document.createElement("img");
                    img.src = ''
                    img.id = 'image'
                    facenetObj = new Facenet('', '')
                    facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                    dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                  }
                })
              } else {
                setOpenModelProgress(false);
                if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                  const img = document.createElement("img");
                  img.src = ''
                  img.id = 'image'
                  const facenet = new Facenet('', '')
                  facenet.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                  dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                  // errorToast("Models folder might have been deleted,please recreate model or if model was uploaded then connect to internet and try again")
                  setErrMsg('Model Not generated')
                }
                // DB.facenetArr.get(examCenterDetails.id).then((resp) => {
                //   // DB.facenetArr.get("classEmbeddings").then((res) => {
                //   console.log(resp)
                //   if (!resp) {
                //     setErrMsg('Model Not generated')
                //     const img = document.createElement("img");
                //     img.src = ''
                //     img.id = 'image'
                //     const facenet = new Facenet('', '')
                //     facenet.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                //     dispatch(actions.FaceNet(facenet));
                //   } else {
                //     presentLoader()
                //     const facenet = new Facenet(resp.classNamesJson, resp.classEmbeddings);
                //     facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                //       // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                //       console.log('model loaded');
                //       // setFaceNetObj(facenet)
                //       // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                //       dispatch(actions.FaceNet(facenet));
                //       dismissLoader()
                //     });
                //   }
                //   // })
                // })
              }
            })
          }).catch((err) => {
            setOpenModelProgress(true)
            ipcRenderer.send("startPranilExe", "");
            ipcRenderer.on("exeSuccessful", (event, arg) => {
              console.log("exeSuccessful", arg)
              if (arg == 'done') {
                apiPath = `http://127.0.0.1:5000/getClassNames?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`
                embeddingsPath = `http://127.0.0.1:5000/getClassEmbeddings?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`
                modelUrl = `../../../models/${examDetails.id}/${examCenterDetails.id}/model.json`
                axios.get(apiPath).then(async (response) => {
                  axios.get(embeddingsPath).then(async (resp) => {
                    console.log('get request:', response)
                    let classNames = []
                    let classEmbeddings = []
                    if (apiPath == examCenterDetails.classNamesJson) {
                      classNames = response.data
                      classEmbeddings = resp.data
                    } else {
                      classNames = response.data.classNames
                      classEmbeddings = resp.data.classEmbeddings
                    }
                    console.log(classNames)
                    const facenet = new Facenet(classNames, classEmbeddings);
                    DB.facenetArr.add({ id: examCenterDetails.id, classNamesJson: classNames, classEmbeddings: classEmbeddings })
                    // DB.facenetArr.add({ id: examCenterDetails.id, data: classEmbeddings })
                    const progressInterval = setInterval(() => {
                      const progress = facenet.getProgress();
                      console.log('progress: ', Math.floor(progress))
                      setModelProgress(Math.floor(progress));
                      if (progress === 100) {
                        clearInterval(progressInterval);
                        setOpenModelProgress(false);
                      }
                    }, 1000);
                    // setOpenModelProgress(true);
                    // if (examCenterDetails.modelUrl) {
                    facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                      // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                      // setErrMsg('')
                      console.log('model loaded');
                      // setFaceNetObj(facenet)
                      // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                      dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                    })
                    // .catch((err) => {
                    //   setErrMsg('Model Not generated')
                    //   if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                    //     const img = document.createElement("img");
                    //     img.src = ''
                    //     img.id = 'image'
                    //     facenetObj = new Facenet('', '')
                    //     facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                    //     dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                    //   }
                    // })
                  })
                  // .catch((err) => {
                  //   setErrMsg('Model Not generated')
                  //   if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                  //     const img = document.createElement("img");
                  //     img.src = ''
                  //     img.id = 'image'
                  //     facenetObj = new Facenet('', '')
                  //     facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                  //     dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                  //   }
                  // })
                  // } else {
                  //   errorToast('Model Not generated,Please generate model before face recognition')
                  // }
                }).catch((err) => {
                  apiPath = examCenterDetails.classNamesJson
                  embeddingsPath = examCenterDetails.classEmbeddings
                  modelUrl = examCenterDetails.modelUrl
                  if (window.navigator.onLine) {
                    axios.get(apiPath).then(async (response) => {
                      axios.get(embeddingsPath).then(async (resp) => {
                        console.log('get request:', response)
                        let classNames = []
                        let classEmbeddings = []
                        if (apiPath == examCenterDetails.classNamesJson) {
                          classNames = response.data
                          classEmbeddings = resp.data
                        } else {
                          classNames = response.data.classNames
                          classEmbeddings = resp.data.classEmbeddings
                        }
                        console.log(classNames)
                        const facenet = new Facenet(classNames, classEmbeddings);
                        DB.facenetArr.add({ id: examCenterDetails.id, classNamesJson: classNames, classEmbeddings: classEmbeddings })
                        // DB.facenetArr.add({ id: examCenterDetails.id, data: classEmbeddings })
                        const progressInterval = setInterval(() => {
                          const progress = facenet.getProgress();
                          console.log('progress: ', Math.floor(progress))
                          setModelProgress(Math.floor(progress));
                          if (progress === 100) {
                            clearInterval(progressInterval);
                            // setOpenModelProgress(false);
                          }
                        }, 1000);
                        // setOpenModelProgress(true);
                        if (window.location.hostname === '') {
                          axios.get('http://127.0.0.1:5000/checkIfRunning').then((resp) => {
                            axios.post(`http://127.0.0.1:5000/downloadModelToLocalFileSystem?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`, { downloadFiles: examCenterDetails.downloadFiles }).then((re) => {
                              if (re.data === "Done") {
                                DB.modelUrl.add({ id: examCenterDetails.id, modelUrl: "local" })
                                facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                                  // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                                  // setErrMsg('')
                                  setOpenModelProgress(false);
                                  console.log('model loaded');
                                  // setFaceNetObj(facenet)
                                  // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                                  dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                                }).catch((err) => {
                                  setOpenModelProgress(false);
                                  // setErrMsg('Model Not generated')
                                  if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                                    const img = document.createElement("img");
                                    img.src = ''
                                    img.id = 'image'
                                    facenetObj = new Facenet('', '')
                                    facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                                    dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                                  }
                                })
                              }
                            })
                          }).catch((err) => {
                            ipcRenderer.send("startPranilExe", "");
                            ipcRenderer.on("exeSuccessful", (event, arg) => {
                              console.log("exeSuccessful", arg)
                              if (arg == 'done') {
                                axios.post(`http://127.0.0.1:5000/downloadModelToLocalFileSystem?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`, { downloadFiles: examCenterDetails.downloadFiles }).then((re) => {
                                  if (re.data === "Done") {
                                    DB.modelUrl.add({ id: examCenterDetails.id, modelUrl: "local" })
                                    facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                                      // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                                      // setErrMsg('')
                                      setOpenModelProgress(false);
                                      console.log('model loaded');
                                      // setFaceNetObj(facenet)
                                      // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                                      dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                                    }).catch((err) => {
                                      // setErrMsg('Model Not generated')
                                      setOpenModelProgress(false);
                                      if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                                        const img = document.createElement("img");
                                        img.src = ''
                                        img.id = 'image'
                                        facenetObj = new Facenet('', '')
                                        facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                                        dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                                      }
                                    })
                                  }
                                })
                              }
                            })
                          })
                        } else {
                          facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                            // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                            // setErrMsg('')
                            setOpenModelProgress(false);
                            console.log('model loaded');
                            // setFaceNetObj(facenet)
                            // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                            dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                          }).catch((err) => {
                            setOpenModelProgress(false);
                            // errorToast("Models folder might have been deleted,please recreate model")
                            setErrMsg('Model Not generated')
                            if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                              const img = document.createElement("img");
                              img.src = ''
                              img.id = 'image'
                              facenetObj = new Facenet('', '')
                              facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                              dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                            }
                          })
                        }
                      }).catch((err) => {
                        setOpenModelProgress(false);
                        // errorToast("Models folder might have been deleted,please recreate model")
                        setErrMsg('Model Not generated')
                        if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                          const img = document.createElement("img");
                          img.src = ''
                          img.id = 'image'
                          facenetObj = new Facenet('', '')
                          facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                          dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                        }
                      })
                      // } else {
                      //   errorToast('Model Not generated,Please generate model before face recognition')
                      // }
                    }).catch((err) => {
                      setOpenModelProgress(false);
                      // errorToast("Models folder might have been deleted,please recreate model")
                      setErrMsg('Model Not generated')
                      if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                        const img = document.createElement("img");
                        img.src = ''
                        img.id = 'image'
                        facenetObj = new Facenet('', '')
                        facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                        dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                      }
                    })
                  } else {
                    setOpenModelProgress(false);
                    if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                      const img = document.createElement("img");
                      img.src = ''
                      img.id = 'image'
                      const facenet = new Facenet('', '')
                      facenet.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                      dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                      // errorToast("Models folder might have been deleted,please recreate model or if model was uploaded then connect to internet and try again")
                      setErrMsg('Model Not generated')
                    }
                    // DB.facenetArr.get(examCenterDetails.id).then((resp) => {
                    //   // DB.facenetArr.get("classEmbeddings").then((res) => {
                    //   console.log(resp)
                    //   if (!resp) {
                    //     setErrMsg('Model Not generated')
                    //     const img = document.createElement("img");
                    //     img.src = ''
                    //     img.id = 'image'
                    //     const facenet = new Facenet('', '')
                    //     facenet.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                    //     dispatch(actions.FaceNet(facenet));
                    //   } else {
                    //     presentLoader()
                    //     const facenet = new Facenet(resp.classNamesJson, resp.classEmbeddings);
                    //     facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                    //       // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                    //       console.log('model loaded');
                    //       // setFaceNetObj(facenet)
                    //       // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                    //       dispatch(actions.FaceNet(facenet));
                    //       dismissLoader()
                    //     });
                    //   }
                    //   // })
                    // })
                  }
                })
              }
            })
          })
          // .catch((err) => {
          //   setErrMsg('Model Not generated')
          //   if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
          //     const img = document.createElement("img");
          //     img.src = ''
          //     img.id = 'image'
          //     facenetObj = new Facenet('', '')
          //     facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
          //     dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
          //   }
          // })
        } else {
          apiPath = examCenterDetails.classNamesJson
          embeddingsPath = examCenterDetails.classEmbeddings
          modelUrl = examCenterDetails.modelUrl
          if (window.navigator.onLine) {
            axios.get(apiPath).then(async (response) => {
              axios.get(embeddingsPath).then(async (resp) => {
                // setOpenModelProgress(true)
                console.log('get request:', response)
                let classNames = []
                let classEmbeddings = []
                if (apiPath == examCenterDetails.classNamesJson) {
                  classNames = response.data
                  classEmbeddings = resp.data
                } else {
                  classNames = response.data.classNames
                  classEmbeddings = resp.data.classEmbeddings
                }
                console.log(classNames)
                const facenet = new Facenet(classNames, classEmbeddings);
                DB.facenetArr.add({ id: examCenterDetails.id, classNamesJson: classNames, classEmbeddings: classEmbeddings })
                // DB.facenetArr.add({ id: examCenterDetails.id, data: classEmbeddings })
                const progressInterval = setInterval(() => {
                  const progress = facenet.getProgress();
                  console.log('progress: ', Math.floor(progress))
                  setModelProgress(Math.floor(progress));
                  if (progress === 100) {
                    clearInterval(progressInterval);
                    // setOpenModelProgress(false);
                  }
                }, 1000);
                setOpenModelProgress(true);
                if (window.location.hostname === '') {
                  axios.get('http://127.0.0.1:5000/checkIfRunning').then((resp) => {
                    axios.post(`http://127.0.0.1:5000/downloadModelToLocalFileSystem?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`, { downloadFiles: examCenterDetails.downloadFiles }).then((re) => {
                      if (re.data === "Done") {
                        DB.modelUrl.add({ id: examCenterDetails.id, modelUrl: "local" })
                        facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                          // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                          // setErrMsg('')
                          setOpenModelProgress(false);
                          console.log('model loaded');
                          // setFaceNetObj(facenet)
                          // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                          dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                        }).catch((err) => {
                          setOpenModelProgress(false);
                          setErrMsg('Model Not generated')
                          if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                            const img = document.createElement("img");
                            img.src = ''
                            img.id = 'image'
                            facenetObj = new Facenet('', '')
                            facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                            dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                          }
                        })
                      }
                    })
                  }).catch((err) => {
                    ipcRenderer.send("startPranilExe", "");
                    ipcRenderer.on("exeSuccessful", (event, arg) => {
                      console.log("exeSuccessful", arg)
                      if (arg == 'done') {
                        axios.post(`http://127.0.0.1:5000/downloadModelToLocalFileSystem?examId=${JSON.parse(localStorage.getItem('exam')).id}&testCenterId=${JSON.parse(localStorage.getItem('examCenter')).id}`, { downloadFiles: examCenterDetails.downloadFiles }).then((re) => {
                          if (re.data === "Done") {
                            DB.modelUrl.add({ id: examCenterDetails.id, modelUrl: "local" })
                            facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                              // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                              // setErrMsg('')
                              setOpenModelProgress(false);
                              console.log('model loaded');
                              // setFaceNetObj(facenet)
                              // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                              dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                            }).catch((err) => {
                              setOpenModelProgress(false);
                              setErrMsg('Model Not generated')
                              if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                                const img = document.createElement("img");
                                img.src = ''
                                img.id = 'image'
                                facenetObj = new Facenet('', '')
                                facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                                dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                              }
                            })
                          }
                        })
                      }
                    })
                  })
                } else {
                  facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
                    // facenet.loadModel('/facenet-data', modelUrl).then(() => {
                    // setErrMsg('')
                    setOpenModelProgress(false);
                    console.log('model loaded');
                    // setFaceNetObj(facenet)
                    // localStorage.setItem("facenetObj", JSON.stringify(facenet));
                    dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
                  }).catch((err) => {
                    setOpenModelProgress(false);
                    setErrMsg('Model Not generated')
                    if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                      const img = document.createElement("img");
                      img.src = ''
                      img.id = 'image'
                      facenetObj = new Facenet('', '')
                      facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                      dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                    }
                  })
                }
              }).catch((err) => {
                setOpenModelProgress(false);
                setErrMsg('Model Not generated')
                if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                  const img = document.createElement("img");
                  img.src = ''
                  img.id = 'image'
                  facenetObj = new Facenet('', '')
                  facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                  dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
                }
              })
              // } else {
              //   errorToast('Model Not generated,Please generate model before face recognition')
              // }
            }).catch((err) => {
              setOpenModelProgress(false);
              setErrMsg('Model Not generated')
              if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
                const img = document.createElement("img");
                img.src = ''
                img.id = 'image'
                facenetObj = new Facenet('', '')
                facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
                dispatch(actions.FaceNet({ [examCenterDetails.id]: facenetObj }));
              }
            })
          } else {
            setOpenModelProgress(false);
            if ((!facenetObj) || (facenetObj && !facenetObj?.hasOwnProperty(JSON.parse(localStorage.getItem('examCenter')).id))) {
              const img = document.createElement("img");
              img.src = ''
              img.id = 'image'
              const facenet = new Facenet('', '')
              facenet.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
              dispatch(actions.FaceNet({ [examCenterDetails.id]: facenet }));
              errorToast("Internet required to load model first time")
            }
            // DB.facenetArr.get(examCenterDetails.id).then((resp) => {
            //   // DB.facenetArr.get("classEmbeddings").then((res) => {
            //   console.log(resp)
            //   if (!resp) {
            //     setErrMsg('Model Not generated')
            //     const img = document.createElement("img");
            //     img.src = ''
            //     img.id = 'image'
            //     const facenet = new Facenet('', '')
            //     facenet.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data')
            //     dispatch(actions.FaceNet(facenet));
            //   } else {
            //     presentLoader()
            //     const facenet = new Facenet(resp.classNamesJson, resp.classEmbeddings);
            //     facenet.loadModel(window.location.hostname === '' ? '../' : '/facenet-data', modelUrl).then(() => {
            //       // facenet.loadModel('/facenet-data', modelUrl).then(() => {
            //       console.log('model loaded');
            //       // setFaceNetObj(facenet)
            //       // localStorage.setItem("facenetObj", JSON.stringify(facenet));
            //       dispatch(actions.FaceNet(facenet));
            //       dismissLoader()
            //     });
            //   }
            //   // })
            // })
          }
        }
      })
    } else {
      // setFaceNetObj(facenetObj)
      console.log(facenetObj)
    }
  }, [dispatch, examCenterDetails, facenetObj])

  const getStudentFromQr = (result) => {
    console.log('result: ', result);
    setScannerToggle(false);
    if (window.navigator.onLine) {
      getStudentByQr(examDetails.id, subExamId, result).then((resp) => { //query changed
        if (!resp.empty) {
          console.log(resp.docs[0].data())
          setCurrentStudentId({ id: resp.docs[0].id, ...resp.docs[0].data() })
          setCapturePhotoModalToggle(true)
        }
      })
    } else {
      let student = applicantsList.filter((e) => { return e.id === result })[0]
      console.log(student)
      setCurrentStudentId(student)
      setCapturePhotoModalToggle(true)
    }
  }


  useEffect(() => {
    // getData()
    let prefix = (localStorage.getItem('searchPrefix'))
    document.getElementById('searchPrefixinput-with-icon-textfield').value = prefix
    console.log("prefix", prefix)
    let arr = [...applicantsList]
    if (studentSearchValue === '') {
      arr.forEach(element => {
        element.showInGrid = true
      });
      setApplicantsList(arr)
    } else {
      arr.forEach((ele) => {
        if (ele.Applicant.includes(prefix ? prefix.trim() + studentSearchValue.trim() : studentSearchValue.trim())) {
          ele.showInGrid = true
        } else {
          ele.showInGrid = false
        }
      })
      setApplicantsList(arr);

    }
  }, [studentSearchValue, searchField]);


  function getDataFromServer(_subExamId, _searchField, _lastDoc, _studentSearchValue, _merge, examId, _examCenterID, _subExamName, __examCenterName, examCentersRes) {
    setDownloadStudentProgressModalToggle(true);
    setDownloadStudentProgressValue(0);
    Promise.all(examCentersRes.map(m => getStudents(_subExamId, _searchField, _lastDoc, _studentSearchValue, examId, m.examCenterName))).then(res => {
      let students = []
      res.forEach((ele, index) => {
        students.push(...ele.docs.map(m => ({ ...m.data(), examCenterCity: examCentersRes[index].City, id: m.id })))
      })
      let _studentList = convertFirebaseObjToDataGridObj(students.map(m => ({ ...m, id: `${_subExamId}_${m.id}` })), examShortName);
      console.log("_studentList0", _studentList);
      if (_studentList.length == 0) {
        cloneStudents()
        return
      } else {
        getCount(_studentList)
      }
      storeStudentDataInIndexDB(students.map(m => ({ ...m, id: `${_subExamId}_${m.id}`, examID: examId, subExamID: _subExamId, examCenterID: _examCenterID, isEdit: "false", subExamName: _subExamName })), examId, _subExamName, __examCenterName);
      convertProforma()
      getPhotoBase64(students.map(m => m.id), examId);
      if (_merge) {
        let _Students = _studentList.map((m) => ({
          Applicant: m.Applicant,
          isVerified: m.isVerified,
          id: m.id,
          email: m.email,
          UID: m.UID,
          Status: m.Status,
          Photo64: m.Photo64,
          Gender: m.Gender,
          "Full Name": m["Full Name"],
          City: m.City,
        }))
        setApplicantsList(pre => [...pre, ..._Students]);
      } else {
        setPageNumber(0);
        setPageMaintain(0);
        // setApplicantsList(_studentList);
      }
      setDataGridLoading(false);
    })
  }

  async function convertProforma(key) {
    let _subExam = JSON.parse(localStorage.getItem('subExam'))
    if (_subExam?.rawResultSheet && _subExam?.rawResultSheet !== "" && window.navigator.onLine) {
      // process.env.REACT_APP_bucketAccessRootPath+_subExam.rawResultSheet
      // let base64PDF = await pdf2base64(process.env.REACT_APP_bucketAccessRootPath+_subExam.rawResultSheet);
      // presentLoader()
      const existingPdfBytes = await fetch(process.env.REACT_APP_bucketAccessRootPath + _subExam.rawResultSheet).then((res) =>
        res.arrayBuffer()).then(res => window.btoa([].slice.call(new Uint8Array(res)).map(function (bin) { return String.fromCharCode(bin) }).join("")));

      DB.pdfBase64.add({
        id: `${_subExam.id}_rawResultSheet`,
        base64URI: existingPdfBytes,
      }).then(res => {
        console.log(res)
        if (key === 'sync') {
          setDownloadStudentProgressModalToggle(false)
        }
        // navigate('/home/student-verify/' + examShortName + '/allSubExams/' + _subExam.examName + '/allExamCenters/');
        // dismissLoader();
      }).catch(err => {
        if (err.inner.message.includes("Key already exists in the object store.")) {
          DB.pdfBase64.update(`${_subExam.id}_rawResultSheet`, { base64URI: existingPdfBytes }).then(res => {
            console.log(res);
            if (key === 'sync') {
              setDownloadStudentProgressModalToggle(false)
            }
            // dismissLoader();
          }).catch(error => {
            if (key === 'sync') {
              setDownloadStudentProgressModalToggle(false)
            }
            console.log(error);
            console.log(err);
            errorToast("Something went wrong please try again later")
          })
        } else {
          if (key === 'sync') {
            setDownloadStudentProgressModalToggle(false)
          }
          errorToast("Something went wrong please try again later")
        }
        // dismissLoader();
      })

    } else {
      if (key === 'sync') {
        setDownloadStudentProgressModalToggle(false)
      }
    }
  }

  async function cloneStudents() {
    let _exam = JSON.parse(localStorage.getItem('exam'))
    let currentSubExam = JSON.parse(localStorage.getItem('subExam'))
    let allSubExams = JSON.parse(localStorage.getItem('allSubExams'))
    console.log(allSubExams)
    let index = findIndex(allSubExams, function (o) { return o.id == currentSubExam.id });
    // if (index > 0) {
    let _Students = []
    for (let i = 0; i <= allSubExams.length - 1; i++) {
      if (i !== index) {
        // _Students = await DB.students.where({ examID: _exam.id, subExamID: allSubExams[i].id, examCenterCity: JSON.parse(localStorage.getItem("examCenter")).City }).toArray();
        _Students = await DB.students.where({ examID: _exam.id, subExamID: allSubExams[i].id, examCenterName: JSON.parse(localStorage.getItem("examCenter")).examCenterName }).toArray();
        if (_Students.length > 0)
          break
      }
    }
    if (_Students.length > 0) {
      _Students.forEach((obj, index) => {

        let currentSubExamHistory = {
          subExamName: obj.subExamName,
          fingerprintImage1: obj?.fingerprint1Base64 ? `exam-management/${obj.id.split('_')[1]}/fingerprint/${obj.examID}_${obj.examName}_${obj.subExamName}_${obj.id.split('_')[1]}_fingerprint1.png` : "",
          fingerprintImage2: obj?.fingerprint2Base64 ? `exam-management/${obj.id.split('_')[1]}/fingerprint/${obj.examID}_${obj.examName}_${obj.subExamName}_${obj.id.split('_')[1]}_fingerprint2.png` : "",
          realTimePhoto: obj?.realTimeBase64 ? `exam-management/${obj.id.split('_')[1]}/photos/${obj.examID}_${obj.examName}_${obj.subExamName}_${obj.id.split('_')[1]}_realTimePhoto.png` : "",
          idCardImage: obj?.idCardBase64 ? `exam-management/${obj.id.split('_')[1]}/idcard/${obj.examID}_${obj.examName}_${obj.subExamName}_${obj.id.split('_')[1]}_idCardImage.png` : "",
          attendanceDateTime: obj?.attendanceDateTime ? obj.attendanceDateTime : ""
        }
        if (obj.hasOwnProperty("previousSubExamHistory") && Array.isArray(obj.previousSubExamHistory)) {
          obj.previousSubExamHistory.push(currentSubExamHistory);
        } else {
          obj.previousSubExamHistory = [currentSubExamHistory];
        }

        let base64Obj = {
          id: obj.id.split('_')[1],
          [obj.subExamName]: {
            realTimePhoto: obj.realTimeBase64,
            idCardImage: obj.idCardBase64,
            fingerprintImage1: obj.fingerprint1Base64,
            fingerprintImage2: obj.fingerprint2Base64
          }
        }

        obj.id = `${currentSubExam.id}_${obj.id.split('_')[1]}`
        obj.subExamID = currentSubExam.id
        obj.subExamName = currentSubExam.examName
        delete obj.ET;
        delete obj.attendanceDateTime;
        delete obj.ET_IsQualified;
        delete obj.ET_height_height;
        delete obj.ET_height_remarks;
        delete obj.ET_height_result;
        delete obj.PST_IsQualified;
        delete obj.PST;
        delete obj.chest_no;
        delete obj.dataURL;
        delete obj.examType;
        delete obj.idCardBase64;
        delete obj.idCardImage;
        delete obj.invoice;
        delete obj.lastUpdateAt;
        delete obj.name;
        delete obj.realTimeBase64;
        delete obj.realTimePhoto;
        delete obj.selectedID_Card;
        delete obj.showInGrid;
        delete obj.uploadedForm;
        delete obj.checkingOfficer;
        delete obj.checkingOfficerDesignation;
        delete obj.isSubmitted;
        obj.Status = "pending"
        obj.isVerified = false

        updateBase64Object(base64Obj).then((resp) => {
          if (index == _Students.length - 1) {
            DB.students.bulkAdd(_Students).then(resp => {
              _Students = _Students.map((m) => ({
                Applicant: m.Applicant,
                isVerified: m.isVerified,
                id: m.id,
                email: m.email,
                UID: m.UID,
                Status: m.Status,
                Photo64: m.Photo64,
                Gender: m.Gender,
                "Full Name": m["Full Name"],
                City: m.City,
              }))
              getCount(_Students)
              setApplicantsList([...convertFirebaseObjToDataGridObj(_Students)]);
              setDataGridLoading(false);
              setDownloadStudentProgressModalToggle(false);
              setDownloadStudentProgressValue(0);
            })
          }
        })
      });
      // presentLoader()
    } else {
      setDataGridLoading(false);
      setDownloadStudentProgressModalToggle(false);
      setDownloadStudentProgressValue(0);
    }
    // } else {
    //   setDataGridLoading(false);
    //   setDownloadStudentProgressModalToggle(false);
    //   setDownloadStudentProgressValue(0);
    // }
  }

  const updateBase64Object = (studentObject) => new Promise((resolve, reject) => {
    studentObject.id = `${JSON.parse(localStorage.getItem('exam')).name}_${studentObject.id}`
    studentObject.examName = JSON.parse(localStorage.getItem('exam')).name
    DB.base64.update(studentObject.id, studentObject).then(res => {
      if (res) {
        resolve(res);
      } else {
        DB.base64.add(studentObject).then(res2 => {
          resolve(res2);
        }).catch(error => {
          reject(error);
        })
      }
    }).catch(err => {
      if (err.toString().includes("Cannot convert undefined or null to object")) {
        DB.base64.add(studentObject).then(res => {
          resolve(res);
        }).catch(error => {
          reject(error);
        })
      } else {
        reject(err);
      }
    })
  })

  function storeStudentDataInIndexDB(_list, examId, _subExamName, __examCenterName) {
    // presentLoader();
    let chunkArrayStudentList = createChunkArray([..._list]);
    getBase64FromImageUrlForPhotoPromise(chunkArrayStudentList).then(responsePhoto => {
      setPhotosStatus(true);
      let chunkArrayResponsePhotoList = createChunkArray([...responsePhoto]);
      getBase64FromImageUrlForFingerPrint1Promise(chunkArrayResponsePhotoList).then(responseFinerPrint1 => {

        let chunkArrayResponseFinerPrint1List = createChunkArray([...responseFinerPrint1]);
        getBase64FromImageUrlForFingerPrint2Promise(chunkArrayResponseFinerPrint1List).then(responseFinerPrint2 => {

          setFingerprintPhotosStatus(true);
          let chunkArrayResponseFinerPrint2List = createChunkArray([...responseFinerPrint2]);
          getBase64FromImageUrlForRealTimePromise(chunkArrayResponseFinerPrint2List).then(responseRealTime => {
            ;
            let chunkArrayResponseRealTimeList = createChunkArray([...responseRealTime]);
            getBase64FromImageUrlForIdCardPromise(chunkArrayResponseRealTimeList).then(responseIdCard => {
              ;
              setDocsPhotosStatus(true);
              setDownloadStudentProgressValue(2);
              ;
              let arr = []
              // DB.students.bulkAdd(responseIdCard).then(resp => {
              //   setAdminLog(examId, loginUser.id, loginUser, { action: "Download Data", subExam: _subExamName, examCenter: __examCenterName, count: responseIdCard.length });
              //   setAddStudentInLocalDb(true);
              //   setPageNumber(0);
              //   setPageMaintain(0);
              //   setApplicantsList([...convertFirebaseObjToDataGridObj(responseIdCard)]);
              //   // dismissLoader();
              //   ;
              // }).catch(err => {
              //   // dismissLoader();
              //   ;
              // })
              responseIdCard.forEach((element, index) => {
                DB.base64.get(`${JSON.parse(localStorage.getItem('exam')).name}_${element.id.split('_')[1]}`).then((base64Obj) => {
                  if (base64Obj) {
                    element.fingerprintTemplate1 = element.fingerprintTemplate1 ? element.fingerprintTemplate1 : base64Obj.fingerprintTemplate1
                    element.fingerprintTemplate2 = element.fingerprintTemplate2 ? element.fingerprintTemplate2 : base64Obj.fingerprintTemplate2
                    element.fingerprint1Base64 = element.fingerprint1Base64 ? element.fingerprint1Base64 : base64Obj.fingerprint1Base64
                    element.fingerprint2Base64 = element.fingerprint2Base64 ? element.fingerprint2Base64 : base64Obj.fingerprint2Base64
                  }
                  DB.students.add(element).then(resp => {
                    arr.push(element)
                    if (index === responseIdCard.length - 1) {
                      setAdminLog(examId, loginUser.id, loginUser, { action: "Download Data", subExam: _subExamName, examCenter: __examCenterName, count: responseIdCard.length });
                      setAddStudentInLocalDb(true);
                      setPageNumber(0);
                      setPageMaintain(0);
                      let _Students = arr.map((m) => ({
                        Applicant: m.Applicant,
                        isVerified: m.isVerified,
                        id: m.id,
                        email: m.email,
                        UID: m.UID,
                        Status: m.Status,
                        Photo64: m.Photo64,
                        Gender: m.Gender,
                        "Full Name": m["Full Name"],
                        City: m.City,
                      }))
                      setApplicantsList(pre => [...pre, ...convertFirebaseObjToDataGridObj(_Students)]);
                      // dismissLoader();
                      ;
                    }
                  }).catch((err) => {
                    if (index === responseIdCard.length - 1) {
                      setAdminLog(examId, loginUser.id, loginUser, { action: "Download Data", subExam: _subExamName, examCenter: __examCenterName, count: responseIdCard.length });
                      setAddStudentInLocalDb(true);
                      setPageNumber(0);
                      setPageMaintain(0);
                      let _Students = arr.map((m) => ({
                        Applicant: m.Applicant,
                        isVerified: m.isVerified,
                        id: m.id,
                        email: m.email,
                        UID: m.UID,
                        Status: m.Status,
                        Photo64: m.Photo64,
                        Gender: m.Gender,
                        "Full Name": m["Full Name"],
                        City: m.City,
                      }))
                      setApplicantsList(pre => [...pre, ...convertFirebaseObjToDataGridObj(_Students)]);
                      // dismissLoader();
                    }
                  })
                })
              })
            })
          })
        })
      })
    }).catch(err => {
      ;
    })
  }

  function storeStudentDataInIndexDB1(_list, examId, _subExamName, __examCenterName) {
    // presentLoader();
    let chunkArrayStudentList = createChunkArray([..._list]);
    getBase64FromImageUrlForPhotoPromise(chunkArrayStudentList).then(responsePhoto => {
      setPhotosStatus(true);
      let chunkArrayResponsePhotoList = createChunkArray([...responsePhoto]);
      getBase64FromImageUrlForFingerPrint1Promise(chunkArrayResponsePhotoList).then(responseFinerPrint1 => {
        let chunkArrayResponseFinerPrint1List = createChunkArray([...responseFinerPrint1]);
        getBase64FromImageUrlForFingerPrint2Promise(chunkArrayResponseFinerPrint1List).then(responseFinerPrint2 => {
          setFingerprintPhotosStatus(true);
          let chunkArrayResponseFinerPrint2List = createChunkArray([...responseFinerPrint2]);
          getBase64FromImageUrlForRealTimePromise(chunkArrayResponseFinerPrint2List).then(responseRealTime => {
            let chunkArrayResponseRealTimeList = createChunkArray([...responseRealTime]);
            getBase64FromImageUrlForIdCardPromise(chunkArrayResponseRealTimeList).then(responseIdCard => {
              setDocsPhotosStatus(true);
              setDownloadStudentProgressValue(2);
              console.log(responseIdCard);
              let arr = []
              responseIdCard.forEach((element, index) => {
                DB.students.update(element.id, element).then(resp => {
                  if (resp === 0) {
                    DB.students.add(element).then(resp => {
                      arr.push(element)
                      if (index === responseIdCard.length - 1) {
                        setAdminLog(examId, loginUser.id, loginUser, { action: "Download Data", subExam: _subExamName, examCenter: __examCenterName, count: responseIdCard.length });
                        setAddStudentInLocalDb(true);
                        setPageNumber(0);
                        setPageMaintain(0);
                        let _Students = arr.map((m) => ({
                          Applicant: m.Applicant,
                          isVerified: m.isVerified,
                          id: m.id,
                          email: m.email,
                          UID: m.UID,
                          Status: m.Status,
                          Photo64: m.Photo64,
                          Gender: m.Gender,
                          "Full Name": m["Full Name"],
                          City: m.City,
                        }))
                        setApplicantsList(pre => [...pre, ...convertFirebaseObjToDataGridObj(_Students)]);
                        // dismissLoader();
                        console.log("res => ", resp);
                      }
                    }).catch((err) => {
                      if (index === responseIdCard.length - 1) {
                        setAdminLog(examId, loginUser.id, loginUser, { action: "Download Data", subExam: _subExamName, examCenter: __examCenterName, count: responseIdCard.length });
                        setAddStudentInLocalDb(true);
                        setPageNumber(0);
                        setPageMaintain(0);
                        let _Students = arr.map((m) => ({
                          Applicant: m.Applicant,
                          isVerified: m.isVerified,
                          id: m.id,
                          email: m.email,
                          UID: m.UID,
                          Status: m.Status,
                          Photo64: m.Photo64,
                          Gender: m.Gender,
                          "Full Name": m["Full Name"],
                          City: m.City,
                        }))
                        setApplicantsList(pre => [...pre, ...convertFirebaseObjToDataGridObj(_Students)]);
                        // dismissLoader();
                      }
                    })
                  } else {
                    if (index === responseIdCard.length - 1) {
                      setAdminLog(examId, loginUser.id, loginUser, { action: "Download Data", subExam: _subExamName, examCenter: __examCenterName, count: responseIdCard.length });
                      setAddStudentInLocalDb(true);
                      setPageNumber(0);
                      setPageMaintain(0);
                      let _Students = arr.map((m) => ({
                        Applicant: m.Applicant,
                        isVerified: m.isVerified,
                        id: m.id,
                        email: m.email,
                        UID: m.UID,
                        Status: m.Status,
                        Photo64: m.Photo64,
                        Gender: m.Gender,
                        "Full Name": m["Full Name"],
                        City: m.City,
                      }))
                      setApplicantsList(pre => [...pre, ...convertFirebaseObjToDataGridObj(_Students)]);
                      // dismissLoader();
                      console.log("res => ", resp);
                    }
                  }
                }).catch(err => {
                  if (err.toString().includes('No document to update')) {
                    DB.students.add(element).then(resp => {
                      arr.push(element)
                      if (index === responseIdCard.length - 1) {
                        setAdminLog(examId, loginUser.id, loginUser, { action: "Download Data", subExam: _subExamName, examCenter: __examCenterName, count: responseIdCard.length });
                        setAddStudentInLocalDb(true);
                        setPageNumber(0);
                        setPageMaintain(0);
                        let _Students = arr.map((m) => ({
                          Applicant: m.Applicant,
                          isVerified: m.isVerified,
                          id: m.id,
                          email: m.email,
                          UID: m.UID,
                          Status: m.Status,
                          Photo64: m.Photo64,
                          Gender: m.Gender,
                          "Full Name": m["Full Name"],
                          City: m.City,
                        }))
                        setApplicantsList(pre => [...pre, ...convertFirebaseObjToDataGridObj(_Students)]);
                        // dismissLoader();
                        console.log("res => ", resp);
                      }
                    }).catch((err) => {
                      if (index === responseIdCard.length - 1) {
                        setAdminLog(examId, loginUser.id, loginUser, { action: "Download Data", subExam: _subExamName, examCenter: __examCenterName, count: responseIdCard.length });
                        setAddStudentInLocalDb(true);
                        setPageNumber(0);
                        setPageMaintain(0);
                        let _Students = arr.map((m) => ({
                          Applicant: m.Applicant,
                          isVerified: m.isVerified,
                          id: m.id,
                          email: m.email,
                          UID: m.UID,
                          Status: m.Status,
                          Photo64: m.Photo64,
                          Gender: m.Gender,
                          "Full Name": m["Full Name"],
                          City: m.City,
                        }))
                        setApplicantsList(pre => [...pre, ...convertFirebaseObjToDataGridObj(_Students)]);
                        // dismissLoader();
                      }
                    })
                  } else {
                    if (index === responseIdCard.length - 1) {
                      setAdminLog(examId, loginUser.id, loginUser, { action: "Download Data", subExam: _subExamName, examCenter: __examCenterName, count: responseIdCard.length });
                      setAddStudentInLocalDb(true);
                      setPageNumber(0);
                      setPageMaintain(0);
                      let _Students = arr.map((m) => ({
                        Applicant: m.Applicant,
                        isVerified: m.isVerified,
                        id: m.id,
                        email: m.email,
                        UID: m.UID,
                        Status: m.Status,
                        Photo64: m.Photo64,
                        Gender: m.Gender,
                        "Full Name": m["Full Name"],
                        City: m.City,
                      }))
                      setApplicantsList(pre => [...pre, ...convertFirebaseObjToDataGridObj(_Students)]);
                      // dismissLoader();
                    }
                  }
                  // dismissLoader();
                  // console.log("err", err);
                })
              });
            })
          })
        })
      })
    }).catch(err => {
      console.log(err);
    })
  }

  async function getData() {
    //* get data from indexDB
    let _Students = await DB.students.toArray();
    setApplicantsList([...convertFirebaseObjToDataGridObj(_Students)]);
  }

  function getDataForOfflineUse() {
    setApplicantsList([])
    getAllStudents(examDetails.id, subExamId, examCenterName).then((resp) => {
      console.log(resp)
      let _studentList = convertFirebaseObjToDataGridObj(resp.docs.map(m => ({ ...m.data(), id: m.id })), examShortName);
      console.log(_studentList);
      setPageNumber(0);
      setPageMaintain(0);
      setServerMetaDataList(resp.docs);
      setApplicantsList(_studentList);
      localStorage.setItem('allApplicants', JSON.stringify(_studentList))
      getAllStudentsFromMainCollection().then((res) => {
        if (res) {
          let arr = []
          res.docs.forEach((ele) => {
            let data = ele.data()
            data.id = ele.id
            arr.push(data)
          })
          setStudentsFromMainCollection(arr)
        }
      })
    })
  }

  function uploadToAws() {
    readFile().then((resp) => {
      console.log(resp)
      if (resp.data) {
        let count = 0
        resp.data.forEach(element => {
          uploadFilesAWS([element],
            (data) => {
              count = count + 1
              if (resp.data.length === count) {
                WriteFile([]).then((resp) => {
                  successToast("Data Uploaded")
                })
              }
              // dismissLoader()
            }, (err) => {
              console.log(err)
              // dismissLoader()
              console.log(err)
            }, (progress) => {
              // console.log(progree)
            });
        });
      }
    })
    // WriteFile([]).then((resp) => {
    //   successToast("Data Uploaded")
    // })
  }

  // function getMoreData() {
  //   setDataGridLoading(true);
  //   getDataFromServer(searchField, serverMetaDataList[serverMetaDataList.length - 1], studentSearchValue === "" ? undefined : searchPrefix + studentSearchValue, true);
  // }

  async function uploadOfflineData() {
    setStudentsToUpload([])
    setUploadProgress(0)
    if (window.navigator.onLine) {
      let studentsToUpdate = await DB.students.where({ subExamID: subExamId, isEdit: "true" }).toArray();
      console.log("Edit true studentsToUpdate= ", studentsToUpdate);
      setStudentsToUpload(studentsToUpdate)
      if (studentsToUpdate.length > 0) {
        setOpenUploadProgress(true)
        studentsToUpdate.forEach((element, index) => {
          if ((element.addedFromEXE && element.isUploaded !== "true") || (element.photoAdded && element.isUploaded !== "true")) {
            const _subExamId = element.id.split('_')[0]
            const sId = element.id.split('_')[1]
            let _SubExamName = element.subExamName;
            let photo64 = element.Photo64
            let realTimeBase64 = element.realTimeBase64
            let fingerprint1Base64 = element.fingerprint1Base64
            let fingerprint2Base64 = element.fingerprint2Base64
            let idCardBase64 = element.idCardBase64
            delete element.examID
            delete element.subExamID
            delete element.subExamName
            delete element.examCenterID
            delete element.isEdit
            delete element.Photo64
            delete element.realTimeBase64
            delete element.fingerprint1Base64
            delete element.fingerprint2Base64
            delete element.idCardBase64
            delete element.uploadedForm64
            delete element.examCenterCity
            delete element.id
            setStudent(_subExamId, examDetails.id, element, sId).then((r) => {
              DB.students.update(`${_subExamId}_${sId}`, { isEdit: "false", isUploaded: "true" }).then((response) => {
                element.id = `${_subExamId}_${sId}`
                element.Photo64 = photo64
                element.realTimeBase64 = realTimeBase64
                element.fingerprint2Base64 = fingerprint2Base64
                element.fingerprint1Base64 = fingerprint1Base64
                element.idCardBase64 = idCardBase64
                // element.addedFromEXE = false
                element.examID = JSON.parse(localStorage.getItem('exam')).id
                const _studentId = element.id.split('_')[1]
                uploadStudentDataToAWS(element, index, studentsToUpdate, _subExamId, _studentId)
              })
            })
          } else {
            const _subExamId = element.id.split('_')[0]
            const _studentId = element.id.split('_')[1]
            uploadStudentDataToAWS(element, index, studentsToUpdate, _subExamId, _studentId)
          }
        })
      } else {
        errorToast("Nothing To Upload")
      }
    } else {
      errorToast("Please check your internet connectivity!")
    }
  }

  function uploadStudentDataToAWS(element, index, studentsToUpdate, _subExamId, _studentId) {
    console.log(element)
    let filesToUpload = []
    let fileNamePrefix = element.examID + '_' + element.examName + "_" + element.subExamName + '_' + element.id.split('_')[1]
    let prefixPath = process.env.REACT_APP_bucketRootKey + '/' + element.id.split('_')[1]
    if ((element.addedFromEXE && element.Photo64) || (element.photoAdded && element.Photo64)) {
      let fileObj = convertBase64ToFile(element.Photo64, `${element.examID}_${element.id.split("_")[1]}.png`, prefixPath + '/photos/')
      element.Photo = prefixPath + '/photos/' + `${element.examID}_${element.id.split("_")[1]}.png`
      filesToUpload.push(fileObj)
    }
    if (element.realTimeBase64) {
      let fileObj = convertBase64ToFile(element.realTimeBase64, fileNamePrefix + '_realTimePhoto.png', prefixPath + '/photos/')
      element.realTimePhoto = prefixPath + '/photos/' + fileNamePrefix + '_realTimePhoto.png'
      filesToUpload.push(fileObj)
    }
    if (element.fingerprint1Base64) {
      let fileObj = convertBase64ToFile(element.fingerprint1Base64, fileNamePrefix + '_fingerprintImage1.png', prefixPath + '/fingerprint/')
      element.fingerprintImage1 = prefixPath + '/fingerprint/' + fileNamePrefix + '_fingerprintImage1.png'
      filesToUpload.push(fileObj)
    }
    if (element.fingerprint2Base64) {
      let fileObj = convertBase64ToFile(element.fingerprint2Base64, fileNamePrefix + '_fingerprintImage2.png', prefixPath + '/fingerprint/')
      element.fingerprintImage2 = prefixPath + '/fingerprint/' + fileNamePrefix + '_fingerprintImage2.png'
      filesToUpload.push(fileObj)
    }
    if (element.idCardBase64) {
      let fileObj = convertBase64ToFile(element.idCardBase64, fileNamePrefix + '_idCardImage.png', prefixPath + '/idcard/')
      element.idCardImage = prefixPath + '/idcard/' + fileNamePrefix + '_idCardImage.png'
      filesToUpload.push(fileObj)
    }
    if (element.uploadedForm64) {
      let fileObj = convertBase64ToFile(element.uploadedForm64, fileNamePrefix + '_uploadedForm.pdf', prefixPath + '/docs/')
      element.uploadedForm = prefixPath + '/docs/' + fileNamePrefix + '_uploadedForm.pdf'
      filesToUpload.push(fileObj)
    }
    console.log("filesToUpload => ", filesToUpload);
    if (filesToUpload.length > 0) {
      console.log("IN IF");
      uploadFilesAWS(filesToUpload,
        (data) => {
          let _SubExamName = element.subExamName;
          delete element.examID
          delete element.subExamID
          delete element.subExamName
          delete element.examCenterID
          delete element.isEdit
          delete element.Photo64
          delete element.realTimeBase64
          delete element.fingerprint1Base64
          delete element.fingerprint2Base64
          delete element.idCardBase64
          delete element.uploadedForm64
          delete element.examCenterCity
          delete element.id
          updateStudentData(_subExamId, examDetails.id, _studentId, element).then((resp) => {
            DB.students.update(`${_subExamId}_${_studentId}`, { isEdit: "false", isUploaded: "true" }).then((response) => {
              if (studentsToUpdate.length - 1 === index) {
                // DB.students.clear().then(() => {
                let progress = index / studentsToUpdate.length * 100;
                console.log("progress => ", progress);
                console.log("index", index)
                setUploadProgress(Math.floor(progress))
                setAdminLog(examDetails.id, loginUser.id, loginUser, { action: "Upload Data", subExam: _SubExamName, examCenter: examCenterDetails.examCenterName, count: studentsToUpdate.length });
                setOpenUploadProgress(false)
                getUploadedCount()
                downloadPdf(studentsToUpdate)
                // })
              } else {
                let progress = index / studentsToUpdate.length * 100;
                console.log("progress => ", progress);
                console.log("index", index)
                setUploadProgress(Math.floor(progress))
              }
            })
          }).catch((err) => {
            if (err.toString().includes('No document to update')) {
              setStudent(_subExamId, examDetails.id, element, _studentId).then((r) => {
                DB.students.update(`${_subExamId}_${_studentId}`, { isEdit: "false", isUploaded: "true" }).then((response) => {
                  if (studentsToUpdate.length - 1 === index) {
                    // DB.students.clear().then(() => {
                    let progress = index / studentsToUpdate.length * 100;
                    console.log("progress => ", progress);
                    console.log("index", index)
                    setUploadProgress(Math.floor(progress))
                    setAdminLog(examDetails.id, loginUser.id, loginUser, { action: "Upload Data", subExam: _SubExamName, examCenter: examCenterDetails.examCenterName, count: studentsToUpdate.length });
                    setOpenUploadProgress(false)
                    getUploadedCount()
                    downloadPdf(studentsToUpdate)
                    // })
                  } else {
                    let progress = index / studentsToUpdate.length * 100;
                    console.log("progress => ", progress);
                    console.log("index", index)
                    setUploadProgress(Math.floor(progress))
                  }
                })
              }).catch(err => console.log(err))
            } else {
              errorToast("Something went wrong,Please try again later")
              console.log(err)
            }
          })
        }, (err) => {
          errorToast("Something went wrong,Please try again later")
          console.log(err)
        }, (progress) => {
        });
    } else {
      console.log("IN ELSE");
      let _SubExamName = element.subExamName;
      delete element.examID
      delete element.subExamID
      delete element.subExamName
      delete element.examCenterID
      delete element.isEdit
      delete element.Photo64
      delete element.realTimeBase64
      delete element.fingerprint1Base64
      delete element.fingerprint2Base64
      delete element.idCardBase64
      delete element.uploadedForm64
      delete element.examCenterCity
      delete element.id
      updateStudentData(_subExamId, examDetails.id, _studentId, element).then((resp) => {
        DB.students.update(`${_subExamId}_${_studentId}`, { isEdit: "false", isUploaded: "true" }).then((response) => {
          if (studentsToUpdate.length - 1 === index) {
            // DB.students.clear().then(() => {
            let progress = index / studentsToUpdate.length * 100;
            console.log("progress => ", progress);
            console.log("index", index)
            setUploadProgress(Math.floor(progress))
            setAdminLog(examDetails.id, loginUser.id, loginUser, { action: "Upload Data", subExam: _SubExamName, examCenter: examCenterDetails.examCenterName, count: studentsToUpdate.length });
            setOpenUploadProgress(false)
            getUploadedCount()
            downloadPdf(studentsToUpdate)
            // })
          } else {
            let progress = index / studentsToUpdate.length * 100;
            console.log("progress => ", progress);
            console.log("index", index)
            setUploadProgress(Math.floor(progress))
          }
        })
      }).catch((err) => {
        if (err.toString().includes('No document to update')) {
          setStudent(_subExamId, examDetails.id, element, _studentId).then((r) => {
            DB.students.update(`${_subExamId}_${_studentId}`, { isEdit: "false", isUploaded: "true" }).then((response) => {
              if (studentsToUpdate.length - 1 === index) {
                // DB.students.clear().then(() => {
                let progress = index / studentsToUpdate.length * 100;
                console.log("progress => ", progress);
                console.log("index", index)
                setUploadProgress(Math.floor(progress))
                setAdminLog(examDetails.id, loginUser.id, loginUser, { action: "Upload Data", subExam: _SubExamName, examCenter: examCenterDetails.examCenterName, count: studentsToUpdate.length });
                setOpenUploadProgress(false)
                getUploadedCount()
                downloadPdf(studentsToUpdate)
                // })
              } else {
                let progress = index / studentsToUpdate.length * 100;
                console.log("progress => ", progress);
                console.log("index", index)
                setUploadProgress(Math.floor(progress))
              }
            })
          })
        } else {
          errorToast("Something went wrong,Please try again later")
          console.log(err)
        }
      })
    }
  }

  const downloadPdf = async (students) => {
    console.log(students)
    let arrayBuffer = _base64ToArrayBuffer(offlineReportPdf)
    const pdfDocument = await PDFDocument.load(arrayBuffer);
    const pages = pdfDocument.getPages()
    console.log(pages)
    const firstPage = pages[0]
    firstPage.drawText(JSON.parse(localStorage.getItem('user')).email, {
      x: 290,
      y: JSON.parse(localStorage.getItem('user')).email.length > 30 ? 600 : 590,
      size: JSON.parse(localStorage.getItem('user')).email.length > 30 ? 7 : 13,
      color: rgb(0, 0, 0),
      maxWidth: 250,
      lineHeight: 11,
      wordBreak: [' ', '-']
    })
    firstPage.drawText(JSON.parse(localStorage.getItem('exam')).name, {
      x: 290,
      y: JSON.parse(localStorage.getItem('exam')).name.length > 30 ? 570 : 560,
      size: JSON.parse(localStorage.getItem('exam')).name.length > 30 ? 7 : 13,
      color: rgb(0, 0, 0),
      maxWidth: 250,
      lineHeight: 11,
      wordBreak: [' ', '-']
    })
    firstPage.drawText(JSON.parse(localStorage.getItem('subExam')).examName, {
      x: 290,
      y: JSON.parse(localStorage.getItem('subExam')).examName.length > 30 ? 540 : 530,
      size: JSON.parse(localStorage.getItem('subExam')).examName.length > 30 ? 7 : 13,
      color: rgb(0, 0, 0),
      maxWidth: 250,
      lineHeight: 11,
      wordBreak: [' ', '-']
    })
    firstPage.drawText(JSON.parse(localStorage.getItem('examCenter')).examCenterName, {
      x: 290,
      y: JSON.parse(localStorage.getItem('examCenter')).examCenterName.length > 30 ? 510 : 500,
      size: JSON.parse(localStorage.getItem('examCenter')).examCenterName.length > 30 ? 7 : 13,
      color: rgb(0, 0, 0),
      maxWidth: 250,
      lineHeight: 11,
      wordBreak: [' ', '-']
    })

    firstPage.drawText(JSON.parse(localStorage.getItem('examCenter')).City, {
      x: 290,
      y: 470,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(totalStudentsCount.toString(), {
      x: 290,
      y: 445,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(students.length.toString(), {
      x: 290,
      y: 413,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(students.filter((e) => { return e.realTimePhoto && e.realTimePhoto !== '' }).length.toString(), {
      x: 290,
      y: 385,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText((students.filter((e) => { return e.fingerprintImage1 && e.fingerprintImage1 !== '' }).length * 3).toString(), {
      x: 290,
      y: 350,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(students.filter((e) => { return e.uploadedForm && e.uploadedForm !== '' }).length.toString(), {
      x: 290,
      y: 320,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText('NA', {
      x: 290,
      y: 290,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText("NA", {
      x: 290,
      y: 260,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(students.filter((e) => { return e.mergedStudent === true }).length.toString(), {
      x: 290,
      y: 230,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(students.filter((e) => { return e.addedFromEXE === true }).length.toString(), {
      x: 290,
      y: 200,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(students.filter((e) => { return e.Status === "qualified" }).length.toString(), {
      x: 290,
      y: 170,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(students.filter((e) => { return e.Status === 'not qualified' }).length.toString(), {
      x: 290,
      y: 140,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(new Date().toDateString(), {
      x: 365,
      y: 40,
      size: 7,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(new Date().toLocaleTimeString(), {
      x: 455,
      y: 40,
      size: 7,
      color: rgb(0, 0, 0),
    })
    const pdfBytes = await pdfDocument.save();
    let readyPdfBase64 = uint8ToBase64(pdfBytes);
    const readyPdfBase64WithPrefix = "data:application/pdf;base64," + readyPdfBase64;
    console.log({ readyPdfBase64WithPrefix });
    const a = document.createElement('a');
    a.href = readyPdfBase64WithPrefix;
    a.download = `OFFLINE UPLOAD REPORT_${new Date().toDateString()}_${new Date().toLocaleTimeString()}.pdf`;
    a.click();
  };

  const downloadLocalPdf = async (students) => {
    console.log(students)
    let arrayBuffer = _base64ToArrayBuffer(downloadReportPdf)
    const pdfDocument = await PDFDocument.load(arrayBuffer);
    const pages = pdfDocument.getPages()
    console.log(pages)
    const firstPage = pages[0]
    firstPage.drawText(JSON.parse(localStorage.getItem('user')).email, {
      x: 290,
      y: JSON.parse(localStorage.getItem('user')).email.length > 30 ? 600 : 590,
      size: JSON.parse(localStorage.getItem('user')).email.length > 30 ? 7 : 13,
      color: rgb(0, 0, 0),
      maxWidth: 250,
      lineHeight: 11,
      wordBreak: [' ', '-']
    })
    firstPage.drawText(JSON.parse(localStorage.getItem('exam')).name, {
      x: 290,
      y: JSON.parse(localStorage.getItem('exam')).name.length > 30 ? 570 : 560,
      size: JSON.parse(localStorage.getItem('exam')).name.length > 30 ? 7 : 13,
      color: rgb(0, 0, 0),
      maxWidth: 250,
      lineHeight: 11,
      wordBreak: [' ', '-']
    })
    firstPage.drawText(JSON.parse(localStorage.getItem('subExam')).examName, {
      x: 290,
      y: JSON.parse(localStorage.getItem('subExam')).examName.length > 30 ? 540 : 530,
      size: JSON.parse(localStorage.getItem('subExam')).examName.length > 30 ? 7 : 13,
      color: rgb(0, 0, 0),
      maxWidth: 250,
      lineHeight: 11,
      wordBreak: [' ', '-']
    })
    firstPage.drawText(JSON.parse(localStorage.getItem('examCenter')).examCenterName, {
      x: 290,
      y: JSON.parse(localStorage.getItem('examCenter')).examCenterName.length > 30 ? 510 : 500,
      size: JSON.parse(localStorage.getItem('examCenter')).examCenterName.length > 30 ? 7 : 13,
      color: rgb(0, 0, 0),
      maxWidth: 250,
      lineHeight: 11,
      wordBreak: [' ', '-']
    })

    firstPage.drawText(JSON.parse(localStorage.getItem('examCenter')).City, {
      x: 290,
      y: 470,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(totalStudentsCount.toString(), {
      x: 290,
      y: 445,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(students.length.toString(), {
      x: 290,
      y: 413,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(students.filter((e) => { return e.realTimeBase64 && e.realTimeBase64 !== '' }).length.toString(), {
      x: 290,
      y: 385,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText((students.filter((e) => { return e.fingerprint1Base64 && e.fingerprint1Base64 !== '' }).length * 3).toString(), {
      x: 290,
      y: 350,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(students.filter((e) => { return e.uploadedForm64 && e.uploadedForm64 !== '' }).length.toString(), {
      x: 290,
      y: 320,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText('NA', {
      x: 290,
      y: 290,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText("NA", {
      x: 290,
      y: 260,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(students.filter((e) => { return e.mergedStudent === true }).length.toString(), {
      x: 290,
      y: 230,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(students.filter((e) => { return e.addedFromEXE === true }).length.toString(), {
      x: 290,
      y: 200,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(students.filter((e) => { return e.Status === "qualified" }).length.toString(), {
      x: 290,
      y: 170,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(students.filter((e) => { return e.Status === 'not qualified' }).length.toString(), {
      x: 290,
      y: 140,
      size: 13,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(new Date().toDateString(), {
      x: 365,
      y: 40,
      size: 7,
      color: rgb(0, 0, 0),
    })
    firstPage.drawText(new Date().toLocaleTimeString(), {
      x: 455,
      y: 40,
      size: 7,
      color: rgb(0, 0, 0),
    })
    const pdfBytes = await pdfDocument.save();
    let readyPdfBase64 = uint8ToBase64(pdfBytes);
    const readyPdfBase64WithPrefix = "data:application/pdf;base64," + readyPdfBase64;
    console.log({ readyPdfBase64WithPrefix });
    const a = document.createElement('a');
    a.href = readyPdfBase64WithPrefix;
    a.download = `OFFLINE DOWNLOAD REPORT_${new Date().toDateString()}_${new Date().toLocaleTimeString()}.pdf`;
    a.click();
  };

  function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  function getPhotoBase64(studentIdsList, _examID, type) {
    let chunkArrayForGetFiles = createChunkArray([...studentIdsList]);
    console.log("chunkArrayForGetFiles => ", chunkArrayForGetFiles);
    // setBase64Status(true);

    createBatchGetStudent(chunkArrayForGetFiles).then((photosResponse) => {
      console.log("photosResponse", photosResponse);
      // f.includes("uploadedForm") || 
      let photosResponseFilter = photosResponse.map(oneArray => oneArray.map(m => m.fileUploadKey).filter(f => ((f.includes(_examID)) && (f.includes("idCardImage") || f.includes("fingerprintImage2") || f.includes("fingerprintImage1") || f.includes("realTimePhoto"))))).filter(f => f.length > 0)
      let makePhotosArrayObject = []
      console.log("photosResponseFilter", photosResponseFilter)
      photosResponseFilter.forEach(studentImageArray => {
        let arr = studentImageArray.map(m => ({
          url: m,
          subExam: m.split(".")[0].split("_")[m.split(".")[0].split("_").length - 3],
          variableName: m.split(".")[0].split("_")[m.split(".")[0].split("_").length - 1],
          studentID: m.split("/")[1]
        }))
        makePhotosArrayObject.push(arr)
      });
      if (makePhotosArrayObject.length > 0)
        convertLinksToBase64(makePhotosArrayObject, type);
      else
        setBase64Status(true);
    }).catch(err => {
      console.log("error", err);
      errorToast("Something went wrong, Please try again later!")
    })
  }

  async function createBatchGetStudent(array) {
    let resultArr = [];
    return await new Promise(async (resolve, reject) => {

      for (let item of array) {

        let reap = await Promise.all(item.map(m => getALLFilesFromAWS(m)));

        resultArr = [...resultArr, ...reap];
      }
      resolve(resultArr);
    })
  }



  function createChunkArray(arr) {
    return arr.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / process.env.REACT_APP_CHUNK_FOR_PHOTO_UPLOAD)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])
  }

  async function convertLinksToBase64(studentsImageArray, type) {
    // console.log("studentsImageArray",studentsImageArray);
    // studentsImageArray.forEach(async (element) => {
    let dbArray = []
    for (const element of studentsImageArray) {
      let res = await imageArrayPromise(element)
      let obj = {
        id: res[0]?.studentID
      }
      res.forEach(oneUrlObject => {
        if (!obj[oneUrlObject.subExam]) {
          obj[oneUrlObject.subExam] = {};
        }
        // if (oneUrlObject.variableName === 'fingerprintImage1')
        //   obj.fingerprint1Base64 = oneUrlObject.dataURL
        // if (oneUrlObject.variableName === 'fingerprintImage2')
        //   obj.fingerprint2Base64 = oneUrlObject.dataURL
        obj[oneUrlObject.subExam][oneUrlObject.variableName] = oneUrlObject.dataURL;
      })
      dbArray.push(obj);
    }
    // });
    // if (type === 'sync') {
    //   DB.base64.bulkAdd(dbArray).then(resp => {
    //     console.log("res => ", resp);
    //     setBase64Status(true);
    //   }).catch(err => {
    //     console.log("err", err)
    //   })
    // } else {
    //   // DB.base64.clear().then(clearRes => {
    //     // console.log("clearRes", clearRes);
    //     console.log("dbArray => ", dbArray);
    //     DB.base64.bulkAdd(dbArray).then(resp => {
    //       console.log("res => ", resp);
    //       setBase64Status(true);
    //     }).catch(err => {
    //       console.log("err", err)
    //     })
    //   // }).catch(clearRes => {
    //   //   console.log("clearRes", clearRes);
    //   // })
    // }
    dbArray.forEach((element, index) => {
      element.id = `${JSON.parse(localStorage.getItem('exam')).name}_${element.id}`
      element.examName = JSON.parse(localStorage.getItem('exam')).name

      DB.base64.update(element.id, element).then(resp => {
        if (resp === 0) {
          DB.base64.add(element).then(resp => {
            if (index === dbArray.length - 1) {
              setBase64Status(true);
            }
          })
        } else {
          console.log('check for resp', resp)
          if (index === dbArray.length - 1) {
            setBase64Status(true);
          }
        }
      }).catch(err => {
        if (err.toString().includes('No document to update')) {
          DB.base64.add(element).then(resp => {
            if (index === dbArray.length - 1) {
              setBase64Status(true);
            }
          })
        } else {
          console.log("err", err);
        }
        // dismissLoader();
      })
    })
  }

  function imageArrayPromise(imgArr) {
    let promises = []
    imgArr.forEach(ele => {
      promises.push(getBase64FromImageUrl(ele))
    });
    return new Promise((resolve, reject) => {
      Promise.all(promises).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      });
    })
  }

  function getBase64FromImageUrl(obj) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/jpeg");
        // 
        // console.log("dataURL",dataURL);
        resolve({ ...obj, dataURL: dataURL })
      };
      img.onerror = function (err) {
        console.log(err);
        resolve({ ...obj, dataURL: "" })
      }
      img.crossOrigin = '';
      img.crossOrigin = 'anonymous'
      // img.src = "https://cors-anywhere.herokuapp.com/" + process.env.REACT_APP_bucketAccessRootPath + obj.url;
      // img.src = process.env.REACT_APP_bucketAccessRootPath + obj.url;
      img.src = process.env.REACT_APP_CORS_ANYWHERE + process.env.REACT_APP_bucketAccessRootPath + obj.url;
    })
  }

  async function downloadLocalSavedData() {
    presentLoader();
    let studentsToUpdate = await DB.students.where({ isEdit: "true", examID: examDetails.id, subExamID: subExamId }).toArray();

    if (studentsToUpdate.length > 0) {
      const jsonFile = new Blob([JSON.stringify(studentsToUpdate, null, 2)], { type: 'application/json' });
      let jsonBuffer = await new Response(jsonFile).arrayBuffer();

      var mz = new Minizip();
      mz.append('OfflineDataBase.json', jsonBuffer, { password: examDetails.id });

      const a = document.createElement('a');
      a.href = "data:application/zip;base64," + uint8ToBase64(new Buffer(mz.zip()));
      a.download = `${examDetails.name}_${subExamName}_${examCenterName}_${new Date().toDateString()}_${new Date().toLocaleTimeString()} Data.zip`;
      a.click();
      downloadLocalPdf(studentsToUpdate)
      // const element = document.createElement("a"); 
      // element.href = URL.createObjectURL(jsonFile);
      // element.download = "OfflineDataBase.json";
      // document.body.appendChild(element);
      // element.click();

      dismissLoader();
    } else {
      errorToast("Nothing to download.");
      dismissLoader();
    }
  }

  function uploadStudents(event) {
    if (event.nativeEvent.target.files.length === 1) {
      presentLoader()
      const reader = new FileReader();
      reader.onload = function (e) {
        // excel.current.value = "";
        document.getElementById("fileSelectForUploadStudent").value = null;
        var data = e.target.result;
        data = new Uint8Array(data);
        var workbook = XLSX.read(data, {
          type: "array",
          cellText: false,
          cellDates: true,
        });
        var roa;
        workbook.SheetNames.forEach(function (sheetName) {
          roa = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName],
            { raw: false, dateNF: "yyyy-mm-dd" }
          );
        });
        console.log(roa);
        const invalidDataArr = [];
        const validDataArr = [];
        for (let i = 0; i < roa.length; i++) {
          const element = roa[i];
          if (!element.Status || element.Status === "") {
            element.Status = "pending";
          }
          element.Applicant = element?.Applicant?.toString() ? element?.Applicant?.toString() : "";

          element.createdAt = new Date(element?.registrationDate) ? (new Date(element.registrationDate)) : "";
          element.paymentMethod = "Offline"
          element.studentFullNameForSearch = element["Full Name"]?.toLowerCase() ? element["Full Name"].toLowerCase() : "";
          element.examDate = element?.examDate?.toString() ? element?.examDate?.toString() : "";
          element.examTime = element?.examTime?.toString() ? element?.examTime?.toString() : "";
          const validationResult = validateExcelObj(element);
          if (validationResult.validation.isValid) {
            validDataArr.push(element);
          } else {
            element.reason = validationResult.validation.reason;
            element.row = i + 2; // +1 for array index; +1 for row heading in excel
            // console.log('i: ', i);
            invalidDataArr.push(element);
          }
        }
        if (invalidDataArr.length > 0) {
          errorToast("Invalid data in excel sheet. Fix it and try again!");
          dismissLoader()
        } else {
          console.log(validDataArr)
          let arr = uniqBy(validDataArr, 'Applicant')
          arr.forEach((element, index) => {
            DB.students.where({ Applicant: element.Applicant, subExamID: subExamId, examID: examDetails.id }).toArray().then(async (resp) => {
              console.log(resp)
              if (resp.length === 0) {
                element.addedFromEXE = true
                element.examCenterCity = examCenterDetails.City
                element.examCenterID = examCenterDetails.id
                element.examID = examDetails.id
                element.subExamID = subExamId
                element.subExamName = subExamName
                element.isEdit = "true"
                element.id = `${subExamId}_${uuidv4()}`
                DB.students.add(element).then(async (resp) => {
                  if (index === arr.length - 1) {
                    // let _Students = await DB.students.where({ examID: examDetails.id, subExamID: subExamId, examCenterCity: examCenterDetails.City }).toArray();
                    let _Students = await DB.students.where({ examID: examDetails.id, subExamID: subExamId, examCenterName: examCenterDetails.examCenterName }).toArray();
                    _Students = _Students.map((m) => ({
                      Applicant: m.Applicant,
                      isVerified: m.isVerified,
                      id: m.id,
                      email: m.email,
                      UID: m.UID,
                      Status: m.Status,
                      Photo64: m.Photo64,
                      Gender: m.Gender,
                      "Full Name": m["Full Name"],
                      City: m.City,
                    }))
                    console.log(_Students)
                    getCount(_Students)
                    setApplicantsList([...convertFirebaseObjToDataGridObj(_Students)]);
                    setDataGridLoading(false)
                    dismissLoader()
                    successToast("Students Added successfully")
                  }
                })
              } else {
                if (index === arr.length - 1) {
                  // let _Students = await DB.students.where({ examID: examDetails.id, subExamID: subExamId, examCenterCity: examCenterDetails.City }).toArray();
                  let _Students = await DB.students.where({ examID: examDetails.id, subExamID: subExamId, examCenterName: examCenterDetails.examCenterName }).toArray();
                  _Students = _Students.map((m) => ({
                    Applicant: m.Applicant,
                    isVerified: m.isVerified,
                    id: m.id,
                    email: m.email,
                    UID: m.UID,
                    Status: m.Status,
                    Photo64: m.Photo64,
                    Gender: m.Gender,
                    "Full Name": m["Full Name"],
                    City: m.City,
                  }))
                  console.log(_Students)
                  getCount(_Students)
                  setApplicantsList([...convertFirebaseObjToDataGridObj(_Students)]);
                  setDataGridLoading(false)
                  successToast("Students Added successfully")
                  dismissLoader()
                }
              }
            })
          });
          // console.log("%c Zala", "color: #ff0000; font-size: 20px");
          // setHandleNext(2);
          // getApiToken(validDataArr);
        }
      };
      reader.readAsArrayBuffer(event.target.files[0]);
    }
  }

  function validateExcelObj(excelObj) {
    const validation = {
      isValid: true,
      reason: ''
    };

    if (excelObj["Full Name"] === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty Full Name";
    }
    if (excelObj?.Applicant === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty applicant id";
    }
    if (excelObj?.UID === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty UID";
    }
    if (excelObj?.examName === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty exam name";
    }
    if (excelObj?.examName !== JSON.parse(localStorage.getItem('exam')).name) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Exam name not match";
    }
    if (excelObj?.examCenterName === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty exam center name";
    }
    if (excelObj?.examCenterName !== JSON.parse(localStorage.getItem('examCenter')).examCenterName) {
      validation.isValid = false;
      validation.reason = validation.reason + ", exam center name not match";
    }
    if (excelObj?.Gender === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty gender";
    }
    if (excelObj?.City === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty city";
    }
    if (excelObj?.Gender?.toLowerCase() !== "male" && excelObj?.Gender?.toLowerCase() !== "female" && excelObj?.Gender?.toLowerCase() !== "transgender") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Invalid gender";
    }
    if (excelObj?.Disability?.toLowerCase() !== "yes" && excelObj?.Disability?.toLowerCase() !== "no") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Invalid disability";
    }
    if (excelObj?.examDate === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty exam date";
    }
    if (excelObj?.examTime === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty exam time";
    }

    if (!excelObj["Full Name"]) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Missing Full Name";
    }
    if (!excelObj?.Applicant) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Missing Applicant id";
    }
    if (!excelObj?.examName) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Missing exam name";
    }
    if (!excelObj?.examCenterName) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Missing exam center name";
    }
    if (!excelObj?.Gender) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Missing gender";
    }
    if (!excelObj?.City) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Missing city";
    }
    if (excelObj?.examDate === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty exam date";
    }
    if (excelObj?.examTime === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty exam time";
    }
    if (excelObj?.Disability === "") {
      validation.isValid = false;
      validation.reason = validation.reason + ", Empty disability";
    }
    if (new Date(excelObj?.registrationDate).toString().includes("Invalid Date")) {
      validation.isValid = false;
      validation.reason = validation.reason + ", Invalid Registration Date : format(MM/DD/YYYY)";
    }
    validation.reason = validation.reason.split(",").filter(f => f !== "").join(",");

    return { validation, excelObj };
  }

  function uploadStudentPhotos(event) {
    presentLoader()
    let applicantId = ''
    console.log(event.target.files)
    let fileCount = event.target.files.length - 1
    event.target.files.forEach((ele, index) => {
      console.log(`${subExamId}_${ele.name.split('.')[0]}`)
      DB.students.where({ subExamID: subExamId, Applicant: ele.name.split('.')[0] }).toArray().then(async (res) => {
        if (res) {
          convertPhotoToBase64(ele).then((resp) => {
            console.log(resp)
            DB.students.update(res[0].id, { Photo64: resp, isEdit: "true", photoAdded: true }).then(async (re) => {
              if (index === fileCount) {
                // let _Students = await DB.students.where({ examID: examDetails.id, subExamID: subExamId, examCenterCity: examCenterDetails.City }).toArray();
                let _Students = await DB.students.where({ examID: examDetails.id, subExamID: subExamId, examCenterName: examCenterDetails.examCenterName }).toArray();
                _Students = _Students.map((m) => ({
                  Applicant: m.Applicant,
                  isVerified: m.isVerified,
                  id: m.id,
                  email: m.email,
                  UID: m.UID,
                  Status: m.Status,
                  Photo64: m.Photo64,
                  Gender: m.Gender,
                  "Full Name": m["Full Name"],
                  City: m.City,
                }))
                console.log(_Students)
                setApplicantsList([...convertFirebaseObjToDataGridObj(_Students)]);
                setDataGridLoading(false)
                dismissLoader()
                successToast("Photos Added Successfully")
              }
            })
          })
        } else {
          applicantId = applicantId + ` ${ele.name.split('.')[0]}`
          console.log(applicantId)
          if (index === fileCount) {
            // let _Students = await DB.students.where({ examID: examDetails.id, subExamID: subExamId, examCenterCity: examCenterDetails.City }).toArray();
            let _Students = await DB.students.where({ examID: examDetails.id, subExamID: subExamId, examCenterName: examCenterDetails.examCenterName }).toArray();
            _Students = _Students.map((m) => ({
              Applicant: m.Applicant,
              isVerified: m.isVerified,
              id: m.id,
              email: m.email,
              UID: m.UID,
              Status: m.Status,
              Photo64: m.Photo64,
              Gender: m.Gender,
              "Full Name": m["Full Name"],
              City: m.City,
            }))
            console.log(_Students)
            setApplicantsList([...convertFirebaseObjToDataGridObj(_Students)]);
            setDataGridLoading(false)
            successToast("Photos Added Successfully")
            dismissLoader()
          }
        }
      })
    })
    document.getElementById("fileSelectForUploadStudentPhotos").value = '';
  }

  function convertPhotoToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  async function downloadResultExcelSheet() {
    presentLoader();
    // setStepperValueForResultSheet(0);
    // setStepperToggleForResultSheet(true);
    // DB.students.where({ examID: examDetails.id, examCenterCity: examCenterDetails.City, subExamID: subExamDetails.id }).toArray().then(res => {
    DB.students.where({ examID: examDetails.id, examCenterName: examCenterDetails.examCenterName, subExamID: subExamDetails.id }).toArray().then(res => {
      console.log("dexie", res)
      if (res.length === 0) {
        errorToast("Student data not found.");
        // setStepperToggleForResultSheet(false);
        // setStepperValueForResultSheet(0);
        // setStepperPhotoProgressValueForResultSheet(0);
        dismissLoader();
        return;
      }

      let _list = res.map((m, index) => ({
        UID: m.id,
        "sNo": (index + 1).toString(),
        "Applicant": m.Applicant,
        "Room No": m.roomNo,
        "Roll No": m.rollNo,
        "Full Name": m["Full Name"],
        "Gender": m.Gender,
        "MobileNo": m.phone,
        "Attendance": (m.realTimeBase64 && m.realTimeBase64 !== "") ? "P" : "A",
        "Date": m.examDate,
        "CenterName": m.examCenterName,
        "ExamName": examDetails.name ? examDetails.name : m.examName,
        "AddedFromEXE": m.hasOwnProperty("addedFromEXE"),
        // "AppPhoto": m?.Photo64 ? m.Photo64 : "",

        // [`LivePhoto`]: m?.realTimeBase64 ? m.realTimeBase64 : "",

        // [`FPPhoto1`]: m?.fingerprint1Base64 ? m.fingerprint1Base64 : "",
        // [`FPPhoto2`]: m?.fingerprint2Base64 ? m.fingerprint2Base64 : "",

        // [`idCardPhoto`]: m?.idCardBase64 ? m.idCardBase64 : "",

        [`Attendance_Date_Time`]: (m?.attendanceDateTime && m?.attendanceDateTime !== "") ? new Date(m.attendanceDateTime)?.toLocaleString() : "",
      }));

      let keys = ["sNo", "Applicant", "Room No", "Roll No", "Full Name", "Gender", "MobileNo", "Attendance", "Date", "CenterName", "ExamName", "AddedFromEXE", "Attendance_Date_Time"];

      console.log("_list => ", _list);

      // console.log("studentHistoryData",studentHistoryData);
      console.log({ keys });
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Data');
      worksheet.properties.defaultColWidth = 30;
      // worksheet.properties.defaultRowHeight = 100;

      worksheet.addRow(keys);
      _list.map((item) => {
        const temp = [];
        keys.map((ind) => {
          temp.push(item[ind]);
          return ind;
        });
        worksheet.addRow(temp);
        return item;
      });
      const row1 = worksheet.getRow(1);
      const row1Data = row1.model.cells.filter(f => {
        if (f.value.includes("AppPhoto") || f.value.includes("LivePhoto") || f.value.includes("FPPhoto1") || f.value.includes("FPPhoto2") || f.value.includes("idCardPhoto")) {
          return true;
        }
      }).map(m => ({ ...m, columnId: m.address.replace(/[^A-Za-z]/g, '') }))
      // console.log("row1",row1Data);

      let allImagesURLs = [];

      row1Data.forEach((rowData, rowIndex) => {
        let rawColumn = worksheet.getColumn(rowData.columnId);
        allImagesURLs.push(...rawColumn.values.slice(2).map((c, i) => ({ url: c, cellId: `${rowData.columnId}${i + 2}` })));
      })

      worksheet.getRows(2, _list.length + 1).forEach(ele => {
        row1Data.forEach(ele1 => {
          ele.getCell(ele1.columnId).value = "";
        })
      })
      console.log("allImagesURLs length => ", allImagesURLs);
      // setStepperValueForResultSheet(1);
      // setStepperValueForResultSheet(2);
      allImagesURLs.forEach((ele, index) => {
        if (ele.url && ele.url !== "") {
          const imageId = workbook.addImage({
            base64: ele.url,
            extension: 'png',
          });
          worksheet.addImage(imageId, `${ele.cellId}:${ele.cellId}`);
        }
      });
      worksheet.addRow(["Total Present", _list.filter((e) => { return e.Attendance === 'P' }).length]).font = {
        bold: true,
      };
      worksheet.addRow(["Total Absent", _list.filter((e) => { return e.Attendance === 'A' }).length]).font = {
        bold: true,
      };

      //* Downloading Excel
      try {
        workbook.xlsx.writeBuffer().then((buffer) => {
          console.log("Downloading Start");
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', });
          fileSaver.saveAs(blob, `Students Report List_${new Date().toDateString()}_${new Date().toLocaleTimeString()}.xlsx`);
          dismissLoader();
          // setStepperValueForResultSheet(3);
        }).catch((ee) => {
          errorToast("Something went wrong! Try again");
          // setStepperValueForResultSheet(0);
          // setStepperToggleForResultSheet(false);
          // setStepperPhotoProgressValueForResultSheet(0);
          dismissLoader();
          console.log("dd", ee)
        });
      } catch (e) {
        console.log(e);
        errorToast("Something went wrong! Try again");
        // setStepperValueForResultSheet(0);
        // setStepperToggleForResultSheet(false);
        dismissLoader();
      }
    }).catch(err => {
      console.log(err);
      errorToast(err.toString());
      // setStepperValueForResultSheet(0);
      // setStepperToggleForResultSheet(false);
      // setStepperPhotoProgressValueForResultSheet(0);
      dismissLoader();
    })

  }

  async function downloadPresentResultExcelSheet(type) {
    presentLoader();
    // setStepperValueForResultSheet(0);
    // setStepperToggleForResultSheet(true);
    // DB.students.where({ examID: examDetails.id, examCenterCity: examCenterDetails.City, subExamID: subExamDetails.id }).toArray().then(res => {
    DB.students.where({ examID: examDetails.id, examCenterName: examCenterDetails.examCenterName, subExamID: subExamDetails.id }).toArray().then(res => {
      if (type === "Present") {
        res = res.filter((ele) => { return ele.realTimeBase64 && ele.realTimeBase64 !== "" })
      } else {
        res = res.filter((ele) => { return !ele.realTimeBase64 || ele.realTimeBase64 === "" })
      }
      console.log("dexie", res)
      if (res.length === 0) {
        errorToast("Student data not found.");
        // setStepperToggleForResultSheet(false);
        // setStepperValueForResultSheet(0);
        // setStepperPhotoProgressValueForResultSheet(0);
        dismissLoader();
        return;
      }

      let _list = res.map((m, index) => ({
        UID: m.id,
        "sNo": (index + 1).toString(),
        "Applicant": m.Applicant,
        "Room No": m.roomNo,
        "Roll No": m.rollNo,
        "Full Name": m["Full Name"],
        "Gender": m.Gender,
        "MobileNo": m.phone,
        "Attendance": (m.realTimeBase64 && m.realTimeBase64 !== "") ? "P" : "A",
        "Date": m.examDate,
        "CenterName": m.examCenterName,
        "ExamName": examDetails.name ? examDetails.name : m.examName,
        "AddedFromEXE": m.hasOwnProperty("addedFromEXE"),
        // "AppPhoto": m?.Photo64 ? m.Photo64 : "",

        // [`LivePhoto`]: m?.realTimeBase64 ? m.realTimeBase64 : "",

        // [`FPPhoto1`]: m?.fingerprint1Base64 ? m.fingerprint1Base64 : "",
        // [`FPPhoto2`]: m?.fingerprint2Base64 ? m.fingerprint2Base64 : "",

        // [`idCardPhoto`]: m?.idCardBase64 ? m.idCardBase64 : "",

        [`Attendance_Date_Time`]: (m?.attendanceDateTime && m?.attendanceDateTime !== "") ? new Date(m.attendanceDateTime)?.toLocaleString() : "",
      }));

      let keys = ["sNo", "Applicant", "Room No", "Roll No", "Full Name", "Gender", "MobileNo", "Attendance", "Date", "CenterName", "ExamName", "AddedFromEXE", "Attendance_Date_Time"];

      console.log("_list => ", _list);

      // console.log("studentHistoryData",studentHistoryData);
      console.log({ keys });
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Data');
      worksheet.properties.defaultColWidth = 30;
      // worksheet.properties.defaultRowHeight = 100;

      worksheet.addRow(keys);
      _list.map((item) => {
        const temp = [];
        keys.map((ind) => {
          temp.push(item[ind]);
          return ind;
        });
        worksheet.addRow(temp);
        return item;
      });
      const row1 = worksheet.getRow(1);
      const row1Data = row1.model.cells.filter(f => {
        if (f.value.includes("AppPhoto") || f.value.includes("LivePhoto") || f.value.includes("FPPhoto1") || f.value.includes("FPPhoto2") || f.value.includes("idCardPhoto")) {
          return true;
        }
      }).map(m => ({ ...m, columnId: m.address.replace(/[^A-Za-z]/g, '') }))
      // console.log("row1",row1Data);

      let allImagesURLs = [];

      row1Data.forEach((rowData, rowIndex) => {
        let rawColumn = worksheet.getColumn(rowData.columnId);
        allImagesURLs.push(...rawColumn.values.slice(2).map((c, i) => ({ url: c, cellId: `${rowData.columnId}${i + 2}` })));
      })

      worksheet.getRows(2, _list.length + 1).forEach(ele => {
        row1Data.forEach(ele1 => {
          ele.getCell(ele1.columnId).value = "";
        })
      })
      console.log("allImagesURLs length => ", allImagesURLs);
      // setStepperValueForResultSheet(1);
      // setStepperValueForResultSheet(2);
      allImagesURLs.forEach((ele, index) => {
        if (ele.url && ele.url !== "") {
          const imageId = workbook.addImage({
            base64: ele.url,
            extension: 'png',
          });
          worksheet.addImage(imageId, `${ele.cellId}:${ele.cellId}`);
        }
      });
      worksheet.addRow(["Total Present", _list.filter((e) => { return e.Attendance === 'P' }).length]).font = {
        bold: true,
      };
      worksheet.addRow(["Total Absent", _list.filter((e) => { return e.Attendance === 'A' }).length]).font = {
        bold: true,
      };

      //* Downloading Excel
      try {
        workbook.xlsx.writeBuffer().then((buffer) => {
          console.log("Downloading Start");
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', });
          fileSaver.saveAs(blob, `Students Report List_${new Date().toDateString()}_${new Date().toLocaleTimeString()}.xlsx`);
          dismissLoader();
          // setStepperValueForResultSheet(3);
        }).catch((ee) => {
          errorToast("Something went wrong! Try again");
          // setStepperValueForResultSheet(0);
          // setStepperToggleForResultSheet(false);
          // setStepperPhotoProgressValueForResultSheet(0);
          dismissLoader();
          console.log("dd", ee)
        });
      } catch (e) {
        console.log(e);
        errorToast("Something went wrong! Try again");
        // setStepperValueForResultSheet(0);
        // setStepperToggleForResultSheet(false);
        dismissLoader();
      }
    }).catch(err => {
      console.log(err);
      errorToast(err.toString());
      // setStepperValueForResultSheet(0);
      // setStepperToggleForResultSheet(false);
      // setStepperPhotoProgressValueForResultSheet(0);
      dismissLoader();
    })

  }

  async function downloadFinalResultSheet() {
    presentLoader();
    // setStepperValueForResultSheet(0);
    // setStepperToggleForResultSheet(true);
    // DB.students.where({ examID: examDetails.id, examCenterCity: examCenterDetails.City, subExamID: subExamDetails.id }).toArray().then(res => {
    DB.students.where({ examID: examDetails.id, examCenterName: examCenterDetails.examCenterName, subExamID: subExamDetails.id }).toArray().then(res => {
      console.log("dexie", res)
      if (res.length === 0) {
        errorToast("Student data not found.");
        // setStepperToggleForResultSheet(false);
        // setStepperValueForResultSheet(0);
        // setStepperPhotoProgressValueForResultSheet(0);
        dismissLoader();
        return;
      }

      let _list = res.map((m, index) => ({
        "POST": '',
        "AddressDistrict": '',
        "GroundDistrict": JSON.parse(localStorage.getItem('examCenter')).City,
        "GroundName": JSON.parse(localStorage.getItem('examCenter')).examCenterName,
        "ETPST_SNO": '',
        "ETPSTScheduledDate": new Date().toDateString(),
        "RunningMetre": '',
        "AppliedAs": m.AppliedAs ? m.AppliedAs : '',
        "ApplicationNo": m.Applicant,
        "Name": m["Full Name"],
        "Roll No": m["rollNo"],
        "Room No": m["roomNo"],
        "DOB": '',
        "Gender": m.Gender,
        "Category": '',
        "Reservation": '',
        "OtherReservation": '',
        "Year_of_Pass": '',
        "Degree": '',
        "Percentage": '',
        "IsArmyGraduation": '',
        "ET Result": m.hasOwnProperty('ET_IsQualified') ? m.ET_IsQualified : '',
        "PST Result": m.hasOwnProperty('PST_IsQualified') ? m.PST_IsQualified : '',
        "FINAL Result": m.Status,
        "EXS HEIGHT": "",
        "REMARKS": "",
      }));

      let keys = [
        "POST",
        "AddressDistrict",
        "GroundDistrict",
        "GroundName",
        "ETPST_SNO",
        "ETPSTScheduledDate",
        "RunningMetre",
        "AppliedAs",
        "ApplicationNo",
        "Name",
        "Roll No",
        "Room No",
        "DOB",
        "Gender",
        "Category",
        "Reservation",
        "OtherReservation",
        "Year_of_Pass",
        "Degree",
        "Percentage",
        "IsArmyGraduation",
        "ET Result",
        "PST Result",
        "FINAL Result",
        "EXS HEIGHT",
        "REMARKS"
      ];

      console.log("_list => ", _list);

      // console.log("studentHistoryData",studentHistoryData);
      console.log({ keys });
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Data');
      worksheet.properties.defaultColWidth = 30;
      // worksheet.properties.defaultRowHeight = 100;

      worksheet.addRow(keys);
      _list.map((item) => {
        const temp = [];
        keys.map((ind) => {
          temp.push(item[ind]);
          return ind;
        });
        worksheet.addRow(temp);
        return item;
      });
      const row1 = worksheet.getRow(1);
      const row1Data = row1.model.cells.filter(f => {
        if (f.value.includes("AppPhoto") || f.value.includes("LivePhoto") || f.value.includes("FPPhoto1") || f.value.includes("FPPhoto2") || f.value.includes("idCardPhoto")) {
          return true;
        }
      }).map(m => ({ ...m, columnId: m.address.replace(/[^A-Za-z]/g, '') }))
      // console.log("row1",row1Data);

      let allImagesURLs = [];

      row1Data.forEach((rowData, rowIndex) => {
        let rawColumn = worksheet.getColumn(rowData.columnId);
        allImagesURLs.push(...rawColumn.values.slice(2).map((c, i) => ({ url: c, cellId: `${rowData.columnId}${i + 2}` })));
      })

      worksheet.getRows(2, _list.length + 1).forEach(ele => {
        row1Data.forEach(ele1 => {
          ele.getCell(ele1.columnId).value = "";
        })
      })

      console.log("allImagesURLs length => ", allImagesURLs);
      // setStepperValueForResultSheet(1);
      // setStepperValueForResultSheet(2);
      allImagesURLs.forEach((ele, index) => {
        if (ele.url && ele.url !== "") {
          const imageId = workbook.addImage({
            base64: ele.url,
            extension: 'png',
          });
          worksheet.addImage(imageId, `${ele.cellId}:${ele.cellId}`);
        }
      });

      // worksheet.addRow(["Total Present", _list.filter((e) => { return e.Attendance === 'P' }).length]).font = {
      //   bold: true,
      // };
      // worksheet.addRow(["Total Absent", _list.filter((e) => { return e.Attendance === 'A' }).length]).font = {
      //   bold: true,
      // };
      //* Downloading Excel
      try {
        workbook.xlsx.writeBuffer().then((buffer) => {
          console.log("Downloading Start");
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', });
          fileSaver.saveAs(blob, `Students Report List_${new Date().toDateString()}_${new Date().toLocaleTimeString()}.xlsx`);
          dismissLoader();
          // setStepperValueForResultSheet(3);
        }).catch((ee) => {
          errorToast("Something went wrong! Try again");
          // setStepperValueForResultSheet(0);
          // setStepperToggleForResultSheet(false);
          // setStepperPhotoProgressValueForResultSheet(0);
          dismissLoader();
          console.log("dd", ee)
        });
      } catch (e) {
        console.log(e);
        errorToast("Something went wrong! Try again");
        // setStepperValueForResultSheet(0);
        // setStepperToggleForResultSheet(false);
        dismissLoader();
      }
    }).catch(err => {
      console.log(err);
      errorToast(err.toString());
      // setStepperValueForResultSheet(0);
      // setStepperToggleForResultSheet(false);
      // setStepperPhotoProgressValueForResultSheet(0);
      dismissLoader();
    })

  }

  async function downloadDateWiseQualifiedCount() {
    presentLoader();
    // setStepperValueForResultSheet(0);
    // setStepperToggleForResultSheet(true);
    DB.students.where({ examID: examDetails.id, examCenterName: examCenterDetails.examCenterName, subExamID: subExamDetails.id }).toArray().then(resp => {
      let res = resp.filter((e) => { return e.attendanceDateTime })
      console.log("dexie", res)
      if (res.length === 0) {
        errorToast("Student data not found.");
        // setStepperToggleForResultSheet(false);
        // setStepperValueForResultSheet(0);
        // setStepperPhotoProgressValueForResultSheet(0);
        dismissLoader();
        return;
      }

      let _list = res.map((m, index) => ({
        "Exam Unit": subExamName,
        "CenterName": m.examCenterName,
        "Date": m.attendanceDateTime ? new Date(m.attendanceDateTime).toDateString() : "",
        "Qualified": m?.Status ? m.Status : "",

        // "Full Name":  m.data()["Full Name"],
        // "Attendance": (m.data().realTimePhoto && m.data().realTimePhoto !== "") ? "P" : "A",

        // "Applicant":  m.data().Applicant,
        // "MobileNo":   m.data()?.phone ? m.data()?.phone : "",
        // "Gender":     m.data().Gender,
        // "AppliedAs":  m.data()?.AppliedAs ? m.data()?.AppliedAs : "",
        // "City": m.data().City,

        // "sNo":        (index+1).toString(),
        // "Attendance": (m.data().realTimePhoto && m.data().realTimePhoto !== "") ? "P" : "A",
        // "Date":       m.data().examDate,
        // "ExamName":   examName ? examName : m.data().examName,
        // "AddedFromEXE": m.data().hasOwnProperty("addedFromEXE"),

        // previousSubExamHistory :  m.data()?.previousSubExamHistory ? m.data()?.previousSubExamHistory : [],

        // "AppPhoto":   m.data()?.Photo ? m.data().Photo : "",

        // [`${subExamName}_LivePhoto`]:  m.data()?.realTimePhoto ? m.data().realTimePhoto : "",

        // [`${subExamName}_FPPhoto1`]:    m.data()?.fingerprintImage1 ? m.data().fingerprintImage1 : "",
        // [`${subExamName}_FPPhoto2`]:    m.data()?.fingerprintImage2 ? m.data().fingerprintImage2 : "",

        // [`${subExamName}_idCardPhoto`]: m.data()?.idCardImage ? m.data().idCardImage : "",

        // [`${subExamName}_Attendance_Date_Time`]: (m.data()?.attendanceDateTime && m.data()?.attendanceDateTime !== "") ? new Date(m.data().attendanceDateTime)?.toLocaleString() : "",
      }));

      let keys = [
        "Exam Unit",
        "CenterName",
        "Date",
        "Qualified",

        // "Full Name",
        // "Attendance",

        // "City",
        // "ETPST_SNo",
        // "ETPSTScheduledDate",
        // "RunningMetre",
        // "AppliedAs",
        // "Applicant",
        // "MobileNo",
        // "DOB",
        // "Gender",
        // "Category",
        // "Reservation",
        // "OtherReservation",
        // "Year_of_Pass",
        // "Degree",
        // "Percentage",
        // "IsArmyGraduation",
        // "ET RESULT",
        // "PST RESULT",
        // "FINAL RESULT",
        // "EXS HEIGHT",
        // "REMARKS"
      ];

      let studentData = _list.map(m => ({ ...m }));

      let studentDataByExamCenter = uniqBy(studentData, "CenterName").map(m => studentData.filter(f => f.CenterName === m.CenterName));
      console.log("studentDataByExamCenter", studentDataByExamCenter)
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Data');
      worksheet.properties.defaultColWidth = 30;
      // worksheet.properties.defaultRowHeight = 100;

      worksheet.addRow(["", "", "Karnataka Sate Police"]).font = {
        bold: true,
      };
      worksheet.addRow([""]);


      studentDataByExamCenter.forEach(element => {
        let printData = uniqBy(element, "Date").map(m => ({ ...m, Qualified: element?.filter(f => f?.Date === m.Date).length }));

        console.log("printData =>", printData);

        worksheet.addRow(["", "", `Exam Name : ${examDetails.name}`]).font = {
          bold: true,
        };
        worksheet.addRow(["", "", `Exam Unit : ${subExamName}`]).font = {
          bold: true,
        };
        worksheet.addRow([""]);

        worksheet.addRow(keys).model.cells.forEach(ele => {
          let header = worksheet.getCell(ele.address);
          header.font = {
            bold: true,
            color: { argb: 'FFFFFFFF' }
          }
          header.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF478778' }
          }
        })
        printData.map((item) => {
          const temp = [];
          keys.map((ind) => {
            temp.push(item[ind]);
            return ind;
          });
          worksheet.addRow(temp);
          return item;
        });

        worksheet.addRow([""]);
        worksheet.addRow([""]);
        worksheet.addRow([""]);

      });


      //* Downloading Excel
      try {
        workbook.xlsx.writeBuffer().then((buffer) => {
          console.log("Downloading Start");
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', });
          fileSaver.saveAs(blob, `${subExamName} Qualified Report Sheets_${new Date().toDateString()}_${new Date().toLocaleTimeString()}.xlsx`);
          dismissLoader();
        }).catch((ee) => {
          errorToast("Something went wrong! Try again");
          dismissLoader();
          console.log("dd", ee)
        });
      } catch (e) {
        console.log(e);
        errorToast("Something went wrong! Try again");
        dismissLoader();
      }


    }).catch(err => {
      console.log(err);
      errorToast(err.toString());
      dismissLoader();
    })
  }

  async function downloadCandidateReportAsPerUnit() {
    presentLoader();
    // setStepperValueForResultSheet(0);
    // setStepperToggleForResultSheet(true);
    DB.students.where({ examID: examDetails.id, examCenterName: examCenterDetails.examCenterName, subExamID: subExamDetails.id }).toArray().then(res => {
      console.log("dexie", res)
      if (res.length === 0) {
        errorToast("Student data not found.");
        // setStepperToggleForResultSheet(false);
        // setStepperValueForResultSheet(0);
        // setStepperPhotoProgressValueForResultSheet(0);
        dismissLoader();
        return;
      }
      let _list = []
      let keys = []
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Data');
      if (JSON.parse(localStorage.getItem('user')).email === 'admin@gmail.com') {
        _list = res.map((m, index) => ({
          UID: m.id,
          "sNo": (index + 1).toString(),
          "Applicant": m.Applicant,
          "Full Name": m["Full Name"],
          "Gender": m.Gender,
          "Roll No": m["rollNo"] ? m["rollNo"] : '',
          "Room No": m["roomNo"] ? m["roomNo"] : '',
          "MobileNo": m.phone,
          "Attendance": (m.realTimeBase64 && m.realTimeBase64 !== "") ? "P" : "A",
          "Qualified Status": m.Status,
          [`Attendance_Date_Time`]: (m?.attendanceDateTime && m?.attendanceDateTime !== "") ? new Date(m.attendanceDateTime)?.toLocaleString() : "",
          "CenterName": m.examCenterName,
          "ExamName": m.subExamName,
          "AppPhoto": m?.Photo64 ? m.Photo64 : "",
          "LivePhoto": m?.realTimeBase64 ? m.realTimeBase64 : "",
          "FPPhoto1": m?.fingerprint1Base64 ? m.fingerprint1Base64 : "",
          "FPPhoto2": m?.fingerprint2Base64 ? m.fingerprint2Base64 : "",
          "idCardPhoto": m?.idCardBase64 ? m.idCardBase64 : "",
        }));

        keys = ["sNo", "Applicant", "Full Name", "Gender", "Roll No", "Room No", "MobileNo", "Attendance", "Qualified Status", "Attendance_Date_Time", "CenterName", "ExamName", "AppPhoto", "LivePhoto", "FPPhoto1", "FPPhoto2", "idCardPhoto"];
      } else {
        _list = res.map((m, index) => ({
          UID: m.id,
          "sNo": (index + 1).toString(),
          "Applicant": m.Applicant,
          "Full Name": m["Full Name"],
          "Gender": m.Gender,
          "rollNo": m["rollNo"] ? m["rollNo"] : '',
          "MobileNo": m.phone,
          "Attendance": (m.realTimeBase64 && m.realTimeBase64 !== "") ? "P" : "A",
          "Qualified Status": m.Status,
          [`Attendance_Date_Time`]: (m?.attendanceDateTime && m?.attendanceDateTime !== "") ? new Date(m.attendanceDateTime)?.toLocaleString() : "",
          "CenterName": m.examCenterName,
          "ExamName": m.subExamName,
          // "AppPhoto": m?.Photo64 ? m.Photo64 : "",
          // [`LivePhoto`]: m?.realTimeBase64 ? m.realTimeBase64 : "",
          // [`FPPhoto1`]: m?.fingerprint1Base64 ? m.fingerprint1Base64 : "",
          // [`FPPhoto2`]: m?.fingerprint2Base64 ? m.fingerprint2Base64 : "",

          // [`idCardPhoto`]: m?.idCardBase64 ? m.idCardBase64 : "",

        }));

        keys = ["sNo", "Applicant", "Full Name", "Gender", "Roll No", "Room No", "MobileNo", "Attendance", "Qualified Status", "Attendance_Date_Time", "CenterName", "ExamName"];
      }


      console.log("_list => ", _list);

      // console.log("studentHistoryData",studentHistoryData);
      console.log({ keys });
      worksheet.properties.defaultColWidth = 30;
      // worksheet.properties.defaultRowHeight = 100;

      worksheet.addRow(["", "", "", "Candidate Reports as per Unit"])
      worksheet.addRow([""])
      worksheet.addRow(["", "", "", `Exam Name--${examDetails.name}`])
      worksheet.addRow(["", "", "", `Exam Center--${examCenterName}`])
      worksheet.addRow([""])

      worksheet.getCell('D1').font = { bold: true, underline: true }

      worksheet.addRow(keys);
      _list.map((item) => {
        const temp = [];
        keys.map((ind) => {
          temp.push(item[ind]);
          return ind;
        });
        worksheet.addRow(temp);
        return item;
      });
      const row1 = worksheet.getRow(6);
      row1.model.cells.forEach((e) => {
        worksheet.getCell(e.address).font = { bold: true }
        worksheet.getCell(e.address).fill = { bgColor: { argb: "FF0000FF" } }
      })
      const row1Data = row1.model.cells.filter(f => {
        if (f.value.includes("AppPhoto") || f.value.includes("LivePhoto") || f.value.includes("FPPhoto1") || f.value.includes("FPPhoto2") || f.value.includes("idCardPhoto")) {
          return true;
        }
      }).map(m => ({ ...m, columnId: m.address.replace(/[^A-Za-z]/g, '') }))
      // console.log("row1",row1Data);

      let allImagesURLs = [];

      row1Data.forEach((rowData, rowIndex) => {
        let rawColumn = worksheet.getColumn(rowData.columnId);
        allImagesURLs.push(...rawColumn.values.slice(7).map((c, i) => ({
          url: c,
          cellId: `${rowData.columnId}${i + 7}`
        })));
      })

      worksheet.getRows(7, _list.length + 1).forEach(ele => {
        row1Data.forEach(ele1 => {
          ele.getCell(ele1.columnId).value = "";
          ele.height = 100;
        })
      })

      console.log("allImagesURLs length => ", allImagesURLs);
      // setStepperValueForResultSheet(1);
      // setStepperValueForResultSheet(2);
      allImagesURLs.forEach((ele, index) => {
        if (ele.url && ele.url !== "") {
          const imageId = workbook.addImage({
            base64: ele.url,
            extension: 'png',
          });
          worksheet.addImage(imageId, `${ele.cellId}:${ele.cellId}`);
        }
      });

      worksheet.addRow(["Total Present", _list.filter((e) => { return e.Attendance === 'P' }).length]).font = {
        bold: true,
      };
      worksheet.addRow(["Total Absent", _list.filter((e) => { return e.Attendance === 'A' }).length]).font = {
        bold: true,
      };
      //* Downloading Excel
      try {
        workbook.xlsx.writeBuffer().then((buffer) => {
          console.log("Downloading Start");
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', });
          fileSaver.saveAs(blob, `Candidate Report As Per Unit_${new Date().toDateString()}_${new Date().toLocaleTimeString()}.xlsx`);
          dismissLoader();
          // setStepperValueForResultSheet(3);
        }).catch((ee) => {
          errorToast("Something went wrong! Try again");
          // setStepperValueForResultSheet(0);
          // setStepperToggleForResultSheet(false);
          // setStepperPhotoProgressValueForResultSheet(0);
          dismissLoader();
          console.log("dd", ee)
        });
      } catch (e) {
        console.log(e);
        errorToast("Something went wrong! Try again");
        // setStepperValueForResultSheet(0);
        // setStepperToggleForResultSheet(false);
        dismissLoader();
      }
    }).catch(err => {
      console.log(err);
      errorToast(err.toString());
      // setStepperValueForResultSheet(0);
      // setStepperToggleForResultSheet(false);
      // setStepperPhotoProgressValueForResultSheet(0);
      dismissLoader();
    })

  }
  async function downloadStudentReportsWithPhotos() {
    presentLoader();
    // setStepperValueForResultSheet(0);
    // setStepperToggleForResultSheet(true);
    DB.students.where({ examID: examDetails.id, examCenterName: examCenterDetails.examCenterName, subExamID: subExamDetails.id }).toArray().then(res => {
      console.log("dexie", res)
      if (res.length === 0) {
        errorToast("Student data not found.");
        // setStepperToggleForResultSheet(false);
        // setStepperValueForResultSheet(0);
        // setStepperPhotoProgressValueForResultSheet(0);
        dismissLoader();
        return;
      }
      let _list = []
      let keys = []
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Data');
      // if (JSON.parse(localStorage.getItem('user')).email === 'admin@gmail.com') { // ! told to change by ranga
      _list = res.map((m, index) => ({
        UID: m.id,
        "sNo": (index + 1).toString(),
        "Applicant": m.Applicant,
        "Full Name": m["Full Name"],
        "Gender": m.Gender,
        "Room No": m["roomNo"] ? m["roomNo"] : '',
        "Roll No": m["rollNo"] ? m["rollNo"] : '',
        "MobileNo": m.phone,
        "Attendance": (m.realTimeBase64 && m.realTimeBase64 !== "") ? "P" : "A",
        "Qualified Status": m.Status,
        [`Attendance_Date_Time`]: (m?.attendanceDateTime && m?.attendanceDateTime !== "") ? new Date(m.attendanceDateTime)?.toLocaleString() : "",
        "CenterName": m.examCenterName,
        "ExamName": m.subExamName,
        "AppPhoto": m?.Photo64 ? m.Photo64 : "",
        "LivePhoto": m?.realTimeBase64 ? m.realTimeBase64 : "",
        "FPPhoto1": m?.fingerprint1Base64 ? m.fingerprint1Base64 : "",
        "FPPhoto2": m?.fingerprint2Base64 ? m.fingerprint2Base64 : "",
        "idCardPhoto": m?.idCardBase64 ? m.idCardBase64 : "",
      }));

      keys = ["sNo", "Applicant", "Full Name", "Gender", "Room No", "Roll No", "MobileNo", "Attendance", "Qualified Status", "Attendance_Date_Time", "CenterName", "ExamName", "AppPhoto", "LivePhoto", "FPPhoto1", "FPPhoto2", "idCardPhoto"];
      // } else {
      //   _list = res.map((m, index) => ({
      //     UID: m.id,
      //     "sNo": (index + 1).toString(),
      //     "Applicant": m.Applicant,
      //     "Full Name": m["Full Name"],
      //     "Gender": m.Gender,
      //     "rollNo": m["rollNo"] ? m["rollNo"] : '',
      //     "MobileNo": m.phone,
      //     "Attendance": (m.realTimeBase64 && m.realTimeBase64 !== "") ? "P" : "A",
      //     "Qualified Status": m.Status,
      //     [`Attendance_Date_Time`]: (m?.attendanceDateTime && m?.attendanceDateTime !== "") ? new Date(m.attendanceDateTime)?.toLocaleString() : "",
      //     "CenterName": m.examCenterName,
      //     "ExamName": m.subExamName,
      //     // "AppPhoto": m?.Photo64 ? m.Photo64 : "",
      //     // [`LivePhoto`]: m?.realTimeBase64 ? m.realTimeBase64 : "",
      //     // [`FPPhoto1`]: m?.fingerprint1Base64 ? m.fingerprint1Base64 : "",
      //     // [`FPPhoto2`]: m?.fingerprint2Base64 ? m.fingerprint2Base64 : "",

      //     // [`idCardPhoto`]: m?.idCardBase64 ? m.idCardBase64 : "",

      //   }));

      //   keys = ["sNo", "Applicant", "Full Name", "Gender", "rollNo", "MobileNo", "Attendance", "Qualified Status", "Attendance_Date_Time", "CenterName", "ExamName"];
      // }


      console.log("_list => ", _list);

      // console.log("studentHistoryData",studentHistoryData);
      console.log({ keys });
      worksheet.properties.defaultColWidth = 30;
      // worksheet.properties.defaultRowHeight = 100;

      worksheet.addRow(["", "", "", "Student Reports With Photos"])
      worksheet.addRow([""])
      worksheet.addRow(["", "", "", `Exam Name--${examDetails.name}`])
      worksheet.addRow(["", "", "", `Exam Center--${examCenterName}`])
      worksheet.addRow([""])

      worksheet.getCell('D1').font = { bold: true, underline: true }

      worksheet.addRow(keys);
      _list.map((item) => {
        const temp = [];
        keys.map((ind) => {
          temp.push(item[ind]);
          return ind;
        });
        worksheet.addRow(temp);
        return item;
      });
      const row1 = worksheet.getRow(6);
      row1.model.cells.forEach((e) => {
        worksheet.getCell(e.address).font = { bold: true }
        worksheet.getCell(e.address).fill = { bgColor: { argb: "FF0000FF" } }
      })
      const row1Data = row1.model.cells.filter(f => {
        if (f.value.includes("AppPhoto") || f.value.includes("LivePhoto") || f.value.includes("FPPhoto1") || f.value.includes("FPPhoto2") || f.value.includes("idCardPhoto")) {
          return true;
        }
      }).map(m => ({ ...m, columnId: m.address.replace(/[^A-Za-z]/g, '') }))
      // console.log("row1",row1Data);

      let allImagesURLs = [];

      row1Data.forEach((rowData, rowIndex) => {
        let rawColumn = worksheet.getColumn(rowData.columnId);
        allImagesURLs.push(...rawColumn.values.slice(7).map((c, i) => ({
          url: c,
          cellId: `${rowData.columnId}${i + 7}`
        })));
      })

      worksheet.getRows(7, _list.length + 1).forEach(ele => {
        row1Data.forEach(ele1 => {
          ele.getCell(ele1.columnId).value = "";
          ele.height = 100;
        })
      })

      console.log("allImagesURLs length => ", allImagesURLs);
      // setStepperValueForResultSheet(1);
      // setStepperValueForResultSheet(2);
      allImagesURLs.forEach((ele, index) => {
        if (ele.url && ele.url !== "") {
          const imageId = workbook.addImage({
            base64: ele.url,
            extension: 'png',
          });
          worksheet.addImage(imageId, `${ele.cellId}:${ele.cellId}`);
        }
      });

      worksheet.addRow(["Total Present", _list.filter((e) => { return e.Attendance === 'P' }).length]).font = {
        bold: true,
      };
      worksheet.addRow(["Total Absent", _list.filter((e) => { return e.Attendance === 'A' }).length]).font = {
        bold: true,
      };
      //* Downloading Excel
      try {
        workbook.xlsx.writeBuffer().then((buffer) => {
          console.log("Downloading Start");
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', });
          fileSaver.saveAs(blob, `Students Report List With Photos_${new Date().toDateString()}_${new Date().toLocaleTimeString()}.xlsx`);
          dismissLoader();
          // setStepperValueForResultSheet(3);
        }).catch((ee) => {
          errorToast("Something went wrong! Try again");
          // setStepperValueForResultSheet(0);
          // setStepperToggleForResultSheet(false);
          // setStepperPhotoProgressValueForResultSheet(0);
          dismissLoader();
          console.log("dd", ee)
        });
      } catch (e) {
        console.log(e);
        errorToast("Something went wrong! Try again");
        // setStepperValueForResultSheet(0);
        // setStepperToggleForResultSheet(false);
        dismissLoader();
      }
    }).catch(err => {
      console.log(err);
      errorToast(err.toString());
      // setStepperValueForResultSheet(0);
      // setStepperToggleForResultSheet(false);
      // setStepperPhotoProgressValueForResultSheet(0);
      dismissLoader();
    })

  }

  function syncData() {
    if (window.navigator.onLine) {
      setDownloadStudentProgressModalToggle(true);
      setDownloadStudentProgressValue(0);
      getAllStudents(examDetails.id, subExamDetails.id, examCenterDetails.examCenterName).then(async (res) => {
        console.log(res)
        if (res.length > 0) {
          getSubExamByDocID(examDetails.id, subExamDetails.id).then((resp) => {
            localStorage.setItem("subExam", JSON.stringify(resp.data));
            DB.allSubExams.update(resp.data.id, resp.data).then((resp) => {
              convertProforma()
            })
          })
          let students = []
          // res.forEach((ele, index) => {
          for (const ele of res) {
            // siddharth
            let data = ele.data()
            console.log(data)
            if (data?.isGroundNameChanged) {
              let arr = await DB.students.where({ id: `${JSON.parse(localStorage.getItem('subExam')).id}_${ele.id}` }).toArray()
              console.log(arr)
              if (arr.length === 0) {
                students.push({ ...ele.data(), examCenterCity: examCenterDetails.City, id: ele.id })
              }
            } else {
              students.push({ ...ele.data(), examCenterCity: examCenterDetails.City, id: ele.id })
            }
          }
          // })
          if (students.length > 0) {
            console.log(students)
            let _studentList = convertFirebaseObjToDataGridObj(students.map(m => ({ ...m, id: `${subExamDetails.id}_${m.id}` })), examShortName);
            console.log("_studentList0", _studentList);
            storeStudentDataInIndexDB1(students.map(m => ({ ...m, id: `${subExamDetails.id}_${m.id}`, examID: examDetails.id, subExamID: subExamDetails.id, examCenterID: examCenterDetails.id, isEdit: "false", subExamName: subExamName })), examDetails.id, subExamName, examCenterDetails.examCenterName);
            getPhotoBase64(students.map(m => m.id), examDetails.id, 'sync');
            setTotalStudentsCount(applicantsList.length)
            setDataGridLoading(true)
            setTimeout(() => {
              getApplicants()
            }, 5000);
          } else {
            getSubExamByDocID(examDetails.id, subExamDetails.id).then((resp) => {
              localStorage.setItem("subExam", JSON.stringify(resp.data));
              convertProforma('sync')
            })
          }
          // setApplicantsList(pre => [...pre, ..._studentList]);
        } else {
          getSubExamByDocID(examDetails.id, subExamDetails.id).then((resp) => {
            localStorage.setItem("subExam", JSON.stringify(resp.data));
            convertProforma('sync')
          })
        }
      })
    } else {
      errorToast("Please check your internet connectivity")
    }
  }

  return (
    <>
      <Box className={cssClasses.mainTabPanelWrapper}>
        <div className={cssClasses.mainTabPanelHeader}>
          <div>
            {/* <p>{examDetails.name} ({new Date(examDetails.examDate).toDateString()})</p> */}
            <Box>
              <div style={{
                display: 'flex',
                columnGap: '5rem',
                fontSize: '2rem',
                padding: '2rem'
              }}>
                <p>Total Students : {applicantsList.length}</p>
                <p>Verified Students : {applicantsList.filter((e) => { return e.isVerified === true }).length}</p>
                {subExamDetails && subExamDetails.isProforma &&
                  <p>Proforma Submitted Students : {submittedStudentsCount}</p>
                }
                <p>Uploaded Students : {uploadedStudentsCount}</p>
              </div>
            </Box>
            <Box>
              <p style={{ fontSize: "2rem", color: "red" }}>{errMsg}</p>
            </Box>
            {loginUser?.menuAccess && Object.keys(loginUser?.menuAccess).filter((ele) => { return loginUser?.menuAccess[ele] === true }).length > 0 &&
              <Box display={"flex"} flexDirection={"row"}>
                <IconButton
                  size="large"
                  edge="start"
                  aria-label="open drawer"
                  tittle={"Message"}
                  onClick={handleClick}
                >
                  <img src={moreVertical} alt="3 dots icon" />
                </IconButton>
                <input type="file" id="fileSelectForUploadStudent" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={uploadStudents} hidden />
                <input type="file" id="fileSelectForUploadStudentPhotos" accept="image/*" multiple onChange={uploadStudentPhotos} hidden />
                <Menu
                  id="basic-menu"
                  anchorEl={menuOpen}
                  open={open}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {loginUser?.menuAccess?.["Upload Offline Data"] &&
                    <MenuItem sx={[menuItem]}
                      onClick={() => { handleMenuClose(); uploadOfflineData() }}
                    >
                      <FileUploadIcon /> Upload Offline Data
                    </MenuItem>
                  }
                  {loginUser?.menuAccess?.["Add Students"] &&
                    <MenuItem sx={[menuItem]}
                      onClick={() => { handleMenuClose(); document.getElementById('fileSelectForUploadStudent').click() }}
                    >
                      <FileUploadIcon /> Add Students
                    </MenuItem>
                  }
                  {loginUser?.menuAccess?.["Add Student Photos"] &&
                    <MenuItem sx={[menuItem]}
                      onClick={() => { handleMenuClose(); document.getElementById('fileSelectForUploadStudentPhotos').click() }}
                    >
                      <FileUploadIcon /> Add Student Photos
                    </MenuItem>
                  }
                  {loginUser?.menuAccess?.["Download Local Saved Data"] &&
                    <MenuItem sx={[menuItem]}
                      onClick={() => { handleMenuClose(); downloadLocalSavedData() }}
                    >
                      <CloudDownloadIcon /> Download Local Saved Data
                    </MenuItem>
                  }
                  {loginUser?.menuAccess?.["Merge Data"] &&
                    <MenuItem sx={[menuItem]}
                      onClick={() => { handleMenuClose(); setDataMergeModalToggle(true) }}
                    >
                      <ConstructionIcon /> Merge Data
                    </MenuItem>
                  }
                  {/* <MenuItem sx={[menuItem]}
                  onClick={() => { handleMenuClose(); getDataForOfflineUse() }}
                >
                  <CloudDownloadIcon /> Get Data For Offline Use
                </MenuItem> */}
                  {loginUser?.menuAccess?.["Download Student Reports"] &&
                    <MenuItem sx={[menuItem]}
                      onClick={() => { handleMenuClose(); downloadResultExcelSheet() }}
                    >
                      <CloudDownloadIcon />Download Student Reports
                    </MenuItem>
                  }
                  {loginUser?.menuAccess?.["Download Present Student Reports"] &&
                    <MenuItem sx={[menuItem]}
                      onClick={() => { handleMenuClose(); downloadPresentResultExcelSheet("Present") }}
                    >
                      <CloudDownloadIcon />Download Present Student Reports
                    </MenuItem>
                  }
                  {loginUser?.menuAccess?.["Download Absent Student Reports"] &&
                    <MenuItem sx={[menuItem]}
                      onClick={() => { handleMenuClose(); downloadPresentResultExcelSheet("Absent") }}
                    >
                      <CloudDownloadIcon />Download Absent Student Reports
                    </MenuItem>
                  }
                  {loginUser?.menuAccess?.["Download Student Reports With Photos"] &&
                    <MenuItem sx={[menuItem]}
                      onClick={() => { handleMenuClose(); downloadStudentReportsWithPhotos() }}
                    >
                      <CloudDownloadIcon />Download Student Reports With Photos
                    </MenuItem>
                  }
                  {loginUser?.menuAccess?.["Download Final ResultSheet Reports"] &&
                    <MenuItem sx={[menuItem]}
                      onClick={() => { handleMenuClose(); downloadFinalResultSheet() }}
                    >
                      <CloudDownloadIcon />Download Final ResultSheet Reports
                    </MenuItem>
                  }
                  {loginUser?.menuAccess?.["Download Candidate Report As Per Unit"] &&
                    <MenuItem sx={[menuItem]}
                      onClick={() => { handleMenuClose(); downloadCandidateReportAsPerUnit() }}
                    >
                      <CloudDownloadIcon />Download Candidate Report As Per Unit
                    </MenuItem>
                  }
                  {isProforma && loginUser?.menuAccess?.["Qualified Report Sheet"] &&
                    <MenuItem sx={[menuItem]}
                      onClick={() => { handleMenuClose(); downloadDateWiseQualifiedCount() }}
                    >
                      <CloudDownloadIcon />{subExamName} Qualified Report Sheet
                    </MenuItem>
                  }
                  {loginUser?.menuAccess?.["Sync Data"] &&
                    <MenuItem sx={[menuItem]}
                      onClick={() => { handleMenuClose(); syncData() }}
                    >
                      <SyncIcon />Sync Data
                    </MenuItem>
                  }
                </Menu>
                {/* <div>
                <Button
                  clicked={uploadOfflineData}
                  btnName={"Upload Offline Data"}
                  style={{ color: "#FFFFFF", backgroundColor: "#7F56D9" }}
                />
              </div>
              <Box ml={"1rem"}>
                <Button
                  clicked={downloadLocalSavedData}
                  btnName={"Download local saved data"}
                  style={{ color: "#7F56D9", backgroundColor: "#FFFFFF" }}
                />
              </Box>
              <Box ml={"1rem"}>
                <Button
                  clicked={() => {
                    setDataMergeModalToggle(true);
                  }}
                  btnName={"Merge data"}
                  style={{ color: "#FFFFFF", backgroundColor: "#7F56D9" }}
                />
              </Box> */}
              </Box>
            }
          </div>
          {/* <div>
            <IconButton
            size="large"
            edge="start"
            aria-label="open drawer"
            tittle={"Message"}
            >
            <img src={moreVertical} alt="3 dots icon" />
            </IconButton>
          </div> */}
        </div>
        <div className={cssClasses.searchAndFilterWrapper}>
          {/* <div>
            <ToggleButtonGroup
              color="primary"
              value={searchField}
              onChange={(e) => { setSearchField(e.target.value); }}
              exclusive
              style={{ filter: "drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))" }}
            // onChange={handleChange}
            >
              <ToggleButton value="CandidateName" sx={[toggleButtonStyle]}>Name</ToggleButton>
              <ToggleButton value="RollNo" sx={[toggleButtonStyle]}>Roll Number</ToggleButton>
              <ToggleButton value="Applicant" sx={[toggleButtonStyle]}>Application Id</ToggleButton>
            </ToggleButtonGroup>
          </div> */}

          <div style={{ marginBottom: "0.5rem", marginRight: "1rem", display: "flex" }}>
            <div style={{ marginRight: "1rem" }}>
              <SearchBar
                id={"searchPrefix"}
                placeholder={"Prefix"}
                search={(_value) => { setSearchPrefix(_value); localStorage.setItem('searchPrefix', _value) }}
              />
            </div>
            <SearchBar
              placeholder={"Search Applicant"}
              search={(_value) => setStudentSearchValue(_value)}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "0.5rem" }}>
            {/* <div style={{ marginRight: "1rem" }}>
              <Button
                clicked={getDataForOfflineUse}
                btnName={"Get Data For Offline Use"}
                style={{ color: "#FFFFFF", backgroundColor: "#7F56D9" }}
              />
            </div> */}
            <div style={{ marginRight: "1rem" }}>
              <Button
                clicked={() => navigate(`/home/student-verify/${examShortName}/allSubExams/${subExamName}/allExamCenters/${examCenterName}/attendance`)}
                btnName={"Start Attendance"}
                style={{ color: "#FFFFFF", backgroundColor: "#7F56D9" }}
              />
            </div>
            {/* <div style={{ marginRight: "1rem" }}>
              <Button
                clicked={getDataForOfflineUse}
                btnName={"Get Data For Offline Use"}
                style={{ color: "#FFFFFF", backgroundColor: "#7F56D9" }}
              />
            </div> */}
          </div>
          {/* <div><Button style={{ color: "#344054", backgroundColor: "#FFFFFF" }} btnIcon={filterIcon} btnName={"Filters"} /></div> */}
        </div>

        <div className={cssClasses.examCenterWrapper}>
          <DataGrid
            rows={dataGridLoading ? [] : applicantsList.filter((e) => { return e.showInGrid == true })}
            columns={applicantColumns}
            hideFooterPagination={false}
            hideFooter={false}
            pageSize={10}
            page={pageMaintain}
            sx={[dataGridStyles]}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
            disableVirtualization
            loading={dataGridLoading}
            rowsPerPageOptions={[10]}
            // rowHeight={"auto"}
            onPageChange={(_page) => {
              console.log({ _page });
              setPageMaintain(_page)
              if (pageNumber < _page) {
                // * Next When Data need
                setPageNumber(_page);
                // getMoreData()
              } else {
                // *previous
              }
            }}
          />
        </div>
      </Box>
      {
        scannerToggle && (
          <QrAndBarcodeScanner
            open={scannerToggle}
            handleClose={() => { setScannerToggle(false) }}
            getCode={getStudentFromQr}
          />
        )
      }
      <ProgressImagesUpload open={openModelProgress}
        title={"Loading Model"}
        handleClose={() => setOpenModelProgress(false)}
        uploadingImages={modelProgress}
      />
      {
        openPhotosModal && <StudentPhotos open={openPhotosModal}
          handleClose={() => setOpenPhotosModal(false)} />
      }
      <ProgressImagesUpload
        open={openUploadProgress}
        title={"Uploading Students"}
        handleClose={() => setOpenUploadProgress(false)}
        uploadingImages={uploadProgress}
      />
      <DownloadStudentProgress
        open={downloadStudentProgressModalToggle}
        value={downloadStudentProgressValue}
        photosStatus={photosStatus}
        fingerprintPhotosStatus={fingerprintPhotosStatus}
        docsPhotosStatus={docsPhotosStatus}
        base64Status={base64Status}
        addStudentInLocalDb={addStudentInLocalDb}

        handleClose={() => {
          setDownloadStudentProgressModalToggle(false);
          setDownloadStudentProgressValue(0);
          setPhotosStatus(false);
          setFingerprintPhotosStatus(false);
          setDocsPhotosStatus(false);
          setBase64Status(false);
          setAddStudentInLocalDb(false);
        }}
      />

      {
        dataMergeModalToggle &&
        <DataMergeModal
          open={dataMergeModalToggle}
          handleClose={() => {
            setDataMergeModalToggle(false)
          }}
        />
      }
    </>
  );
}
function convertFirebaseObjToDataGridObj(_serverList, examShortName) {
  return _serverList.map(m => ({
    fileName: {
      id: m.id,
      img: m.hasOwnProperty("Photo") ? m?.Photo : m.hasOwnProperty("studentPhoto") ? m?.studentPhoto : "",
      name: m["Full Name"],
      email: m?.email || m?.Email,
      onErrorImg: man,
    },
    showInGrid: true,
    examName: examShortName ? examShortName : "",
    isVerified: false,
    ...m
  }))
}

function convertBase64ToFile(base64, fileName, key) {
  let fileObj = {};
  if (base64.includes("data:image")) {
    fileObj = {
      name: fileName,
      size: new Buffer(base64, 'base64').length,
      key: key + fileName,
      arrayBuffer: new Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64')
    }
  } else if (base64.includes("data:application")) {
    fileObj = {
      name: fileName,
      size: new Buffer(base64, 'base64').length,
      key: key + fileName,
      arrayBuffer: new Buffer.from(base64.replace(/^data:application\/\w+;base64,/, ""), 'base64')
    }
  }
  return fileObj
}

const uint8ToBase64 = (arr) => btoa(Array(arr.length).fill("").map((_, i) => String.fromCharCode(arr[i])).join(""));


// realTimeBase64
// fingerprint1Base64
// fingerprint2Base64
// idCardBase64

//* For Photos
async function getBase64FromImageUrlForPhotoPromise(array) {
  let resultArr = [];
  return await new Promise(async (resolve, reject) => {

    for (let item of array) {

      let reap = await Promise.all(item.map(m => getBase64FromImageUrlForPhoto(m)));

      resultArr = [...resultArr, ...reap];
    }
    resolve(resultArr);
  })
}
async function getBase64FromImageUrlForPhoto(object) {
  return new Promise((resolve, reject) => {

    if (object?.Photo && object.Photo !== "") {
      // let photoUrl = "https://cors-anywhere.herokuapp.com/" + process.env.REACT_APP_bucketAccessRootPath + object?.Photo;
      let photoUrl = process.env.REACT_APP_bucketAccessRootPath + object?.Photo;
      fetch(photoUrl).then((res) => res.arrayBuffer()).then(res => window.btoa([].slice.call(new Uint8Array(res)).map(function (bin) { return String.fromCharCode(bin) }).join(""))).then(res => {
        // console.log({data: res,photoUrl});
        resolve({ Photo64: res.includes("data:image") ? res : "data:image/png;base64," + res, ...object });
      }).catch(err => {
        console.log(err);
        resolve({ Photo64: "", ...object })
      })

    } else {
      resolve({ ...object });
    }

    //   var img = new Image();
    //   img.onload = function () {
    //     var canvas = document.createElement("canvas");
    //     canvas.width = img.width;
    //     canvas.height = img.height;
    //     var ctx = canvas.getContext("2d");
    //     ctx.drawImage(img, 0, 0);
    //     var dataURL = canvas.toDataURL("image/jpeg");
    //     // resolve(dataURL)
    //     resolve({ Photo64: dataURL, ...object })
    //   };
    //   img.onerror = function (err) {
    //     console.log(err,object?.Photo);
    //     resolve({ Photo64: "", ...object })
    //   }
    //   if (object?.Photo && object.Photo !== "") {
    //     img.crossOrigin = '';
    //     img.crossOrigin = 'anonymous'
    //     img.src = process.env.REACT_APP_bucketAccessRootPath + object?.Photo;
    //     console.log("url",process.env.REACT_APP_bucketAccessRootPath+object?.Photo);
    //   } else {
    //     resolve({ ...object });
    //   }
  })
}

//* For FingerPrint 1
async function getBase64FromImageUrlForFingerPrint1Promise(array) {
  let resultArr = [];
  return await new Promise(async (resolve, reject) => {

    for (let item of array) {

      let reap = await Promise.all(item.map(m => getBase64FromImageUrlForFingerPrint1(m)));

      resultArr = [...resultArr, ...reap];
    }
    resolve(resultArr);
  })
}
async function getBase64FromImageUrlForFingerPrint1(object) {
  return new Promise((resolve, reject) => {

    if (object?.fingerprintImage1 && object.fingerprintImage1 !== "") {
      // let photoUrl = "https://cors-anywhere.herokuapp.com/" + process.env.REACT_APP_bucketAccessRootPath + object?.fingerprintImage1;
      let photoUrl = process.env.REACT_APP_bucketAccessRootPath + object?.fingerprintImage1;
      fetch(photoUrl).then((res) => res.arrayBuffer()).then(res => window.btoa([].slice.call(new Uint8Array(res)).map(function (bin) { return String.fromCharCode(bin) }).join(""))).then(res => {
        // console.log({data: res.includes("data:image") ? res : "data:image/png;base64,"+res,photoUrl});
        resolve({ fingerprint1Base64: res.includes("data:image") ? res : "data:image/png;base64," + res, ...object });
      }).catch(err => {
        console.log(err);
        resolve({ fingerprint1Base64: "", ...object })
      })

    } else {
      resolve({ ...object });
    }
  })
}

//* For FingerPrint 2
async function getBase64FromImageUrlForFingerPrint2Promise(array) {
  let resultArr = [];
  return await new Promise(async (resolve, reject) => {

    for (let item of array) {

      let reap = await Promise.all(item.map(m => getBase64FromImageUrlForFingerPrint2(m)));

      resultArr = [...resultArr, ...reap];
    }
    resolve(resultArr);
  })
}
async function getBase64FromImageUrlForFingerPrint2(object) {
  return new Promise((resolve, reject) => {

    if (object?.fingerprintImage2 && object.fingerprintImage2 !== "") {
      // let photoUrl = "https://cors-anywhere.herokuapp.com/" + process.env.REACT_APP_bucketAccessRootPath + object?.fingerprintImage2;
      let photoUrl = process.env.REACT_APP_bucketAccessRootPath + object?.fingerprintImage2;
      fetch(photoUrl).then((res) => res.arrayBuffer()).then(res => window.btoa([].slice.call(new Uint8Array(res)).map(function (bin) { return String.fromCharCode(bin) }).join(""))).then(res => {
        // console.log({data: res,photoUrl});
        resolve({ fingerprint2Base64: res.includes("data:image") ? res : "data:image/png;base64," + res, ...object });
      }).catch(err => {
        console.log(err);
        resolve({ fingerprint2Base64: "", ...object })
      })

    } else {
      resolve({ ...object });
    }
  })
}

//* For Real Time photos
async function getBase64FromImageUrlForRealTimePromise(array) {
  let resultArr = [];
  return await new Promise(async (resolve, reject) => {

    for (let item of array) {

      let reap = await Promise.all(item.map(m => getBase64FromImageUrlForRealTime(m)));

      resultArr = [...resultArr, ...reap];
    }
    resolve(resultArr);
  })
}
async function getBase64FromImageUrlForRealTime(object) {
  return new Promise((resolve, reject) => {

    if (object?.realTimePhoto && object.realTimePhoto !== "") {
      // let photoUrl = "https://cors-anywhere.herokuapp.com/" + process.env.REACT_APP_bucketAccessRootPath + object?.realTimePhoto;
      let photoUrl = process.env.REACT_APP_bucketAccessRootPath + object?.realTimePhoto;
      fetch(photoUrl).then((res) => res.arrayBuffer()).then(res => window.btoa([].slice.call(new Uint8Array(res)).map(function (bin) { return String.fromCharCode(bin) }).join(""))).then(res => {
        // console.log({data: res,photoUrl});
        resolve({ realTimeBase64: res.includes("data:image") ? res : "data:image/png;base64," + res, ...object });
      }).catch(err => {
        console.log(err);
        resolve({ realTimeBase64: "", ...object })
      })

    } else {
      resolve({ ...object });
    }
  })
}

//* For Real ID card photos
async function getBase64FromImageUrlForIdCardPromise(array) {
  let resultArr = [];
  return await new Promise(async (resolve, reject) => {

    for (let item of array) {

      let reap = await Promise.all(item.map(m => getBase64FromImageUrlForIdCard(m)));

      resultArr = [...resultArr, ...reap];
    }
    resolve(resultArr);
  })
}
async function getBase64FromImageUrlForIdCard(object) {
  return new Promise((resolve, reject) => {

    if (object?.idCardImage && object.idCardImage !== "") {
      // let photoUrl = "https://cors-anywhere.herokuapp.com/" + process.env.REACT_APP_bucketAccessRootPath + object?.idCardImage;
      let photoUrl = process.env.REACT_APP_bucketAccessRootPath + object?.idCardImage;
      fetch(photoUrl).then((res) => res.arrayBuffer()).then(res => window.btoa([].slice.call(new Uint8Array(res)).map(function (bin) { return String.fromCharCode(bin) }).join(""))).then(res => {
        // console.log({data: res,photoUrl});
        resolve({ idCardBase64: res.includes("data:image") ? res : "data:image/png;base64," + res, ...object });
      }).catch(err => {
        console.log(err);
        resolve({ idCardBase64: "", ...object })
      })

    } else {
      resolve({ ...object });
    }
  })
}