import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { dismissLoader, presentLoader } from "../../services/loaderService";

// //* images
// import addIcon from "../../assets/images/add icon.svg";
// import filterIcon from "../../assets/images/filter-icon.svg";
// import crossArrow from "../../assets/images/cross arrow.svg";

//* import sub-component
// import Button from "../../Subcomponent/Button";
import SearchBar from "../../Subcomponent/SearchBar";

//* Import css
import cssClasses from "./SubExam.module.css";

//* import MUI
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { getSubExamByDocID, setSelectedSubExam } from "../../services/ExamService";
import { useParams } from "react-router-dom";

import { DB } from "../../db"
import { errorToast } from "../../services/Toast";
import WarningAlert from "../../Subcomponent/WarningAlert";
import { useDispatch, useSelector } from "react-redux";
import actions from '../../Redux Store/actions'
import { orderBy } from "lodash";

const dataGridStyles = {
  fontWeight: "400",
  fontSize: "1.4rem",
  lineHeight: "2rem",
  color: "#667085",
  fontFamily: 'Inter-Medium',

  '& div[data-colindex="1"]': {
    color: "#101828",
    fontWeight: "500",
  },
  '& 	.MuiDataGrid-columnHeaders': {
    background: "#F9FAFB",
  },
  '& .MuiDataGrid-columnSeparator': {
    display: "none"
  },
  "& .MuiDataGrid-checkboxInput": {
    "& svg": {
      width: "2rem",
      height: "2rem",
      background: "#FFFFFF",
    }
  },
  "& .MuiTablePagination-displayedRows": {
    display: "none"
  },
  "& [title='Go to previous page']:after": {
    content: "'Previous'",
    marginLeft: "1rem",
  },
  "& [title='Go to next page']:before": {
    content: "'Next'",
    marginRight: "1rem",
  },
  "& .MuiButtonBase-root": {
    borderRadius: '0'
  }
}
export default function SubExam() {
  let params = useParams();
  let examShortName = params.examShortName;
  const navigate = useNavigate();
  const loginUser = useSelector((state) => state.Auth.user);
  const dispatch = useDispatch()
  const columns = [
    { field: 'examName', headerName: 'Sub Exam Name', sortable: false, width: 250 },
    // { field: 'address', headerName: 'Address', sortable: false, width: 250 },
    // { field: 'capacity', headerName: 'Capacity', sortable: false, width: 100 },
    // { field: 'examCenterLocation', headerName: 'Exam Center Location', sortable: false, width: 175 },
    // { field: 'examDate', headerName: 'Exam Date', sortable: false, width: 150 },
    // { field: 'id', headerName: '', sortable: false, width: 70 },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setSelectedSubExam(params.row.examName);
          onClickView(params.row);
        };

        return <p onClick={onClick} style={{ color: "#6941C6", cursor: "pointer" }}>View Exam</p>;
      },
    },
  ]
  const [examCenterList, setExamCenterList] = useState([]);
  const [examId, setExamId] = useState(null)
  const [openWarningAlert, setOpenWarningAlert] = useState(false)
  const [localDataSubExamName, setLocalDataSubExamName] = useState("")
  const [currentSubExam, setCurrentSubExam] = useState(null);

  useEffect(() => {
    if (loginUser) {
      let _exam = JSON.parse(localStorage.getItem("exam"));
      if (_exam) {
        setExamId(_exam.id);
      } else {
        errorToast("Please Select Exam.");
        navigate("/home/student-verify");
        return;
      }
      if (loginUser?.examAccess?.find(f => f?.value === _exam?.id)?.subExamList?.filter(f => f.isAccess).length > 0) {
        // Promise.all(loginUser?.examAccess?.find(f => f?.value === _exam?.id)?.subExamList.filter(f => f.isAccess).map(m => getSubExamByDocID(_exam?.id, m.value))).then(res => {
        //   let _subExamList = res.filter(f => f.success).map(m => m.data);
        //   console.log(_subExamList);
        //   localStorage.setItem('allSubExams', JSON.stringify(_subExamList))
        //   setExamCenterList([..._subExamList]);
        // })
        DB.allSubExams.where({ examDocId: _exam?.id }).toArray((resp) => {
          console.log(resp, _exam?.id)
          let subexams = []
          for (const ele of resp) {
            if (loginUser?.examAccess?.find(f => f?.value === _exam?.id)?.subExamList?.filter(f => f.label === ele.examName)[0].isAccess) {
              subexams.push(ele)
            }
          }
          localStorage.setItem('allSubExams', JSON.stringify(subexams))
          let arr = orderBy(subexams, 'index')
          setExamCenterList([...arr]);
        })
      } else {
        errorToast("You don't have access to any sub-exam!");
      }
    }
  }, [loginUser])


  const SearchHandler = (_value) => {
    console.log(_value);
  }

  function generateModels() {
    presentLoader()
    axios.post("http://192.168.2.62:5000/createModel", { "examId": examId }).then((resp) => {
      if (resp) {
        console.log(resp)
        dismissLoader()
      }
    })
  }

  async function onClickView(_subExam) {
    setCurrentSubExam(_subExam)
    // let subExamFromLocal = JSON.parse(localStorage.getItem('subExam'))
    // let studentDB = await DB.students.toArray();
    // let _Students = await DB.students.where({ examID: examId, subExamID: _subExam.id }).toArray();
    // // let studentsToUpdate = await DB.students.where({ isEdit: "true" }).toArray();
    // // if (subExamFromLocal?.id !== _subExam.id && studentsToUpdate?.length > 0) {
    // if (studentDB.length > 0 && _Students.length == 0 && _subExam.id !== subExamFromLocal.id && examId === subExamFromLocal.examId) {
    //   // setLocalDataSubExamName(studentsToUpdate[0].subExamName)
    //   // setOpenWarningAlert(true)
    //   // } else if (subExamFromLocal?.id !== _subExam.id && studentsToUpdate?.length === 0) {
    //   //   if (window.navigator.onLine) {
    //   //     DB.students.clear()
    //   //     DB.facenetArr.clear()
    //   //     DB.base64.clear()
    //   //     dispatch(actions.FaceNet(null));
    //   //     goToExamCenter(_subExam)
    //   //   } else {
    //   //     errorToast("Offline data not downloaded for this subExam! Please connect to internet and try again")
    //   //   }
    //   // } else {
    //   localStorage.setItem('modelUrl', 'local')
    //   let _exam = JSON.parse(localStorage.getItem('exam'))
    //   let _subExam = JSON.parse(localStorage.getItem('subExam'))
    //   let _Students = await DB.students.where({ examID: _exam.id, subExamID: _subExam.id }).toArray();
    //   _Students.forEach(obj => {

    //     let currentSubExamHistory = {
    //       subExamName: obj.subExamName,
    //       fingerprintImage1: obj?.fingerprint1Base64 ? `exam-management/${obj.id.split('_')[1]}/fingerprint/${obj.examID}_${obj.examName}_${obj.subExamName}_${obj.id.split('_')[1]}_fingerprint1.png` : "",
    //       fingerprintImage2: obj?.fingerprint2Base64 ? `exam-management/${obj.id.split('_')[1]}/fingerprint/${obj.examID}_${obj.examName}_${obj.subExamName}_${obj.id.split('_')[1]}_fingerprint2.png` : "",
    //       realTimePhoto: obj?.realTimeBase64 ? `exam-management/${obj.id.split('_')[1]}/photos/${obj.examID}_${obj.examName}_${obj.subExamName}_${obj.id.split('_')[1]}_realTimePhoto.png` : "",
    //       idCardImage: obj?.idCardBase64 ? `exam-management/${obj.id.split('_')[1]}/idcard/${obj.examID}_${obj.examName}_${obj.subExamName}_${obj.id.split('_')[1]}_idCardImage.png` : "",
    //       attendanceDateTime: obj?.attendanceDateTime ? obj.attendanceDateTime : ""
    //     }
    //     if (obj.hasOwnProperty("previousSubExamHistory") && Array.isArray(obj.previousSubExamHistory)) {
    //       obj.previousSubExamHistory.push(currentSubExamHistory);
    //     } else {
    //       obj.previousSubExamHistory = [currentSubExamHistory];
    //     }

    //     let base64Obj = {
    //       id: obj.id.split('_')[1],
    //       [obj.subExamName]: {
    //         realTimePhoto: obj.realTimeBase64,
    //         idCardImage: obj.idCardBase64,
    //         fingerprintImage1: obj.fingerprint1Base64,
    //         fingerprintImage2: obj.fingerprint2Base64
    //       }
    //     }

    //     updateBase64Object(base64Obj)

    //     obj.id = `${currentSubExam.id}_${obj.id.split('_')[1]}`
    //     obj.subExamID = currentSubExam.id
    //     obj.subExamName = currentSubExam.examName
    //     delete obj.ET;
    //     delete obj.attendanceDateTime;
    //     delete obj.ET_IsQualified;
    //     delete obj.ET_height_height;
    //     delete obj.ET_height_remarks;
    //     delete obj.ET_height_result;
    //     delete obj.PST_IsQualified;
    //     delete obj.PST;
    //     delete obj.chest_no;
    //     delete obj.dataURL;
    //     delete obj.examType;
    //     delete obj.idCardBase64;
    //     delete obj.idCardImage;
    //     delete obj.invoice;
    //     delete obj.lastUpdateAt;
    //     delete obj.name;
    //     delete obj.realTimeBase64;
    //     delete obj.realTimePhoto;
    //     delete obj.selectedID_Card;
    //     delete obj.showInGrid;
    //     delete obj.uploadedForm;
    //     delete obj.checkingOfficer;
    //     delete obj.checkingOfficerDesignation;
    //     delete obj.isSubmitted;
    //     obj.Status = "pending"
    //     obj.isVerified = false
    //   });
    //   presentLoader()
    //   DB.students.bulkAdd(_Students).then(resp => {
    //     goToExamCenter(currentSubExam)
    //     dismissLoader()
    //   })
    // } else {
    // localStorage.setItem('modelUrl', 'online')
    goToExamCenter(_subExam)
    // }
  }

  // const updateBase64Object = (studentObject) => new Promise((resolve, reject) => {
  //   DB.base64.update(studentObject.id, studentObject).then(res => {
  //     if (res) {
  //       resolve(res);
  //     } else {
  //       DB.base64.add(studentObject).then(res2 => {
  //         resolve(res2);
  //       }).catch(error => {
  //         reject(error);
  //       })
  //     }
  //   }).catch(err => {
  //     if (err.toString().includes("Cannot convert undefined or null to object")) {
  //       DB.base64.add(studentObject).then(res => {
  //         resolve(res);
  //       }).catch(error => {
  //         reject(error);
  //       })
  //     } else {
  //       reject(err);
  //     }
  //   })
  // })

  async function goToExamCenter(_subExam) {
    _subExam.examId = examId
    localStorage.setItem("subExam", JSON.stringify(_subExam));
    console.log(_subExam);
    navigate('/home/student-verify/' + examShortName + '/allSubExams/' + _subExam.examName + '/allExamCenters/')
  }

  return (
    <>
      <Box className={cssClasses.mainTabPanelWrapper}>
        <div className={cssClasses.mainTabPanelHeader}>
          <div>
            <p>Sub Exams</p>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> */}
          </div>
          {/* <div>
            <div><Button  style={{ color: "#344054", backgroundColor: "#FFFFFF" }} btnIcon={crossArrow} btnName={"Move\xa0Students"} clicked={() => {setShiftStudentsModalToggle(true);}}/></div>
            <div><Button  style={{ color: "#FFFFFF", backgroundColor: "#7F56D9" }} btnIcon={addIcon} btnName={"Add\xa0Exam\xa0Center"}  clicked={() => {setAddExamCenterModalToggle(true);}}/></div>
          </div> */}
        </div>

        <div className={cssClasses.searchAndFilterWrapper}>
          {/* <div>
            <ToggleButtonGroup
              color="primary"
              value={'View All'}
              exclusive
              style={{filter: "drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))"}}
              // onChange={handleChange}
            >
              <ToggleButton value="View All" sx={[toggleButtonStyle]}>View All</ToggleButton>
              <ToggleButton value="By Exam date" sx={[toggleButtonStyle]}>By Exam date</ToggleButton>
              <ToggleButton value="By etc" sx={[toggleButtonStyle]}>By etc</ToggleButton>
            </ToggleButtonGroup>
          </div> */}
          {/* <div>
            <div><SearchBar placeholder={"Search"} search={SearchHandler} /></div> */}
          {/* <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Generate Models"} clicked={generateModels} /></div> */}
          {/* </div> */}
        </div>

        <div className={cssClasses.examCenterWrapper}>
          <DataGrid
            rows={examCenterList}
            columns={columns}
            hideFooterPagination={false}
            hideFooter={false}
            pageSize={10}
            // checkboxSelection
            sx={[dataGridStyles]}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
            disableVirtualization
            rowsPerPageOptions={[10]}
          />
        </div>

      </Box>

      {openWarningAlert &&
        <WarningAlert currentSubExam={currentSubExam} openAlert={openWarningAlert} handleClose={() => { setOpenWarningAlert(false) }} goToExamCenter={goToExamCenter} SubExamName={localDataSubExamName} />
      }

      {/* * for Exam Center Add */}
      {/* <AddExamCenter open={addExamCenterModalToggle} handleClose={() => {setAddExamCenterModalToggle(false);}} /> */}


      {/* <ShiftStudentsModal open={shiftStudentsModalToggle} handleClose={() => {setShiftStudentsModalToggle(false)}}/> */}
    </>
  )
}
