import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";

async function WriteFile(_arr_data) {
  return Filesystem.writeFile({
    path: "attendanceOfflineData.json",
    data: _arr_data,
    directory: Directory.Documents,
  });
}

async function readFile() {
  return await Filesystem.readFile({
    path: "attendanceOfflineData.json",
    directory: Directory.Documents,
    encoding: Encoding.UTF8,
  });
}

async function downloadFile(file, fileName) {
  return Filesystem.writeFile({
    path: `${fileName}`,
    data: file,
    directory: Directory.Documents,
  });
}

function isNativePlatform() {
  return Capacitor.isNativePlatform();
}

export { WriteFile, readFile, downloadFile, isNativePlatform };