import React, { useState, useEffect, useCallback } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "../../Subcomponent/Button";
import cssClasses from "./CacheModal.module.css"
import crossImage from "../../assets/images/x.svg"
import { DB } from "../../db";
import { groupBy } from 'lodash'
import { dismissLoader, presentLoader } from "../../services/loaderService";
import { errorToast, successToast } from "../../services/Toast";

export default function CacheModal({ open, handleClose }) {

    const [allExams, setAllExams] = useState(null)

    useEffect(() => {
        presentLoader()
        DB.students.toArray().then((resp) => {
            console.log(resp)
            if (resp.length > 0) {
                let group = groupBy(resp, 'examName')
                let newarr = []
                Object.keys(group).forEach((ele, index) => {
                    newarr.push({ examName: ele, isSelected: false, key: index + 1 })
                    if (Object.keys(group).length === index + 1)
                        setAllExams(newarr)
                })
                dismissLoader()
            } else {
                dismissLoader()
                handleClose()
                errorToast("No Data Present")
            }
        })
    }, [])

    function clear() {
        let arr = allExams.filter((e) => e.isSelected)
        console.log(arr)
        if (arr.length > 0) {
            presentLoader()
            arr.forEach((element, index) => {
                DB.students.where({ examName: element.examName }).delete().then((resp) => {
                    console.log(resp)
                    DB.base64.where({ examName: element.examName }).delete().then((res) => {
                        console.log(res)
                        if (index === arr.length - 1) {
                            successToast(`Cache cleared`)
                            dismissLoader()
                            handleClose()
                        }
                    })
                })
            });
        } else {
            errorToast("No exams selected")
        }
    }

    return (
        <Modal open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={[
                {
                    ".MuiBackdrop-root": {
                        background: "rgba(193, 201, 210, 0.7)"
                    }
                }
            ]}
            disableAutoFocus={true}
        >
            <Box className={cssClasses.modalWrapper}>
                <div className={cssClasses.header}>
                    <h1>Clear Cache</h1>
                    <img src={crossImage} alt="" style={{ cursor: "pointer" }} onClick={handleClose} />
                </div>
                <div className={cssClasses.mainContainer}>
                    {allExams &&
                        allExams.map((element, index) => (
                            <div style={{ display: "flex", alignItems: "center", fontSize: "1.6rem", marginBottom: "1rem" }}>
                                <input type="checkbox" name="" id="" onChange={(e) => { setAllExams((pre) => { pre[index].isSelected = e.target.checked; return [...pre] }) }} />
                                <p style={{ marginLeft: "1rem" }}>{element.examName}</p>
                            </div>
                        ))
                    }
                </div>
                <div className={cssClasses.footer}>
                    <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Cancel"} clicked={handleClose} /></div>
                    <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Clear"} clicked={clear} /></div>
                </div>
            </Box>
        </Modal>
    )
}
