//* firebase
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";
import { collection, doc, getDoc, getDocs, getFirestore } from "firebase/firestore";
import action from "../Redux Store/actions/index";
import store from "../Redux Store/store";
import { errorToast } from "./Toast";

function saveUser(_user) {
  store.dispatch(
    action.authLogin(_user)
  );
}
function getUser() {
  let _user = localStorage.getItem("user");
  return JSON.parse(_user);
}
function getUserData(id) {
  return new Promise(async (resolve, reject) => {
    const auth = getAuth();
    const user = await auth.currentUser
    const userRef = doc(getFirestore(), 'admins', id);
    getDoc(userRef).then((resp) => {
      const loggedInUser = resp.data();
      loggedInUser.id = resp.id;
      // console.log('loggedInUser: ', loggedInUser);
      saveUser(loggedInUser);
      resolve(loggedInUser);
    }).catch((err) => {
      console.log('err: ', err);
      reject(err);
    });
  });
}
function getAdmins() {
  const adminsref = collection(getFirestore(), 'admins');
  getDocs(adminsref).then((resp) => {
    console.log('resp: ', resp);
  }).catch((err) => {
    console.log('err: ', err);
  });
}
async function loginWithFirebase(email, password) {
  const auth = getAuth();
  let login = await signInWithEmailAndPassword(auth, email, password);
  return login;
}

async function forgetPasswordFirebase(email) {
  const auth = getAuth();
  return await sendPasswordResetEmail(auth, email);
}

async function isAuthenticated() {
  const auth = getAuth();
  const user = await auth.currentUser
  console.log(user);
}
function signout() {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    signOut(auth).then(res=>{
      console.log(res);
      store.dispatch(action.authLogout());
      resolve(res);
    }).catch(err=>{
      console.log(err);
      reject(err);
    }) 
  }) 
}

function getAllExams() {
  return new Promise((resolve, reject) => {
    const examsref = collection(getFirestore(), 'exams');
    getDocs((examsref)).then((resp) => {
      resolve(resp)
    }).catch((err) => {
      console.log(err);
      errorToast("Something went wrong please try again later")
    })
  })
}

export { saveUser, getUser, loginWithFirebase, forgetPasswordFirebase, isAuthenticated, signout, getUserData, getAdmins, getAllExams };
