import cssClasses from "./VerifyStudent.module.css";
import Webcam from "react-webcam";
import { errorToast } from "../../services/Toast"
import React, { useState, useCallback, useEffect } from "react";
import Button from "../../Subcomponent/Button";
import ScanImg from '../../assets/images/scan.png'
import { dismissLoader, presentLoader } from "../../services/loaderService";
import { DB } from "../../db";
import verifiedIcon from '../../assets/images/verified-icon.svg'
import BarcodeScannerComponent from "react-qr-barcode-scanner";

export default function VerifyStudent({ facenetObj, sendFaceID, sendQrResult, setCurrentTab, currentTab, isVisible }) {
    const faceRef = React.useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isCameraFound, setIsCameraFound] = useState(true);
    const [deviceId, setDeviceId] = React.useState({});
    const [devices, setDevices] = React.useState([]);
    const handleDevices = useCallback(mediaDevices => setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")), [setDevices]);
    const [result, setResult] = useState('')
    const [student, setStudent] = useState(null)

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }, [handleDevices]);

    useEffect(() => {
        setResult('')
    }, [isVisible])

    const cameraError = async (err) => {
        console.log(err);
        const { state } = await window.navigator.permissions.query({ name: 'camera' });
        if (state === "denied") {
            errorToast("Please allow to access your camera")
        }
        setIsLoading(false);
        setIsCameraFound(false);
    }

    const cameraFound = (res) => {
        if (devices.map(m => ({ label: m.label, value: m.deviceId })).some(s => s.label === "" || s.value === "")) {
            navigator.mediaDevices.enumerateDevices().then(handleDevices);
            setIsCameraFound(res.active);
        } else {
            setIsCameraFound(res.active);
            setIsLoading(false);
        }
    }

    function verify() {
        if (result === 'Candidate Found') {
            if (student && student.isVerified !== true) {
                sendFaceID(student.id);
                // setStudent((pre)=>{
                //     pre.realTimeBase64 = 
                let subExam = JSON.parse(localStorage.getItem('subExam'))
                DB.students.update(student.id, student).then((resp) => {
                    // successToast("Student data stored locally")
                    // setStudent(null)
                    setResult('')
                })
            } else {
                errorToast("Student Attendance already completed")
                setStudent(null)
                setResult('')
            }
        } else {
            errorToast("Student Not Found")
            setStudent(null)
            setResult('')
        }
        // })
        // realTimeBase64
    }

    function startFaceRecognition() {
        presentLoader()
        const imageSrc = faceRef.current.getScreenshot();
        console.log(imageSrc)
        const img = document.createElement("img");
        img.src = imageSrc
        img.id = 'image'
        if ((!facenetObj) || (facenetObj && !facenetObj?.model)) {
            errorToast("Model Not generated! please generate model and try again")
            dismissLoader()
        } else {
            facenetObj.runFaceDetection(img).then((result) => {
                if (result.includes('No Face detected') || result.includes('Face not found in database')) {
                    console.log(result)
                    setResult(result)
                    dismissLoader()
                } else {
                    console.log(result);
                    let subExam = JSON.parse(localStorage.getItem('subExam'))
                    DB.students.get(`${subExam.id}_${result}`).then((resp) => {
                        const img = document.createElement("img");
                        img.src = imageSrc
                        img.id = 'image'
                        facenetObj.cropImage(img, window.location.hostname === '' ? '../' : '/facenet-data').then((res) => {
                            resp.realTimeBase64 = res
                            // resp.realTimePhotoCaptureTime = new Date()
                            setStudent(resp)
                            console.log(resp)
                            dismissLoader()
                            setResult('Candidate Found')
                        })
                    })
                }
            })
        }
    }

    return (
        <div className={cssClasses.mainContainer} style={{ visibility: isVisible ? "hidden" : "unset", height: isVisible ? "0" : "100%" }}>
            <div style={{ display: "flex" }} className={cssClasses.header}>
                <div onClick={() => setCurrentTab('face')}>
                    <div className={cssClasses.buttonWrapper} style={{ background: currentTab == 'face' ? 'rgb(127, 86, 217)' : '', color: currentTab == 'face' ? 'white' : 'black' }}>
                        <div className={cssClasses.button}>
                            <p className={cssClasses.btnText}>Face Recognition</p>
                        </div>
                    </div>
                </div>
                <div onClick={() => setCurrentTab('qr')}>
                    <div className={cssClasses.buttonWrapper1} style={{ background: currentTab == 'qr' ? 'rgb(127, 86, 217)' : '', color: currentTab == 'qr' ? 'white' : 'black' }}>
                        <div className={cssClasses.button}>
                            <p className={cssClasses.btnText}>Scan Qr Code</p>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ padding: "3.2rem", display: "flex", justifyContent: "center" }}>
                {currentTab == 'face' ?
                    <div className={cssClasses.faceDiv}>
                        <div className={cssClasses.webcamDiv}>
                            <Webcam
                                audio={false}
                                ref={faceRef}
                                screenshotFormat="image/jpeg"
                                className={cssClasses.videoCamera}
                                onUserMediaError={cameraError}
                                onUserMedia={cameraFound}
                                videoConstraints={{ deviceId: deviceId }}
                            />
                            {result != '' &&
                                <div className={cssClasses.candidateInfo}>
                                    {(result.includes('No Face detected') || result.includes('Face not found in database')) ?
                                        <div>
                                            <div className={cssClasses.verifiedWrapper}>
                                                <img src={verifiedIcon} />
                                                < p className={cssClasses.candidateMatched} >Candidate Not Found</p>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div className={cssClasses.verifiedWrapper}>
                                                <img src={verifiedIcon} />
                                                <div>
                                                    <p className={cssClasses.candidateMatched} >Candidate Matched</p>
                                                </div>
                                            </div>
                                            <div className={cssClasses.flexDiv} style={{ marginTop: "1.6rem" }}>
                                                <img style={{ width: "5rem", height: "5rem", borderRadius: "50%" }} src={student.Photo64} />
                                                <div className={cssClasses.marginLeft}>
                                                    <p style={{ fontSize: "1.4rem", fontWeight: "600" }}>{student["Full Name"]}</p>
                                                    <div className={cssClasses.flexDiv}>
                                                        <p className={cssClasses.info1}>{student.email}</p>
                                                        <p style={{ marginLeft: "1.2rem" }} className={cssClasses.info2}>{student.Applicant}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div>
                                <Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={result == '' ? "Verify" : "Retry"} clicked={startFaceRecognition} />
                            </div>
                        </div>
                    </div>
                    :
                    <div className={cssClasses.webcamDiv}>
                        <BarcodeScannerComponent
                            // width={500}
                            // height={500}
                            onError={cameraError}
                            onUpdate={(err, result) => {
                                cameraFound(true);
                                if (result) sendQrResult(result.text);
                            }}
                            videoConstraints={{ deviceId: deviceId }}
                        />
                        <img src={ScanImg} className={cssClasses.scanImg} alt="" />
                    </div>
                }
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }} className={cssClasses.footer}>
                {currentTab == 'face' &&
                    <div>
                        <Button disable={currentTab == 'face' && result == ''} style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Confirm to Proceed"} clicked={verify} />
                    </div>
                }
            </div>
        </div >
    )
}
