/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "../../Subcomponent/Button";
import Modal from "@mui/material/Modal";
import Webcam from "react-webcam";
import cssClasses from "./uploadImageModal.module.css";
import CircularProgress from '@mui/material/CircularProgress';

import Dropdown from "../../Subcomponent/Dropdown";

import cameraNotFoundImage from "../../assets/images/cameraNotFound.jpg"
import crossImage from "../../assets/images/x.svg"

export default function UploadImageModal({ open, handleClose, picture }) {
  const webcamRef = React.useRef(null);
  const [image, setImage] = React.useState("");
  const [sendImage, setsendImage] = React.useState();

  const [isLoading, setIsLoading] = useState(true);
  const [isCameraFound, setIsCameraFound] = useState(true);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    const file = dataURLtoFile(imageSrc, "hello.png");
    console.log(file);
    setsendImage(file);
  }, [webcamRef]);

  function send() {
    picture(image);
    ModalClose();
  }
  const cameraError = (err) => {
    console.log(err);
    setIsLoading(false);
    setIsCameraFound(false);

  }
  const cameraFound = (res) => {
    // console.log(res);
    setIsCameraFound(res.active);
    setIsLoading(false);
  }
  function dataURLtoFile(dataurl, filename) {
    // for convert base64 str to file object
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const ModalClose = () => {
    setImage("")
    handleClose();
  }

  const [deviceId, setDeviceId] = React.useState({});
  const [devices, setDevices] = React.useState([]);

  const handleDevices = useCallback(mediaDevices => setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")), [setDevices]);
  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  useEffect(() => {
    console.log(devices);
    if (devices.length > 0) {
      setDeviceId(devices[0].deviceId)
    }
  }, [devices])


  return (
    <Modal
      open={true}
      onClose={ModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={[
        {
          visibility: open ? "visible" : "hidden",
          ".MuiBackdrop-root": {
            background: "rgba(193, 201, 210, 0.7)"
          }
        }
      ]}
      disableAutoFocus={true}
    >
      <Box className={cssClasses.modalWrapper}>
        <div className={cssClasses.header}>
          <h1>Capture : </h1>
          <img src={crossImage} alt="" style={{ cursor: "pointer" }} onClick={ModalClose} />
        </div>

        <div className={cssClasses.mainContainer}>
          {isLoading && <div className={cssClasses.Loader}><CircularProgress /></div>}
          {isCameraFound && image === "" &&
            <>
              {!isLoading &&
                <>
                  <p>Camera</p>
                  <Dropdown
                    list={devices.map(m => ({ label: m.label, value: m.deviceId }))}
                    selectedValue={deviceId}
                    onChangeSelect={(value) => { setDeviceId(value) }}
                  />
                  <p></p>
                </>
              }
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                className={cssClasses.videoCamera}
                onUserMediaError={cameraError}
                onUserMedia={cameraFound}
                videoConstraints={{ deviceId: deviceId }}
              />
            </>
          }
          {
            !isCameraFound && !isLoading && <img src={cameraNotFoundImage} alt="" style={{ width: "100%" }} />
          }
          {image !== "" &&
            <>
              <p>Preview</p>
              <img src={image} alt="" />
            </>
          }
        </div>

        <div className={cssClasses.footer}>
          {image === "" && !isLoading && isCameraFound && <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Capture"} clicked={capture} /></div>}
          {image !== "" && !isLoading && isCameraFound && <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Save"} clicked={send} /></div>}
          {image !== "" && !isLoading && isCameraFound && <div><Button style={{ color: "#ffffff", backgroundColor: "#7F56D9" }} btnName={"Recapture"} clicked={() => setImage("")} /></div>}
        </div>

      </Box>
    </Modal>
  );
}